import {
  CreateTaskBody,
  ExtendedTask,
  TaskSearchResults,
  TaskUpdateResponse,
  UpdateTaskBody,
} from "@parallel/vertex/types/task.types";
import { BaseAPI } from "@/api/base.api";

export class TaskAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public searchTasks = (providerId: string): Promise<TaskSearchResults> =>
    this.instance.get("task", { params: { providerId } }).then(r => r.data);

  public createTask = (body: CreateTaskBody): Promise<ExtendedTask> =>
    this.instance.post("task", body).then(r => r.data);

  public updateTask = (taskId: string, body: UpdateTaskBody): Promise<TaskUpdateResponse> =>
    this.instance.put(`task/${taskId}`, body).then(r => r.data);
}
