import { z } from "zod";
import { ApprovalStatusValues } from "./prisma.enums";

export const approvalStatusEnum = z.enum(ApprovalStatusValues);

export const APPROVAL_STATUS_NAMES = {
  APPROVED: "Approved",
  DENIED: "Denied",
  PENDING: "Pending",
  FOR_REVIEW: "Provider Review",
};

export const ALL_APPROVAL_TYPES = ["APPOINTMENT", "TIME"] as const;
