import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { ExtendedReportTestUpload } from "@parallel/vertex/types/report.types";
import { ReportContentLayout } from "@/components/report/ReportContent";
import BulkUploadInput from "@/components/report/data/upload/BulkUploadInput";
import CustomUploadInput from "@/components/report/data/upload/CustomUploadInput";
import SkipTestPrompt from "@/components/report/data/upload/SkipTestPrompt";
import TestUploadRow from "@/components/report/data/upload/TestUploadRow";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";

type UploadModal = "bulk" | "custom";

const ReportTestsAdministered = () => {
  const {
    reportStore: { currentReport: report },
  } = useContext(StoreContext);

  const [uploadModal, setUploadModal] = useState<UploadModal>();
  const [skippingUpload, setSkippingUpload] = useState<ExtendedReportTestUpload>();

  const clearModal = () => {
    setUploadModal(undefined);
    setSkippingUpload(undefined);
  };

  if (!report) return <></>;

  if (!report.formSubmissions.testingPlan.hasAnswers) {
    return (
      <ReportContentLayout title="Tests Administered">
        <Typography variant="body1">Must submit testing plan before uploading test results</Typography>
      </ReportContentLayout>
    );
  }

  return (
    <ReportContentLayout
      title="Tests Administered"
      actions={
        <Button startIcon={<UploadIcon />} onClick={() => setUploadModal("bulk")}>
          Bulk Upload
        </Button>
      }
    >
      <Stack gap={1} pb={2}>
        {report.testUploads.map((upload, i) => (
          <>
            {i !== 0 && upload.fileName && report.testUploads[i - 1].fileName && <Divider />}
            <TestUploadRow upload={upload} onSkip={() => setSkippingUpload(upload)} key={upload.reportTestUploadId} />
          </>
        ))}
      </Stack>

      <Button onClick={() => setUploadModal("custom")} startIcon={<AddIcon />}>
        Add Custom Test Scores
      </Button>

      <CenterModal isOpen={!!uploadModal || !!skippingUpload} onClose={clearModal}>
        {uploadModal === "bulk" && <BulkUploadInput onFinished={clearModal} />}
        {uploadModal === "custom" && <CustomUploadInput onClose={clearModal} />}
        {skippingUpload && <SkipTestPrompt upload={skippingUpload} onFinished={clearModal} />}
      </CenterModal>
    </ReportContentLayout>
  );
};

export default observer(ReportTestsAdministered);
