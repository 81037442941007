import type { LanguageType, Provider } from "@prisma/client";
import { z } from "zod";
import { ContractTypeValues, LanguageTypeValues, ProviderTypeValues } from "../../enums/prisma.enums";
import { Override, paginateQueryFields, sortQueryFields } from "../shared.types";
import { ExtendedUser } from "./user.types";

export type ExtendedProviderUser = Override<
  ExtendedUser &
    Omit<Provider, "providerId"> & {
      studentIds: string[];
      languages: LanguageType[];
      districts: { id: string; name: string }[];
      studentDistricts: { id: string; name: string }[];
    },
  // ! Override for serialization
  {
    npiNumber?: string | null;
    personalEmail?: string | null;
    primaryState?: string | null;
    residenceState?: string | null;
    serviceStatus?: string | null;
    surflyAgentId?: string | null;
  }
>;

const providerTypeEnum = z.enum(ProviderTypeValues);

export const searchProviderQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  facilitatorId: z.string().uuid().optional(),
  districtUserId: z.string().uuid().optional(),
  assignedStudentIds: z.string().uuid().array().optional(),
  eligibleStudentIds: z.string().uuid().array().optional(),
  providerType: providerTypeEnum.optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchProviderQuery = z.infer<typeof searchProviderQuerySchema>;

export const createProviderBodySchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email().trim().toLowerCase(),
  providerType: providerTypeEnum,
  languages: z.enum(LanguageTypeValues).array().min(1),
  contractType: z.enum(ContractTypeValues),
  districtIds: z.string().uuid().array().optional(),
});

export type CreateProviderBody = z.infer<typeof createProviderBodySchema>;

export const updateProviderBodySchema = createProviderBodySchema.partial();

export type UpdateProviderBody = z.infer<typeof updateProviderBodySchema> & { surflyAgentId?: string };
