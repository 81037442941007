import { useNavigate, useSearchParams } from "react-router";

type UrlNavigation = {
  navigate: (toUrl: string, options?: { keepQuery?: boolean; query?: Record<string, string> }) => void;
  navigateBack: () => void;
  query: Record<string, string>;
  setQuery: (q: Record<string, string>) => void;
};

export const useUrlNavigation = (): UrlNavigation => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    navigate: (toUrl, { keepQuery, query } = {}) => {
      const keptQuery = keepQuery ? Object.fromEntries(searchParams) : {};
      const queryParams = { ...query, ...keptQuery };
      const newParams = new URLSearchParams(queryParams);
      navigate(keepQuery ? `${toUrl}?${newParams}` : toUrl);
    },
    navigateBack: () => navigate(-1),
    query: Object.fromEntries(searchParams),
    setQuery: setSearchParams,
  };
};
