import { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";
import { FullGrid } from "@parallel/polygon/components/shared/layout/container";
import { displayInputFieldStyles } from "@parallel/polygon/util/style.util";
import { FRIENDLY_PROVIDER_TYPES } from "@parallel/vertex/enums/user.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { getStudentPermissions } from "@parallel/vertex/role/role.student";
import { SingleStudentUser, StudentService } from "@parallel/vertex/types/user/student.types";
import StudentServices from "@/components/user/student/StudentServices";
import { StudentEditColumn } from "@/components/user/student/form/StudentEditForm";
import StudentInfoInput from "@/components/user/student/form/StudentInfoInput";
import StudentGoalSummary from "@/components/user/student/progress/StudentGoalSummary";
import { StoreContext } from "@/stores";
import { getInitialStudentParams } from "@/util/student.form.util";

const SectionHeader = ({ text, onEdit }: { text: string; onEdit?: () => void }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    height={40}
    width="100%"
    px={1}
    borderBottom={1}
    borderColor="grey.300"
  >
    <Typography variant="h3">{text}</Typography>
    {onEdit && (
      <Button onClick={onEdit} startIcon={<EditIcon />}>
        Edit
      </Button>
    )}
  </Stack>
);

const StudentInfo = ({
  student,
  onEdit,
  onServiceArchive,
}: {
  student: SingleStudentUser;
  onEdit: (c: StudentEditColumn) => void;
  onServiceArchive: (service: StudentService) => void;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const roleAllowsEdit = !!getStudentPermissions(currentUser, "write");
  const canEdit = roleAllowsEdit && !student.archivedAt;

  return (
    <FullGrid container pt={2}>
      <Grid size={{ xs: 4 }} pr={2} pb={2}>
        <SectionHeader text="Personal Info" onEdit={canEdit ? () => onEdit("info") : undefined} />
        <Stack gap={3} py={3} sx={displayInputFieldStyles}>
          <StudentInfoInput readonly params={getInitialStudentParams(student)} setParams={noop} />
        </Stack>

        <SectionHeader
          text="Providers"
          onEdit={canEdit && hasRoleFlag(currentUser, "edit-related-providers") ? () => onEdit("providers") : undefined}
        />
        <Stack pt={2} pb={3}>
          {student.providers.map(provider => (
            <Stack p={1} key={provider.userId}>
              <Typography variant="body1">{provider.fullName}</Typography>
              <Typography variant="body2" color="grey.700">
                {FRIENDLY_PROVIDER_TYPES[provider.providerType]}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <SectionHeader text="Facilitators" onEdit={canEdit ? () => onEdit("facilitators") : undefined} />
        <Stack pt={2}>
          {student.facilitators.map(facilitator => (
            <Stack p={1} key={facilitator.userId}>
              <Typography variant="body1">{facilitator.fullName}</Typography>
              <Typography variant="body2" color="grey.700">
                {facilitator.email}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>

      <Grid size={{ xs: 4 }} px={2} pb={2}>
        <SectionHeader text="Parallel Services" onEdit={canEdit ? () => onEdit("services") : undefined} />
        <StudentServices student={student} onArchive={onServiceArchive} />
      </Grid>

      <Grid size={{ xs: 4 }} pl={2} pb={2}>
        <SectionHeader text="Goals Summary" onEdit={canEdit ? () => onEdit("goals") : undefined} />
        <StudentGoalSummary student={student} />
      </Grid>
    </FullGrid>
  );
};

export default StudentInfo;
