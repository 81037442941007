import { UserType } from "@prisma/client";
import { DateTime } from "luxon";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type ProgressPermissions = {
  accessLevels: AccessLevel[];
  canWriteForAppointment: (
    appointment: { startTime: DateTime },
    previousPayPeriod?: { startTime: DateTime },
  ) => boolean;
};

export const PROGRESS_ROLE_GRANTS: Partial<Record<UserType, ProgressPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    canWriteForAppointment: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    canWriteForAppointment: (appointment, previousPayPeriod) =>
      !previousPayPeriod || appointment.startTime < previousPayPeriod.startTime,
  },
};

export const getProgressPermissions = getDataPermissionResolver(PROGRESS_ROLE_GRANTS);
