import { useContext } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { isNull, isNumber } from "lodash";
import { DateTime } from "luxon";
import { getObjectiveTitle, ObjectiveDisplayIndices, useListItemColorSx } from "@parallel/polygon/util/progress.util";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import { getMetricNumberString } from "@parallel/vertex/util/progress.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import ProgressListItemLayout from "@/components/user/student/progress/ProgressListItemLayout";
import { StoreContext } from "@/stores";

export type ObjectiveItemProps = {
  objective: StudentObjective;
  displayIndices: ObjectiveDisplayIndices;
  goal: StudentGoal;
  isLink?: boolean;
};

const ObjectiveListItem = ({
  objective,
  displayIndices,
  goal,
  isLink,
  averageMetricValue,
}: ObjectiveItemProps & { averageMetricValue?: number | null }) => {
  const {
    progressStore: { updateGoal, currentStudentProgress },
  } = useContext(StoreContext);

  const colorSx = useListItemColorSx(objective, "objective", isLink);

  const targetMetricString = isNumber(objective.targetValue) && getMetricNumberString(objective, objective.targetValue);

  const averageMetricString = isNumber(averageMetricValue)
    ? getMetricNumberString(objective, averageMetricValue)
    : isNull(averageMetricValue)
      ? "-"
      : undefined;

  const box = (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 1,
        cursor: isLink ? "pointer" : undefined,
        ...colorSx,
      }}
    >
      <ProgressListItemLayout
        item={objective}
        title={getObjectiveTitle(objective, displayIndices)}
        actions={
          <ObjectiveActions
            objective={objective}
            goal={goal}
            displayIndices={displayIndices}
            stackProps={{ justifyContent: "space-between" }}
          />
        }
        data={filterExists([
          { left: "Started:", right: objective.createdAt.toLocaleString(DateTime.DATE_SHORT) },
          { left: "Achieved:", right: objective.completedAt?.toLocaleString(DateTime.DATE_SHORT) || "-" },
          targetMetricString && { left: "Target Metric:", right: targetMetricString },
          averageMetricString && { left: "Average Metric:", right: averageMetricString },
          {
            left: "Latest Metric:",
            right: objective.latestMetric?.collectedAt.toLocaleString(DateTime.DATE_SHORT) || "-",
          },
        ])}
        endIcon={isLink ? <ArrowRightIcon /> : undefined}
        onPinnedToggle={() =>
          updateGoal(goal.goalId, {
            objectives: [{ objectiveId: objective.objectiveId, isPinned: !objective.isPinned }],
          })
        }
      />
    </Box>
  );

  if (!isLink) return box;

  return (
    <Link
      href={
        currentStudentProgress &&
        `/user/student/${currentStudentProgress.userId}/goals/${goal.goalId}/objective/${objective.objectiveId}`
      }
      sx={{ textDecoration: "none", color: "text.primary" }}
    >
      {box}
    </Link>
  );
};

export default ObjectiveListItem;
