import { AdminFileKey } from "@parallel/vertex/enums/report.file.enums";
import { InterviewFormType } from "@parallel/vertex/enums/report.org.enums";
import { ApprovalStatus } from "@parallel/vertex/types/approval.types";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import {
  CreateReportBody,
  CreateReportEligibilityBody,
  CreateReportNeedGroupBody,
  CustomBlock,
  ExtendedAssessmentEnrollment,
  ExtendedEligibility,
  ExtendedReport,
  Need,
  Recommendation,
  ReportApproval,
  ReportNeedGroup,
  ReportSectionCustom,
  ReportTestUploadIncludeReason,
  ReportTestUploadSkipReason,
  ReportTestUploadSource,
  SearchAssessmentEnrollmentQuery,
  SearchRecommendationsQuery,
  SearchReportQuery,
  SearchTestUploadSourcesQuery,
  SingleReport,
  UpdateReportBlockBody,
  UpdateReportSectionBody,
  UpdateReportUploadBody,
  UpdateTestingPlanBody,
  ExtendedReportInterviewee,
  BlockImage,
} from "@parallel/vertex/types/report.types";
import { PaginatedResult, ReorderRequestBody } from "@parallel/vertex/types/shared.types";
import { UpdateStudentBody } from "@parallel/vertex/types/user/student.types";
import { BaseAPI } from "@/api/base.api";
import config from "@/config";

export type ReportTestFileUploadRequest = {
  sourceId: string;
  file: File;
  includeReasonId?: string;
};

type InterviewNotesParams = {
  reportId: string;
  interviewType: InterviewFormType;
};

export class ReportAPI extends BaseAPI {
  constructor() {
    super({
      parseTimestamps: true,
      parseQueryNulls: true,
      baseURL: config.nexusReportServerUrl,
    });
  }

  public searchAssessmentEnrollments = (
    params: SearchAssessmentEnrollmentQuery,
  ): Promise<PaginatedResult<ExtendedAssessmentEnrollment>> =>
    this.instance.get("assessment/report/enrollment", { params }).then(r => r.data);

  public getAssessmentEnrollment = (serviceLineClientId: string): Promise<ExtendedAssessmentEnrollment> =>
    this.instance.get(`assessment/report/enrollment/${serviceLineClientId}`).then(r => r.data);

  public getReport = (reportId: string): Promise<SingleReport> =>
    this.instance.get(`assessment/report/${reportId}`).then(r => r.data);

  public createReport = (body: CreateReportBody): Promise<SingleReport> =>
    this.instance.post("assessment/report/", body).then(r => r.data);

  public searchReports = (query: SearchReportQuery): Promise<PaginatedResult<ExtendedReport>> =>
    this.instance.get("assessment/report/", { params: query }).then(r => r.data);

  public updateReportStudent = (reportId: string, body: UpdateStudentBody): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/student`, body).then(r => r.data);

  public updateTestingPlan = (reportId: string, body: UpdateTestingPlanBody): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/testing-plan`, body).then(r => r.data);

  public createInterviewNotesSubmission = (
    { interviewType, reportId }: InterviewNotesParams,
    intervieweeName?: string,
  ) =>
    this.instance
      .post(`assessment/report/${reportId}/interview-form/${interviewType}`, { intervieweeName })
      .then(r => r.data);

  public submitInterviewNotesSubmission = (
    { interviewType, reportId }: InterviewNotesParams,
    submissionId: string,
    formAnswers: FormAnswers,
  ): Promise<SingleReport> =>
    this.instance
      .put(`assessment/report/${reportId}/interview-form/${interviewType}/${submissionId}`, { formAnswers })
      .then(r => r.data);

  public updateIntervieweeName = (
    { interviewType, reportId }: InterviewNotesParams,
    submissionId: string,
    intervieweeName: string,
  ): Promise<ExtendedReportInterviewee> =>
    this.instance
      .put(`assessment/report/${reportId}/interview-form/${interviewType}/${submissionId}/interviewee`, {
        intervieweeName,
      })
      .then(r => r.data);

  public sendInterviewEmail = (
    { interviewType, reportId }: InterviewNotesParams,
    submissionId: string,
    toEmail: string,
  ): Promise<ExtendedReportInterviewee> =>
    this.instance
      .post(`assessment/report/${reportId}/interview-form/${interviewType}/${submissionId}/email`, { toEmail })
      .then(r => r.data);

  public deleteInterviewNotesSubmission = (
    { interviewType, reportId }: InterviewNotesParams,
    submissionId: string,
  ): Promise<SingleReport> =>
    this.instance
      .delete(`assessment/report/${reportId}/interview-form/${interviewType}/${submissionId}`)
      .then(r => r.data);

  public uploadTestFiles = (reportId: string, files: ReportTestFileUploadRequest[]): Promise<SingleReport> => {
    const formData = new FormData();
    files.forEach(({ file, sourceId, includeReasonId }) =>
      formData.append(includeReasonId ? `${sourceId}~${includeReasonId}` : sourceId, file, file.name),
    );
    return this.instance.post(`assessment/report/${reportId}/test`, formData).then(r => r.data);
  };

  public updateTestUpload = (
    reportId: string,
    testUploadId: string,
    body: UpdateReportUploadBody,
  ): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/test/${testUploadId}`, body).then(r => r.data);

  public deleteTestUpload = (reportId: string, testUploadId: string): Promise<SingleReport> =>
    this.instance.delete(`assessment/report/${reportId}/test/${testUploadId}`).then(r => r.data);

  public getTestUploadFileUrl = (reportId: string, testUploadId: string): Promise<string> =>
    this.instance.get(`assessment/report/${reportId}/test/${testUploadId}/file`).then(r => r.data.url);

  public upsertCustomSection = (
    reportId: string,
    sectionTemplateId: string,
    body: UpdateReportSectionBody,
  ): Promise<ReportSectionCustom> =>
    this.instance.put(`assessment/report/${reportId}/section/${sectionTemplateId}`, body).then(r => r.data);

  public updateSubsectionOrder = (
    reportId: string,
    sectionTemplateId: string,
    body: ReorderRequestBody,
  ): Promise<void> =>
    this.instance.post(`assessment/report/${reportId}/section/${sectionTemplateId}/subsection-order`, body);

  public upsertCustomBlock = (
    reportId: string,
    blockTemplateId: string,
    body: UpdateReportBlockBody,
  ): Promise<CustomBlock> =>
    this.instance.put(`assessment/report/${reportId}/block/${blockTemplateId}`, body).then(r => r.data);

  public uploadBlockImages = (reportId: string, blockTemplateId: string, files: File[]): Promise<BlockImage[]> => {
    const formData = new FormData();
    files.forEach(file => formData.append(file.name, file));
    return this.instance
      .post(`assessment/report/${reportId}/block/${blockTemplateId}/image`, formData)
      .then(r => r.data.images);
  };

  public searchRecommendations = (params: SearchRecommendationsQuery): Promise<PaginatedResult<Recommendation>> =>
    this.instance.get("assessment/report/recommendation", { params }).then(r => r.data);

  public createReportEligibility = (
    reportId: string,
    eligibilityId: string,
    body: CreateReportEligibilityBody,
  ): Promise<SingleReport> =>
    this.instance.post(`assessment/report/${reportId}/eligibility/${eligibilityId}`, body).then(r => r.data);

  public deleteReportEligibility = (reportId: string, eligibilityId: string): Promise<SingleReport> =>
    this.instance.delete(`assessment/report/${reportId}/eligibility/${eligibilityId}`).then(r => r.data);

  public createReportNeedGroups = (reportId: string, groups: CreateReportNeedGroupBody[]): Promise<SingleReport> =>
    this.instance.post(`assessment/report/${reportId}/need/group`, { groups }).then(r => r.data);

  public updateNeedGroupContent = (reportId: string, groupId: string, content: string): Promise<ReportNeedGroup> =>
    this.instance.put(`assessment/report/${reportId}/need/group/${groupId}`, { content }).then(r => r.data);

  public updateNeedGroupOrder = (reportId: string, body: ReorderRequestBody): Promise<void> =>
    this.instance.post(`assessment/report/${reportId}/need/group/order`, body);

  public deleteNeedGroup = (reportId: string, groupId: string): Promise<SingleReport> =>
    this.instance.delete(`assessment/report/${reportId}/need/group/${groupId}`).then(r => r.data);

  public uploadSignature = (reportId: string, file: File): Promise<{ url: string }> => {
    const formData = new FormData();
    formData.append("signature", file);
    return this.instance.post(`assessment/report/${reportId}/signature`, formData).then(r => r.data);
  };

  public deleteSignature = (reportId: string): Promise<void> =>
    this.instance.delete(`assessment/report/${reportId}/signature`);

  public buildReport = (reportId: string): Promise<{ url: string }> =>
    this.instance.post(`assessment/report/${reportId}/build`).then(r => r.data);

  public updateReportApproval = (reportId: string, status: ApprovalStatus): Promise<ReportApproval> =>
    this.instance.put(`assessment/report/${reportId}/approval`, { status }).then(r => r.data);

  public deleteReport = (reportId: string): Promise<void> => this.instance.delete(`assessment/report/${reportId}`);

  public getProtocolFile = (fileKey: AdminFileKey, studentId: string): Promise<string> =>
    this.instance.get(`assessment/report/protocol/${fileKey}/student/${studentId}`).then(r => r.data.url);

  public searchTestUploadSources = (params: SearchTestUploadSourcesQuery = {}): Promise<ReportTestUploadSource[]> =>
    this.instance.get("assessment/report/test/source", { params }).then(r => r.data.sources);

  public getAllTestUploadIncludeReasons = (): Promise<ReportTestUploadIncludeReason[]> =>
    this.instance.get("assessment/report/test/include-reason").then(r => r.data.reasons);

  public getAllTestUploadSkipReasons = (): Promise<ReportTestUploadSkipReason[]> =>
    this.instance.get("assessment/report/test/skip-reason").then(r => r.data.reasons);

  public getAllEligibilities = (): Promise<ExtendedEligibility[]> =>
    this.instance.get("assessment/report/eligibility").then(r => r.data.eligibilities);

  public getAllNeeds = (): Promise<Need[]> => this.instance.get("assessment/report/need").then(r => r.data.needs);
}
