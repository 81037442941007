import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useDebouncedProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { ReportEditorBlock } from "@parallel/vertex/types/report.types";
import TiptapInput from "@/components/report/editor/content/TiptapInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("BlockContentInput", getLoggerContext);

const BlockContentInput = ({
  block,
  areCommentsExpanded,
  setAreCommentsExpanded,
}: {
  block: ReportEditorBlock;
  areCommentsExpanded: boolean;
  setAreCommentsExpanded: (areExpanded: boolean) => void;
}) => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport, canEdit, upsertCustomBlock },
  } = useContext(StoreContext);

  const { reportBlockTemplateId } = block;
  const { status: saveStatus, perform: upsertCustomContent } = useDebouncedProcessStatus(
    (content: string) =>
      upsertCustomBlock(reportBlockTemplateId, { content }).catch(logger.handleFailureAndThrow("upsertCustomContent")),
    { saveKey: reportBlockTemplateId },
  );

  const uploadImages = async (files: File[]) => {
    if (!currentReport) return [];
    const images = await reportApi.uploadBlockImages(currentReport.reportId, block.reportBlockTemplateId, files);
    return images.map(i => ({ url: i.url, key: i.reportBlockImageId }));
  };

  return (
    <TiptapInput
      content={block.custom?.content || block.content || undefined}
      placeholder={block.placeholder || undefined}
      saveContent={upsertCustomContent}
      saveStatus={saveStatus}
      uploadImages={uploadImages}
      areCommentsExpanded={areCommentsExpanded}
      setAreCommentsExpanded={setAreCommentsExpanded}
      disabled={!canEdit}
    />
  );
};

export default observer(BlockContentInput);
