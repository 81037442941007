import {
  AssessmentBookMetadata,
  AssessmentBookRawData,
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
  CreateAssessessmentTestBody,
  UpdateAssessmentTest,
} from "@parallel/vertex/types/assessment.types";
import { BaseAPI } from "./base.api";

export class StimulusAPI extends BaseAPI {
  public getAllRawBooks = (): Promise<AssessmentBookRawData[]> =>
    this.instance.get(`/assessment/stimulus/book`).then(r => r.data);

  public setBookHidden = (bookId: string, isHidden: boolean): Promise<AssessmentBookRawData> =>
    this.instance.put(`/assessment/stimulus/book/${bookId}`, { isHidden }).then(r => r.data);

  public createBook = (book: AssessmentBookRawData): Promise<AssessmentBookRawData> =>
    this.instance.post(`/assessment/stimulus/book`, book).then(r => r.data);

  public getBookMetadata = (bookId: string): Promise<AssessmentBookMetadata> =>
    this.instance.get(`/assessment/stimulus/book/${bookId}/metadata`).then(r => r.data);

  public createTest = (bookId: string, test: CreateAssessessmentTestBody): Promise<AssessmentBookMetadata> =>
    this.instance.post(`/assessment/stimulus/book/${bookId}/test`, test).then(r => r.data);

  public updateTests = (bookId: string, tests: UpdateAssessmentTest[]): Promise<AssessmentBookMetadata> =>
    this.instance.put(`/assessment/stimulus/book/${bookId}/test`, { tests }).then(r => r.data);

  public deleteTest = (bookId: string, testId: string): Promise<AssessmentBookMetadata> =>
    this.instance.delete(`/assessment/stimulus/book/${bookId}/test/${testId}`).then(r => r.data);

  public createDisplayGroup = (
    bookId: string,
    group: Partial<AssessmentDisplayGroupData>,
  ): Promise<AssessmentDisplayGroupData> =>
    this.instance.post(`/assessment/stimulus/book/${bookId}/display-group`, group).then(r => r.data);

  public createConfigGroup = (
    bookId: string,
    group: Partial<AssessmentConfigGroupData>,
  ): Promise<AssessmentConfigGroupData> =>
    this.instance.post(`/assessment/stimulus/book/${bookId}/config-group`, group).then(r => r.data);

  public updateDisplayGroup = (
    bookId: string,
    groupId: string,
    update: Partial<AssessmentDisplayGroupData>,
  ): Promise<AssessmentDisplayGroupData> =>
    this.instance.put(`/assessment/stimulus/book/${bookId}/display-group/${groupId}`, update).then(r => r.data);

  public updateConfigGroup = (
    bookId: string,
    groupId: string,
    update: Partial<AssessmentConfigGroupData>,
  ): Promise<AssessmentConfigGroupData> =>
    this.instance.put(`/assessment/stimulus/book/${bookId}/config-group/${groupId}`, update).then(r => r.data);

  public deleteDisplayGroup = (bookId: string, groupId: string): Promise<AssessmentBookMetadata> =>
    this.instance.delete(`/assessment/stimulus/book/${bookId}/display-group/${groupId}`).then(r => r.data);

  public deleteConfigGroup = (bookId: string, groupId: string): Promise<AssessmentBookMetadata> =>
    this.instance.delete(`/assessment/stimulus/book/${bookId}/config-group/${groupId}`).then(r => r.data);

  public uploadImage = (bookId: string, testId: string, imageData: Blob): Promise<File> => {
    const file = new File([imageData], `${testId}.png`, { type: "image/png" });
    const formData = new FormData();
    formData.append("uploadFile", file, file.name);
    return this.instance.post(`assessment/stimulus/book/${bookId}/image`, formData).then(() => file);
  };
}
