import { useContext, useState } from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty, isNull, isUndefined, uniq } from "lodash";
import { v4 as uuid } from "uuid";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { CreateReportNeedGroupBody } from "@parallel/vertex/types/report.types";
import { mapExists, removeAtIndex } from "@parallel/vertex/util/collection.util";
import { getCommaList } from "@parallel/vertex/util/string.util";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import MultipleSelectInput from "@/components/shared/input/MultipleSelectInput";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type RecommendationParams = {
  key: string;
  needIds: string[];
  recommendations: (SelectOption | null)[];
};

const logger = initLogger("AddRecommendationInput", getLoggerContext);

const AddRecommendationInput = ({ onFinished }: { onFinished: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { needs, createReportNeedGroups },
  } = useContext(StoreContext);

  const needOptions = mapExists(needs, n => ({ key: n.needId, label: n.name }));

  const [params, setParams] = useState<RecommendationParams[]>([]);
  const [selectedParamKey, setSelectedParamKey] = useState<string>();

  const selectedParam = params.find(p => p.key === selectedParamKey);

  const updateSelectedParam = (update: Partial<RecommendationParams>) => {
    if (!selectedParam) return;
    const updatedParams = params.map(p => (p.key === selectedParamKey ? { ...p, ...update } : p));
    setParams(updatedParams);
  };

  const setRecommendationParam = (selection: SelectOption | null, index: number) => {
    if (!selectedParam) return;
    const updated = selectedParam.recommendations.map((r, i) => (i === index ? selection : r));
    updateSelectedParam({ recommendations: updated });
  };

  const addRecommendationParam = () => {
    const newKey = uuid();
    setParams([...params, { key: newKey, needIds: [], recommendations: [] }]);
    setSelectedParamKey(newKey);
  };

  const removeCurrentNeed = () => {
    const removeIndex = params.findIndex(p => p.key === selectedParamKey);
    if (removeIndex === -1) return;

    const [updatedParams, newSelectedIndex] = removeAtIndex(params, removeIndex);
    const newSelectedKey = !isUndefined(newSelectedIndex) ? params[newSelectedIndex]?.key : undefined;

    setParams(updatedParams);
    setSelectedParamKey(newSelectedKey);
  };

  const removeRecommendationAtIndex = (index: number) => {
    if (!selectedParam) return;
    const updatedRecommendations = selectedParam.recommendations.flatMap((r, i) => (i === index ? [] : [r]));
    updateSelectedParam({ recommendations: updatedRecommendations });
  };

  const paramMenuItems = params.map((p, i) => ({
    key: p.key,
    prefix: `${i + 1}`,
    content: getCommaList(mapExists(needs, n => p.needIds.includes(n.needId) && n.name)) || "[New Area of Need]",
  }));

  const isParamsIncomplete = isEmpty(params) || params.some(p => isEmpty(p.needIds) || p.recommendations.some(isNull));

  const uniqueNeedGroups = uniq(params.map(p => p.needIds.join("~")));
  const isParamsNeedDuplicated = uniqueNeedGroups.length !== params.length;

  const validGroupRequests: CreateReportNeedGroupBody[] | undefined =
    !isParamsIncomplete && !isParamsNeedDuplicated
      ? params.map(p => ({ needIds: p.needIds, recommendationIds: mapExists(p.recommendations, r => r.key) }))
      : undefined;

  const addRecommendation = async () => {
    if (!validGroupRequests) return;
    await createReportNeedGroups(validGroupRequests).catch(logger.handleFailureAndThrow("createReportNeedGroups"));
    toast.success("Successfully Added Recommendations to Report");
    onFinished();
  };

  return (
    <PromptLayout
      headerText="Add Recommendations"
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{
        fn: addRecommendation,
        label: "Add",
        icon: <AddIcon />,
        disabled: !validGroupRequests,
      }}
    >
      <Grid container width={1000} height={500} spacing={2}>
        <Grid size={{ xs: 4 }}>
          <Typography variant="subtitle1">Areas of Need</Typography>
          <ListMenu items={paramMenuItems} selectedKey={selectedParamKey} onSelect={setSelectedParamKey} />
          <Button fullWidth onClick={addRecommendationParam} startIcon={<AddIcon />} sx={{ mt: 1 }}>
            Add Area of Need
          </Button>
        </Grid>

        {selectedParam && (
          <Grid size={{ xs: 8 }} sx={{ pl: 2, borderLeft: 1, borderColor: "grey.200" }}>
            <Stack gap={3}>
              <Stack direction="row" gap={1} alignItems="center">
                <MultipleSelectInput
                  fullWidth
                  label="Areas of Need"
                  options={needOptions}
                  value={selectedParam.needIds}
                  onChange={needIds => updateSelectedParam({ needIds })}
                />
                <IconButton onClick={removeCurrentNeed}>
                  <DeleteIcon sx={{ color: "error.main" }} />
                </IconButton>
              </Stack>

              {!isEmpty(selectedParam.needIds) && (
                <Stack gap={1}>
                  <Typography variant="subtitle1">Recommendations</Typography>
                  {selectedParam.recommendations.map((option, i) => (
                    <Stack direction="row" gap={1} alignItems="center" key={i}>
                      <AutoCompletePageSearchInput
                        label=""
                        selected={option}
                        onSelect={selection => setRecommendationParam(selection, i)}
                        search={text => reportApi.searchRecommendations({ text, needIds: selectedParam.needIds })}
                        getOption={r => ({ key: r.recommendationId, label: r.text })}
                      />
                      <IconButton onClick={() => removeRecommendationAtIndex(i)}>
                        <DeleteIcon sx={{ color: "error.main" }} />
                      </IconButton>
                    </Stack>
                  ))}
                  <Button
                    fullWidth
                    onClick={() => updateSelectedParam({ recommendations: [...selectedParam.recommendations, null] })}
                    startIcon={<AddIcon />}
                  >
                    Add Recommendation
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </PromptLayout>
  );
};

export default AddRecommendationInput;
