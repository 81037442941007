import { keyBy } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { UpsertMetricOptions } from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import { ProgressUpdateOperation, resolveObjectiveCompletionToggleUpdate } from "@parallel/polygon/util/progress.util";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import {
  AppointmentProgress,
  CreateGoalBody,
  ObjectiveMetricBody,
  SetStudentGoalsBody,
  StudentGoal,
  StudentObjective,
  UpdateGoalBody,
} from "@parallel/vertex/types/progress.types";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { ProgressAPI } from "@/api/progress.api";

type StudentProgress = { userId: string; goals: StudentGoal[] };

type ProgressUpdate = {
  operation: ProgressUpdateOperation;
  goal: { record: StudentGoal; displayIndex: number };
  objective?: { record: StudentObjective; displayIndex: number };
};

export class ProgressStore {
  goalsByStudent: Partial<Record<string, StudentGoal[]>> = {};
  currentStudentId?: string = undefined;

  pendingUpdate?: ProgressUpdate = undefined;

  constructor(private progressApi: ProgressAPI) {
    makeAutoObservable(this);
  }

  get currentStudentProgress(): StudentProgress | undefined {
    if (!this.currentStudentId) return;

    const goals = this.goalsByStudent[this.currentStudentId];
    if (!goals) return;

    return { userId: this.currentStudentId, goals };
  }

  public setSingleStudent = (student: SingleStudentUser) => {
    this.goalsByStudent = { [student.userId]: student.goals };
    this.currentStudentId = student.userId;
  };

  public setAppointmentStudents = (appointment: ExtendedAppointment, progress: AppointmentProgress) => {
    const progressByStudent = keyBy(progress.students, "studentId");
    this.goalsByStudent = appointment.students.reduce(
      (currentGoals, { userId }) => ({
        ...currentGoals,
        [userId]: progressByStudent[userId]?.goals || [],
      }),
      {},
    );
    this.currentStudentId = appointment.students[0]?.userId;

    return progressByStudent;
  };

  public setCurrentStudentId = (studentId: string) => {
    this.currentStudentId = studentId;
  };

  public createGoal = async (body: CreateGoalBody) => {
    const student = this.currentStudentProgress;
    if (!student) return;

    const created = await this.progressApi.createStudentGoal(student.userId, body);

    runInAction(() => {
      this.goalsByStudent[student.userId]?.push(created);
    });

    return created;
  };

  public updateGoal = async (goalId: string, body: UpdateGoalBody) => {
    const student = this.currentStudentProgress;
    if (!student) return;

    const updated = await this.progressApi.updateStudentGoal(student.userId, goalId, body);
    runInAction(() => {
      this.setGoal(updated);
      this.pendingUpdate = undefined;
    });

    return updated;
  };

  public setStudentGoals = async (body: SetStudentGoalsBody) => {
    const student = this.currentStudentProgress;
    if (!student) return;

    const updated = await this.progressApi.setStudentGoals(student.userId, body);

    runInAction(() => {
      this.goalsByStudent[student.userId] = updated;
    });
  };

  public upsertMetric = async (
    objectiveId: string,
    goal: StudentGoal,
    appointmentId: string,
    body: ObjectiveMetricBody & UpsertMetricOptions,
  ) => {
    const student = this.currentStudentProgress;
    if (!student) return;

    const updatedObjective = await this.progressApi.upsertObjectiveAppointmentMetric(objectiveId, appointmentId, body);

    if (!body.skipStateUpdate) {
      this.setGoal({
        ...goal,
        objectives: goal.objectives.map(o => (o.objectiveId === objectiveId ? updatedObjective : o)),
      });
    }
  };

  public updateNoteMetric = (objectiveId: string, goal: StudentGoal, note: string) => {
    const student = this.currentStudentProgress;
    if (!student) return;

    const updatedObjectives: StudentObjective[] = goal.objectives.map(o =>
      o.objectiveId === objectiveId && o.metric ? { ...o, metric: { ...o.metric, type: "string", value: note } } : o,
    );
    this.setGoal({ ...goal, objectives: updatedObjectives });
  };

  private setGoal = (updatedGoal: StudentGoal) => {
    if (!this.currentStudentProgress) return;
    const updatedGoals = this.currentStudentProgress.goals.map(g =>
      g.goalId === updatedGoal.goalId ? updatedGoal : g,
    );
    this.goalsByStudent[this.currentStudentProgress.userId] = updatedGoals;
  };

  public clearPendingUpdate = () => {
    this.pendingUpdate = undefined;
  };

  public startPendingUpdate = (
    operation: ProgressUpdateOperation,
    goal: { record: StudentGoal; displayIndex: number },
    objective?: { record: StudentObjective; displayIndex: number },
  ) => {
    this.pendingUpdate = { operation, goal, objective };
  };

  public setGoalCompleted = async ({ goal, isCompleted }: { goal: StudentGoal; isCompleted: boolean }) =>
    this.updateGoal(goal.goalId, {
      isCompleted,
      objectives: mapExists(
        goal.objectives,
        o => !!o.completedAt !== isCompleted && { objectiveId: o.objectiveId, isCompleted },
      ),
    });

  public setObjectiveCompleted = async ({
    goal,
    objectiveId,
    isCompleted,
  }: {
    goal: StudentGoal;
    objectiveId: string;
    isCompleted: boolean;
  }) => {
    const update = resolveObjectiveCompletionToggleUpdate(objectiveId, isCompleted, goal);
    return this.updateGoal(goal.goalId, update);
  };

  public setGoalArchived = async ({ goal, isArchived }: { goal: StudentGoal; isArchived: boolean }) =>
    this.updateGoal(goal.goalId, {
      isArchived,
      objectives: mapExists(
        goal.objectives,
        o => (o.isArchived ?? false) !== isArchived && { objectiveId: o.objectiveId, isArchived },
      ),
    });

  public setObjectiveArchived = async ({
    goal,
    objectiveId,
    isArchived,
  }: {
    goal: StudentGoal;
    objectiveId: string;
    isArchived: boolean;
  }) =>
    this.updateGoal(goal.goalId, {
      objectives: [{ objectiveId, isArchived }],
    });
}
