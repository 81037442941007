import { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import SelectInput from "@/components/shared/input/SelectInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { FileState, guessTestUploadSourceLabel, resolveUploadRequest, useUploadOptions } from "@/util/report.util";

const logger = initLogger("CustomUploadInput", getLoggerContext);

const CustomUploadInput = ({ onClose }: { onClose: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport, uploadTestFiles },
  } = useContext(StoreContext);

  const { sourceOptions, includeReasonOptions } = useUploadOptions(currentReport, reportApi, logger);

  const [fileState, setFileState] = useState<FileState>();

  const setFile = (file: File) => {
    const sourceLabel = guessTestUploadSourceLabel(file);
    const source = sourceLabel ? sourceOptions.find(s => s.label === sourceLabel) : undefined;
    setFileState({ file, sourceId: source?.key });
  };

  const validUploadRequest =
    currentReport && fileState && resolveUploadRequest(currentReport, fileState, { requireCustomReason: true });

  const uploadFile = async () => {
    if (!validUploadRequest) return;
    await uploadTestFiles([validUploadRequest]).catch(logger.handleFailureAndThrow("uploadFile"));
    onClose();
  };

  return (
    <Stack gap={2} width={600}>
      <Typography variant="h2">Add Custom Test Score</Typography>

      <FileInputBox onFileSelect={fs => setFile(fs[0])}>
        <Stack justifyContent="center" alignItems="center" height={200}>
          <NoteAddIcon sx={{ fontSize: 80, color: "primary.light" }} />
          <Link component="label" sx={{ cursor: "pointer" }}>
            <Typography variant="body2">Select file to upload</Typography>
            <VisuallyHiddenInput type="file" onChange={e => e.target.files && setFile(e.target.files[0])} />
          </Link>
          <Typography variant="body2">or drag and drop here</Typography>
        </Stack>
      </FileInputBox>

      {fileState && (
        <Stack gap={2}>
          <Stack direction="row" width="100%" py={2} gap={2} alignItems="center">
            <NoteAddIcon />
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {fileState.file.name}
            </Typography>
            <IconButton onClick={() => setFileState(undefined)}>
              <DeleteIcon />
            </IconButton>
          </Stack>

          <SelectInput
            label="Test"
            options={sourceOptions}
            value={fileState.sourceId}
            onChange={sourceId => setFileState({ ...fileState, sourceId })}
            size="small"
            fullWidth
          />
          <SelectInput
            label="Reason Added"
            options={includeReasonOptions}
            value={fileState.includeReasonId}
            onChange={includeReasonId => setFileState({ ...fileState, includeReasonId })}
            size="small"
            fullWidth
          />
        </Stack>
      )}

      <Stack direction="row" width="100%" pt={2} justifyContent="space-between">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <ProcessButton
          variant="contained"
          disabled={!validUploadRequest}
          process={uploadFile}
          startIcon={<UploadIcon />}
        >
          Upload
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default CustomUploadInput;
