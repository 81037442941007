import { ReactNode, useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { init } from "@feathery/react";
import { LicenseInfo as MUILicenseInfo } from "@mui/x-license";
import { useMountEffect } from "@react-hookz/web";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import config from "@/config";
import AppointmentEventsScreen from "@/screens/AppointmentEventsScreen";
import AppointmentProgressScreen from "@/screens/AppointmentProgressScreen";
import ApprovalScreen from "@/screens/ApprovalScreen";
import CalendarScreen from "@/screens/CalendarScreen";
import CleverLoginScreen from "@/screens/CleverLoginScreen";
import FormSubmissionScreen from "@/screens/FormSubmissionScreen";
import HomeScreen from "@/screens/HomeScreen";
import LoadingScreen from "@/screens/LoadingScreen";
import LoginScreen from "@/screens/LoginScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import ProtocolsScreen from "@/screens/ProtocolsScreen";
import ReportHomeScreen from "@/screens/ReportHomeScreen";
import ReportScreen from "@/screens/ReportScreen";
import ReportTemplateScreen from "@/screens/ReportTemplateScreen";
import ResetPasswordScreen from "@/screens/ResetPasswordScreen";
import StimulusBookEditScreen from "@/screens/StimulusBookEditScreen";
import StimulusHomeScreen from "@/screens/StimulusHomeScreen";
import StimulusUploadScreen from "@/screens/StimulusUploadScreen";
import StudentBulkUploadScreen from "@/screens/StudentBulkUploadScreen";
import StudentScreen from "@/screens/StudentScreen";
import TaskListScreen from "@/screens/TaskListScreen";
import UserListScreen from "@/screens/UserListScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("App", getLoggerContext);

const SentryRoutes = import.meta.env.MODE === "development" ? Routes : Sentry.withSentryReactRouterV6Routing(Routes);

const Page = ({ title, children }: { title: string; children: ReactNode }) => {
  useEffect(() => {
    document.title = `${title} - Pathway`;
  }, [title]);
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};

const _AuthPage = ({
  title,
  screen,
  noAuthScreen = <LoginScreen />,
}: {
  title: string;
  screen: ReactNode;
  noAuthScreen?: ReactNode;
}) => {
  const { authStore, calendarStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const isSignedIn = !!currentUser;

  const [isRestoringSignIn, setIsRestoringSignIn] = useState(true);

  useMountEffect(() => {
    if (!isSignedIn) {
      authStore
        .restoreSignIn()
        .catch(logger.handleFailure("restoreSignIn"))
        .finally(() => setIsRestoringSignIn(false));
    } else {
      setIsRestoringSignIn(false);
    }
  });

  useEffect(() => {
    if (!isSignedIn) return;
    calendarStore.setPayPeriod().catch(logger.handleFailure("setPayPeriod"));
  }, [isSignedIn]);

  if (!isSignedIn) screen = isRestoringSignIn ? <LoadingScreen /> : noAuthScreen;

  return <Page title={title}>{screen}</Page>;
};

const AuthPage = observer(_AuthPage);

const App = () => {
  init(config.featherySdkKey);
  MUILicenseInfo.setLicenseKey(config.muiPremiumLicenseKey);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/" element={<AuthPage title="Home" screen={<HomeScreen />} />} />

        <Route
          path="/clever"
          element={
            <Page title="Logging In">
              <CleverLoginScreen />
            </Page>
          }
        />

        <Route path="/user" element={<Navigate to="/user/student" />} />
        <Route
          path="/user/student"
          element={<AuthPage title="Students" screen={<UserListScreen listType="STUDENT" />} />}
        />
        <Route
          path="/user/student/bulk-create"
          element={<AuthPage title="Students" screen={<StudentBulkUploadScreen />} />}
        />
        <Route
          path="/user/facilitator"
          element={<AuthPage title="Facilitators" screen={<UserListScreen listType="FACILITATOR" />} />}
        />
        <Route path="/user/slpa" element={<AuthPage title="SLPAs" screen={<UserListScreen listType="SLPA" />} />} />
        <Route
          path="/user/site-director"
          element={<AuthPage title="SpEd Admins" screen={<UserListScreen listType="SITE_DIRECTOR" />} />}
        />
        <Route
          path="/user/provider"
          element={<AuthPage title="Providers" screen={<UserListScreen listType="PROVIDER" />} />}
        />
        <Route path="/user/admin" element={<AuthPage title="Admins" screen={<UserListScreen listType="ADMIN" />} />} />
        <Route path="/user/student/:studentId" element={<AuthPage title="Student" screen={<StudentScreen />} />} />
        <Route
          path="/user/student/:studentId/info"
          element={<AuthPage title="Student Info" screen={<StudentScreen currentView="info" />} />}
        />
        <Route
          path="/user/student/:studentId/goals"
          element={<AuthPage title="Student Goals" screen={<StudentScreen currentView="goals" />} />}
        />
        <Route
          path="/user/student/:studentId/goals/:goalId/objective/:objectiveId"
          element={<AuthPage title="Student Objective" screen={<StudentScreen currentView="goals" />} />}
        />
        <Route
          path="/user/student/:studentId/progress"
          element={<AuthPage title="Student Progress" screen={<StudentScreen currentView="progress" />} />}
        />
        <Route
          path="/user/student/:studentId/documents"
          element={<AuthPage title="Student Documents" screen={<StudentScreen currentView="documents" />} />}
        />

        <Route path="/calendar" element={<AuthPage title="Calendar" screen={<CalendarScreen />} />} />
        <Route
          path="/calendar/appointment/:itemId"
          element={<AuthPage title="Appointment" screen={<CalendarScreen itemType="appointment" />} />}
        />
        <Route
          path="/calendar/appointment/:itemId/progress"
          element={<AuthPage title="Appointment Progress" screen={<AppointmentProgressScreen />} />}
        />
        <Route
          path="/calendar/appointment/:itemId/events"
          element={<AuthPage title="Appointment Events" screen={<AppointmentEventsScreen />} />}
        />
        <Route
          path="/calendar/block/:itemId"
          element={<AuthPage title="Calendar Block" screen={<CalendarScreen itemType="block" />} />}
        />
        <Route
          path="/calendar/time/:itemId"
          element={<AuthPage title="Indirect Time" screen={<CalendarScreen itemType="indirect-time" />} />}
        />
        <Route
          path="/calendar/facilitator/:relationId"
          element={<AuthPage title="Facilitator Calendar" screen={<CalendarScreen relationType="FACILITATOR" />} />}
        />
        <Route
          path="/calendar/slpa/:relationId"
          element={<AuthPage title="SLPA Calendar" screen={<CalendarScreen relationType="SLPA" />} />}
        />
        <Route
          path="/calendar/provider/:relationId"
          element={<AuthPage title="Provider Calendar" screen={<CalendarScreen relationType="PROVIDER" />} />}
        />
        <Route
          path="/calendar/student/:relationId"
          element={<AuthPage title="Student Calendar" screen={<CalendarScreen relationType="STUDENT" />} />}
        />
        <Route
          path="/calendar/campus/:relationId"
          element={<AuthPage title="Campus Calendar" screen={<CalendarScreen relationType="CAMPUS" />} />}
        />

        <Route
          path="/form/submission/:submissionId"
          element={<AuthPage title="Form Submission" screen={<FormSubmissionScreen />} />}
        />

        <Route path="/report" element={<AuthPage title="Report Home" screen={<ReportHomeScreen />} />} />
        <Route
          path="/report/start/:serviceLineClientId"
          element={<AuthPage title="Create Report" screen={<ReportHomeScreen />} />}
        />
        <Route path="/report/:reportId" element={<AuthPage title="Report" screen={<ReportScreen />} />} />
        <Route
          path="/report/template/:templateKey"
          element={<AuthPage title="Report Template" screen={<ReportTemplateScreen />} />}
        />

        <Route path="/approval" element={<Navigate to="/approval/appointment" />} />
        <Route
          path="/approval/appointment"
          element={
            <AuthPage title="Late Appointment Submissions" screen={<ApprovalScreen approvalType="APPOINTMENT" />} />
          }
        />
        <Route
          path="/approval/time"
          element={<AuthPage title="Indirect Time Submissions" screen={<ApprovalScreen approvalType="TIME" />} />}
        />

        <Route path="/task" element={<AuthPage title="Tasks" screen={<TaskListScreen />} />} />

        <Route path="/protocols" element={<AuthPage title="Report Protocols" screen={<ProtocolsScreen />} />} />

        <Route path="/stimulus" element={<AuthPage title="Stimulus Editor" screen={<StimulusHomeScreen />} />} />
        <Route
          path="/stimulus/upload"
          element={<AuthPage title="Stimulus Upload" screen={<StimulusUploadScreen />} />}
        />
        <Route
          path="/stimulus/book/:bookId/edit"
          element={<AuthPage title="Stimulus Book" screen={<StimulusBookEditScreen />} />}
        />

        <Route
          path="/reset-password/:resetId"
          element={
            <Page title="Reset Password">
              <ResetPasswordScreen />
            </Page>
          }
        />

        <Route
          path="*"
          element={<AuthPage title="Not Found" screen={<NotFoundScreen />} noAuthScreen={<NotFoundScreen />} />}
        />
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
