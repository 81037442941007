import { useContext, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { AppointmentProgress } from "@parallel/vertex/types/progress.types";
import SelectInput from "@/components/shared/input/SelectInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const GROUP_NOTE_TARGET_KEY = "GROUP";

const logger = initLogger("AppointmentProgressNotePrompt", getLoggerContext);

const AppointmentProgressNotePrompt = ({
  appointment,
  progress,
  onSuccess,
  onBack,
}: {
  appointment: ExtendedAppointment;
  progress: AppointmentProgress;
  onSuccess: (update: Partial<AppointmentProgress>) => void;
  onBack: () => void;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const [progressNoteTarget, setProgressNoteTarget] = useState(GROUP_NOTE_TARGET_KEY);
  const [progressNote, setProgressNote] = useState("");

  const submitNote = () => {
    if (progressNoteTarget === GROUP_NOTE_TARGET_KEY) {
      return progressApi
        .setGroupNote(appointment.appointmentId, progressNote)
        .catch(logger.handleFailureAndThrow("setGroupNote"))
        .then(() => onSuccess({ groupNote: progressNote }));
    } else {
      return progressApi
        .setStudentNote(progressNoteTarget, appointment.appointmentId, progressNote)
        .catch(logger.handleFailureAndThrow("setStudentNote"))
        .then(() =>
          onSuccess({
            students: progress.students.map(s =>
              s.studentId === progressNoteTarget ? { ...s, note: progressNote } : s,
            ),
          }),
        );
    }
  };

  return (
    <Stack gap={2} width={500}>
      <Typography variant="body1">
        This appointment type requires qualitative notes to be included in the session notes before it can be marked as{" "}
        <strong>Occurred</strong>.
      </Typography>

      <Typography variant="body1" mb={2}>
        Please select a student below and add a note to continue.
      </Typography>

      <SelectInput
        label="Student"
        options={[
          { key: "GROUP", label: "All Students" },
          ...appointment.students.map(s => ({ key: s.userId, label: s.fullName })),
        ]}
        value={progressNoteTarget}
        onChange={setProgressNoteTarget}
      />

      <TextField
        label="Note"
        value={progressNote}
        onChange={e => setProgressNote(e.target.value)}
        multiline
        rows={4}
        sx={{ marginBottom: 2 }}
      />

      <Stack direction="row" gap={2}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onBack} fullWidth>
          Back
        </Button>
        <ProcessButton
          variant="contained"
          process={submitNote}
          startIcon={<SaveIcon />}
          disabled={!progressNote}
          fullWidth
        >
          Submit
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default AppointmentProgressNotePrompt;
