import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { INTERVIEW_FORM_TYPES, InterviewFormType } from "@parallel/vertex/enums/report.org.enums";
import { INTERVIEW_TYPE_LABELS } from "@parallel/vertex/util/report.util";
import SelectInput from "@/components/shared/input/SelectInput";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("AddInterviewInput", getLoggerContext);

const AddInterviewInput = ({ onFinished }: { onFinished: () => void }) => {
  const {
    reportStore: { currentReport, createInterviewSubmission },
  } = useContext(StoreContext);

  const [interviewType, setInterviewType] = useState<InterviewFormType>();
  const [intervieweeName, setIntervieweeName] = useState("");

  const isSubmitReady = !!interviewType && (!!intervieweeName || interviewType === "student");

  const createSubmission = async () => {
    if (!isSubmitReady) return;
    await createInterviewSubmission(interviewType, intervieweeName).catch(
      logger.handleFailureAndThrow("createSubmission"),
    );
    onFinished();
  };

  const typeOptions = INTERVIEW_FORM_TYPES.filter(
    t => t !== "student" || !currentReport?.formSubmissions.studentInterview,
  ).map(key => ({ key, label: INTERVIEW_TYPE_LABELS[key] }));

  return (
    <PromptLayout
      headerText="Add Input Note"
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{ fn: createSubmission, label: "Submit", icon: <AddIcon />, disabled: !isSubmitReady }}
    >
      <Stack width={400} gap={2}>
        <SelectInput
          label="Input Type"
          options={typeOptions}
          value={interviewType}
          onChange={setInterviewType}
          fullWidth
        />
        {interviewType && interviewType !== "student" && (
          <TextField
            label="Interviewee Name"
            value={intervieweeName}
            onChange={e => setIntervieweeName(e.target.value)}
          />
        )}
      </Stack>
    </PromptLayout>
  );
};

export default AddInterviewInput;
