import { debounce } from "lodash";
import { ObjectiveMetricBody, StudentObjective } from "@parallel/vertex/types/progress.types";
import { stepValuesSchema } from "@parallel/vertex/util/progress.util";
import { useAsyncProcessStatus } from "../../shared/input/status.input";
import AccuracyInput, { DEFAULT_STEP_VALUES } from "./AccuracyInput";
import DurationInput from "./DurationInput";
import FrequencyInput from "./FrequencyInput";
import NoteInput from "./NoteInput";

const WRITE_DEBOUNCE_MS = 250;

export type UpsertMetricOptions = { skipStateUpdate?: boolean };

const ObjectiveMetricInput = ({
  objective,
  upsertMetric,
  onNoteUpdated,
  isReadOnly,
  compact,
}: {
  objective: StudentObjective;
  upsertMetric: (body: ObjectiveMetricBody & UpsertMetricOptions) => Promise<void>;
  onNoteUpdated: (note: string) => void;
  isReadOnly?: boolean;
  compact?: boolean;
}) => {
  const writeValue = debounce(upsertMetric, WRITE_DEBOUNCE_MS, { leading: true });
  const { status, perform: performWrite } = useAsyncProcessStatus(writeValue, { throwError: true });

  const isDisabled = !!objective.completedAt || objective.isArchived || isReadOnly;

  switch (objective.category) {
    case "ACCURACY":
      return (
        objective.metric?.type !== "string" && (
          <AccuracyInput
            value={objective.metric?.value || 0}
            stepValues={
              objective.metric ? stepValuesSchema.safeParse(objective.metric.metadata)?.data : DEFAULT_STEP_VALUES
            }
            writeValue={(metricValue, stepValues) => performWrite({ metricValue, metadata: stepValues })}
            status={status}
            disabled={isDisabled}
            compact={compact}
          />
        )
      );
    case "DURATION":
      return (
        objective.metric?.type !== "string" && (
          <DurationInput
            value={objective.metric?.value || 0}
            writeValue={metricValue => performWrite({ metricValue })}
            status={status}
            disabled={isDisabled}
            compact={compact}
          />
        )
      );
    case "DECREASING_FREQUENCY":
    case "INCREASING_FREQUENCY":
    case "PROMPTING_FREQUENCY":
      return (
        objective.metric?.type !== "string" && (
          <FrequencyInput
            value={objective.metric?.value || 0}
            writeValue={metricValue => performWrite({ metricValue })}
            status={status}
            disabled={isDisabled}
            compact={compact}
          />
        )
      );
    case "ANECDOTAL":
      return (
        <NoteInput
          objectiveId={objective.objectiveId}
          value={objective.metric?.value.toString() || ""}
          writeValue={metricValue => performWrite({ metricValue, skipStateUpdate: !!objective.metric })}
          onValidSave={metricValue => onNoteUpdated(metricValue)}
          status={status}
          disabled={isDisabled}
          compact={compact}
        />
      );
  }
};

export default ObjectiveMetricInput;
