import AddIcon from "@mui/icons-material/Add";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import ProgressDisplayLayout from "@/components/progress/ProgressDisplayLayout";

const StudentProgressConfiguration = ({
  onGoalAdd,
  onGoalEdit,
}: {
  onGoalAdd: () => void;
  onGoalEdit: (editing: { goal: StudentGoal; index: number }) => void;
}) => {
  return (
    <ProgressDisplayLayout
      headerAction={{ text: "New Goal", icon: <AddIcon />, perform: onGoalAdd }}
      onEditGoal={onGoalEdit}
    />
  );
};

export default StudentProgressConfiguration;
