import { UserType } from "../enums/user.enums";
import { PartialUser } from "./role.shared";

type GenericRole = "internal-support" | "manage-form-approvals" | "edit-assessment-stimuli";

const GENERIC_ROLE_GRANTS: Record<GenericRole, UserType[]> = {
  "internal-support": ["ADMIN", "PROVIDER"],
  "manage-form-approvals": ["ADMIN"],
  "edit-assessment-stimuli": ["ADMIN"],
};

export const hasGenericRole = (user: PartialUser, role: GenericRole) =>
  user && GENERIC_ROLE_GRANTS[role].includes(user.userType);
