import { UserType } from "../enums/user.enums";
import { FULL_ACCESS_PERMISSIONS, getDataPermissionResolver, PartialUser } from "./role.shared";
import { UserPermissions } from "./role.user";

export type SlpaPermissions = UserPermissions & {
  defaults?: { supervisors: "currentUser" };
};

export const SLPA_USER_GRANTS: Partial<Record<UserType, SlpaPermissions>> = {
  ADMIN: FULL_ACCESS_PERMISSIONS,
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowEditIf: "has-common-district",
    allowDeleteIf: (user, requestedBy) => user.createdBy === requestedBy,
    validateRequestor: (user: PartialUser) => user?.providerType === "SLP",
    defaults: { supervisors: "currentUser" },
  },
  SITE_DIRECTOR: {
    accessLevels: ["read"],
  },
};

export const getSlpaPermissions = getDataPermissionResolver(SLPA_USER_GRANTS);
