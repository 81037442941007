import { useContext, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty, partition } from "lodash";
import { observer } from "mobx-react-lite";
import { InterviewFormType } from "@parallel/vertex/enums/report.org.enums";
import {
  ReportEditorSection,
  ReportEditorSubsection,
  ReportInterviewForm,
  SingleReport,
} from "@parallel/vertex/types/report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { isSectionVisible } from "@parallel/vertex/util/report.util";
import { getCommaList } from "@parallel/vertex/util/string.util";
import AddInterviewInput from "@/components/report/data/AddInterviewInput";
import AddSubsectionInput from "@/components/report/editor/AddSubsectionInput";
import AddEligibilityInput from "@/components/report/editor/guidance/AddEligibilityInput";
import AddRecommendationInput from "@/components/report/editor/guidance/AddRecommendationInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";
import { getDataSectionTitle, REPORT_DATA_SECTIONS, ReportMenuSelection } from "@/util/report.util";

type SidebarMenuItem = {
  id: string;
  label: string;
  onSelect?: () => void;
  isSelected?: boolean;
  isHidden?: boolean;
  onAdd?: () => void;
};

const ReportSidebarItem = ({
  item,
  isHeader,
  isSortable,
}: {
  item: SidebarMenuItem;
  isHeader?: boolean;
  isSortable?: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable(item);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 1,
        pl: isHeader ? 1 : 2,
        borderLeft: 2,
        bgcolor: item.isSelected ? "highlight.main" : undefined,
        borderColor: item.isSelected ? "primary.main" : "background.default",
        "&:hover": !item.isSelected && item.onSelect ? { borderColor: "primary.light" } : undefined,
        cursor: item.onSelect ? "pointer" : undefined,
      }}
      onClick={item.onSelect}
      ref={setNodeRef}
      style={style}
    >
      <Typography
        variant={isHeader ? "subtitle2" : "body2"}
        sx={{ textDecoration: item.isHidden ? "line-through" : undefined }}
      >
        {item.label}
      </Typography>
      {item.onAdd && (
        <IconButton size="small" onClick={item.onAdd}>
          <AddIcon fontSize="small" sx={{ color: "primary.main" }} />
        </IconButton>
      )}
      {isSortable && (
        <button {...listeners} {...attributes}>
          <Box px="4px">
            <DragHandleIcon fontSize="small" sx={{ color: "grey.600" }} />
          </Box>
        </button>
      )}
    </Stack>
  );
};

type ReportSidebarMenuProps = {
  header: SidebarMenuItem;
  items: SidebarMenuItem[];
  onReorder?: (movedId: string, overId: string) => Promise<void>;
};

const ReportSidebarMenu = ({ header, items, onReorder }: ReportSidebarMenuProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!onReorder) return;
    if (!over || active.id === over.id) return;
    onReorder(active.id.toString(), over.id.toString());
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Stack>
        <ReportSidebarItem isHeader item={{ ...header, label: header.label.toUpperCase() }} />

        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Stack>
            {items.map((item, i) => (
              <ReportSidebarItem item={item} isSortable={!!onReorder} key={i} />
            ))}
          </Stack>
        </SortableContext>
      </Stack>
    </DndContext>
  );
};

const getEditorMenuItem = (
  report: SingleReport,
  section: ReportEditorSection,
  sectionType: "parent" | "child",
  onMenuSelect: (s: ReportMenuSelection) => void,
  selectedSectionTemplateId: string | undefined,
): SidebarMenuItem | undefined => {
  const isVisible = isSectionVisible(report, section);
  if (sectionType === "child" && !isVisible) return;
  return {
    id: section.reportSectionTemplateId,
    label: section.title,
    onSelect: () => onMenuSelect({ type: "editor", sectionId: section.reportSectionTemplateId }),
    isSelected: section.reportSectionTemplateId === selectedSectionTemplateId,
    isHidden: !isVisible || section.custom?.isHidden,
  };
};

const getInterviewLabel = (interviewType: InterviewFormType, { intervieweeName }: ReportInterviewForm) => {
  const suffix = intervieweeName ? ` - ${intervieweeName}` : "";
  switch (interviewType) {
    case "caregiver":
      return `Caregiver${suffix}`;
    case "student":
      return "Student";
    case "teacher":
      return `Teacher${suffix}`;
  }
};

const ReportSidebar = () => {
  const {
    reportStore: {
      currentReport,
      allInterviewSubmissions,
      menuSelection,
      canEdit,
      setMenuSelection,
      updateSubsectionOrder,
      updateNeedGroupOrder,
    },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [isAddingInterview, setIsAddingInterview] = useState(false);

  const [addSubsectionOptions, setAddSubsectionOptions] = useState<ReportEditorSubsection[]>();

  const [isAddingEligibility, setIsAddingEligibility] = useState(false);
  const [isAddingRecommendation, setIsAddingRecommendation] = useState(false);

  if (!currentReport) return <></>;

  const filteredReportDataSections = currentUser?.featureFlags.showDocumentCenter
    ? REPORT_DATA_SECTIONS
    : REPORT_DATA_SECTIONS.filter(section => section !== "documents");

  const dataMenuItems = filteredReportDataSections.map(section => ({
    id: section,
    label: getDataSectionTitle(section),
    onSelect: () => setMenuSelection({ type: "data", section }),
    isSelected: menuSelection.type === "data" && menuSelection.section === section,
  }));

  const interviewMenuItems = allInterviewSubmissions.map(form => ({
    id: form.submissionId,
    label: getInterviewLabel(form.interviewType, form),
    onSelect: () =>
      setMenuSelection({ type: "interview", submissionId: form.submissionId, interviewType: form.interviewType }),
    isSelected: menuSelection.type === "interview" && menuSelection.submissionId === form.submissionId,
  }));

  const selectedSectionId = menuSelection.type === "editor" ? menuSelection.sectionId : undefined;

  const editorMenuProps: ReportSidebarMenuProps[] = mapExists(currentReport?.editorSections, section => {
    const parentItem = getEditorMenuItem(currentReport, section, "parent", setMenuSelection, selectedSectionId);
    if (!parentItem) return;

    if (section.title === "Recommendations") {
      return {
        header: { ...parentItem, onAdd: canEdit ? () => setIsAddingRecommendation(true) : undefined },
        items: currentReport.needGroups.map(group => ({
          id: group.groupId,
          label: getCommaList(
            group.needs.map(n => n.name),
            { delimiter: "&" },
          ),
          onSelect: () => setMenuSelection({ type: "editor", sectionId: group.groupId }),
          isSelected: group.groupId === selectedSectionId,
        })),
        onReorder: currentReport.needGroups.length > 1 && canEdit ? updateNeedGroupOrder : undefined,
      };
    }

    const subsections = mapExists(
      section.children,
      child =>
        child.type === "subsection" && {
          subsection: child,
          menuItem: getEditorMenuItem(currentReport, child, "child", setMenuSelection, selectedSectionId),
        },
    );
    const [showSubsections, hideSubsections] = partition(subsections, s => !!s.menuItem);

    let onAdd: (() => void) | undefined = undefined;
    if (canEdit) {
      if (section.title === "Summary & Eligibility Criteria") onAdd = () => setIsAddingEligibility(true);
      if (!isEmpty(hideSubsections)) onAdd = () => setAddSubsectionOptions(hideSubsections.map(s => s.subsection));
    }

    const items = parentItem.isHidden ? [] : mapExists(showSubsections, s => s.menuItem);
    return {
      header: {
        ...parentItem,
        onAdd,
        isHidden:
          parentItem.isHidden || (isEmpty(showSubsections) && section.children.some(c => c.type === "subsection")),
      },
      items,
      onReorder: items.length > 1 && canEdit ? updateSubsectionOrder : undefined,
    };
  });

  const isModalOpen = isAddingInterview || !!addSubsectionOptions || isAddingEligibility || isAddingRecommendation;
  const clearModal = () => {
    setIsAddingInterview(false);
    setAddSubsectionOptions(undefined);
    setIsAddingEligibility(false);
    setIsAddingRecommendation(false);
  };

  return (
    <Stack gap={2}>
      <ReportSidebarMenu header={{ id: "data-header", label: "Data" }} items={dataMenuItems} />
      <ReportSidebarMenu
        header={{ id: "interview-header", label: "Input Notes", onAdd: () => setIsAddingInterview(true) }}
        items={interviewMenuItems}
      />

      {!isEmpty(editorMenuProps) && currentReport.formSubmissions.testingPlan.hasAnswers && (
        <>
          <Divider />
          {editorMenuProps.map((menuProps, i) => (
            <ReportSidebarMenu {...menuProps} key={i} />
          ))}
        </>
      )}

      <CenterModal isOpen={isModalOpen} onClose={clearModal}>
        {isAddingInterview && <AddInterviewInput onFinished={clearModal} />}
        {addSubsectionOptions && <AddSubsectionInput options={addSubsectionOptions} onFinished={clearModal} />}
        {isAddingRecommendation && <AddRecommendationInput onFinished={clearModal} />}
        {isAddingEligibility && <AddEligibilityInput onFinished={clearModal} />}
      </CenterModal>
    </Stack>
  );
};

export default observer(ReportSidebar);
