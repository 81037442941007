import type { Recurrence } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { DayOfWeekValues, RecurrenceUnitValues } from "../../enums/prisma.enums";
import { Override, datetimeRequestField } from "../shared.types";

export type { DayOfWeek, RecurrenceUnit } from "@prisma/client";

export type ExtendedRecurrence = Override<Recurrence, { endsOn: DateTime }>;

export const recurrenceBody = z.object({
  periodUnit: z.enum(RecurrenceUnitValues),
  periodSize: z.number(),
  daysOfWeek: z.array(z.enum(DayOfWeekValues)),
  endsOn: datetimeRequestField,
});

export type RecurrenceBody = z.infer<typeof recurrenceBody>;
