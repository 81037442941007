import { useContext, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { mean } from "lodash";
import { DateTime } from "luxon";
import { useListItemColorSx } from "@parallel/polygon/util/progress.util";
import { StudentGoal, StudentObjective, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import GoalActions from "@/components/progress/GoalActions";
import ObjectiveListItem from "@/components/user/student/progress/ObjectiveListItem";
import ProgressListItemLayout from "@/components/user/student/progress/ProgressListItemLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("GoalListItem", getLoggerContext);

type IndexedGoal = Override<
  StudentGoal,
  {
    displayIndex: number;
    objectives: (StudentObjective & { displayIndex: number })[];
  }
>;

const GoalListItem = ({ goal, onEdit }: { goal: IndexedGoal; onEdit: () => void }) => {
  const {
    progressStore: { updateGoal },
  } = useContext(StoreContext);

  const attemptGoalUpdate = (update: UpdateGoalBody) =>
    updateGoal(goal.goalId, update).catch(logger.handleFailure("updateStudentGoal"));

  const [isExpanded, setIsExpanded] = useState(false);

  const objectiveDurationDays = mapExists(goal.objectives, o => {
    if (!o.completedAt) return;
    return o.completedAt.diff(goal.createdAt, "days").days;
  });
  const averageObjectiveDurationDays = Math.round(mean(objectiveDurationDays));

  const colorSx = useListItemColorSx(goal, "goal");

  return (
    <Stack
      gap={2}
      sx={{
        width: "100%",
        p: 2,
        bgcolor: goal.isArchived ? "grey.50" : undefined,
        border: 1,
        borderColor: "grey.300",
        ...colorSx,
      }}
    >
      <ProgressListItemLayout
        item={goal}
        title={`Goal ${goal.displayIndex}`}
        actions={
          <Stack direction="row" justifyContent="space-between">
            <GoalActions goal={goal} displayIndex={goal.displayIndex} onEdit={onEdit} logger={logger} />
          </Stack>
        }
        data={[
          { left: "Started:", right: goal.createdAt.toLocaleString(DateTime.DATE_SHORT) },
          { left: "Achieved:", right: goal.completedAt?.toLocaleString(DateTime.DATE_SHORT) || "-" },
          {
            left: "Avg Objective Time:",
            right: !isNaN(averageObjectiveDurationDays) ? `${averageObjectiveDurationDays} days` : "-",
          },
        ]}
        endIcon={
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
          </IconButton>
        }
        onPinnedToggle={() => attemptGoalUpdate({ isPinned: !goal.isPinned })}
      />
      {isExpanded &&
        goal.objectives.map(objective => (
          <ObjectiveListItem
            objective={objective}
            displayIndices={{ goal: goal.displayIndex, objective: objective.displayIndex }}
            goal={goal}
            isLink
            key={objective.objectiveId}
          />
        ))}
    </Stack>
  );
};

export default GoalListItem;
