import { useContext } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { getOperationResultName, ProgressUpdateOperation } from "@parallel/polygon/util/progress.util";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("UpdateGoalPrompt", getLoggerContext);

const UpdateGoalPrompt = ({
  operation,
  goal,
}: {
  operation: ProgressUpdateOperation;
  goal: { record: StudentGoal; displayIndex: number };
}) => {
  const { progressStore } = useContext(StoreContext);

  const performUpdate = () => {
    switch (operation) {
      case "complete":
        return progressStore
          .setGoalCompleted({ goal: goal.record, isCompleted: true })
          .catch(logger.handleFailureAndThrow("setGoalCompleted"));
      case "archive":
        return progressStore
          .setGoalArchived({ goal: goal.record, isArchived: true })
          .catch(logger.handleFailureAndThrow("setGoalArchived"));
    }
  };

  const resultName = getOperationResultName(operation);

  return (
    <PromptLayout
      headerText={`Mark Goal ${capitalize(resultName)}`}
      leftAction={{ fn: progressStore.clearPendingUpdate, label: "Back" }}
      rightAction={{
        fn: performUpdate,
        label: `Goal ${capitalize(resultName)}`,
        icon: <TaskAltIcon />,
      }}
    >
      <Stack gap={2} width={550}>
        {operation === "complete" && (
          <Typography variant="body1">
            The student has met this goal. All objectives for this goal will be marked as achieved, and no further data
            will be captured for this goal’s objectives.
          </Typography>
        )}
        <Stack p={2} gap={1} sx={{ bgcolor: "highlight.main", borderRadius: 1 }}>
          <Typography variant="subtitle1">{goal.displayIndex}. Goal</Typography>
          <Typography variant="body1">{goal.record.description}</Typography>
        </Stack>
        <Typography variant="body1">Are you sure you want to mark this goal {resultName}?</Typography>
      </Stack>
    </PromptLayout>
  );
};

export default UpdateGoalPrompt;
