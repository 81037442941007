import { useContext, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import CalendarBackground from "@/components/calendar/CalendarBackground";
import CalendarColumn from "@/components/calendar/CalendarColumn";
import { StoreContext } from "@/stores";

const CalendarDayView = ({ date }: { date: DateTime }) => {
  const {
    calendarStore: { zoomFactor },
  } = useContext(StoreContext);

  const height = percentString(zoomFactor);

  const theme = useTheme();

  const parentContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={parentContainerRef}
      sx={{
        height: "100%",
        width: "100%",
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.bordergrey.light,
        overflowY: "scroll",
      }}
    >
      <Box
        role="main"
        ref={parentContainerRef}
        sx={{
          height,
          width: "100%",
          position: "relative",
        }}
      >
        <CalendarBackground key="day-view" parentContainerRef={parentContainerRef} />
        <FullBox sx={{ position: "absolute", top: 0, paddingLeft: "6rem", paddingRight: "2px" }}>
          <CalendarColumn date={date} />
        </FullBox>
      </Box>
    </Box>
  );
};

export default observer(CalendarDayView);
