import { useContext, useState } from "react";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PublicIcon from "@mui/icons-material/Public";
import TimerIcon from "@mui/icons-material/Timer";
import TodayIcon from "@mui/icons-material/Today";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { capitalize, isEmpty, isNull, omit } from "lodash";
import { DateTime } from "luxon";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { hasRoleFlag } from "@parallel/vertex/role";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { shortTimeZone, getValidTimezones } from "@parallel/vertex/util/datetime.util";
import CalendarPeriodPicker from "@/components/calendar/CalendarPeriodPicker";
import CalendarHeaderFilterInput from "@/components/calendar/header/CalendarHeaderFilterInput";
import { StyledMenu, StyledMenuItem } from "@/components/shared/display/menu";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import { CreateModalType } from "@/screens/CalendarScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { CALENDAR_VIEW_TYPES, CalendarViewType } from "@/stores/calendar.store";
import { initLogger } from "@/util/logging.util";
import { useUrlNavigation } from "@/util/router.util";

const ZOOM_FACTOR_DELTA = 0.2;

const VIEW_TYPE_ICONS = { day: <ViewDayIcon />, week: <CalendarViewWeekIcon />, list: <MenuIcon /> };

const logger = initLogger("CalendarHeader", getLoggerContext);

const getRangeInputDisplay = (viewType: CalendarViewType, period: DateTimeRange) => {
  switch (viewType) {
    case "day":
      return period.startTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    case "week":
      return period.startTime.toLocaleString({ month: "long", year: "numeric" });
    case "list":
      const range = [period.startTime, period.endTime].map(d =>
        d.toLocaleString({ month: "short", day: "numeric", year: "numeric" }),
      );
      return `${range[0]} - ${range[1]}`;
  }
};

const CalendarHeader = ({ onCreate }: { onCreate: (type: CreateModalType) => void }) => {
  const {
    apiStore: { calendarApi },
    authStore,
    calendarStore,
  } = useContext(StoreContext);
  const { currentUser } = authStore;
  const {
    viewType,
    period,
    typeFilters,
    selectedRelationFilter,
    availableRelationTypes,
    toRelationOption,
    selectedUserOption,
    isShowingWeekends,
    appointmentKeywordFilters,
  } = calendarStore;

  const { navigate, query, setQuery } = useUrlNavigation();

  const now = DateTime.now().setZone(authStore.timezone);

  const [periodAnchor, setPeriodAnchor] = useState<HTMLElement>();
  const [weekPickerAnchor, setWeekPickerAnchor] = useState<HTMLElement>();

  const setViewParams = (
    startTime: DateTime,
    { endTime, viewType: newViewType }: { endTime?: DateTime; viewType?: CalendarViewType } = {},
  ) => {
    const range = endTime ? { startTime, endTime } : calendarStore.getPeriod(newViewType || viewType, startTime);
    const isoStartDate = range.startTime.toISODate();
    const isoEndDate = range.endTime.toISODate();

    const queryUpdate: Record<string, string> = { ...query };
    if (isoStartDate && isoEndDate) {
      queryUpdate.startDate = isoStartDate;
      queryUpdate.endDate = isoEndDate;
    }
    if (newViewType) {
      queryUpdate.view = newViewType;
    }
    setQuery(queryUpdate);

    setWeekPickerAnchor(undefined);
  };

  const shiftPeriod = (delta: number) => {
    const { startTime } = period;
    switch (viewType) {
      case "day":
        return setViewParams(startTime.plus({ days: delta }));
      case "week":
        return setViewParams(startTime.plus({ weeks: delta }));
    }
  };

  const setViewType = (viewType: CalendarViewType) => {
    setPeriodAnchor(undefined);

    if (!query.startDate && !query.endDate) {
      setQuery({ view: viewType });
      return;
    }
    setViewParams(period.startTime, { viewType });
  };

  const setPeriodToToday = () => setQuery(omit(query, "startDate", "endDate"));

  const toggleWeekend = action(() => {
    calendarStore.isShowingWeekends = !calendarStore.isShowingWeekends;

    const newStartTime = isShowingWeekends ? period.startTime.plus({ days: 1 }) : period.startTime.minus({ days: 1 });

    setViewParams(newStartTime, { viewType: "week" });
  });

  const [createAnchor, setCreateAnchor] = useState<HTMLElement>();

  const [timezoneAnchor, setTimezoneAnchor] = useState<HTMLElement>();
  const saveTimezone = (timezone: string) => {
    setTimezoneAnchor(undefined);
    authStore.setTimezone(timezone).catch(logger.handleFailure("setTimezone"));
  };

  const createSelected = (type: CreateModalType) => {
    setCreateAnchor(undefined);
    onCreate(type);
  };

  const isListView = viewType === "list";

  const canShowModelFilterSelect = !isNull(availableRelationTypes);
  const [isFilterExpanded, setIsFilterExpanded] = useState(!canShowModelFilterSelect || isListView);

  const showListFilter = isFilterExpanded && isListView;
  const showModelFilterSelect = canShowModelFilterSelect && !showListFilter;

  return (
    <Grid container py={3} px={3}>
      <Grid size={{ xs: 4 }} alignContent="center" sx={{ displayPrint: "none" }}>
        <Stack direction="row" gap={1}>
          <Stack direction="row">
            <IconButton
              onClick={() => calendarStore.zoom(ZOOM_FACTOR_DELTA * -1)}
              disabled={calendarStore.zoomFactor <= 1 || isListView}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton onClick={() => calendarStore.zoom(ZOOM_FACTOR_DELTA)} disabled={isListView}>
              <ZoomInIcon />
            </IconButton>
          </Stack>

          <Button
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{ height: 40 }}
            onClick={e => setPeriodAnchor(e.currentTarget)}
          >
            {capitalize(viewType)}
          </Button>
          <StyledMenu anchorEl={periodAnchor} open={!!periodAnchor} onClose={() => setPeriodAnchor(undefined)}>
            {CALENDAR_VIEW_TYPES.map(type => (
              <StyledMenuItem onClick={() => setViewType(type)} key={type}>
                <ListItemIcon>{VIEW_TYPE_ICONS[type]}</ListItemIcon>
                <ListItemText>{type === "list" ? "Appointment List" : capitalize(type)}</ListItemText>
              </StyledMenuItem>
            ))}
            <Divider key="divider-1" />
            {hasRoleFlag(currentUser, "calendar-item-filters") && [
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("appointments")}
                isEnabled={typeFilters.appointments}
                text="Show Sessions"
                key="show-sessions"
              />,
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("indirectTime")}
                isEnabled={typeFilters.indirectTime}
                text="Show Indirect Time"
                key="show-indirect-time"
              />,
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("availabilities")}
                isEnabled={typeFilters.availabilities}
                text="Show Availabilities"
                key="show-availabilities"
              />,
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("focusTime")}
                isEnabled={typeFilters.focusTime}
                text="Show Focus Time"
                key="show-focus-time"
              />,
              <Divider key="divider-2" />,
            ]}
            <ToggleMenuItem
              toggle={calendarStore.toggleCancelFilter}
              isEnabled={calendarStore.isShowingCancelled}
              text="Show Cancelled Sessions"
              key="show-cancelled-sessions"
            />
            <ToggleMenuItem
              toggle={toggleWeekend}
              isEnabled={calendarStore.isShowingWeekends}
              text="Show Weekends"
              key="show-weekends"
            />
            {hasRoleFlag(currentUser, "calendar-custom-filters") && [
              <Divider key="divider-3" />,
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("consultAppointments")}
                isEnabled={typeFilters.consultAppointments}
                text="Show Consult Sessions"
                key="show-consult-sessions"
              />,
              <ToggleMenuItem
                toggle={() => calendarStore.toggleFilter("progressMeetings")}
                isEnabled={typeFilters.progressMeetings}
                text="Show Meetings"
                key="show-meetings"
              />,
            ]}
          </StyledMenu>

          <Button
            startIcon={<PublicIcon />}
            sx={{ height: 40, marginLeft: 1 }}
            onClick={e => setTimezoneAnchor(e.currentTarget)}
          >
            {shortTimeZone(now)}
          </Button>
          <StyledMenu anchorEl={timezoneAnchor} open={!!timezoneAnchor} onClose={() => setTimezoneAnchor(undefined)}>
            {getValidTimezones(now).map(({ name, friendly, isCurrent }) => (
              <StyledMenuItem onClick={() => saveTimezone(name)} selected={isCurrent} key={name}>
                {friendly}
              </StyledMenuItem>
            ))}
          </StyledMenu>

          <Button
            onClick={setPeriodToToday}
            startIcon={<TodayIcon />}
            sx={{ height: 40, paddingX: 2, displayPrint: "none" }}
          >
            Today
          </Button>
        </Stack>
      </Grid>

      <Grid size={{ xs: 4 }} sx={{ "@media print": { width: "100vw" } }}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          {!isListView && (
            <IconButton onClick={() => shiftPeriod(-1)}>
              <NavigateBeforeIcon />
            </IconButton>
          )}

          <Button
            variant="text"
            onClick={e => setWeekPickerAnchor(e.currentTarget)}
            sx={{ border: 1, borderColor: "grey.300" }}
          >
            <Typography variant="h2">{getRangeInputDisplay(viewType, period)}</Typography>
          </Button>
          <StyledMenu
            anchorEl={weekPickerAnchor}
            open={!!weekPickerAnchor}
            onClose={() => setWeekPickerAnchor(undefined)}
            sx={{ marginTop: 2 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <CalendarPeriodPicker
              viewType={viewType}
              period={period}
              onUpdate={(startTime, endTime) => setViewParams(startTime, { endTime })}
            />
          </StyledMenu>

          {!isListView && (
            <IconButton onClick={() => shiftPeriod(1)}>
              <NavigateNextIcon />
            </IconButton>
          )}
        </Stack>
      </Grid>

      <Grid size={{ xs: 4 }} sx={{ displayPrint: "none" }}>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end" sx={{ height: "100%" }}>
          {isListView && hasRoleFlag(currentUser, "full-org-calendar-access") && (
            <Tooltip title={isFilterExpanded ? "Show Calendar Select" : "Show List Filter"}>
              <IconButton onClick={() => setIsFilterExpanded(!isFilterExpanded)}>
                {isFilterExpanded ? (
                  <PeopleAltIcon />
                ) : (
                  <Badge
                    color="primary"
                    variant="dot"
                    overlap="circular"
                    invisible={isEmpty(appointmentKeywordFilters)}
                  >
                    <FilterAltIcon />
                  </Badge>
                )}
              </IconButton>
            </Tooltip>
          )}
          {showModelFilterSelect && (
            <Box width={300}>
              <AutoCompletePageSearchInput
                label={
                  selectedRelationFilter ? capitalize(selectedRelationFilter.type.toLowerCase()) : "Select Calendar For"
                }
                search={keyword => calendarApi.searchCalendarRelations({ keyword, types: availableRelationTypes })}
                getOption={toRelationOption}
                selected={selectedRelationFilter ? toRelationOption(selectedRelationFilter) : null}
                onSelect={selected =>
                  navigate(selected ? `/calendar/${selected.groupName?.toLowerCase()}/${selected.key}` : "/calendar", {
                    keepQuery: true,
                  })
                }
                size="small"
              />
            </Box>
          )}
          {showListFilter && <CalendarHeaderFilterInput />}

          {calendarStore.canWriteCalendar() && (
            <Button
              variant="contained"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ height: 40, displayPrint: "none" }}
              onClick={e => setCreateAnchor(e.currentTarget)}
              disabled={hasRoleFlag(currentUser, "full-org-calendar-access") && !selectedUserOption}
            >
              New
            </Button>
          )}
          <StyledMenu anchorEl={createAnchor} open={!!createAnchor} onClose={() => setCreateAnchor(undefined)}>
            <StyledMenuItem onClick={() => createSelected("appointment")}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText>Session</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("availability")}>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText>Availability</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("indirect-time")}>
              <ListItemIcon>
                <TimerIcon />
              </ListItemIcon>
              <ListItemText>Indirect Time</ListItemText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => createSelected("focus-time")}>
              <ListItemIcon>
                <HeadphonesIcon />
              </ListItemIcon>
              <ListItemText>Focus Time</ListItemText>
            </StyledMenuItem>
          </StyledMenu>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default observer(CalendarHeader);
