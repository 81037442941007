import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { useMountEffect } from "@react-hookz/web";
import { ObjectiveType, SetStudentGoalsBody, setStudentGoalsBodySchema } from "@parallel/vertex/types/progress.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import MultipleGoalInput from "@/components/progress/form/MultipleGoalInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { MultipleGoalParams, getInitialGoalParams, transformGoalObjectiveValues } from "@/util/progress.form.util";

const logger = initLogger("MultipleGoalForm", getLoggerContext);

const MultipleGoalForm = ({ onFinished }: { onFinished: () => void }) => {
  const {
    apiStore: { progressApi },
    progressStore: { currentStudentProgress, setStudentGoals },
  } = useContext(StoreContext);

  const [params, setParams] = useState<MultipleGoalParams>({
    goals: mapExists(currentStudentProgress?.goals, g => ({ goalId: g.goalId, ...getInitialGoalParams(g) })),
  });

  const [allObjectiveTypes, setAllObjectiveTypes] = useState<ObjectiveType[]>([]);
  useMountEffect(() =>
    progressApi.getAllObjectiveTypes().then(setAllObjectiveTypes).catch(logger.handleFailure("getAllObjectiveTypes")),
  );

  if (!currentStudentProgress) return <></>;

  const formContent = (
    <Box width={1000} height={500}>
      <MultipleGoalInput params={params} setParams={setParams} allObjectiveTypes={allObjectiveTypes} />
    </Box>
  );

  const validate = (params: MultipleGoalParams) =>
    setStudentGoalsBodySchema.safeParse({
      goals: params.goals.map(g => transformGoalObjectiveValues(g, allObjectiveTypes)),
    })?.data;

  const onSubmit = (request: SetStudentGoalsBody) =>
    setStudentGoals(request)
      .catch(logger.handleFailureAndThrow("setAllStudentGoals", { level: "warning" }))
      .then(onFinished);

  return (
    <SubmitForm
      recordName="goals"
      operationName="update"
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onFinished}
    />
  );
};

export default MultipleGoalForm;
