import { RefObject, useRef, useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useKeyboardEvent, useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { AssessmentBookRawData, AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { StimulusAPI } from "@/api/stimulus.api";
import SelectInput from "@/components/shared/input/SelectInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import MarginData from "@/components/stimulus/MarginData";
import { ImageMargins } from "@/components/stimulus/MarginSelect";
import PdfViewer from "@/components/stimulus/PdfViewer";
import StimulusBookForm from "@/components/stimulus/StimulusBookForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("StimulusUploadScreen", getLoggerContext);

const isMarginError = (m?: ImageMargins) => !!m && (m.left > m.right || m.top > m.bottom);

const uploadCanvas = (
  stimulusApi: StimulusAPI,
  canvasRef: RefObject<HTMLCanvasElement | undefined>,
  bookId: string,
  testId: string,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    if (!canvasRef.current) {
      reject(new Error("canvas ref not ready"));
      return;
    }
    try {
      canvasRef.current.toBlob(async imageData => {
        try {
          if (!imageData) {
            reject(new Error("unable to get blob from canvas"));
            return;
          }
          const file = await stimulusApi.uploadImage(bookId, testId, imageData);
          resolve(file);
        } catch (e) {
          logger.error("error uploading stimulus image", { causedBy: e });
          reject(e);
        }
      }, "image/png");
    } catch (e) {
      reject(e);
    }
  });
};

type StagedTest = Partial<{
  bookId: string;
  bookTests: AssessmentTestData[];
  testName: string;
  insertAfterTestId: string;
  isProviderOnly: boolean;
  providerMargin: ImageMargins;
  clientMargin: ImageMargins;
}>;

const resolveNewTestData = (
  { bookId, bookTests, testName, insertAfterTestId, providerMargin, clientMargin }: StagedTest,
  allBooks?: AssessmentBookRawData[],
) => {
  if (!bookId || !bookTests || !testName) return;
  if (isMarginError(providerMargin) || isMarginError(clientMargin)) return;

  const parentBook = allBooks?.find(b => b.id === bookId);
  if (!parentBook) return;

  const insertAfterTest = bookTests.find(t => t.id === insertAfterTestId);
  const orderIndex = insertAfterTest ? insertAfterTest.orderIndex + 1 : 0;

  return {
    parentBook,
    bookTests,
    newTest: {
      id: `${parentBook.testPrefix}-${uuid().split("-")[0]}`,
      name: testName,
      displayName: testName,
      orderIndex,
    },
    bumpOrderTests: bookTests.filter(t => t.orderIndex >= orderIndex),
  };
};

const StimulusUploadScreen = () => {
  const {
    apiStore: { stimulusApi },
    stimulusStore: { books: allBooks, fetchRawBooks },
  } = useContext(StoreContext);

  useMountEffect(() => {
    if (!allBooks) fetchRawBooks();
  });

  const [stagedTest, setStagedTest] = useState<StagedTest>({});

  const { bookId } = stagedTest;
  useEffect(() => {
    if (!bookId) return;
    stimulusApi.getBookMetadata(bookId).then(({ tests }) => setStagedTest({ ...stagedTest, bookTests: tests }));
  }, [bookId]);

  const [_message, setMessage] = useState<{ text: string; isError?: boolean; isSuccess?: boolean }>();

  const [showCreateBookModal, setShowCreateBookModal] = useState(false);

  const providerCanvasRef = useRef<HTMLCanvasElement>(null);
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const testNameInputRef = useRef<HTMLInputElement>(null);

  const newTestData = resolveNewTestData(stagedTest, allBooks);

  const writeTest = async () => {
    if (!newTestData) return;

    const { parentBook, newTest, bumpOrderTests } = newTestData;

    try {
      setMessage({ text: `writing test ${newTest.name} to book ${parentBook.id}` });
      logger.info("writing test", { context: { newTestData } });

      await Promise.all(
        bumpOrderTests.map(t => stimulusApi.updateTests(parentBook.id, [{ id: t.id, orderIndex: t.orderIndex + 1 }])),
      );

      const providerFile = await uploadCanvas(stimulusApi, providerCanvasRef, parentBook.id, `${newTest.id}-provider`);
      const clientFile = stagedTest.isProviderOnly
        ? undefined
        : await uploadCanvas(stimulusApi, clientCanvasRef, parentBook.id, `${newTest.id}-client`);

      const updatedBook = await stimulusApi.createTest(parentBook.id, {
        ...newTest,
        image: {
          staff: `/assessments/${parentBook.name}/${providerFile.name}`,
          staffMargin: stagedTest.providerMargin || { left: 0, top: 0, right: 0, bottom: 0 },
          client: clientFile ? `/assessments/${parentBook.name}/${clientFile.name}` : undefined,
          clientMargin: clientFile ? stagedTest.clientMargin : undefined,
        },
        audio: [],
      });
      const createdTest = updatedBook.tests.find(t => t.orderIndex === newTest.orderIndex);

      logger.info("wrote test record", { context: { updatedBook } });

      setStagedTest({
        ...stagedTest,
        bookTests: updatedBook.tests,
        insertAfterTestId: createdTest?.id,
      });
      setMessage({ text: `wrote test record ${createdTest?.id}`, isSuccess: true });
      testNameInputRef.current?.focus();
    } catch (e) {
      const msg = `error writing test record ${newTest.name}`;
      logger.error(msg, { causedBy: e });
      setMessage({ text: `${msg} - error: ${e}`, isError: true });
      throw e;
    }
  };

  useKeyboardEvent(
    e => e.ctrlKey && e.key === "Enter",
    e => {
      e.preventDefault();
      writeTest();
    },
  );

  return (
    <PrimaryLayout>
      <FullCenterBox>
        <Stack width="100%" height="100%" overflow="hidden">
          <Typography variant="h1" py={2}>
            Upload Stimulus Tests
          </Typography>

          <Grid container sx={{ width: "100%", flex: "1 1 0%", overflow: "hidden" }}>
            <Grid size={{ xs: 2 }} height="100%">
              <Stack gap={2} pr={1} height="100%" sx={{ overflowY: "auto" }}>
                <Typography variant="h2">Test Details</Typography>
                <SelectInput
                  label="Book"
                  size="small"
                  options={mapExists(allBooks, b => ({ key: b.id, label: b.name }))}
                  value={stagedTest.bookId}
                  onChange={bookId => setStagedTest({ ...stagedTest, bookId })}
                />
                <Button onClick={() => setShowCreateBookModal(true)}>Add New Book</Button>
                <TextField
                  label="Test Name"
                  size="small"
                  value={stagedTest.testName || ""}
                  onChange={e => setStagedTest({ ...stagedTest, testName: e.target.value })}
                  inputRef={testNameInputRef}
                />
                <SelectInput
                  label="Insert After"
                  size="small"
                  options={mapExists(stagedTest.bookTests, t => ({ key: t.id, label: t.name }))}
                  value={stagedTest.insertAfterTestId}
                  onChange={insertAfterTestId => setStagedTest({ ...stagedTest, insertAfterTestId })}
                />
                <FormControlLabel
                  label="Is Provider Only"
                  control={
                    <Checkbox
                      checked={stagedTest.isProviderOnly}
                      onChange={e => setStagedTest({ ...stagedTest, isProviderOnly: e.target.checked })}
                    />
                  }
                />
                <MarginData label="Provider Margins" margins={stagedTest.providerMargin} />
                <MarginData label="Client Margins" margins={stagedTest.clientMargin} />
                <ProcessButton variant="contained" process={writeTest} disabled={!newTestData}>
                  Submit
                </ProcessButton>
              </Stack>
            </Grid>

            <Grid size={{ xs: stagedTest.isProviderOnly ? 10 : 5 }} px={1} height="100%">
              <PdfViewer
                header="Provider Image"
                canvasRef={providerCanvasRef}
                margins={stagedTest.providerMargin}
                setMargins={providerMargin => setStagedTest({ ...stagedTest, providerMargin })}
              />
            </Grid>
            {!stagedTest.isProviderOnly && (
              <Grid size={{ xs: 5 }} px={1}>
                <PdfViewer
                  header="Client Image"
                  canvasRef={clientCanvasRef}
                  margins={stagedTest.clientMargin}
                  setMargins={clientMargin => setStagedTest({ ...stagedTest, clientMargin })}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </FullCenterBox>

      <CenterModal isOpen={showCreateBookModal} onClose={() => setShowCreateBookModal(false)}>
        <StimulusBookForm
          onCreated={b => {
            setStagedTest({ ...stagedTest, bookId: b.id, bookTests: undefined, insertAfterTestId: undefined });
            setShowCreateBookModal(false);
          }}
          onFinished={() => setShowCreateBookModal(false)}
        />
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(StimulusUploadScreen);
