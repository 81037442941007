import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { ImageMargins } from "@/components/stimulus/MarginSelect";

const MARGIN_KEYS = ["left", "top", "right", "bottom"] as const;

const MarginData = ({ label, margins }: { label?: string; margins?: ImageMargins }) => (
  <Stack gap={1}>
    {label && <Typography variant="h3">{label}</Typography>}
    {margins &&
      MARGIN_KEYS.map(key => (
        <Grid container key={key}>
          <Grid size={{ xs: 6 }}>
            <Typography variant="body1">{capitalize(key)}</Typography>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Typography variant="body1">{margins[key]}px</Typography>
          </Grid>
        </Grid>
      ))}
  </Stack>
);

export default MarginData;
