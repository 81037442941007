import { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { isEmpty, isNumber, mean } from "lodash";
import { DateTime } from "luxon";
import { CenterBox, FullCenterBox, FullStack, SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { SingleStudentAppointmentProgress } from "@parallel/vertex/types/progress.types";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { roundToDecimal } from "@parallel/vertex/util/number.util";
import AppointmentProgressData from "@/components/user/student/progress/AppointmentProgressData";
import ObjectiveListItem, { ObjectiveItemProps } from "@/components/user/student/progress/ObjectiveListItem";
import StudentObjectiveMetricChart from "@/components/user/student/progress/StudentObjectiveMetricChart";
import { StoreContext } from "@/stores";

const StudentObjectiveDetails = (props: ObjectiveItemProps) => {
  const { objective, goal } = props;

  const {
    apiStore: { progressApi },
    authStore: { timezone },
    progressStore: { currentStudentProgress: student },
  } = useContext(StoreContext);

  const [dateRange, setDateRange] = useState<Partial<DateTimeRange>>({
    startTime: objective.collectedAtFirst && toLocalDate(objective.collectedAtFirst, timezone),
    endTime: toLocalDate(DateTime.utc(), timezone).plus({ days: 1 }),
  });

  const [appointments, setAppointments] = useState<SingleStudentAppointmentProgress[]>();

  useEffect(() => {
    if (!student) return;
    setAppointments(undefined);
    progressApi.searchStudentProgress(student.userId, { ...dateRange, pageSize: 1000 }).then(page => {
      setAppointments(page.records);
    });
  }, [dateRange]);

  const numericMetricValues = mapExists(appointments, a => {
    const goalMatch = a.goals.find(g => g.goalId === goal.goalId);
    const objectiveMatch = goalMatch?.objectives.find(o => o.objectiveId === objective.objectiveId);
    const metricValue = objectiveMatch?.metric?.value;
    return isNumber(metricValue) ? metricValue : undefined;
  });
  const averageMetricValue = !isEmpty(numericMetricValues) ? roundToDecimal(mean(numericMetricValues), 2) : null;

  return (
    <FullCenterBox>
      <FullStack gap={2} pt={2} sx={{ maxWidth: 1600 }}>
        <SplitRow
          left={
            <Button startIcon={<ArrowBackIcon />} href={student && `/user/student/${student.userId}/goals`}>
              Back to goals list
            </Button>
          }
          right={
            <DateRangePicker
              label="Report Dates"
              value={[dateRange?.startTime || null, dateRange?.endTime || null]}
              onAccept={range =>
                setDateRange({
                  startTime: range[0] ? toLocalDate(range[0], timezone) : undefined,
                  endTime: range[1] ? toLocalDate(range[1], timezone) : undefined,
                })
              }
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: 360 }}
            />
          }
        />
        <ObjectiveListItem {...props} averageMetricValue={averageMetricValue} />
        {!appointments ? (
          <CenterBox width="100%">
            <CircularProgress />
          </CenterBox>
        ) : isEmpty(appointments) ? (
          <CenterBox width="100%">
            <Typography variant="body1">No Appointments Match Filters</Typography>
          </CenterBox>
        ) : (
          <>
            {appointments.length > 1 && objective.category !== "ANECDOTAL" && (
              <Box width="100%" maxHeight={200}>
                <StudentObjectiveMetricChart appointments={appointments} objective={objective} goalId={goal.goalId} />
              </Box>
            )}
            <Stack gap={1}>
              {appointments.map(a => (
                <AppointmentProgressData
                  progress={a}
                  shouldIncludeItem={item => item.type === "goal" || item.id === objective.objectiveId}
                  key={a.appointment.id}
                />
              ))}
            </Stack>
          </>
        )}
      </FullStack>
    </FullCenterBox>
  );
};

export default StudentObjectiveDetails;
