import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { ALL_APPROVAL_TYPES } from "@parallel/vertex/enums/approval.enums";
import { ApprovalType } from "@parallel/vertex/types/approval.types";
import SubHeader from "@/components/shared/layout/SubHeader";

const APPROVAL_TYPE_ICONS = {
  APPOINTMENT: <AccountCircleIcon />,
  TIME: <BadgeIcon />,
};

const APPROVAL_TYPE_LABELS = {
  APPOINTMENT: "Late Appointment Submissions",
  TIME: "Indirect Time Entries",
};

const ApprovalListHeader = ({ approvalType }: { approvalType: ApprovalType }) => {
  const navigate = useNavigate();

  return (
    <SubHeader
      icon={<ChecklistIcon />}
      title={"Time Tracking"}
      navigation={{
        tabs: ALL_APPROVAL_TYPES.map(type => ({
          key: type,
          label: APPROVAL_TYPE_LABELS[type],
          icon: APPROVAL_TYPE_ICONS[type],
        })),
        currentKey: approvalType,
        onChange: type => navigate(`/approval/${type.toLowerCase()}`),
      }}
    />
  );
};

export default ApprovalListHeader;
