import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { defaultTheme } from "@parallel/polygon/util/style.util";
import App from "./App.tsx";
import config from "./config.ts";
import "./main.scss";
import ErrorBoundaryScreen from "./screens/ErrorBoundaryScreen.tsx";

const isProd = config.parallelEnv === "production";

if (config.parallelEnv !== "local") {
  Sentry.init({
    dsn: config.sentryEndpoint,
    environment: config.parallelEnv,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: isProd,
        blockAllMedia: isProd,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["pathway-sentry-application-key"],
        behaviour: "apply-tag-if-contains-third-party-frames",
      }),
    ],
    profilesSampleRate: isProd ? 0.1 : 1.0,
    replaysOnErrorSampleRate: isProd ? 0.1 : 1.0,
    replaysSessionSampleRate: isProd ? 0.1 : 1.0,
    tracesSampleRate: isProd ? 0.1 : 1.0,
    beforeSend: (event, hint) => {
      if (hint.originalException instanceof AxiosError) {
        event.tags = { ...event.tags, warningType: "axios" };
        event.level = "warning";
      }
      return event;
    },
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryScreen />} showDialog>
      <GoogleOAuthProvider clientId={config.oauthClientId}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ThemeProvider theme={defaultTheme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
