import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react-lite";
import AutoSaveTextField from "@parallel/polygon/components/shared/input/AutoSaveTextField";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { CenterBox } from "@parallel/polygon/components/shared/layout/container";
import { AssessmentBookMetadata } from "@parallel/vertex/types/assessment.types";
import SelectInput from "@/components/shared/input/SelectInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import TestImageMarginInput, { ImageType } from "@/components/stimulus/TestImageMarginInput";
import { StoreContext } from "@/stores";

const TestList = ({ book }: { book: AssessmentBookMetadata }) => {
  const {
    stimulusStore: {
      updateTest,
      updateTests,
      displayGroupsByTestId,
      flatDisplayGroups,
      assignTestToDisplayGroupId,
      configGroupsByTestId,
      orderedConfigGroups,
      assignTestToConfigGroupId,
      groupSelection,
      deleteTest,
    },
  } = useContext(StoreContext);

  const { tests } = book;

  const swapTestOrder = async (ai: number, bi: number) => {
    const testA = tests[ai];
    const testB = tests[bi];
    if (!testA || !testB) return;

    return updateTests([
      { id: testA.id, orderIndex: testB.orderIndex },
      { id: testB.id, orderIndex: testA.orderIndex },
    ]);
  };

  const [marginEdit, setMarginEdit] = useState<{ testIndex: number; imageType: ImageType }>();

  return (
    <Stack gap={2}>
      {tests.map((test, i) => (
        <Stack
          gap={2}
          bgcolor="white"
          sx={{ p: 1, pt: 2, bgcolor: "white", border: 1, borderColor: "grey.300", borderRadius: 1 }}
          key={test.id}
        >
          <AutoSaveTextField
            label="Name"
            size="small"
            value={test.displayName || test.name}
            onSave={displayName => updateTest({ id: test.id, displayName })}
            saveKey={test.id}
            statusIcon
          />
          <Stack direction="row" gap={1}>
            <AutoSaveTextField
              label="Client Image Path"
              size="small"
              value={test.image.client || ""}
              onSave={path => updateTest({ id: test.id, image: { ...test.image, client: path } })}
              saveKey={test.id}
              fullWidth
              statusIcon
            />
            <CenterBox sx={{ width: 30, flexShrink: 0 }}>
              <IconButton size="small" onClick={() => setMarginEdit({ testIndex: i, imageType: "client" })}>
                <HighlightAltIcon fontSize="small" />
              </IconButton>
            </CenterBox>
          </Stack>
          <Stack direction="row" gap={1}>
            <AutoSaveTextField
              label="Provider Image Path"
              size="small"
              value={test.image.staff || ""}
              onSave={path => updateTest({ id: test.id, image: { ...test.image, staff: path } })}
              saveKey={test.id}
              fullWidth
              statusIcon
            />
            <CenterBox sx={{ width: 30, flexShrink: 0 }}>
              <IconButton size="small" onClick={() => setMarginEdit({ testIndex: i, imageType: "staff" })}>
                <HighlightAltIcon fontSize="small" />
              </IconButton>
            </CenterBox>
          </Stack>
          <Stack direction="row" gap={1}>
            <SelectInput
              label="Display Group"
              size="small"
              options={flatDisplayGroups.map(g => ({ key: g.id, label: g.name }))}
              value={displayGroupsByTestId[test.id]?.id}
              onChange={groupId => assignTestToDisplayGroupId(test.id, groupId)}
              fullWidth
            />
            <Tooltip title="Add to selected display group">
              <CenterBox sx={{ width: 30, flexShrink: 0 }}>
                <ProcessIconButton
                  size="small"
                  process={
                    groupSelection
                      ? () => assignTestToDisplayGroupId(test.id, groupSelection.subgroupId || groupSelection.groupId)
                      : undefined
                  }
                  disabled={groupSelection?.groupType !== "display"}
                >
                  <AddIcon fontSize="small" />
                </ProcessIconButton>
              </CenterBox>
            </Tooltip>
          </Stack>
          <Stack direction="row" gap={1}>
            <SelectInput
              label="Config Group"
              size="small"
              options={orderedConfigGroups.map(g => ({ key: g.id, label: g.name }))}
              value={configGroupsByTestId[test.id]?.id}
              onChange={groupId => assignTestToConfigGroupId(test.id, groupId)}
              fullWidth
            />
            <Tooltip title="Add to selected config group">
              <CenterBox sx={{ width: 30, flexShrink: 0 }}>
                <ProcessIconButton
                  size="small"
                  process={
                    groupSelection ? () => assignTestToConfigGroupId(test.id, groupSelection.groupId) : undefined
                  }
                  disabled={groupSelection?.groupType !== "config"}
                >
                  <AddIcon fontSize="small" />
                </ProcessIconButton>
              </CenterBox>
            </Tooltip>
          </Stack>
          <Stack direction="row" gap={1}>
            <ProcessIconButton size="small" process={() => deleteTest(test.id)}>
              <DeleteIcon fontSize="small" />
            </ProcessIconButton>
            <ProcessIconButton size="small" process={() => swapTestOrder(i, i - 1)} disabled={i === 0}>
              <ArrowUpwardIcon fontSize="small" />
            </ProcessIconButton>
            <ProcessIconButton size="small" process={() => swapTestOrder(i, i + 1)} disabled={i === tests.length - 1}>
              <ArrowDownwardIcon fontSize="small" />
            </ProcessIconButton>
          </Stack>
        </Stack>
      ))}

      <CenterModal isOpen={!!marginEdit} onClose={() => setMarginEdit(undefined)}>
        {marginEdit && (
          <TestImageMarginInput
            bookId={book.bookId}
            test={tests[marginEdit.testIndex]}
            imageType={marginEdit.imageType}
            onNext={() =>
              tests[marginEdit.testIndex + 1]
                ? setMarginEdit({ ...marginEdit, testIndex: marginEdit.testIndex + 1 })
                : undefined
            }
            onPrev={() =>
              tests[marginEdit.testIndex - 1]
                ? setMarginEdit({ ...marginEdit, testIndex: marginEdit.testIndex - 1 })
                : undefined
            }
            key={marginEdit.testIndex}
          />
        )}
      </CenterModal>
    </Stack>
  );
};

export default observer(TestList);
