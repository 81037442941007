import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { hasGenericRole } from "@parallel/vertex/role/role.generic";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import TestGroupDetails from "@/components/stimulus/TestGroupDetails";
import TestGroupList from "@/components/stimulus/TestGroupList";
import TestList from "@/components/stimulus/TestList";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const StimulusBookEditScreen = () => {
  const {
    authStore: { currentUser },
    stimulusStore: { activeBook: book, selectBook, selectedRootGroup, orderedParentDisplayGroups },
  } = useContext(StoreContext);

  const hasAccess = hasGenericRole(currentUser, "edit-assessment-stimuli");

  const { bookId } = useParams();
  useEffect(() => {
    if (!bookId || !hasAccess) return;
    selectBook(bookId);
  }, [bookId]);

  if (!hasAccess) return <NotFoundScreen />;
  if (!book) return <LoadingScreen />;

  return (
    <PrimaryLayout>
      <FullCenterBox>
        <Stack width={1200} height="100%" overflow="hidden" pt={2}>
          <Typography variant="h1" py={2}>
            Edit Stimulus Book - {book.name}
          </Typography>

          <Grid container>
            <Grid size={{ xs: 4 }}>
              <Typography variant="h2" p={1}>
                Tests
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Typography variant="h2" p={1}>
                Groups
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Typography variant="h2" p={1}>
                Selected Group
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            flex="1 1 0%"
            sx={{ overflowY: "hidden", bgcolor: "grey.100", border: 1, borderColor: "grey.300" }}
          >
            <Grid
              size={{ xs: 4 }}
              sx={{ height: "100%", p: 1, overflowY: "auto", borderRight: 1, borderColor: "grey.300" }}
            >
              <TestList book={book} />
            </Grid>
            <Grid
              size={{ xs: 4 }}
              sx={{ height: "100%", p: 1, overflowY: "auto", borderRight: 1, borderColor: "grey.300" }}
            >
              <Typography variant="h3" mb={2} mt={1} px={1}>
                Display Groups
              </Typography>
              <TestGroupList
                groups={orderedParentDisplayGroups.map(g => ({ type: "display", ...g }))}
                groupType="display"
              />
              <Typography variant="h3" mb={2} mt={4} px={1}>
                Configuration Groups
              </Typography>
              <TestGroupList groups={book.configGroups.map(g => ({ type: "config", ...g }))} groupType="config" />
            </Grid>
            <Grid size={{ xs: 4 }} sx={{ height: "100%", p: 1, overflowY: "auto" }}>
              {selectedRootGroup ? (
                <TestGroupDetails group={selectedRootGroup} allTests={book.tests} />
              ) : (
                <Typography variant="body1" p={1}>
                  Select a group from the center column to view details here
                </Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
      </FullCenterBox>
    </PrimaryLayout>
  );
};

export default observer(StimulusBookEditScreen);
