import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMeasure } from "@react-hookz/web";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { ReportEditorBlock } from "@parallel/vertex/types/report.types";
import { resolveBlockTableWarning } from "@parallel/vertex/util/report.util";
import BlockContentInput from "@/components/report/editor/content/BlockContentInput";
import BlockTable from "@/components/report/editor/content/BlockTable";
import StaticBlockContent from "@/components/report/editor/content/StaticBlockContent";
import BlockMarginBox from "@/components/report/editor/margin/BlockMarginBox";
import BlockNote from "@/components/report/editor/margin/BlockNote";
import RightMarginBox from "@/components/report/editor/margin/RightMarginBox";
import { StoreContext } from "@/stores";

const ReportEditorBlockComponent = ({ block }: { block: ReportEditorBlock }) => {
  const {
    reportStore: { currentReport, upsertCustomBlock },
  } = useContext(StoreContext);

  const [areCommentsExpanded, setAreCommentsExpanded] = useState(
    currentReport?.approval?.approvalStatus === "FOR_REVIEW" && !isEmpty(block.custom?.comments),
  );

  const [isTableWarningCollapsed, setIsTableWarningCollapsed] = useState(block.custom?.isTableWarningNoteCollapsed);

  const toggleTableWarningCollapsed = () => {
    const updatedFlag = !isTableWarningCollapsed;
    setIsTableWarningCollapsed(updatedFlag);
    upsertCustomBlock(block.reportBlockTemplateId, { isTableWarningNoteCollapsed: updatedFlag });
  };

  const [contentMeasure, contentRef] = useMeasure<HTMLDivElement>();
  const [tableMeasure, tableRef] = useMeasure<HTMLDivElement>();

  const blockTableWarning = resolveBlockTableWarning(block);

  return (
    <Stack gap={2} position="relative">
      <Box
        width="100%"
        sx={{
          fontFamily: "Matter",
          "& p": {
            marginBlockStart: "1em",
            marginBlockEnd: "1em",
          },
          "& p:first-of-type": {
            marginTop: 0,
          },
          "& .rich-header": {
            display: "flex",
            gap: 4,
            alignItems: "start",
            "& img": {
              width: "160px",
            },
          },
        }}
        className="report-editor-block"
        ref={contentRef}
      >
        {block.canEdit ? (
          <BlockContentInput
            block={block}
            areCommentsExpanded={areCommentsExpanded}
            setAreCommentsExpanded={setAreCommentsExpanded}
          />
        ) : (
          <StaticBlockContent block={block} />
        )}
      </Box>

      {block.canEdit && (
        <BlockMarginBox
          block={block}
          areCommentsExpanded={areCommentsExpanded}
          setAreCommentsExpanded={setAreCommentsExpanded}
          maxHeight={contentMeasure?.height}
        />
      )}

      {block.table && (
        <Box position="relative" ref={tableRef}>
          <BlockTable block={block} />
          {blockTableWarning && !currentReport?.approval && (
            <RightMarginBox maxHeight={tableMeasure?.height}>
              <BlockNote
                text={blockTableWarning}
                severity="warn"
                isCollapsed={isTableWarningCollapsed}
                toggleCollapsed={toggleTableWarningCollapsed}
              />
            </RightMarginBox>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default observer(ReportEditorBlockComponent);
