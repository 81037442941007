import { useContext, useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isEmpty, pick } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { TimeEntryPermissions } from "@parallel/vertex/role/role.time";
import { SYSTEM_START_DATE } from "@parallel/vertex/types/calendar/calendar.types";
import {
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  TaskType,
  createIndirectTimeBodySchema,
} from "@parallel/vertex/types/calendar/time.types";
import { toArray } from "@parallel/vertex/util/collection.util";
import { safeIsInRange } from "@parallel/vertex/util/datetime.util";
import TaskTypeInput from "@/components/calendar/time/TaskTypeInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import RecurrenceEditModeInput from "@/components/shared/input/RecurrenceEditModeInput";
import RecurrenceInput from "@/components/shared/input/RecurrenceInput";
import TimeRangeInput from "@/components/shared/input/TimeRangeInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { UserOption } from "@/components/user/input/UserInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type IndirectTimeParams = Partial<
  Omit<CreateIndirectTimeBody, "userId" | "taskTypeId" | "studentUserIds" | "campusId">
> & {
  user: SelectOption | null;
  taskType: TaskType | null;
  students: SelectOption[];
  campus: SelectOption | null;
};

const MAX_DURATION_HOURS = 8;

const logger = initLogger("IndirectTimeForm", getLoggerContext);

const IndirectTimeForm = ({
  editing,
  selectedUser,
  onClose,
  permissions,
}: {
  editing?: ExtendedTimeEntry;
  selectedUser?: UserOption;
  onClose: () => void;
  permissions: TimeEntryPermissions;
}) => {
  const {
    apiStore: { institutionApi, userApi },
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const [params, setParams] = useState<IndirectTimeParams>({
    ...calendarStore.defaultTimeRange,
    ...editing,
    user:
      selectedUser?.userType === "PROVIDER"
        ? { key: selectedUser.userId, label: selectedUser.fullName }
        : editing
          ? { key: editing.userId, label: editing.user.fullName }
          : null,
    taskType: editing?.taskType || null,
    students: editing ? editing.students.map(s => ({ key: s.userId, label: s.fullName })) : [],
    campus: editing?.campus ? { key: editing.campus.institutionId, label: editing.campus.salesforceName } : null,
  });

  const currentTypeCategory = params.taskType?.category;
  const currentTypeCategoryConfig = currentTypeCategory ? TYPE_CATEGORY_CONFIG[currentTypeCategory] : undefined;
  if (currentTypeCategoryConfig && params.taskType?.title === "Supervision")
    currentTypeCategoryConfig.student = "required";

  const { currentPayPeriod, previousPayPeriod } = calendarStore;

  const validStartRange = !hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period")
    ? { startTime: SYSTEM_START_DATE, endTime: DateTime.utc() }
    : params.taskType?.canSchedule
      ? { startTime: SYSTEM_START_DATE, endTime: undefined }
      : { startTime: previousPayPeriod?.startTime, endTime: DateTime.utc() };

  const isLateSubmission =
    params.startTime &&
    previousPayPeriod &&
    params.startTime > previousPayPeriod.startTime &&
    currentPayPeriod &&
    params.startTime < currentPayPeriod.startTime;

  useEffect(() => {
    if (currentTypeCategory === "ASSESSMENT") {
      setParams({ ...params, students: [] });
    }
  }, [currentTypeCategory]);

  const isDescriptionRequired = useMemo(() => {
    if (!params.startTime || !params.endTime || !params.taskType?.descriptionRequiredMinutes) return false;

    return params.endTime.diff(params.startTime, "minutes").minutes >= params.taskType.descriptionRequiredMinutes;
  }, [params]);

  const formContent = (
    <>
      {!permissions.defaults?.userId && (
        <AutoCompletePageSearchInput
          label="Provider"
          search={keyword => userApi.searchProviders({ keyword }).catch(logger.handleFailure("searchProviders"))}
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={params.user}
          onSelect={user => setParams({ ...params, user })}
        />
      )}

      <TaskTypeInput
        providerId={params.user?.key}
        selected={params.taskType}
        onSelect={taskType => setParams({ ...params, taskType })}
        isSplit
      />

      {params.taskType?.description && (
        <GreyBox sx={{ maxWidth: "524px" }}>
          <Typography variant="subtitle2" fontWeight="normal" fontStyle="italic">
            {params.taskType.description}
          </Typography>
        </GreyBox>
      )}

      {currentTypeCategoryConfig?.student && (
        <AutoCompletePageSearchInput
          label={currentTypeCategoryConfig.student === "required" ? "Student(s)" : "Student (optional)"}
          search={keyword =>
            userApi.searchStudents({
              keyword,
              providerId: params.user?.key,
              hasAssessmentAssignment: currentTypeCategory === "ASSESSMENT",
            })
          }
          getOption={s => ({ key: s.userId, label: s.fullName })}
          selected={
            currentTypeCategoryConfig.student === "single-optional" ? params.students[0] || null : params.students
          }
          onSelect={selected => setParams({ ...params, students: toArray(selected) })}
        />
      )}

      {currentTypeCategoryConfig?.campus && (
        <AutoCompletePageSearchInput
          label="Campus"
          search={name =>
            institutionApi
              .searchCampuses({ name, providerId: params.user?.key })
              .catch(logger.handleFailure("searchCampuses"))
          }
          getOption={c => ({
            key: c.institutionId,
            label: c.salesforceName,
            groupName: c.district?.salesforceName || "No District",
          })}
          selected={params.campus}
          onSelect={campus => setParams({ ...params, campus })}
        />
      )}

      <TimeRangeInput
        value={pick(params, "startTime", "endTime")}
        onChange={range => setParams({ ...params, ...range })}
        minStart={validStartRange?.startTime}
        maxStart={validStartRange?.endTime}
      />

      {hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period") && isLateSubmission && (
        <GreyBox sx={{ maxWidth: "524px" }}>
          <Typography variant="subtitle2" fontWeight="normal" fontStyle="italic">
            This task is scheduled for the pay period that just closed, and will go through an additional approval
            process in order to be paid. Payments for these late submissions may not be applied to the original pay
            period but will be processed and reflected in the following pay cycle.
          </Typography>
        </GreyBox>
      )}

      {(!editing || editing.recurrence) && params.taskType?.canSchedule && !isLateSubmission && (
        <RecurrenceInput
          recurrence={params.recurrence}
          onChange={recurrence => setParams({ ...params, recurrence })}
          eventStartTime={params.startTime || calendarStore.defaultTimeRange.startTime}
        />
      )}

      <TextField
        multiline
        rows={2}
        required={isDescriptionRequired}
        label={isDescriptionRequired ? "Description" : "Description (optional)"}
        value={params.description || ""}
        onChange={e => setParams({ ...params, description: e.target.value })}
      />
    </>
  );

  const onSubmit = async (body: CreateIndirectTimeBody) => {
    editing
      ? await calendarStore
          .updateIndirectTime(editing.timeEntryId, body)
          .catch(logger.handleFailureAndThrow("updateIndirectTime", { level: "warning" }))
      : await calendarStore
          .createIndirectTime(body)
          .catch(logger.handleFailureAndThrow("createIndirectTime", { level: "warning" }));

    onClose();
  };

  const validate = (params: IndirectTimeParams) => {
    if (currentTypeCategoryConfig?.student === "required" && isEmpty(params.students)) return undefined;
    if (currentTypeCategoryConfig?.campus === "required" && isEmpty(params.campus)) return undefined;
    if (isDescriptionRequired && (!params.description || params.description === "")) return undefined;

    if (params.startTime && !safeIsInRange(validStartRange, params.startTime)) return undefined;
    if (params.startTime && params.endTime && params.endTime.diff(params.startTime, "hours").hours > MAX_DURATION_HOURS)
      return undefined;

    return createIndirectTimeBodySchema.safeParse({
      ...params,
      userId: params.user?.key,
      taskTypeId: params.taskType?.taskTypeId,
      studentUserIds: params.students.map(s => s.key),
      campusId: params.campus?.key,
    })?.data;
  };

  const confirmation = editing?.recurrence
    ? {
        prompt: async () => setParams({ ...params, recurrenceEditMode: "single" }),
        content: params.recurrenceEditMode && (
          <RecurrenceEditModeInput
            params={params}
            setParams={setParams}
            actionName="update"
            recordName={{ singular: "indirect time", plural: "indirect time" }}
          />
        ),
      }
    : undefined;

  return (
    <SubmitForm
      recordName="indirect time"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
      confirmation={confirmation}
    />
  );
};

export default observer(IndirectTimeForm);
