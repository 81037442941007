import { UserTypeValues } from "../enums/prisma.enums";
import { ProviderType, UserType } from "../enums/user.enums";
import { UserPermissions } from "./role.user";

export type PartialUser = { userType: UserType; providerType?: ProviderType } | undefined;

export type AccessLevel = "read" | "write";

export type PartialGrant = {
  accessLevels: AccessLevel[];
  validateRequestor?: (u: PartialUser) => boolean;
};

export const getDataPermissionResolver =
  <A extends PartialGrant>(grants: Partial<Record<UserType, A>>) =>
  (user: PartialUser, accessLevel: AccessLevel): A | null => {
    if (!user) return null;

    const permission = grants[user.userType];
    if (!permission || !permission.accessLevels.includes(accessLevel)) return null;
    if (permission?.validateRequestor && !permission.validateRequestor(user)) return null;

    return permission;
  };

export const getRequestorTypeResolver =
  <A extends PartialGrant>(grants: Partial<Record<UserType, A>>) =>
  (level: AccessLevel): UserType[] =>
    UserTypeValues.filter(userType => {
      const permission = grants[userType];
      if (!permission || !permission.accessLevels.includes(level)) return false;
      return true;
    });

export const FULL_ACCESS_PERMISSIONS: UserPermissions = {
  accessLevels: ["read", "write"],
  allowEditIf: () => true,
  allowDeleteIf: () => true,
};
