import { isEmpty } from "lodash";
import { DayOfWeekValues } from "@parallel/vertex/enums/prisma.enums";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { DayOfWeek, ExtendedRecurrence } from "@parallel/vertex/types/calendar/recurrence.types";
import { TaskTypeCategory } from "@parallel/vertex/types/calendar/time.types";
import { isConsultAppointment, isProgressAppointment } from "@parallel/vertex/util/appointment.util";
import { getCommaList, toTitleCase } from "@parallel/vertex/util/string.util";
import config from "@/config";

export const DEFAULT_MIN_CALENDAR_HOUR = 0;
export const DEFAULT_MAX_CALENDAR_HOUR = 24;

type TaskTypeGroupConfig = {
  title: string;
  student?: "required" | "single-optional";
  campus?: "required";
};

export const TYPE_CATEGORY_CONFIG: Record<TaskTypeCategory, TaskTypeGroupConfig> = {
  ASSESSMENT: {
    title: "Assessments and Evaluations",
    student: "required",
  },
  CASE_MANAGEMENT: {
    title: "Case Management",
    student: "required",
  },
  PARALLEL_ACTIVITY: {
    title: "Parallel Activities",
  },
  SCHOOL_MEETING: {
    title: "School Meetings",
    campus: "required",
  },
  THERAPY: {
    title: "Session Delivery",
    student: "single-optional",
    campus: "required",
  },
};

export const recurrenceText = (recurrence: ExtendedRecurrence) => {
  const periodUnitName = recurrence.periodUnit.toLowerCase();
  const periodPart = recurrence.periodSize === 1 ? periodUnitName : `${recurrence.periodSize} ${periodUnitName}s`;

  let daysPart = "";
  if (!isEmpty(recurrence.daysOfWeek)) {
    const sortedDays = recurrence.daysOfWeek
      .sort(
        (a, b) =>
          DayOfWeekValues.indexOf(a.toUpperCase() as DayOfWeek) - DayOfWeekValues.indexOf(b.toUpperCase() as DayOfWeek),
      )
      .map(day => toTitleCase(day));
    daysPart = ` on ${getCommaList(sortedDays)}`;
  }

  return `Repeats every ${periodPart}${daysPart}`;
};

export const resolveShouldSkipAttendance = (appointment: ExtendedAppointment) =>
  isConsultAppointment(appointment) || isProgressAppointment(appointment, config);
