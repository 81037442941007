import { DateTime } from "luxon";
import { z } from "zod";
import {
  DateTimeRange,
  Override,
  datetimeQueryField,
  datetimeRequestField,
  paginateQueryFields,
} from "../shared.types";
import { ExtendedAppointment } from "./appointment.types";
import { ExtendedCalendarBlock } from "./calendar.block.types";
import { ExtendedTimeEntry } from "./time.types";

export const SYSTEM_START_DATE = DateTime.fromISO("2024-08-01");

export type ExtendedCalendarItem<A> = Override<
  A,
  { archivedAt: DateTime | null; createdAt: DateTime; updatedAt: DateTime; startTime: DateTime; endTime: DateTime }
>;

export type CalendarItems = {
  appointments: ExtendedAppointment[];
  blocks: ExtendedCalendarBlock[];
  indirectTime: ExtendedTimeEntry[];
};

export const timeRangeFields = {
  startTime: datetimeRequestField,
  endTime: datetimeRequestField,
};

export const timeRangeQuerySchema = z.object({
  startTime: datetimeQueryField.optional(),
  endTime: datetimeQueryField.optional(),
});

export const timeRangeRefine: [(range: Partial<DateTimeRange>) => any, { message: string }] = [
  range => !range.startTime || !range.endTime || range.endTime > range.startTime,
  { message: "endTime must come after startTime" },
];

const searchUserCalendarItemQuerySchema = timeRangeQuerySchema.extend({
  userIds: z.string().uuid().array().optional(),
  recurrenceId: z.string().uuid().optional(),
  ...paginateQueryFields,
});

export type SearchUserCalendarItemQuery = z.infer<typeof searchUserCalendarItemQuerySchema>;

export const searchCalendarQuerySchema = z.object({
  startTime: datetimeQueryField,
  endTime: datetimeQueryField,
  userId: z.string().uuid().optional(),
  studentId: z.string().uuid().optional(),
  providerId: z.string().uuid().optional(),
  slpaId: z.string().uuid().optional(),
  facilitatorId: z.string().uuid().optional(),
  districtUserId: z.string().uuid().optional(),
  campusId: z.string().uuid().optional(),
});

export type SearchCalendarQuery = z.infer<typeof searchCalendarQuerySchema>;

export type DisplayRangeFilter = { displayRangeStart?: string; displayRangeEnd?: string };

export const ALL_CALENDAR_RELATION_TYPES = ["FACILITATOR", "SLPA", "PROVIDER", "STUDENT", "CAMPUS"] as const;
const calendarRelationTypeEnum = z.enum(ALL_CALENDAR_RELATION_TYPES);
export type CalendarRelationType = z.infer<typeof calendarRelationTypeEnum>;

export type CalendarRelation = {
  key: string;
  label: string;
  type: CalendarRelationType;
};

export const searchCalendarRelationQuerySchema = z.object({
  pageSize: z.number().min(1).optional(),
  keyword: z.string().trim().optional(),
  types: calendarRelationTypeEnum.array(),
  districtUserId: z.string().uuid().optional(),
});
export type SearchCalendarRelationQuery = z.infer<typeof searchCalendarRelationQuerySchema>;
