import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { ReportEditorParentSection } from "@parallel/vertex/types/report.types";
import ReportEditorSection from "@/components/report/editor/ReportEditorSection";
import LoadingScreen from "@/screens/LoadingScreen";
import { StoreContext } from "@/stores";

export const REPORT_CONTENT_WIDTH = 840;

const ReportEditor = ({ title, editorSections }: { title: string; editorSections: ReportEditorParentSection[] }) => {
  const {
    reportStore: { isEditorLoading },
  } = useContext(StoreContext);

  if (isEditorLoading) return <LoadingScreen />;

  return (
    <FullBox>
      <Stack width={REPORT_CONTENT_WIDTH} p={2} gap={4} sx={{ border: 2, borderColor: "grey.300" }}>
        <Typography variant="h1">{title}</Typography>

        {editorSections.map(section => (
          <ReportEditorSection section={section} key={section.reportSectionTemplateId} />
        ))}
      </Stack>
    </FullBox>
  );
};

export default observer(ReportEditor);
