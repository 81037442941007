import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import GoalActions from "@/components/progress/GoalActions";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

export type GoalDisplayProps = {
  goal: StudentGoal;
  goalIndex: number;
  onEdit: () => void;
  onObjectiveSelect: (objective: StudentObjective) => void;
};

const logger = initLogger("GoalDisplay", getLoggerContext);

const GoalDisplay = ({ goal, goalIndex, onEdit, onObjectiveSelect }: GoalDisplayProps) => {
  return (
    <FullStack role="region">
      <Stack pb={2} gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Goal {goalIndex}.</Typography>

          <Stack direction="row" gap={1}>
            <GoalActions goal={goal} displayIndex={goalIndex} onEdit={onEdit} logger={logger} />
          </Stack>
        </Stack>

        <Typography>{goal.description}</Typography>
      </Stack>

      {goal.objectives.map((objective, i) => (
        <Stack py={2} gap={1} key={objective.objectiveId}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Link onClick={() => onObjectiveSelect(objective)} sx={{ cursor: "pointer", textDecoration: "none" }}>
              <Typography variant="h3" sx={{ color: "primary.main" }}>
                {toTitleCase(objective.category)} Objective {goalIndex}.{i + 1}
              </Typography>
            </Link>

            <ObjectiveActions
              objective={objective}
              goal={goal}
              displayIndices={{ goal: goalIndex, objective: i + 1 }}
            />
          </Stack>

          <Typography variant="body1">{objective.description}</Typography>
        </Stack>
      ))}
    </FullStack>
  );
};

export default GoalDisplay;
