import { useContext, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import printJS from "print-js";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { AdminFileKey, AllAdminFileKeys } from "@parallel/vertex/enums/report.file.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SelectInput from "@/components/shared/input/SelectInput";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportProtocolsScreen", getLoggerContext);

const ProtocolsScreen = () => {
  const {
    apiStore: { userApi, reportApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [student, setStudent] = useState<SelectOption | null>(null);
  const [fileKey, setFileKey] = useState<AdminFileKey>();

  if (!hasRoleFlag(currentUser, "download-protocols")) return <NotFoundScreen />;

  const performDownload = async () => {
    if (!student || !fileKey) return;
    return reportApi.getProtocolFile(fileKey, student.key).then(url => {
      try {
        printJS(url);
      } catch (e) {
        logger.handleFailureAndThrow("downloadProtocol")(e);
      }
    });
  };

  return (
    <PrimaryLayout>
      <FullCenterBox>
        <Stack width={800} height="100%" pt={8} gap={4}>
          <Stack gap={2}>
            <Typography variant="body1">
              To print assessment protocol, first select the student being tested.
            </Typography>
            <AutoCompletePageSearchInput
              label="Student"
              search={keyword => userApi.searchStudents({ keyword })}
              getOption={s => ({ key: s.userId, label: s.fullName })}
              selected={student}
              onSelect={setStudent}
            />
          </Stack>
          {student && (
            <>
              <Stack gap={2}>
                <Typography variant="body1">Next, select the test being administered.</Typography>
                <SelectInput
                  label="Test"
                  options={AllAdminFileKeys.map(key => ({ key, label: key }))}
                  value={fileKey}
                  onChange={setFileKey}
                />
              </Stack>
              <Box>
                <ProcessButton
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  process={performDownload}
                  disabled={!student || !fileKey}
                >
                  Download
                </ProcessButton>
              </Box>
            </>
          )}
        </Stack>
      </FullCenterBox>
    </PrimaryLayout>
  );
};

export default ProtocolsScreen;
