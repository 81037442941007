import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { lighten, useTheme } from "@mui/material/styles";
import { isUndefined } from "lodash";
import { DateTime, DurationLike } from "luxon";
import { observer } from "mobx-react-lite";
import { ExtendedTask, TaskCategory } from "@parallel/vertex/types/task.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import DataTable, { ChipColor, DataTableColumn, renderChip } from "@/components/shared/layout/DataTable";
import TaskQuicklink from "@/components/task/TaskQuicklink";
import { StoreContext } from "@/stores";

type TaskRowDueDate = {
  label: string;
  chipColor?: ChipColor | null;
};

type TaskRow = {
  task: ExtendedTask;
  key: string;
  category: string;
  title: string;
  dueDate: TaskRowDueDate;
  studentNames: string[];
};

const renderDueDate = ({ label, chipColor }: TaskRowDueDate) => {
  if (isUndefined(chipColor))
    return (
      <Stack height="100%" direction="row" alignItems="center">
        <Typography variant="body2">{label}</Typography>
      </Stack>
    );
  return renderChip([{ title: label }], { color: chipColor || undefined });
};

const TASK_COLUMNS: DataTableColumn<TaskRow>[] = [
  { key: "key", header: "ID", hidden: true, isID: true },
  { key: "category", header: "Task Type" },
  { key: "title", header: "Title" },
  { key: "dueDate", header: "Due Date", renderCell: params => renderDueDate(params.value) },
  {
    key: "studentNames",
    header: "Student(s)",
    renderCell: params => renderChip(params.value.map((title: string) => ({ title }))),
  },
  {
    key: "task",
    header: "Quicklink",
    renderCell: params => <TaskQuicklink task={params.value} />,
  },
];

const isWithinDuration = (date: DateTime, start: DateTime, duration: DurationLike) =>
  date >= start && date < start.plus(duration);

const getDueDateLabel = (dueDate: DateTime, timezone: string) => {
  const todayStart = toLocalDate(DateTime.utc(), timezone).set({ hour: 0 });
  if (isWithinDuration(dueDate, todayStart, { days: 1 })) return "Today";

  if (isWithinDuration(dueDate, todayStart.plus({ days: 1 }), { days: 1 })) return "Tomorrow";
  if (isWithinDuration(dueDate, todayStart.minus({ days: 1 }), { days: 1 })) return "Yesterday";

  const weekStart = todayStart.startOf("week");
  if (isWithinDuration(dueDate, weekStart, { days: 7 })) return dueDate.toLocaleString({ weekday: "long" });

  return dueDate.toLocaleString({ month: "short", day: "numeric", year: "numeric" });
};

const getDueDateColor = ({ dueDate, completedAt }: ExtendedTask): ChipColor | undefined | null => {
  if (completedAt) return undefined;

  const now = DateTime.utc();
  if (dueDate < now) return "error";

  if (dueDate < now.plus({ weeks: 1 })) return "warning";

  return null;
};

const getCategoryLabel = (category: TaskCategory): string => {
  switch (category) {
    case "IEP_DUE":
      return "IEP Due";
    default:
      return toTitleCase(category);
  }
};

const toTaskRow = (task: ExtendedTask, timezone: string): TaskRow => ({
  ...task,
  category: getCategoryLabel(task.category),
  dueDate: {
    label: getDueDateLabel(task.dueDate, timezone),
    chipColor: getDueDateColor(task),
  },
  studentNames: task.students.map(s => s.fullName),
  task,
});

const TaskListData = () => {
  const {
    authStore: { timezone },
    taskStore: { currentTasks },
  } = useContext(StoreContext);

  const { palette } = useTheme();

  const taskRows = currentTasks?.records.map(t => toTaskRow(t, timezone));

  return (
    <DataTable
      columns={TASK_COLUMNS}
      data={taskRows}
      isLoading={!currentTasks}
      isSortable={false}
      rowStyles={[
        {
          className: "complete",
          sx: { bgcolor: lighten(palette.success.main, 0.7) },
          shouldApply: ({ task }) => !!task.completedAt,
        },
      ]}
    />
  );
};

export default observer(TaskListData);
