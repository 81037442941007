export const INTERVIEW_FORM_TYPES = ["student", "caregiver", "teacher"] as const;
export type InterviewFormType = (typeof INTERVIEW_FORM_TYPES)[number];

export const REPORT_REVIEW_REASONS = [
  "first-provider-reports-ever",
  "first-provider-report-this-month",
  "missing-required-edits",
  "missing-psych-recommendations",
  "questionable-eligibilities",
] as const;

export type ReportReviewReason = (typeof REPORT_REVIEW_REASONS)[number];

export const friendlyReportReviewReasons: Record<ReportReviewReason, string> = {
  "first-provider-reports-ever": "One of provider's first three reports ever",
  "first-provider-report-this-month": "Provider's first report this month",
  "missing-required-edits": "Some required blocks or tables have not been edited",
  "missing-psych-recommendations": "No recommendations have been selected",
  "questionable-eligibilities": "Eligibility is selected by all composite standard scores are average or above",
};

export const TEMPLATE_KEYS = ["psy", "slp"] as const;
