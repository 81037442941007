import { ReactNode, useContext } from "react";
import CommentIcon from "@mui/icons-material/Comment";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import { useUpdateEffect } from "@react-hookz/web";
import { Editor, useCurrentEditor } from "@tiptap/react";
import { isUndefined } from "lodash";
import { CopyContent, CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { Status, StatusIcon } from "@parallel/polygon/components/shared/input/status.input";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getBlockContentMarkdown } from "@/util/report.content.util";

const FORMAT_OPTIONS = ["bold", "italic", "underline"] as const;
type FormatOption = (typeof FORMAT_OPTIONS)[number];

const LIST_OPTIONS = ["bulletList", "orderedList"] as const;
type ListOption = (typeof LIST_OPTIONS)[number];

type EditorOption = FormatOption | ListOption;

type EditorOptionProps = {
  onChange: () => unknown;
  disabled: boolean;
  icon: ReactNode;
};

const logger = initLogger("BlockContentMenu", getLoggerContext);

const useEditorProps = (disabled?: boolean) => {
  const { editor } = useCurrentEditor();
  return {
    editor,
    bold: {
      onChange: () => editor?.chain().focus().toggleBold().run(),
      disabled: disabled || !editor?.can().chain().focus().toggleBold().run(),
      icon: <FormatBoldIcon />,
    },
    italic: {
      onChange: () => editor?.chain().focus().toggleItalic().run(),
      disabled: disabled || !editor?.can().chain().focus().toggleItalic().run(),
      icon: <FormatItalicIcon />,
    },
    underline: {
      onChange: () => editor?.chain().focus().toggleUnderline().run(),
      disabled: disabled || !editor?.can().chain().focus().toggleUnderline().run(),
      icon: <FormatUnderlinedIcon />,
    },
    bulletList: {
      onChange: () => editor?.chain().focus().toggleBulletList().run(),
      disabled: disabled || !editor?.can().chain().focus().toggleBulletList().run(),
      icon: <FormatListBulletedIcon />,
    },
    orderedList: {
      onChange: () => editor?.chain().focus().toggleOrderedList().run(),
      disabled: disabled || !editor?.can().chain().focus().toggleOrderedList().run(),
      icon: <FormatListNumberedIcon />,
    },
  };
};

const EditorToggleButton = ({
  editor,
  option,
  props: { onChange, disabled, icon },
}: {
  editor: Editor | null;
  option: EditorOption;
  props: EditorOptionProps;
}) => (
  <ToggleButton
    value={option}
    selected={!disabled && editor?.isActive(option)}
    onChange={onChange}
    disabled={disabled}
    sx={{ border: 0, m: 0.5, "&.Mui-disabled": { border: 0, m: 0.5 } }}
    aria-label={option}
    key={option}
    size="small"
  >
    {icon}
  </ToggleButton>
);

const BlockContentMenu = ({
  content,
  saveStatus,
  areCommentsExpanded,
  setAreCommentsExpanded,
  disabled,
}: {
  content?: string;
  saveStatus?: Status;
  areCommentsExpanded?: boolean;
  setAreCommentsExpanded: (areExpanded: boolean) => void;
  disabled?: boolean;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const editorProps = useEditorProps(disabled);
  const { editor } = editorProps;

  useUpdateEffect(() => {
    if (editor?.isFocused) return;
    editor?.commands.setContent(content || "");
  }, [content]);

  const copyHtml = editor?.getHTML();
  const copyContent: CopyContent | undefined = copyHtml
    ? currentUser?.featureFlags.useHtmlReportClipboard
      ? { value: copyHtml, type: "text/html" }
      : { value: getBlockContentMarkdown(copyHtml), type: "text/plain" }
    : undefined;

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ pr: 1, borderBottom: 1, borderColor: "grey.300" }}
    >
      <Stack direction="row">
        {FORMAT_OPTIONS.map(option => (
          <EditorToggleButton editor={editor} option={option} props={editorProps[option]} key={option} />
        ))}
        <Divider orientation="vertical" flexItem />
        {LIST_OPTIONS.map(option => (
          <EditorToggleButton editor={editor} option={option} props={editorProps[option]} key={option} />
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        <StatusIcon status={saveStatus} />
        {!isUndefined(areCommentsExpanded) && (
          <ToggleButton
            value="comments"
            selected={areCommentsExpanded}
            onChange={() => setAreCommentsExpanded(!areCommentsExpanded)}
            sx={{ border: 0, p: "5px" }}
            size="small"
          >
            <CommentIcon fontSize="small" />
          </ToggleButton>
        )}
        {copyContent && <CopyIconButton content={copyContent} label="Block" logger={logger} />}
      </Stack>
    </Stack>
  );
};

export default BlockContentMenu;
