import { z } from "zod";

const assessmentImageMarginSchema = z.object({
  left: z.number(),
  top: z.number(),
  right: z.number(),
  bottom: z.number(),
});

export type AssessmentImageMargin = z.infer<typeof assessmentImageMarginSchema>;

export const ASSESSMENT_DISPLAY_CONFIG_VALUES = ["Provider Video", "Client Video", "Client Stimulus", "Other"] as const;
const assessmentDisplayConfigField = z.enum(ASSESSMENT_DISPLAY_CONFIG_VALUES);
export type AssessmentDisplayConfig = z.infer<typeof assessmentDisplayConfigField>;

/** raw firestore data */

export const assessmentBookRawDataSchema = z.object({
  id: z.string(),
  name: z.string(),
  orderIndex: z.number(),
  testPrefix: z.string().optional(),
  hidden: z.boolean().optional(),
});

export type AssessmentBookRawData = z.infer<typeof assessmentBookRawDataSchema>;

const assessmentImageSchema = z.object({
  staff: z.string(),
  staffMargin: assessmentImageMarginSchema.default({ left: 0, top: 0, right: 0, bottom: 0 }),
  client: z.string().optional(),
  clientMargin: assessmentImageMarginSchema.optional(),
});

const assessmentTestFields = {
  name: z.string(),
  displayName: z.string().optional(),
  image: assessmentImageSchema,
  audio: z.string().array().default([]),
  orderIndex: z.number(),
};

export const assessmentTestDataSchema = z.object({
  id: z.string(),
  ...assessmentTestFields,
});
export type AssessmentTestData = z.infer<typeof assessmentTestDataSchema>;

export const createAssessmentTestBodySchema = z.object({
  id: z.string().optional(),
  ...assessmentTestFields,
});
export type CreateAssessessmentTestBody = z.infer<typeof createAssessmentTestBodySchema>;

const updateAssessmentTestBodySchema = z.object(assessmentTestFields).partial().extend({ id: z.string() });

export type UpdateAssessmentTest = z.infer<typeof updateAssessmentTestBodySchema>;

export const updateAssessmentTestsBodySchema = z.object({
  tests: updateAssessmentTestBodySchema.array(),
});

export type UpdateAssessmentTestsBody = z.infer<typeof updateAssessmentTestsBodySchema>;

const baseDisplayGroupSchema = z.object({
  name: z.string(),
  orderIndex: z.number(),
  testIds: z.string().array(),
});
export type BaseDisplayGroup = z.infer<typeof baseDisplayGroupSchema>;

// deprecated
export type DisplaySubgroup = z.infer<typeof baseDisplayGroupSchema> & {
  subgroups?: DisplaySubgroup[];
};

export type AssessmentDisplayGroupData = DisplaySubgroup & {
  id: string;
};

const baseCreateDisplayGroupSchema = baseDisplayGroupSchema.extend({ testIds: z.string().array().optional() });

export type CreateAssessmentDisplayGroupBody = z.infer<typeof baseCreateDisplayGroupSchema> & {
  subgroups?: DisplaySubgroup[];
};

export const createAssessmentDisplayGroupBodySchema: z.ZodType<CreateAssessmentDisplayGroupBody> =
  baseCreateDisplayGroupSchema.extend({
    subgroups: z.lazy(() => baseDisplayGroupSchema.array().optional()),
  });

const baseUpdateDisplayGroupSchema = baseDisplayGroupSchema.partial();

export type UpdateAssessmentDisplayGroupBody = z.infer<typeof baseUpdateDisplayGroupSchema> & {
  subgroups?: DisplaySubgroup[];
};

export const updateAssessmentDisplayGroupBodySchema: z.ZodType<UpdateAssessmentDisplayGroupBody> =
  baseUpdateDisplayGroupSchema.extend({
    subgroups: z.lazy(() => baseDisplayGroupSchema.array().optional()),
  });

const configGroupSchema = z.object({
  name: z.string(),
  testIds: z.string().array().default([]),
  clientStimulus: z
    .object({
      showClient: z.boolean().default(true),
      showProvider: z.boolean().default(true),
    })
    .default({ showClient: true, showProvider: true }),
  providerDisplayPriority: assessmentDisplayConfigField.default("Client Stimulus"),
  shareProviderMouse: z.boolean().default(false),
  shareClientMouse: z.boolean().default(false),
  stopwatch: z.boolean().default(false),
  orderIndex: z.number(),
});

const configGroupDataSchema = configGroupSchema.extend({
  id: z.string(),
});

export type AssessmentConfigGroupData = z.infer<typeof configGroupDataSchema>;

export const createAssessmentConfigGroupBodySchema = configGroupSchema;

export type CreateAssessmentConfigGroupBody = z.infer<typeof createAssessmentConfigGroupBodySchema>;

export const updateAssessmentConfigGroupBodySchema = configGroupSchema.partial();

export type UpdateAssessmentConfigGroupBody = z.infer<typeof updateAssessmentConfigGroupBodySchema>;

export type AssessmentBookMetadata = {
  bookId: string;
  tests: AssessmentTestData[];
  displayGroups: AssessmentDisplayGroupData[];
  configGroups: AssessmentConfigGroupData[];
};

/** selection data (used for setting meeting state) */

export const assessmentTestSelectionSchema = z.object({
  name: z.string().optional(),
  bookName: z.string(),
  id: z.string(),
  image: assessmentImageSchema,
  audio: z.string().array().optional(),
  clientStimulus: z.object({
    showClient: z.boolean(),
    showProvider: z.boolean(),
  }),
  providerDisplayPriority: assessmentDisplayConfigField,
  shareProviderMouse: z.boolean(),
  shareClientMouse: z.boolean(),
  stopwatch: z.boolean(),
  parentGroupNames: z.string().array().optional(),
});

export type AssessmentTestSelection = z.infer<typeof assessmentTestSelectionSchema>;

export const assessmentZoomSelectionSchema = z.object({
  width: z.number(),
  height: z.number(),
  position: z.object({ x: z.number(), y: z.number() }),
});

export type AssessmentZoomSelection = z.infer<typeof assessmentZoomSelectionSchema>;

export type AssessmentStimulusTestGroup = {
  name: string;
  availableTests: AssessmentTestSelection[];
  subgroups: AssessmentStimulusTestGroup[];
  // deprecated
  tests?: AssessmentTestData[];
  groups?: {
    display: AssessmentDisplayGroupData[];
    config: AssessmentConfigGroupData[];
  };
};

export type AssessmentStimulusMetadataDto = AssessmentStimulusTestGroup[];
