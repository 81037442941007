import { useContext, useState } from "react";
import { ReportEditorBlock, UpdateReportBlockBody } from "@parallel/vertex/types/report.types";
import { resolveBlockContentWarning } from "@parallel/vertex/util/report.util";
import BlockCommentThread from "@/components/report/editor/margin/BlockCommentThread";
import BlockNote, { NoteSeverity } from "@/components/report/editor/margin/BlockNote";
import RightMarginBox from "@/components/report/editor/margin/RightMarginBox";
import { StoreContext } from "@/stores";

const getCollapseBlockUpdate = (severity: NoteSeverity, isCollapsed: boolean): UpdateReportBlockBody => {
  switch (severity) {
    case "info":
      return { isInfoNoteCollapsed: isCollapsed };
    case "warn":
      return { isWarningNoteCollapsed: isCollapsed };
  }
};

const BlockMarginBox = ({
  block,
  areCommentsExpanded,
  setAreCommentsExpanded,
  maxHeight,
}: {
  block: ReportEditorBlock;
  areCommentsExpanded: boolean;
  setAreCommentsExpanded: (areCommentsExpanded: boolean) => void;
  maxHeight?: number;
}) => {
  const {
    reportStore: { currentReport, upsertCustomBlock },
  } = useContext(StoreContext);

  const [noteCollapsedState, setNoteCollapsedState] = useState({
    info: block.custom?.isInfoNoteCollapsed,
    warn: block.custom?.isWarningNoteCollapsed,
  });

  const toggleNoteCollapsed = (severity: "info" | "warn") => () => {
    const updatedFlag = !noteCollapsedState[severity];
    setNoteCollapsedState({ ...noteCollapsedState, [severity]: updatedFlag });
    upsertCustomBlock(block.reportBlockTemplateId, getCollapseBlockUpdate(severity, updatedFlag));
  };

  const blockContentWarning = resolveBlockContentWarning(block);

  return (
    <RightMarginBox maxHeight={maxHeight}>
      {block.infoNoteText && !currentReport?.approval && (
        <BlockNote
          text={block.infoNoteText}
          severity="info"
          isCollapsed={noteCollapsedState.info}
          toggleCollapsed={toggleNoteCollapsed("info")}
        />
      )}
      {blockContentWarning && !currentReport?.approval && (
        <BlockNote
          text={blockContentWarning}
          severity="warn"
          isCollapsed={noteCollapsedState.warn}
          toggleCollapsed={toggleNoteCollapsed("warn")}
        />
      )}
      <BlockCommentThread block={block} isExpanded={areCommentsExpanded} setIsExpanded={setAreCommentsExpanded} />
    </RightMarginBox>
  );
};

export default BlockMarginBox;
