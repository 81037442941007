import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import { ApprovalStatus } from "@parallel/vertex/types/approval.types";
import {
  AppointmentEventSummary,
  ExtendedFrontendEvent,
  SearchAppointmentEventsQuery,
} from "@parallel/vertex/types/calendar/appointment.event.types";
import {
  AppointmentCancelReason,
  AppointmentDetails,
  AppointmentType,
  CreateAppointmentBody,
  ExtendedAppointment,
  ExtendedAppointmentUser,
  SearchAppointmentsQuery,
  SearchAppointmentTypeQuery,
  UpdateAppointmentBody,
  UpdateAppointmentUserBody,
} from "@parallel/vertex/types/calendar/appointment.types";
import {
  CreateCalendarBlockBody,
  ExtendedCalendarBlock,
  UpdateCalendarBlockBody,
} from "@parallel/vertex/types/calendar/calendar.block.types";
import {
  CalendarItems,
  SearchCalendarQuery,
  SearchUserCalendarItemQuery,
  DisplayRangeFilter,
  SearchCalendarRelationQuery,
  CalendarRelation,
} from "@parallel/vertex/types/calendar/calendar.types";
import {
  BulkUpdateIndirectTimeBody,
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  IndirectTimeTypeCategory,
  SearchIndirectTimeTypesQuery,
  UpdateIndirectTimeBody,
} from "@parallel/vertex/types/calendar/time.types";
import { PayPeriods, PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";

export class CalendarAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public searchCalendarItems = (query: SearchCalendarQuery): Promise<CalendarItems> =>
    this.instance.get("calendar", { params: query }).then(r => r.data);

  public searchCalendarRelations = (query: SearchCalendarRelationQuery): Promise<PaginatedResult<CalendarRelation>> =>
    this.instance.get("calendar/relation", { params: query }).then(r => r.data);

  public getAppointment = (appointmentId: string): Promise<ExtendedAppointment> =>
    this.instance.get(`calendar/appointment/${appointmentId}`).then(r => r.data);

  public searchAppointments = (params: SearchAppointmentsQuery): Promise<PaginatedResult<ExtendedAppointment>> =>
    this.instance.get("calendar/appointment", { params }).then(r => r.data);

  public createAppointment = (
    body: CreateAppointmentBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedAppointment[]> =>
    this.instance.post("calendar/appointment", body, { params }).then(r => r.data.appointments);

  public updateAppointment = (
    appointmentId: string,
    body: UpdateAppointmentBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedAppointment[]> =>
    this.instance.put(`calendar/appointment/${appointmentId}`, body, { params }).then(r => r.data.appointments);

  public deleteAppointment = (
    appointmentId: string,
    recurrenceEditMode?: RecurrenceEditMode,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedAppointment[]> =>
    this.instance
      .delete(`calendar/appointment/${appointmentId}`, { data: { recurrenceEditMode }, params })
      .then(r => r.data.appointments);

  public updateAppointmentUser = (
    appointmentId: string,
    userId: string,
    body: UpdateAppointmentUserBody,
  ): Promise<ExtendedAppointmentUser> =>
    this.instance.put(`calendar/appointment/${appointmentId}/user/${userId}`, body).then(r => r.data);

  public getAppointmentDetails = (appointmentId: string): Promise<AppointmentDetails> =>
    this.instance.get(`calendar/appointment/${appointmentId}/details`).then(r => r.data);

  public updateLateSubmissionsStatuses = (body: Record<string, ApprovalStatus>): Promise<ExtendedAppointment[]> =>
    this.instance.put("calendar/appointment/late-submission", body).then(r => r.data.appointments);

  public searchAppointmentTypes = (
    params: SearchAppointmentTypeQuery = {},
  ): Promise<PaginatedResult<AppointmentType>> =>
    this.instance.get("calendar/appointment/type", { params }).then(r => r.data);

  public getAllAppointmentCancelReasons = (): Promise<AppointmentCancelReason[]> =>
    this.instance.get("calendar/appointment/cancel-reason").then(r => r.data.reasons);

  public getAppointmentEventSummary = (appointmentId: string): Promise<AppointmentEventSummary> =>
    this.instance.get(`calendar/appointment/${appointmentId}/event/summary`).then(r => r.data);

  public searchAppointmentEvents = (
    appointmentId: string,
    params: SearchAppointmentEventsQuery = {},
  ): Promise<PaginatedResult<ExtendedFrontendEvent>> =>
    this.instance.get(`calendar/appointment/${appointmentId}/event`, { params }).then(r => r.data);

  public getCalendarBlock = (blockId: string): Promise<ExtendedCalendarBlock> =>
    this.instance.get(`calendar/block/${blockId}`).then(r => r.data);

  public createCalendarBlock = (
    body: CreateCalendarBlockBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedCalendarBlock[]> =>
    this.instance.post("calendar/block", body, { params }).then(r => r.data.blocks);

  public updateCalendarBlock = (
    blockId: string,
    body: UpdateCalendarBlockBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedCalendarBlock[]> =>
    this.instance.put(`calendar/block/${blockId}`, body, { params }).then(r => r.data.blocks);

  public deleteCalendarBlock = (
    blockId: string,
    recurrenceEditMode?: RecurrenceEditMode,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedCalendarBlock[]> =>
    this.instance
      .delete(`calendar/block/${blockId}`, { data: { recurrenceEditMode }, params })
      .then(r => r.data.blocks);

  public getIndirectTime = (timeId: string): Promise<ExtendedTimeEntry> =>
    this.instance.get(`calendar/time/${timeId}`).then(r => r.data);

  public searchIndirectTime = (query: SearchUserCalendarItemQuery): Promise<PaginatedResult<ExtendedTimeEntry>> =>
    this.instance.get("calendar/time", { params: query }).then(r => r.data);

  public createIndirectTime = (
    body: CreateIndirectTimeBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedTimeEntry[]> => this.instance.post("calendar/time", body, { params }).then(r => r.data.entries);

  public updateIndirectTime = (
    timeId: string,
    body: UpdateIndirectTimeBody,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedTimeEntry[]> =>
    this.instance.put(`calendar/time/${timeId}`, body, { params }).then(r => r.data.entries);

  public bulkUpdateIndirectTime = (body: BulkUpdateIndirectTimeBody): Promise<ExtendedTimeEntry[]> =>
    this.instance.put("calendar/time", body).then(r => r.data.entries);

  public deleteIndirectTime = (
    timeId: string,
    recurrenceEditMode?: RecurrenceEditMode,
    params?: DisplayRangeFilter,
  ): Promise<ExtendedTimeEntry[]> =>
    this.instance.delete(`calendar/time/${timeId}`, { data: { recurrenceEditMode }, params }).then(r => r.data.entries);

  public searchIndirectTimeTypeCategories = (
    query: SearchIndirectTimeTypesQuery,
  ): Promise<IndirectTimeTypeCategory[]> =>
    this.instance.get("calendar/time/type/category", { params: query }).then(r => r.data.categories);

  public getPayPeriods = (): Promise<PayPeriods> => this.instance.get("calendar/pay-period").then(r => r.data);
}
