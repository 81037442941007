import { ReactElement, ReactNode, cloneElement } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isArray, isString } from "lodash";
import { filterExists } from "@parallel/vertex/util/collection.util";
import SubHeaderLayout, { SUB_HEADER_HEIGHT } from "@/components/shared/layout/SubHeaderLayout";

type HeaderBadge = { label: string; icon?: ReactElement };

export type HeaderNavigation<A extends string> = {
  tabs: (HeaderBadge & { key: A })[];
  currentKey?: A;
  onChange: (key: A) => void;
};

const SubHeader = <A extends string>({
  icon,
  title,
  subtitle,
  badges,
  navigation,
  actions,
}: {
  icon?: ReactElement;
  title?: string;
  subtitle?: string | ReactNode[];
  badges?: HeaderBadge[];
  navigation?: HeaderNavigation<A>;
  actions?: ReactNode;
}) => {
  const tooltipContents = badges ? (
    <Stack sx={{ padding: 0.5 }}>
      {badges.map(({ label, icon }, i) => (
        <Stack direction="row" gap={1} alignItems="center" key={i}>
          {icon && cloneElement(icon, { sx: { height: 16, width: 16 } })}
          <Typography variant="body2">{label}</Typography>
        </Stack>
      ))}
    </Stack>
  ) : undefined;

  const subtitles = isArray(subtitle) ? subtitle : filterExists([subtitle]);

  const titleElement = title && (
    <Tooltip title={tooltipContents} placement="bottom-start">
      <Stack direction="row" gap={1.5} alignItems="center" sx={{ cursor: tooltipContents ? "pointer" : "default" }}>
        {icon && cloneElement(icon, { sx: { fontSize: "20px", color: "text.secondary" } })}
        <Typography variant="body1">{title}</Typography>
        <Stack direction="row">
          {subtitles.map((subtitle, i) => (
            <Box sx={{ color: "text.secondary", borderLeft: 1, borderColor: "grey.200", px: 1.5 }} key={i}>
              {isString(subtitle) ? <Typography variant="body1">{subtitle}</Typography> : subtitle}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Tooltip>
  );

  const navigationTabs = navigation && (
    <Tabs
      value={navigation.currentKey}
      onChange={(_, key) => navigation.onChange(key)}
      sx={{ borderLeft: 1, borderLeftColor: "grey.300" }}
    >
      {navigation.tabs.map(tab => (
        <Tab
          value={tab.key}
          label={<Typography variant="body1">{tab.label}</Typography>}
          icon={tab.icon ? cloneElement(tab.icon, { style: { fontSize: "20px" } }) : undefined}
          iconPosition="start"
          sx={{
            minHeight: SUB_HEADER_HEIGHT,
            height: SUB_HEADER_HEIGHT,
            paddingX: 3,
            borderRight: 1,
            borderRightColor: "grey.300",
            color: "primary.main",
            "&.Mui-selected": {
              color: "text.secondary",
              bgcolor: "white",
              borderBottom: 1,
            },
          }}
          key={tab.key}
        />
      ))}
    </Tabs>
  );

  return <SubHeaderLayout title={titleElement} leftControls={navigationTabs} rightControls={actions} />;
};

export default SubHeader;
