import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ArchiveIcon from "@mui/icons-material/Archive";
import BusinessIcon from "@mui/icons-material/Business";
import CakeIcon from "@mui/icons-material/Cake";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EventIcon from "@mui/icons-material/Event";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RestoreIcon from "@mui/icons-material/Restore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { hasRoleFlag } from "@parallel/vertex/role";
import { getStudentPermissions } from "@parallel/vertex/role/role.student";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import { StyledMenu, StyledMenuItem } from "@/components/shared/display/menu";
import SubHeader from "@/components/shared/layout/SubHeader";
import { StoreContext } from "@/stores";
import { ALL_STUDENT_VIEW_TYPES, StudentViewType } from "@/util/student.util";

const VIEW_ICONS = {
  info: <InfoIcon />,
  goals: <EmojiEventsIcon />,
  progress: <AnalyticsIcon />,
  documents: <FilePresentIcon />,
};

const StudentHeader = ({
  student,
  currentView,
  onArchive,
  onRestore,
}: {
  student: SingleStudentUser;
  currentView: StudentViewType;
  onArchive: () => void;
  onRestore: () => void;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const navigate = useNavigate();

  const [actionAnchor, setActionAnchor] = useState<HTMLElement>();

  const studentPermissions = getStudentPermissions(currentUser, "read");
  const roleAllowsEdit = studentPermissions?.accessLevels.includes("write");

  const badges =
    student.archivedAt && student.archiveReason
      ? [{ label: `Archived on ${student.archivedAt.toLocaleString(DateTime.DATE_SHORT)}`, icon: <ArchiveIcon /> }]
      : filterExists([
          student.birthDate && { label: student.birthDate.toLocaleString(DateTime.DATE_SHORT), icon: <CakeIcon /> },
          student.campus && { label: student.campus.name, icon: <BusinessIcon /> },
        ]);

  const archiveDisabledReason = !isEmpty(student.services) ? "Cannot archive student with active services" : undefined;

  const filteredStudentViewTypes = studentPermissions?.infoOnly
    ? (["info"] as StudentViewType[])
    : currentUser?.featureFlags.showDocumentCenter
      ? ALL_STUDENT_VIEW_TYPES
      : ALL_STUDENT_VIEW_TYPES.filter(type => type !== "documents");

  const actionsDropdown = (
    <>
      <Box>
        {roleAllowsEdit && (
          <Button endIcon={<KeyboardArrowDownIcon />} onClick={e => setActionAnchor(e.currentTarget)}>
            <Typography variant="body1" fontWeight="500">
              More
            </Typography>
          </Button>
        )}
      </Box>
      <StyledMenu
        anchorEl={actionAnchor}
        open={!!actionAnchor}
        onClose={() => setActionAnchor(undefined)}
        sx={{ "& .MuiMenu-paper": { paddingX: 1, bgcolor: "surface.light" } }}
      >
        {student.archivedAt ? (
          <StyledMenuItem
            onClick={() => {
              setActionAnchor(undefined);
              onRestore();
            }}
            sx={{
              "& .MuiListItemText-root": { color: "primary.main" },
              "& .MuiListItemIcon-root": { color: "primary.main" },
            }}
          >
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText>Restore Student</ListItemText>
          </StyledMenuItem>
        ) : (
          <Tooltip
            title={<Typography variant="subtitle2">{archiveDisabledReason}</Typography>}
            disableHoverListener={!archiveDisabledReason}
          >
            <div>
              <StyledMenuItem
                onClick={() => {
                  setActionAnchor(undefined);
                  onArchive();
                }}
                sx={{
                  "& .MuiListItemText-root": { color: "error.main" },
                  "& .MuiListItemIcon-root": { color: "error.main" },
                }}
                disabled={!!archiveDisabledReason}
              >
                <ListItemIcon color="error.main">
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText>Archive Student</ListItemText>
              </StyledMenuItem>
            </div>
          </Tooltip>
        )}
        {hasRoleFlag(currentUser, "full-org-calendar-access") && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <Link href={`/calendar/student/${student.userId}`}>
              <StyledMenuItem
                sx={{
                  "& .MuiListItemText-root": { color: "primary.main" },
                  "& .MuiListItemIcon-root": { color: "primary.main" },
                }}
              >
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText>View Calendar</ListItemText>
              </StyledMenuItem>
            </Link>
          </>
        )}
      </StyledMenu>
    </>
  );

  return (
    <SubHeader
      icon={<GroupsIcon />}
      title={student.fullName}
      badges={badges}
      navigation={{
        tabs: filteredStudentViewTypes.map(type => ({
          key: type,
          label: toTitleCase(type),
          icon: VIEW_ICONS[type],
        })),
        currentKey: currentView,
        onChange: type => navigate(`/user/student/${student.userId}/${type}`),
      }}
      actions={actionsDropdown}
    />
  );
};

export default StudentHeader;
