import { useContext, useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { groupBy, omit, uniqBy } from "lodash";
import { DateTime } from "luxon";
import AutoCompleteInput, {
  InputOverride,
  SelectOption,
} from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { downloadBlob } from "@parallel/polygon/util/file.util";
import { getObjectiveTitle, getProgressIcon, ProgressItemType } from "@parallel/polygon/util/progress.util";
import { SingleStudentAppointmentProgress } from "@parallel/vertex/types/progress.types";
import { DateTimeRange, PaginatedResult, PaginateState } from "@parallel/vertex/types/shared.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { StyledMenu } from "@/components/shared/display/menu";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import PaginationControl from "@/components/shared/navigation/PaginationControl";
import AppointmentProgressData, { ItemFilterFn } from "@/components/user/student/progress/AppointmentProgressData";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const NAME_SELECT_GROUPS = {
  goals: "Goals",
  objectives: "Objectives",
} as const;

export type NameSelectGroup = (typeof NAME_SELECT_GROUPS)[keyof typeof NAME_SELECT_GROUPS];

const getGroupName = (itemType: ProgressItemType): NameSelectGroup => {
  switch (itemType) {
    case "goal":
      return "Goals";
    case "objective":
      return "Objectives";
  }
};

const logger = initLogger("StudentProgressReport", getLoggerContext);

const StudentProgressReport = ({ studentId }: { studentId: string }) => {
  const {
    apiStore: { progressApi },
    authStore: { timezone },
  } = useContext(StoreContext);

  const currentDate = toLocalDate(DateTime.now(), timezone);
  const [timeRange, setTimeRange] = useState<Partial<DateTimeRange>>({
    startTime: currentDate.minus({ days: 7 }),
    endTime: currentDate,
  });
  const isTimeRangeValid = timeRange.startTime && timeRange.endTime;

  const [itemFilter, setItemFilter] = useState<SelectOption | null>(null);
  const [providerFilter, setProviderFilter] = useState<SelectOption | null>(null);
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement>();
  const [showArchived, setShowArchived] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);
  const [pageState, setPageState] = useState<PaginateState>({ pageSize: 20, offset: 0 });

  const validSearchRequest = isTimeRangeValid && {
    startTime: timeRange.startTime,
    endTime: timeRange.endTime!.plus({ days: 1 }),
    providerId: providerFilter?.key,
  };

  const [progressPage, setProgressPage] = useState<PaginatedResult<SingleStudentAppointmentProgress>>();
  useEffect(() => {
    if (!validSearchRequest) return;
    progressApi
      .searchStudentProgress(studentId, {
        ...validSearchRequest,
        ...pageState,
      })
      .then(page => {
        setProgressPage(page);
        if (page.totalCount < pageState.offset) setPageState({ pageSize: 20, offset: 0 });
      })
      .catch(logger.handleFailure("searchStudentProgress"));
  }, [timeRange, providerFilter, isTimeRangeValid, pageState]);

  const downloadCsv = async () => {
    if (!validSearchRequest) return;
    const blob = await progressApi.downloadStudentProgressCsv(studentId, validSearchRequest);
    downloadBlob(blob, `student_progress_${DateTime.utc().toSeconds()}.csv`);
  };

  let content = isTimeRangeValid ? (
    <LoadingScreen />
  ) : (
    <Typography variant="body1" p={2}>
      Select a valid time range above
    </Typography>
  );

  let nameOptions: SelectOption[] = [];
  let nameInputOverride: InputOverride = {};

  let providerOptions: SelectOption[] = [];

  const shouldIncludeItem: ItemFilterFn = item => {
    if (itemFilter && itemFilter.groupName === getGroupName(item.type) && itemFilter.key !== item.id) return false;

    if (!showCompleted && item.completedAt) return false;
    if (!showArchived && item.isArchived) return false;

    return true;
  };

  if (progressPage) {
    if (progressPage.totalCount === 0) {
      content = (
        <FullStack width="100%" alignItems="center" role="region" aria-label="progress-list">
          <Typography pt={4}>No Data Found</Typography>
        </FullStack>
      );
    } else {
      content = (
        <FullStack gap={1} role="region" aria-label="progress-list">
          {progressPage.records.map(progress => (
            <AppointmentProgressData
              progress={progress}
              shouldIncludeItem={shouldIncludeItem}
              key={progress.appointment.id}
            />
          ))}
        </FullStack>
      );

      const allNameOptions = progressPage.records.flatMap(progress =>
        progress.goals.flatMap((goal, i) => [
          {
            key: goal.goalId,
            label: `Goal ${i + 1} - ${goal.description}`,
            value: goal.goalId,
            groupName: NAME_SELECT_GROUPS.goals,
            shortLabel: `Goal ${i + 1}`,
            record: goal,
          },
          ...goal.objectives.map((objective, j) => ({
            key: objective.objectiveId,
            label: `${getObjectiveTitle(objective, { goal: i + 1, objective: j + 1 })} - ${objective.description}`,
            groupName: NAME_SELECT_GROUPS.objectives,
            shortLabel: getObjectiveTitle(objective, { goal: i + 1, objective: j + 1 }),
            record: objective,
          })),
        ]),
      );
      const uniqueNameOptions = groupBy(uniqBy(allNameOptions, "key"), "groupName");
      const goals = uniqueNameOptions[NAME_SELECT_GROUPS.goals] || [];
      const objectives = uniqueNameOptions[NAME_SELECT_GROUPS.objectives] || [];

      const allUniqueOptions = [...goals, ...objectives];
      nameOptions = allUniqueOptions.map(o => omit(o, "shortLabel"));

      const selectedOption = allUniqueOptions.find(o => o.key === itemFilter?.key);
      if (selectedOption) {
        nameInputOverride = { textValue: selectedOption.shortLabel, startIcon: getProgressIcon(selectedOption.record) };
      }

      providerOptions = uniqBy(
        progressPage.records.flatMap(
          record =>
            ({
              key: record.appointment.providerId,
              label: record.appointment.providerName,
            }) as SelectOption,
        ),
        "key",
      ).sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  return (
    <FullStack pt={2}>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <AutoCompleteInput
          label="Search ..."
          options={nameOptions}
          selected={itemFilter}
          onSelect={setItemFilter}
          size="small"
          width={400}
          inputOverride={nameInputOverride}
        />
        <Stack gap={2} direction="row" alignItems="center">
          <AutoCompleteInput
            label="Provider"
            options={providerOptions}
            selected={providerFilter}
            onSelect={setProviderFilter}
            size="small"
            width={240}
          />
          <DateRangePicker
            label="Date Range"
            value={[timeRange.startTime || null, timeRange.endTime || null]}
            onAccept={range =>
              setTimeRange({
                startTime: range[0] ? toLocalDate(range[0], timezone) : undefined,
                endTime: range[1] ? toLocalDate(range[1], timezone) : undefined,
              })
            }
            slots={{ field: SingleInputDateRangeField }}
            sx={{ width: 240 }}
            slotProps={{ textField: { size: "small" } }}
          />
          <Button startIcon={<SettingsIcon />} onClick={e => setFilterAnchor(e.currentTarget)}>
            <Typography variant="body1" fontWeight="500">
              Settings
            </Typography>
          </Button>
          <StyledMenu anchorEl={filterAnchor} open={!!filterAnchor} onClose={() => setFilterAnchor(undefined)}>
            <ToggleMenuItem
              toggle={() => setShowArchived(!showArchived)}
              isEnabled={showArchived}
              text="Show Archived"
              key="show-archived"
            />
            <ToggleMenuItem
              toggle={() => setShowCompleted(!showCompleted)}
              isEnabled={showCompleted}
              text="Show Achieved"
              key="show-achieved"
            />
          </StyledMenu>
          <ProcessButton startIcon={<DownloadIcon />} process={downloadCsv} disabled={!validSearchRequest}>
            Download CSV
          </ProcessButton>
        </Stack>
      </Stack>

      <Box
        sx={{
          width: "100%",
          flex: "1 1 0%",
          overflowY: "auto",
          borderRadius: "4px 0 0 4px",
          borderWidth: "1px 1px 0 1px",
          borderColor: "grey.300",
        }}
      >
        {content}
      </Box>

      {progressPage && (
        <Box
          sx={{
            width: "100%",
            borderRadius: "0 4px 4px 0",
            borderWidth: "0 1px 1px 1px",
            borderColor: "grey.300",
          }}
        >
          <PaginationControl pageState={pageState} setPageState={setPageState} totalCount={progressPage.totalCount} />
        </Box>
      )}
    </FullStack>
  );
};

export default StudentProgressReport;
