import { cloneElement, ReactElement, useContext } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReportIcon from "@mui/icons-material/Report";
import Schedule from "@mui/icons-material/Schedule";
import ScienceIcon from "@mui/icons-material/Science";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { uniqBy } from "lodash";
import { FRIENDLY_PROVIDER_TYPES, UserType } from "@parallel/vertex/enums/user.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { PaginateState, PaginatedResult, SortQuery } from "@parallel/vertex/types/shared.types";
import { ExtendedFacilitatorUser } from "@parallel/vertex/types/user/facilitator.types";
import { ExtendedProviderUser } from "@parallel/vertex/types/user/provider.types";
import { ExtendedSlpaUser } from "@parallel/vertex/types/user/slpa.types";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import { UnconstrainedTooltip } from "@/components/shared/display/tooltip";
import DataTable, { DataTableAction, DataTableColumn } from "@/components/shared/layout/DataTable";
import { StoreContext } from "@/stores";
import { getShortServiceLineTitle } from "@/util/service.util";
import { getUserTypeLabel } from "@/util/user.util";

type StudentReportService = { title: string; reportStatus: string };

type StudentUserRow = ExtendedStudentUser & {
  campusName?: string;
  districtName?: string;
  providerNames: string[];
  facilitatorNames: string[];
  activeServiceNames: string[];
  reportServices: StudentReportService[];
  tags?: { icon: ReactElement; label: string; color: string }[];
};

export const toStudentRow = (user: ExtendedStudentUser): StudentUserRow => ({
  ...user,
  fullName: user.fullName,
  campusName: user.campus?.name,
  districtName: user.district?.name,
  providerNames: user.providers.map(p => p.fullName),
  facilitatorNames: user.facilitators.map(f => f.fullName),
  activeServiceNames: user.activeServices.map(s => s.title),
  reportServices: mapExists(user.activeServices, s => s.report && { title: s.title, reportStatus: s.report.status }),
  tags: [
    ...(user.campus?.name === "Parallel Campus"
      ? [{ icon: <ScienceIcon />, label: "Test Student", color: "primary.main" }]
      : []),
    ...(user.providers.length === 0
      ? [{ icon: <ReportIcon />, label: "No Assigned Provider", color: "error.main" }]
      : []),
    ...(user.archivedAt ? [{ icon: <InventoryIcon />, label: "Archived Student", color: "surface.dark" }] : []),
  ],
});

type ProviderUserRow = ExtendedProviderUser & {
  friendlyProviderType: string;
  districtNames: string[];
};

export const toProviderRow = (user: ExtendedProviderUser): ProviderUserRow => ({
  ...user,
  friendlyProviderType: FRIENDLY_PROVIDER_TYPES[user.providerType],
  districtNames: uniqBy([...user.districts, ...user.studentDistricts], "id").map(d => d.name),
});

export type UserState = {
  STUDENT?: PaginatedResult<StudentUserRow>;
  FACILITATOR?: PaginatedResult<ExtendedFacilitatorUser>;
  SLPA?: PaginatedResult<ExtendedSlpaUser>;
  SITE_DIRECTOR?: PaginatedResult<ExtendedUser>;
  PROVIDER?: PaginatedResult<ProviderUserRow>;
  ADMIN?: PaginatedResult<ExtendedUser>;
};

const renderFullName = (row: any) => {
  const linkedName = (
    <Box sx={{ flex: "inherit", minWidth: 0 }}>
      <Link href={`/user/student/${row.userId}`}>
        <button
          onClick={e => e.stopPropagation()}
          style={{
            all: "unset",
            cursor: "pointer",
            display: "block",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.fullName}
        </button>
      </Link>
    </Box>
  );

  if (!(row.tags || []).length) return linkedName;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {linkedName}
      {row.tags.map((tag: { icon: ReactElement; label: string; color: string }, index: number) => (
        <UnconstrainedTooltip
          title={<Typography variant="body2">{tag.label}</Typography>}
          key={index}
          placement="right"
        >
          <Chip
            label={cloneElement(tag.icon, {
              sx: { width: "16px", height: "16px", color: tag.color, display: "flex" },
            })}
            sx={{
              borderRadius: "100%",
              minWidth: "24px",
              maxHeight: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiChip-label": { padding: "0 0 1px 0" },
            }}
          />
        </UnconstrainedTooltip>
      ))}
    </Stack>
  );
};

const renderChipList = <A,>(
  items: A[],
  {
    getLabel,
    getTooltip,
    isLabelTruncated,
  }: { getLabel: (a: A) => string; getTooltip?: (a: A) => string; isLabelTruncated?: boolean },
) => {
  if (!items.length) return <></>;
  const mainLabel = getLabel(items[0]);

  return (
    <Stack direction="row" alignItems="center" height="100%" gap={1}>
      <UnconstrainedTooltip
        title={getTooltip && isLabelTruncated && <Typography variant="body2">{getTooltip(items[0])}</Typography>}
        placement="left"
      >
        <Chip label={mainLabel} sx={{ borderRadius: 1 }} />
      </UnconstrainedTooltip>

      {items.length > 1 && (
        <UnconstrainedTooltip
          title={
            getTooltip && (
              <Stack>
                {items.slice(1).map((item, index) => (
                  <Typography variant="body2" key={index}>
                    {getTooltip(item)}
                  </Typography>
                ))}
              </Stack>
            )
          }
          placement="right"
        >
          <Chip label={`+${items.length - 1}`} sx={{ borderRadius: 1 }} />
        </UnconstrainedTooltip>
      )}
    </Stack>
  );
};

const renderStringChipList = (items: string[]) => renderChipList(items, { getLabel: s => s, getTooltip: s => s });

const STUDENT_COLUMN_PROPS: DataTableColumn<StudentUserRow>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  {
    key: "fullName",
    header: "Name",
    renderCell: (params: any) => renderFullName(params.row),
    width: 200,
  },
  { key: "birthDate", header: "Date of Birth", type: "date", flexWidth: 0.6 },
  { key: "districtName", header: "District", hidden: true, sortable: false },
  { key: "campusName", header: "Campus", sortable: false },
  {
    key: "activeServiceNames",
    header: "Parallel Services",
    sortable: false,
    renderCell: ({ value: names = [] }: { value?: string[] }) =>
      renderChipList(names, {
        getLabel: title => getShortServiceLineTitle({ title }),
        getTooltip: title => title,
        isLabelTruncated: true,
      }),
    flexWidth: 1.4,
  },
  {
    key: "reportServices",
    header: "Report Status",
    sortable: false,
    renderCell: ({ value: services = [] }: { value?: StudentReportService[] }) =>
      renderChipList(services, {
        getLabel: s => s.reportStatus,
        getTooltip: s => s.title,
        isLabelTruncated: true,
      }),
    flexWidth: 0.6,
  },
  {
    key: "providerNames",
    header: "Providers",
    sortable: false,
    renderCell: (params: any) => renderStringChipList(params.value),
    flexWidth: 0.8,
  },
  {
    key: "facilitatorNames",
    header: "Facilitators",
    sortable: false,
    renderCell: (params: any) => renderStringChipList(params.value),
    flexWidth: 0.8,
  },
];

type DistrictUser = ExtendedUser & { districtName?: string };

const DISTRICT_USER_COLUMN_PROPS: DataTableColumn<DistrictUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "districtName", header: "District", sortable: false },
];

const SLPA_COLUMN_PROPS: DataTableColumn<ExtendedSlpaUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "districtName", header: "District", sortable: false },
  {
    key: "supervisorNames",
    header: "Supervisors",
    sortable: false,
    renderCell: (params: any) => renderStringChipList(params.value),
  },
];

const PROVIDER_COLUMN_PROPS: DataTableColumn<ProviderUserRow>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "friendlyProviderType", header: "Type" },
  {
    key: "districtNames",
    header: "Districts",
    sortable: false,
    renderCell: params => renderStringChipList(params.value),
  },
];

const ADMIN_COLUMN_PROPS: DataTableColumn<ExtendedUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
];

export const INITIAL_PAGE_SIZE = 25;

const StudentDataTable = ({
  data,
  viewAs,
  isLoading,
  pagination,
  actions,
  onSelect,
  onSort,
  footer,
}: {
  data: StudentUserRow[] | undefined;
  viewAs: UserType | undefined;
  isLoading: boolean;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: StudentUserRow[]) => void;
  onSort: (s: SortQuery) => void;
  footer?: () => ReactElement;
}) => {
  const columns = STUDENT_COLUMN_PROPS.map(
    column =>
      ({
        ...column,
        hidden: column.key === "districtName" ? viewAs !== "ADMIN" : column.hidden,
      }) as DataTableColumn<StudentUserRow>,
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      onSelect={onSelect}
      onSortChange={onSort}
      actions={actions}
      footer={footer}
    />
  );
};

const DistrictUserDataTable = ({
  userType,
  data,
  isLoading,
  pagination,
  actions,
  onSelect,
  onSort,
  onEdit,
  onEmail,
  onDelete,
}: {
  userType: "FACILITATOR" | "SITE_DIRECTOR";
  data: ExtendedUser[] | undefined;
  isLoading: boolean;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ExtendedUser[]) => void;
  onSort: (s: SortQuery) => void;
  onEdit: (f: ExtendedUser) => void;
  onEmail: (f: ExtendedUser) => void;
  onDelete: { fn: (f: ExtendedUser) => void; isEnabled: (f: ExtendedUser) => boolean };
}) => {
  const withUser = <A,>(id: string, fn: (f?: ExtendedUser) => A) => {
    const user = data?.find(f => f.userId === id);
    return fn(user);
  };

  const buildAction = (fn: (f: ExtendedUser) => string | void, icon: ReactElement, label?: string) => ({
    onClick: (userId: string) => withUser(userId, f => (f ? fn(f) : undefined)),
    icon,
    label,
  });

  const userLabel = getUserTypeLabel(userType);
  const tableActions: DataTableAction[] = [
    buildAction(onEdit, <EditIcon />, `Edit ${userLabel}`),
    buildAction(onEmail, <EmailIcon />, "Send Welcome Email"),
    ...(actions || []),
    {
      ...buildAction(onDelete.fn, <DeleteForeverIcon />, `Delete ${userLabel}`),
      isEnabled: (id: string) => withUser(id, f => (f ? onDelete.isEnabled(f) : false)),
    },
  ];

  return (
    <DataTable
      columns={DISTRICT_USER_COLUMN_PROPS}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      onSelect={onSelect}
      onSortChange={onSort}
      actions={tableActions}
    />
  );
};

const SlpaDataTable = ({
  data,
  isLoading,
  pagination,
  actions,
  onSelect,
  onSort,
  onSlpaEdit,
  onSlpaEmail,
  onSlpaDelete,
}: {
  data: ExtendedSlpaUser[] | undefined;
  isLoading: boolean;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ExtendedSlpaUser[]) => void;
  onSort: (s: SortQuery) => void;
  onSlpaEdit?: (f: ExtendedSlpaUser) => void;
  onSlpaEmail?: (f: ExtendedSlpaUser) => void;
  onSlpaDelete: { fn: (f: ExtendedSlpaUser) => void; isEnabled: (f: ExtendedSlpaUser) => boolean };
}) => {
  const withSlpa = <A,>(id: string, fn: (f?: ExtendedSlpaUser) => A) => {
    const slpa = data?.find(f => f.userId === id);
    return fn(slpa);
  };

  const buildAction = (fn: (f: ExtendedSlpaUser) => string | void, icon: ReactElement, label?: string) => ({
    onClick: (slpaId: string) => withSlpa(slpaId, f => (f ? fn(f) : undefined)),
    icon,
    label,
  });

  const slpaActions: DataTableAction[] = filterExists([
    onSlpaEdit && buildAction(onSlpaEdit, <EditIcon />, "Edit Slpa"),
    onSlpaEmail && buildAction(onSlpaEmail, <EmailIcon />, "Send Welcome Email"),
    ...(actions || []),
    {
      ...buildAction(onSlpaDelete.fn, <DeleteForeverIcon />, "Delete Slpa"),
      isEnabled: (id: string) => withSlpa(id, f => (f ? onSlpaDelete.isEnabled(f) : false)),
    },
  ]);

  return (
    <DataTable
      columns={SLPA_COLUMN_PROPS}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      onSelect={onSelect}
      onSortChange={onSort}
      actions={slpaActions}
    />
  );
};

const ProviderDataTable = ({
  data,
  isLoading,
  pagination,
  actions,
  onSelect,
  onSort,
  onProviderEdit,
}: {
  data: ProviderUserRow[] | undefined;
  isLoading: boolean;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ProviderUserRow[]) => void;
  onSort: (s: SortQuery) => void;
  onProviderEdit?: (p: ProviderUserRow) => void;
}) => {
  const withProvider = <A,>(id: string, fn: (f: ProviderUserRow) => A) => {
    const provider = data?.find(f => f.userId === id);
    return provider && fn(provider);
  };

  const providerActions: DataTableAction[] = filterExists([
    onProviderEdit && {
      onClick: (providerId: string) => withProvider(providerId, onProviderEdit),
      icon: <EditIcon />,
      label: "Edit Provider",
    },
    ...(actions || []),
  ]);

  return (
    <DataTable
      columns={PROVIDER_COLUMN_PROPS}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      onSelect={onSelect}
      onSortChange={onSort}
      actions={providerActions}
    />
  );
};

const UserDataTable = ({
  userType,
  data,
  isLoading,
  onPageChange,
  onSortChange,
  onStudentSelect,
  onEdit,
  onEmail,
  onDelete,
  onSlpaEdit,
  onSlpaEmail,
  onSlpaDelete,
  onProviderEdit,
  footer,
}: {
  userType: UserType;
  data: UserState;
  isLoading: boolean;
  onPageChange: (p: PaginateState) => void;
  onSortChange: (s: SortQuery) => void;
  onStudentSelect: (s: StudentUserRow[]) => void;
  onEdit: (f: ExtendedUser) => void;
  onEmail: (f: ExtendedUser) => void;
  onDelete: { fn: (f: ExtendedUser) => void; isEnabled: (f: ExtendedUser) => boolean };
  onSlpaEdit?: (s: ExtendedSlpaUser) => void;
  onSlpaEmail?: (s: ExtendedSlpaUser) => void;
  onSlpaDelete: { fn: (s: ExtendedSlpaUser) => void; isEnabled: (s: ExtendedSlpaUser) => boolean };
  onProviderEdit?: (p: ExtendedProviderUser) => void;
  footer?: () => ReactElement;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const getPaginationProps = (page?: PaginatedResult<unknown>) => ({
    initialPageSize: INITIAL_PAGE_SIZE,
    onPageChange,
    totalCount: page?.totalCount || 0,
  });

  const calendarAction = hasRoleFlag(currentUser, "full-org-calendar-access")
    ? {
        href: (id: string) => `/calendar/${userType.toLowerCase()}/${id}`,
        icon: <Schedule />,
        label: "View Calendar",
      }
    : undefined;

  switch (userType) {
    case "STUDENT":
      return (
        <StudentDataTable
          data={data.STUDENT?.records}
          viewAs={currentUser?.userType}
          isLoading={isLoading}
          pagination={getPaginationProps(data.STUDENT)}
          onSelect={onStudentSelect}
          onSort={onSortChange}
          actions={calendarAction ? [calendarAction] : undefined}
          footer={footer}
        />
      );
    case "FACILITATOR":
      return (
        <DistrictUserDataTable
          userType="FACILITATOR"
          data={data.FACILITATOR?.records}
          isLoading={isLoading}
          pagination={getPaginationProps(data.FACILITATOR)}
          onSelect={() => {}}
          onSort={onSortChange}
          onEdit={onEdit}
          onEmail={onEmail}
          onDelete={onDelete}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "SITE_DIRECTOR":
      return (
        <DistrictUserDataTable
          userType="SITE_DIRECTOR"
          data={data.SITE_DIRECTOR?.records}
          isLoading={isLoading}
          pagination={getPaginationProps(data.SITE_DIRECTOR)}
          onSelect={() => {}}
          onSort={onSortChange}
          onEdit={onEdit}
          onEmail={onEmail}
          onDelete={onDelete}
        />
      );
    case "SLPA":
      return (
        <SlpaDataTable
          data={data.SLPA?.records}
          isLoading={isLoading}
          pagination={getPaginationProps(data.SLPA)}
          onSelect={() => {}}
          onSort={onSortChange}
          onSlpaEdit={onSlpaEdit}
          onSlpaEmail={onSlpaEmail}
          onSlpaDelete={onSlpaDelete}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "PROVIDER":
      return (
        <ProviderDataTable
          data={data.PROVIDER?.records}
          isLoading={isLoading}
          pagination={getPaginationProps(data.PROVIDER)}
          onSelect={() => {}}
          onSort={onSortChange}
          onProviderEdit={onProviderEdit}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "ADMIN":
      return (
        <DataTable
          columns={ADMIN_COLUMN_PROPS}
          data={data.ADMIN?.records}
          isLoading={isLoading}
          onSelect={() => {}}
          onSortChange={onSortChange}
          pagination={getPaginationProps(data.ADMIN)}
        />
      );
  }
};

export default UserDataTable;
