import type { AppointmentStatus, ClientGoal, GoalObjective, ObjectiveCategory } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { timeRangeQuerySchema } from "./calendar/calendar.types";
import { booleanQuerySchema, Override, paginateQueryFields } from "./shared.types";

export type { ObjectiveType, AppointmentCompletionRequirement } from "@prisma/client";

export type ObjectiveMetricValue = { metricId: string; metadata: Record<string, string | number> } & (
  | { type: "number"; value: number }
  | { type: "string"; value: string }
);

export type StudentObjective = Override<
  GoalObjective,
  {
    category: ObjectiveCategory;
    targetValue?: number;
    metric?: ObjectiveMetricValue;
    isArchived: boolean;
    createdAt: DateTime;
    collectedAtFirst?: DateTime;
    latestMetric?: { collectedAt: DateTime; value: ObjectiveMetricValue };
    completedAt?: DateTime;
  }
>;

export type StudentGoal = Override<
  ClientGoal,
  {
    objectives: StudentObjective[];
    isArchived: boolean;
    createdAt: DateTime;
    lastCollectedAt?: DateTime;
    completedAt?: DateTime;
  }
>;

export type ProgressAppointment = {
  id: string;
  title: string;
  startTime: DateTime;
  providerId?: string;
  providerName?: string;
  status: AppointmentStatus | null;
  cancelReason?: { id: string; title: string };
};

export type StudentAppointmentProgress = {
  studentId: string;
  studentName: string;
  appointment: ProgressAppointment;
  goals: StudentGoal[];
  isWaived: boolean;
  note?: string;
  previousNote?: string;
};

export type AppointmentProgress = {
  students: StudentAppointmentProgress[];
  groupNote?: string;
};

export type SingleStudentAppointmentProgress = StudentAppointmentProgress & { groupNote?: string };

const createObjectiveBodySchema = z.object({
  objectiveId: z.string().uuid().optional(),
  typeId: z.string().uuid(),
  description: z.string().trim(),
  targetValue: z.number().min(0).optional(),
});

export type CreateObjectiveBody = z.infer<typeof createObjectiveBodySchema>;

export const createGoalBodySchema = z.object({
  description: z.string().trim(),
  objectives: createObjectiveBodySchema.array().nonempty(),
});

export type CreateGoalBody = z.infer<typeof createGoalBodySchema>;

const updateObjectiveBodySchema = createObjectiveBodySchema.partial().extend({
  isCompleted: z.boolean().optional(),
  isArchived: z.boolean().optional(),
  isPinned: z.boolean().optional(),
});

export type UpdateObjectiveBody = z.infer<typeof updateObjectiveBodySchema>;

export const updateGoalBodySchema = createGoalBodySchema.partial().extend({
  objectives: updateObjectiveBodySchema.array().optional(),
  isCompleted: z.boolean().optional(),
  isArchived: z.boolean().optional(),
  isPinned: z.boolean().optional(),
  includeArchived: z.boolean().optional(),
});

export type UpdateGoalBody = z.infer<typeof updateGoalBodySchema>;

export const setStudentGoalsBodySchema = z.object({
  goals: createGoalBodySchema.extend({ goalId: z.string().uuid().optional() }).array(),
});

export type SetStudentGoalsBody = z.infer<typeof setStudentGoalsBodySchema>;

export const updateAppointmentProgressBodySchema = z.object({
  isWaived: z.boolean().optional(),
  includeArchived: z.boolean().optional(),
});

export type UpdateAppointmentProgressBody = z.infer<typeof updateAppointmentProgressBodySchema>;

const metricValueType = z.union([z.string().trim(), z.number()]);

export const objectiveMetricBodySchema = z.object({
  metricValue: metricValueType,
  metadata: z.record(z.string(), metricValueType).optional(),
});

export type ObjectiveMetricBody = z.infer<typeof objectiveMetricBodySchema>;

export const searchStudentProgressQuerySchema = timeRangeQuerySchema.extend({
  ...paginateQueryFields,
  providerId: z.string().uuid().optional(),
  includeArchived: booleanQuerySchema.optional(),
});

export type SearchStudentProgressQuery = z.infer<typeof searchStudentProgressQuerySchema>;
