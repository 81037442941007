import { ReactNode, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import LogoutIcon from "@mui/icons-material/Logout";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TimerIcon from "@mui/icons-material/Timer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SxProps, useTheme } from "@mui/material/styles";
import { isEmpty, isEqual, isString, omit } from "lodash";
import { StatusIcon, useAsyncProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { hasRoleFlag } from "@parallel/vertex/role";
import { hasGenericRole } from "@parallel/vertex/role/role.generic";
import { getReportPermissions } from "@parallel/vertex/role/role.report";
import { getValidUserTypes } from "@parallel/vertex/role/role.user";
import { FloatingMenu, StyledMenu, StyledMenuItem } from "@/components/shared/display/menu";
import config from "@/config";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import ZendeskWidget from "./components/ZendeskWidget";

const HEADER_HEIGHT_PX = 64;

const ToolbarButton = (props: ButtonProps & { isFlat?: boolean; isSelected?: boolean }) => {
  const theme = useTheme();
  const children = isString(props.children) ? (
    <Typography variant="body1">{props.children}</Typography>
  ) : (
    props.children
  );
  return (
    <Button
      {...omit(props, "isSelected", "isFlat")}
      sx={{
        color: "white",
        px: props.isFlat ? 0 : 3,
        backgroundColor: props.isSelected ? theme.palette.primary.dark : undefined,
        height: "36px",
        "&:hover": {
          backgroundColor: props.isFlat ? undefined : theme.palette.primary.dark,
        },
        "& .MuiButton-startIcon": {
          marginRight: props.children ? 1 : 0,
        },
      }}
    >
      {children}
    </Button>
  );
};

const isRouteSeleted = ({ route, pathname }: { route: string; pathname: string }) => new RegExp(route).test(pathname);

const ToolbarNavButton = ({ icon, route, children }: { icon?: ReactNode; route?: string; children?: ReactNode }) => {
  const { pathname } = useLocation();
  return (
    <ToolbarButton startIcon={icon} isSelected={route ? isRouteSeleted({ route, pathname }) : false} href={route}>
      {children}
    </ToolbarButton>
  );
};

export const SimpleHeaderContent = ({ text, controls }: { text: string; controls?: ReactNode }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" pt={2} px={3}>
      <Typography variant="h2">{text}</Typography>
      <Stack direction="row" gap={2}>
        {controls}
      </Stack>
    </Stack>
  );
};

const logger = initLogger("PrimaryLayout", getLoggerContext);

const PrimaryLayout = ({
  headerContent,
  children,
  containerStyles,
}: {
  headerContent?: ReactNode;
  children: ReactNode;
  containerStyles?: SxProps;
}) => {
  const { authStore } = useContext(StoreContext);
  const { currentUser } = authStore;

  const [protocolAnchor, setProtocolAnchor] = useState<HTMLElement>();
  const [userAnchor, setUserAnchor] = useState<HTMLElement>();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { status: signOutStatus, perform: performSignOut } = useAsyncProcessStatus(() =>
    authStore
      .signOut()
      .then(() => navigate("/"))
      .catch(logger.handleFailureAndThrow("signOut")),
  );

  const canReadUserRoles = getValidUserTypes(currentUser, "read");
  const reportReadPermissions = getReportPermissions(currentUser, "read");

  return (
    <FullBox sx={{ overflowX: "hidden" }}>
      <AppBar sx={{ height: HEADER_HEIGHT_PX, displayPrint: "none", boxShadow: "none" }}>
        <FullStack direction="row" px={3} justifyContent="space-between" alignItems="center">
          <Stack direction="row" height="100%" alignItems="center" gap={1}>
            <button
              aria-label="Navigate to home"
              onClick={() => navigate("/")}
              style={{
                cursor: "pointer",
                height: "70%",
                marginRight: "28px",
              }}
            >
              <img
                src={`${config.staticAssetsUrl}/logos/parallel-wordmark-white.svg`}
                alt="Parallel Logo"
                style={{ height: 0.45 * HEADER_HEIGHT_PX }}
              />
            </button>

            <ToolbarNavButton icon={<EventIcon />} route="/calendar">
              Calendar
            </ToolbarNavButton>

            {!isEmpty(canReadUserRoles) && (
              <ToolbarNavButton icon={<PeopleAltIcon />} route="/user">
                {isEqual(canReadUserRoles, ["STUDENT"]) ? "Students" : "Users"}
              </ToolbarNavButton>
            )}

            {!!reportReadPermissions && (
              <>
                {currentUser?.featureFlags.showNewReportWriter ? (
                  <ToolbarNavButton icon={<AssessmentIcon />} route="/report">
                    Report Writer
                  </ToolbarNavButton>
                ) : (
                  <Link href={config.reportWriterUrl} rel="noopener noreferrer" target="_blank">
                    <ToolbarButton startIcon={<OpenInNewIcon />}>Report Writer</ToolbarButton>
                  </Link>
                )}

                <ToolbarButton
                  startIcon={<DescriptionIcon />}
                  onClick={e => setProtocolAnchor(e.currentTarget)}
                  isSelected={isRouteSeleted({ route: "/protocols", pathname })}
                >
                  Protocols
                </ToolbarButton>
                <FloatingMenu
                  anchorEl={protocolAnchor}
                  onClose={() => setProtocolAnchor(undefined)}
                  items={[
                    { type: "sync", icon: <OpenInNewIcon />, text: "Mail Order", href: config.assessmentOrderUrl },
                    {
                      type: "sync",
                      icon: <CloudDownloadIcon />,
                      text: "Digital Access",
                      onClick: () => navigate("/protocols"),
                    },
                  ]}
                />
              </>
            )}

            {currentUser && (
              <Link
                href={hasGenericRole(currentUser, "internal-support") ? config.internalFaqUrl : config.externalFaqUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <ToolbarButton startIcon={<OpenInNewIcon />}>Help Center</ToolbarButton>
              </Link>
            )}
          </Stack>

          {currentUser && (
            <Stack direction="row">
              {hasGenericRole(currentUser, "internal-support") && currentUser?.featureFlags.showZendeskWidget && (
                <ZendeskWidget />
              )}

              {hasRoleFlag(currentUser, "manage-approvals") && (
                <Tooltip title={<Typography variant="body2"> Time Tracking </Typography>} placement="bottom">
                  <div>
                    <ToolbarNavButton icon={<TimerIcon />} route="/approval" />
                  </div>
                </Tooltip>
              )}

              <ToolbarButton
                style={{ marginLeft: "24px" }}
                startIcon={<AccountCircle />}
                onClick={e => setUserAnchor(e.currentTarget)}
                isFlat
              >
                {`${currentUser.firstName} ${currentUser.lastName}`}
              </ToolbarButton>
              <StyledMenu anchorEl={userAnchor} open={!!userAnchor} onClose={() => setUserAnchor(undefined)}>
                <StyledMenuItem onClick={performSignOut}>
                  <ListItemIcon>
                    {signOutStatus ? (
                      <StatusIcon status={signOutStatus} size={20} />
                    ) : (
                      <LogoutIcon sx={{ fontSize: 20 }} />
                    )}
                  </ListItemIcon>
                  <ListItemText>Sign Out</ListItemText>
                </StyledMenuItem>
              </StyledMenu>
            </Stack>
          )}
        </FullStack>
      </AppBar>

      <FullStack
        pt={`${HEADER_HEIGHT_PX}px`}
        sx={{
          "@media print": {
            paddingTop: 0,
          },
        }}
      >
        {headerContent}
        <Box sx={{ width: "100%", px: 3, pb: 3, flex: "1 1 0%", overflowY: "auto", ...containerStyles }}>
          {children}
        </Box>
      </FullStack>
    </FullBox>
  );
};

export default PrimaryLayout;
