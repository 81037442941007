import { ReactNode, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import ReportInterviewNotes from "@/components/report/data/ReportInterviewNotes";
import ReportTestingPlan from "@/components/report/data/ReportTestingPlan";
import ReportTestsAdministered from "@/components/report/data/ReportTestsAdministered";
import ReportEditor from "@/components/report/editor/ReportEditor";
import StudentDocuments from "@/components/user/student/document/StudentDocuments";
import { StoreContext } from "@/stores";

const ReportContent = () => {
  const {
    reportStore: { menuSelection, currentReport, appendDocumentUploads, removeDocumentUpload },
  } = useContext(StoreContext);

  if (menuSelection.type === "editor")
    return currentReport ? (
      <ReportEditor title={currentReport.template.title} editorSections={currentReport.editorSections} />
    ) : (
      <></>
    );

  if (menuSelection.type === "interview")
    return (
      <ReportInterviewNotes
        submissionId={menuSelection.submissionId}
        interviewType={menuSelection.interviewType}
        key={menuSelection.submissionId}
      />
    );

  switch (menuSelection.section) {
    case "testing-plan":
      return <ReportTestingPlan />;
    case "tests-administered":
      return <ReportTestsAdministered />;
    case "documents":
      if (!currentReport) return <></>;
      return (
        <StudentDocuments
          uploads={currentReport.documentUploads}
          studentId={currentReport.clientId}
          reportId={currentReport.reportId}
          header="Documents"
          onUploaded={appendDocumentUploads}
          onDeleted={removeDocumentUpload}
        />
      );
  }
};

export const ReportContentLayout = ({
  title,
  actions,
  children,
  headerSx,
}: {
  title: string;
  actions?: ReactNode;
  children: ReactNode;
  headerSx?: SxProps;
}) => (
  <FullStack>
    <Stack direction="row" pb={2} justifyContent="space-between" alignItems="start" sx={{ width: "100%", ...headerSx }}>
      <Typography variant="h2">{title}</Typography>
      {actions}
    </Stack>
    <Box sx={{ width: "100%", flex: "1 1 0%" }}>{children}</Box>
  </FullStack>
);

export default observer(ReportContent);
