import { useContext } from "react";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { CenterBox, FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { hasGenericRole } from "@parallel/vertex/role/role.generic";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const StimulusHomeScreen = () => {
  const {
    authStore: { currentUser },
    stimulusStore: { books, fetchRawBooks },
  } = useContext(StoreContext);

  const hasAccess = hasGenericRole(currentUser, "edit-assessment-stimuli");

  useMountEffect(() => {
    if (!hasAccess) return;
    fetchRawBooks();
  });

  const navigate = useNavigate();

  if (!hasAccess) return <NotFoundScreen />;

  return (
    <PrimaryLayout>
      <FullCenterBox>
        <Stack width={1000} height="100%" overflow="hidden" pt={4} gap={1}>
          <Stack direction="row" justifyContent="space-between" mb={4}>
            <Typography variant="h1">Assessment Stimulus Editor</Typography>
            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} href="/stimulus/upload">
              Upload Stimulus
            </Button>
          </Stack>

          <Typography variant="h2">Test Books</Typography>
          <CenterBox flex="1 1 0%" sx={{ overflowY: "auto", border: 1, borderColor: "grey.200" }}>
            {!books ? (
              <CircularProgress />
            ) : (
              <List sx={{ width: "100%", p: 0 }}>
                {books.map((book, i) => (
                  <>
                    {i !== 0 && <Divider />}
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <DeleteIcon />
                        </IconButton>
                      }
                      sx={{ padding: 0 }}
                    >
                      <ListItemButton onClick={() => navigate(`/stimulus/book/${book.id}/edit`)}>
                        <ListItemText>{book.name}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </>
                ))}
              </List>
            )}
          </CenterBox>
        </Stack>
      </FullCenterBox>
    </PrimaryLayout>
  );
};

export default observer(StimulusHomeScreen);
