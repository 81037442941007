import { ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EmailIcon from "@mui/icons-material/Email";
import ManageAccountIcon from "@mui/icons-material/ManageAccounts";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid-premium";
import { capitalize, isEmpty, isString } from "lodash";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { getSlpaPermissions } from "@parallel/vertex/role/role.slpa";
import { getStudentPermissions } from "@parallel/vertex/role/role.student";
import {
  getFacilitatorPermissions,
  getProviderPermissions,
  getSiteDirectorPermissions,
  getValidUserTypes,
  UserPermissions,
} from "@parallel/vertex/role/role.user";
import { PaginateParams, SortQuery } from "@parallel/vertex/types/shared.types";
import { ExtendedProviderUser } from "@parallel/vertex/types/user/provider.types";
import { ExtendedSlpaUser } from "@parallel/vertex/types/user/slpa.types";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { FilterOption } from "@/components/shared/input/FilterSearchInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import AdminForm from "@/components/user/AdminForm";
import DistrictUserForm from "@/components/user/DistrictUserForm";
import ProviderForm from "@/components/user/ProviderForm";
import SlpaForm from "@/components/user/SlpaForm";
import UserDataTable, {
  INITIAL_PAGE_SIZE,
  UserState,
  toProviderRow,
  toStudentRow,
} from "@/components/user/UserDataTable";
import UserListFilter, { CategoricalFilters } from "@/components/user/UserListFilter";
import UserListHeader from "@/components/user/UserListHeader";
import StudentAssignmentEdit from "@/components/user/student/form/StudentAssignmentEdit";
import StudentCreateForm from "@/components/user/student/form/StudentCreateForm";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getUserTypeLabel } from "@/util/user.util";

const logger = initLogger("UserListScreen", getLoggerContext);

const Confirmation = ({
  text,
  confirm,
  onFinished,
}: {
  text: string;
  confirm: {
    icon: ReactNode;
    process: () => Promise<unknown>;
    verb: string;
    pastTenseVerb: string;
    object: string;
    onSuccess?: () => void;
  };
  onFinished: () => void;
}) => {
  const onConfirm = () =>
    confirm
      .process()
      .then(() => {
        confirm.onSuccess && confirm.onSuccess();
        toast.success(`Successfully ${confirm.pastTenseVerb} ${confirm.object}`);
      })
      .catch(e => {
        logger.logFailure(`${confirm.verb} ${confirm.object}`, e);
        toast.error(`Failed to ${confirm.verb} ${confirm.object}`);
      })
      .finally(onFinished);

  return (
    <Stack width={400} gap={2}>
      <Typography variant="body1">{text}</Typography>
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button onClick={onFinished}>Cancel</Button>
        <ProcessButton variant="contained" startIcon={confirm.icon} process={onConfirm}>
          {capitalize(confirm.verb)}
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

const UserListScreen = ({ listType = "STUDENT" }: { listType?: UserType }) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [filters, setFilters] = useState<FilterOption[]>([]);

  const searchQuery = filters.reduce(
    (currParams, nextFilter) => ({
      ...currParams,
      [nextFilter.searchParamKey]: nextFilter.searchParamValue,
    }),
    {},
  );

  useEffect(() => setFilters([]), [listType]);

  const [categoricalFilters, setCategoricalFilters] = useState<CategoricalFilters>({
    showTestData: true,
    showArchivedStudents: false,
    filterUnassignedStudents: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [pageModel, setPageModel] = useState<PaginateParams>({ pageSize: INITIAL_PAGE_SIZE });
  const [sortModel, setSortModel] = useState<SortQuery>();

  useEffect(() => {
    setPageModel({ pageSize: INITIAL_PAGE_SIZE });
    setSortModel(undefined);
  }, [listType]);

  const [users, setUsers] = useState<UserState>({});

  const fetchUsers = (type: UserType) => {
    setIsLoading(true);
    switch (type) {
      case "STUDENT":
        return userApi
          .searchStudents({ ...searchQuery, ...categoricalFilters, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, STUDENT: { ...r, records: r.records.map(toStudentRow) } }))
          .catch(logger.handleFailure("searchStudents"))
          .finally(() => setIsLoading(false));
      case "FACILITATOR":
        return userApi
          .searchFacilitators({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, FACILITATOR: r }))
          .catch(logger.handleFailure("searchFacilitators"))
          .finally(() => setIsLoading(false));
      case "SITE_DIRECTOR":
        return userApi
          .searchUsers({ ...searchQuery, userTypes: ["SITE_DIRECTOR"], ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, SITE_DIRECTOR: r }))
          .catch(logger.handleFailure("searchSiteDirectors"))
          .finally(() => setIsLoading(false));
      case "SLPA":
        return userApi
          .searchSlpas({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, SLPA: r }))
          .catch(logger.handleFailure("searchSlpas"))
          .finally(() => setIsLoading(false));
      case "PROVIDER":
        return userApi
          .searchProviders({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, PROVIDER: { ...r, records: r.records.map(toProviderRow) } }))
          .catch(logger.handleFailure("searchProviders"))
          .finally(() => setIsLoading(false));
      case "ADMIN":
        return userApi
          .searchUsers({ ...searchQuery, userTypes: ["ADMIN"], ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, ADMIN: r }))
          .catch(logger.handleFailure("searchAdmins"))
          .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchUsers(listType);
  }, [listType, filters, categoricalFilters, pageModel, sortModel]);

  const [creatingType, setCreatingType] = useState<UserType>();

  const [selectedStudents, setSelectedStudents] = useState<ExtendedStudentUser[]>([]);
  const [isEditingStudentAssignments, setIsEditingStudentAssignments] = useState(false);

  const studentAssignmentsUpdated = (updated: ExtendedStudentUser[]) => {
    const students = users.STUDENT;
    if (!students) return;
    const updatedRows = updated.map(toStudentRow);
    const merged = students.records.map(current => updatedRows.find(u => u.userId === current.userId) || current);
    setUsers({ ...users, STUDENT: { records: merged, totalCount: students.totalCount } });
    setSelectedStudents(updated);
  };

  const [editingUser, setEditingUser] = useState<ExtendedUser>();
  const [welcomeEmailUser, setWelcomeEmailUser] = useState<ExtendedUser>();
  const [deletingUser, setDeletingUser] = useState<ExtendedUser>();

  const [editingSlpa, setEditingSlpa] = useState<ExtendedSlpaUser>();
  const [welcomeEmailSlpa, setWelcomeEmailSlpa] = useState<ExtendedSlpaUser>();
  const [deletingSlpa, setDeletingSlpa] = useState<ExtendedSlpaUser>();

  const [editingProvider, setEditingProvider] = useState<ExtendedProviderUser>();

  const clearModal = () => {
    setCreatingType(undefined);
    setIsEditingStudentAssignments(false);
    setEditingUser(undefined);
    setWelcomeEmailUser(undefined);
    setDeletingUser(undefined);
    setEditingSlpa(undefined);
    setWelcomeEmailSlpa(undefined);
    setDeletingSlpa(undefined);
    setEditingProvider(undefined);
  };

  let modalContent: ReactNode | undefined;

  const getCommonUserFormProps = (userType: UserType) => ({
    onWrite: () => {
      fetchUsers(userType);
      clearModal();
    },
    onClose: clearModal,
  });

  const studentWritePermissions = getStudentPermissions(currentUser, "write");
  const slpaWritePermissions = getSlpaPermissions(currentUser, "write");
  const facilitatorWritePermissions = getFacilitatorPermissions(currentUser, "write");
  const siteDirectorWritePermissions = getSiteDirectorPermissions(currentUser, "write");
  const providerWritePermissions = getProviderPermissions(currentUser, "write");

  const formUserType = creatingType || editingUser?.userType;

  let districtUserPermissions: UserPermissions | null = null;
  if (formUserType === "FACILITATOR") districtUserPermissions = facilitatorWritePermissions;
  if (formUserType === "SITE_DIRECTOR") districtUserPermissions = siteDirectorWritePermissions;

  if (isEditingStudentAssignments) {
    modalContent = (
      <StudentAssignmentEdit students={selectedStudents} onCancel={clearModal} onSave={studentAssignmentsUpdated} />
    );
  } else if (creatingType === "STUDENT" && studentWritePermissions) {
    modalContent = <StudentCreateForm {...getCommonUserFormProps("STUDENT")} permissions={studentWritePermissions} />;
  } else if (formUserType && ["FACILITATOR", "SITE_DIRECTOR"].includes(formUserType) && districtUserPermissions) {
    modalContent = (
      <DistrictUserForm
        userType={formUserType as "FACILITATOR" | "SITE_DIRECTOR"}
        {...getCommonUserFormProps(formUserType)}
        editing={editingUser}
        writePermissions={districtUserPermissions}
      />
    );
  } else if ((creatingType === "SLPA" || editingSlpa) && slpaWritePermissions) {
    modalContent = (
      <SlpaForm {...getCommonUserFormProps("SLPA")} editing={editingSlpa} permissions={slpaWritePermissions} />
    );
  } else if (creatingType === "PROVIDER" || editingProvider) {
    modalContent = <ProviderForm {...getCommonUserFormProps("PROVIDER")} editing={editingProvider} />;
  } else if (creatingType === "ADMIN") {
    modalContent = <AdminForm {...getCommonUserFormProps("ADMIN")} />;
  } else if (welcomeEmailUser || welcomeEmailSlpa) {
    const emailTarget = welcomeEmailUser || welcomeEmailSlpa;
    const emailNoun = `Parallel welcome email to ${emailTarget!.fullName}`;
    modalContent = (
      <Confirmation
        text={`Please confirm that you want to resend the ${emailNoun} at ${emailTarget!.email}`}
        confirm={{
          icon: <EmailIcon />,
          process: welcomeEmailUser
            ? () => userApi.sendWelcomeEmail(emailTarget!.userId)
            : () => userApi.sendSlpaWelcomeEmail(emailTarget!.userId),
          verb: "resend",
          pastTenseVerb: "resent",
          object: emailNoun,
        }}
        onFinished={clearModal}
      />
    );
  } else if (deletingUser || deletingSlpa) {
    const archiveTarget = deletingUser || deletingSlpa;
    const archiveUserType = archiveTarget!.userType;
    const archiveNoun = `${getUserTypeLabel(archiveUserType)} ${archiveTarget!.fullName}`;
    modalContent = (
      <Confirmation
        text={`Please confirm that you want to permanently delete ${archiveNoun}`}
        confirm={{
          icon: <DeleteForeverIcon />,
          process: deletingUser
            ? () => userApi.deleteUser(archiveTarget!.userId)
            : () => userApi.deleteSlpa(archiveTarget!.userId),
          verb: "delete",
          pastTenseVerb: "deleted",
          object: archiveNoun,
          onSuccess: () => fetchUsers(archiveUserType),
        }}
        onFinished={clearModal}
      />
    );
  }

  const canEditProviders = hasRoleFlag(currentUser, "edit-related-providers");
  const canEditFacilitators = hasRoleFlag(currentUser, "edit-related-facilitators");

  const UserTableFooter = () =>
    canEditProviders || canEditFacilitators ? (
      <GridFooterContainer>
        <Button
          onClick={() => setIsEditingStudentAssignments(true)}
          startIcon={<ManageAccountIcon />}
          disabled={isEmpty(selectedStudents)}
          sx={{ ml: 1.5 }}
        >
          <Typography variant="body1" fontWeight="500">
            {canEditProviders ? "Edit Facilitators / Providers" : "Edit Facilitators"}
          </Typography>
        </Button>
        <GridFooter sx={{ border: "none" }} />
      </GridFooterContainer>
    ) : (
      <></>
    );

  const readableUserTypes = getValidUserTypes(currentUser, "read");
  const writableUserTypes = getValidUserTypes(currentUser, "write");

  if (!currentUser || !readableUserTypes.includes(listType)) return <NotFoundScreen />;

  const isDeleteEnabled = (targetUser: ExtendedUser, permissions: UserPermissions | null): boolean => {
    if (!permissions?.allowDeleteIf) return false;
    if (!permissions.accessLevels.includes("write")) return false;

    if (isString(permissions.allowDeleteIf)) return true;

    return permissions.allowDeleteIf(targetUser, currentUser.userId);
  };

  return (
    <PrimaryLayout headerContent={<UserListHeader listType={listType} />}>
      <FullStack>
        <UserListFilter
          listType={listType}
          selectedFilters={filters}
          setSelectedFilters={setFilters}
          categoricalFilters={categoricalFilters}
          setCategoricalFilters={setCategoricalFilters}
          onAdd={writableUserTypes.includes(listType) ? () => setCreatingType(listType) : undefined}
        />

        <Box sx={{ height: 0, width: "100%", flex: "1 1 0%" }}>
          <FullBox role="tabpanel">
            <UserDataTable
              userType={listType}
              data={users}
              isLoading={isLoading}
              onPageChange={pageModel => setPageModel(pageModel)}
              onSortChange={setSortModel}
              onStudentSelect={setSelectedStudents}
              onEdit={setEditingUser}
              onEmail={setWelcomeEmailUser}
              onDelete={{
                fn: setDeletingUser,
                isEnabled: f => isDeleteEnabled(f, facilitatorWritePermissions),
              }}
              onSlpaEdit={slpaWritePermissions ? setEditingSlpa : undefined}
              onSlpaEmail={slpaWritePermissions ? setWelcomeEmailSlpa : undefined}
              onSlpaDelete={{
                fn: setDeletingSlpa,
                isEnabled: s => isDeleteEnabled(s, slpaWritePermissions),
              }}
              onProviderEdit={providerWritePermissions ? setEditingProvider : undefined}
              footer={UserTableFooter}
            />
          </FullBox>
        </Box>
      </FullStack>

      <CenterModal isOpen={!!modalContent} onClose={clearModal}>
        {modalContent}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default UserListScreen;
