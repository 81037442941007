import { get, isArray, isEmpty, isString } from "lodash";
import { FormAnswers } from "../types/form.types";
import { SingleReport } from "../types/report.types";
import { mapExists } from "./collection.util";
import { getPossessiveNoun } from "./string.util";

type InfoSourceTableSource =
  | { type: "testUpload"; sourceId: string }
  | { type: "testingPlan"; name: string | ((report: SingleReport) => string); testingPlanMatches: string[] };

type InfoSourceTableGroup = {
  title: string;
  sources: InfoSourceTableSource[];
};

const PSYCH_INFO_SOURCE_TABLE_GROUPS: InfoSourceTableGroup[] = [
  {
    title: "Interviews",
    sources: [
      {
        type: "testingPlan",
        name: report => `Student Interview with ${report.client.firstName}`,
        testingPlanMatches: ["non-test-measure~pl_clinical_interview"],
      },
      {
        type: "testingPlan",
        name: report => `Interview with ${getPossessiveNoun(report.client.firstName)} caregiver(s)`,
        testingPlanMatches: ["non-test-measure~pl_collateral_interview"],
      },
      {
        type: "testingPlan",
        name: report => `Interview with ${getPossessiveNoun(report.client.firstName)} teacher(s)`,
        testingPlanMatches: ["non-test-measure~pl_teacher_interview"],
      },
      {
        type: "testUpload",
        sourceId: "14e39466-d111-4080-8d80-334fc5d96be2",
      },
      {
        type: "testingPlan",
        name: "Monteiro Interview Guidelines for Diagnosing the Autism Spectrum, Second Edition (MIGDAS-2)",
        testingPlanMatches: ["autism-measure~migdas-2"],
      },
    ],
  },
  {
    title: "Reviews of Records",
    sources: [
      {
        type: "testingPlan",
        name: "Review of previous psychoeducational evaluation",
        testingPlanMatches: ["non-test-measure~prev_evaluation_review"],
      },
      {
        type: "testingPlan",
        name: "Review of Individualized Education Plan IEP",
        testingPlanMatches: ["non-test-measure~iep_review"],
      },
    ],
  },
  {
    title: "Self-Report Survey Measures",
    sources: [
      {
        type: "testUpload",
        sourceId: "638895cd-4c62-4b42-9810-8ee9516527cd",
      },
      {
        type: "testUpload",
        sourceId: "ef50b06f-bf79-4ac1-864d-3b06e7a9a219",
      },
      {
        type: "testUpload",
        sourceId: "14cd3ce5-336c-4264-9036-ea180749e882",
      },
      {
        type: "testUpload",
        sourceId: "ad806e2c-3346-4604-889c-e9c038e71f9b",
      },
      {
        type: "testUpload",
        sourceId: "03e194b4-b331-4837-8485-e39c9095e6c1",
      },
    ],
  },
  {
    title: "Rating Scale Measures",
    sources: [
      {
        type: "testUpload",
        sourceId: "036f06f6-669b-4af0-874b-f4e2f30a837c",
      },
      {
        type: "testUpload",
        sourceId: "e052a6a2-1881-4792-979b-1bd72bf938f7",
      },
      {
        type: "testUpload",
        sourceId: "a7e51a73-2685-460c-abda-3d95d9730f8d",
      },
      {
        type: "testUpload",
        sourceId: "71cb493a-176e-4bb8-b70f-c6b2d8372063",
      },
      {
        type: "testUpload",
        sourceId: "9e3ee2bc-5688-4c99-9179-81ef5948efee",
      },
      {
        type: "testUpload",
        sourceId: "f9e7ca2f-8fb4-4a6e-b711-bb0b25a421d2",
      },
      {
        type: "testUpload",
        sourceId: "42c40b5b-3068-4ddb-ab34-8c45f30d957a",
      },
      {
        type: "testUpload",
        sourceId: "492f4fe7-4f64-4e8b-afc4-fd344732610c",
      },
      {
        type: "testUpload",
        sourceId: "fb201e8a-ff1b-461c-be49-d72d28a7eb88",
      },
      {
        type: "testUpload",
        sourceId: "e74ec9a3-40a1-4276-80c2-5c6ba0b8183b",
      },
      {
        type: "testUpload",
        sourceId: "7aeb1678-84e3-4379-a8dc-731328207125",
      },
      {
        type: "testUpload",
        sourceId: "57744c37-ed9b-426e-89cc-dfaca0f6d81a",
      },
      {
        type: "testUpload",
        sourceId: "208e82f5-79c6-4553-92ba-b26df01c1904",
      },
      {
        type: "testUpload",
        sourceId: "c70ff5d0-6f0e-4375-8f5b-c814c27d2347",
      },
      {
        type: "testUpload",
        sourceId: "7e4a6192-b8ca-4682-8940-c895368eda18",
      },
      {
        type: "testUpload",
        sourceId: "ceeab4a9-7d39-4d5b-8637-690622dde189",
      },
      {
        type: "testUpload",
        sourceId: "d8203eb1-e96d-4108-8cbb-cb1ab7adbb36",
      },
      {
        type: "testUpload",
        sourceId: "bef1f625-309f-4263-bf07-3ea06a75bc6e",
      },
      {
        type: "testUpload",
        sourceId: "cd01daaa-334a-4846-9f4c-8bc7ec2754bd",
      },
    ],
  },
  {
    title: "Performance-Based Cognitive and Academic Measures",
    sources: [
      {
        type: "testUpload",
        sourceId: "0f406234-a757-4dd3-9943-3802eeb7e0da",
      },
      {
        type: "testingPlan",
        name: "Bateria IV Pruebas de Cognitivo",
        testingPlanMatches: ["spanish-cog-toggle~TRUE"],
      },
      {
        type: "testUpload",
        sourceId: "de25dd6d-f087-449f-85d1-5e9e3fcc45f2",
      },
      {
        type: "testUpload",
        sourceId: "3dc2c816-fd35-402d-8063-d3accb4fb660",
      },
      {
        type: "testUpload",
        sourceId: "09e19212-4808-493d-ba6e-86def47076de",
      },
      {
        type: "testUpload",
        sourceId: "16dd064e-62c2-4d5f-92aa-1ec469fbd76a",
      },
      {
        type: "testUpload",
        sourceId: "08bb68d5-cbd2-4f05-bd70-78d83459537a",
      },
      {
        type: "testUpload",
        sourceId: "cc23a2d5-64c8-43dc-b566-f1d858b52f4a",
      },
    ],
  },
];

export const doesTestingPlanMatch = (answers: FormAnswers, matches: string[]) =>
  matches.some(match => {
    const [matchKey, matchValue] = match.split("~");
    const testingPlanAnswer = get(answers, matchKey);
    return isArray(testingPlanAnswer) ? testingPlanAnswer.includes(matchValue) : testingPlanAnswer === matchValue;
  });

export const getPsychInfoSourceTableData = (report: SingleReport) =>
  mapExists(PSYCH_INFO_SOURCE_TABLE_GROUPS, ({ title, sources }) => {
    const sourceNames = mapExists(sources, source => {
      switch (source.type) {
        case "testUpload":
          const upload = report.testUploads.find(
            upload => upload.reportTestUploadSourceId === source.sourceId && !upload.skipReason,
          );
          return upload?.source.longName;
        case "testingPlan":
          if (!doesTestingPlanMatch(report.formSubmissions.testingPlanAnswers || {}, source.testingPlanMatches)) return;
          return isString(source.name) ? source.name : source.name(report);
      }
    });
    return !isEmpty(sourceNames) ? { title, sourceNames } : null;
  });
