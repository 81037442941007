import { UserType } from "../enums/user.enums";

export type CalendarRoleFlag =
  | "full-org-calendar-access"
  | "write-calendar"
  | "restrict-calendar-writes-to-pay-period"
  | "calendar-item-filters"
  | "calendar-custom-filters"
  | "calendar-custom-filtersDefaultOn"
  | "manage-appointment-details" // manage progress, status, observation notes, etc. from appointment modal
  | "view-session-event-logs"
  | "manage-approvals";

export const CALENDAR_ROLE_FLAG_GRANTS: Record<CalendarRoleFlag, UserType[]> = {
  "full-org-calendar-access": ["ADMIN"],
  "write-calendar": ["ADMIN", "PROVIDER"],
  "restrict-calendar-writes-to-pay-period": ["PROVIDER"],
  "calendar-item-filters": ["ADMIN", "PROVIDER"],
  "calendar-custom-filters": ["ADMIN", "PROVIDER", "FACILITATOR", "STUDENT"],
  "calendar-custom-filtersDefaultOn": ["ADMIN", "PROVIDER", "FACILITATOR"],
  "manage-appointment-details": ["ADMIN", "PROVIDER"],
  "view-session-event-logs": ["ADMIN"],
  "manage-approvals": ["ADMIN"],
};
