import BarChartIcon from "@mui/icons-material/BarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";

const StudentGoalItem = ({ goal, index }: { goal: StudentGoal; index: number }) => {
  const objectivesTotal = goal.objectives.length;
  const objectivesComplete = goal.objectives.filter(o => !!o.completedAt).length;
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack width="100%" direction="row" alignItems="center" gap={1}>
          <Typography variant="h3">Goal {index + 1}</Typography>
          <BarChartIcon fontSize="small" sx={{ color: "grey.600" }} />
          <Box sx={{ flex: "1 1 0%", px: 2 }}>
            <Typography variant="body2">
              Objectives Achieved: {objectivesComplete} of {objectivesTotal}
            </Typography>
            <Box height="8px" width="100%" position="relative">
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "8px",
                  border: 1,
                  borderColor: "grey.400",
                  borderRadius: 1,
                }}
              />
              {!!objectivesComplete && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: percentString(objectivesComplete / objectivesTotal),
                    height: "8px",
                    bgcolor: "primary.main",
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: 1,
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          <Typography variant="body1" pb={1}>
            {goal.description}
          </Typography>
          <SplitRow
            left={<Typography variant="body1">Started:</Typography>}
            right={<Typography variant="body1">{goal.createdAt.toLocaleString(DateTime.DATE_SHORT)}</Typography>}
          />
          <SplitRow
            left={<Typography variant="body1">Last Collected Data:</Typography>}
            right={
              <Typography variant="body1">
                {goal.lastCollectedAt ? goal.lastCollectedAt.toLocaleString(DateTime.DATE_SHORT) : "N/A"}
              </Typography>
            }
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const StudentGoalSummary = ({ student }: { student: SingleStudentUser }) => {
  return (
    <Stack gap={2} pt={2}>
      {student.goals.map((goal, i) => (
        <StudentGoalItem goal={goal} index={i} key={goal.goalId} />
      ))}
    </Stack>
  );
};

export default StudentGoalSummary;
