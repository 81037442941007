import { useEffect, useContext, useState } from "react";
import HeadsetMic from "@mui/icons-material/HeadsetMic";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { initLogger } from "@parallel/vertex/util/logging.util";
import { StoreContext } from "@/stores";

const logger = initLogger("ZendeskWidget");

const isDevelopment = import.meta.env.MODE === "development";

const ZENDESK_SCRIPT_ID = "ze-snippet";
const ZENDESK_SCRIPT_SRC =
  "https://static.zdassets.com/ekr/snippet.js?key=8685dd93-08b8-4768-956f-8f3878bfd5ee";

const ZendeskWidget = () => {
  const {
    apiStore: { authApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (isDevelopment) {
      const existingScript = document.getElementById(ZENDESK_SCRIPT_ID);
      if (existingScript) {
        existingScript.remove();
      }
      return;
    }

    let script = document.getElementById(ZENDESK_SCRIPT_ID) as HTMLScriptElement | null;
    if (!script) {
      script = document.createElement("script");
      script.id = ZENDESK_SCRIPT_ID;
      script.src = ZENDESK_SCRIPT_SRC;
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
      };
      script.onerror = () => {
        logger.error("Failed to load Zendesk script.");
      };
      document.body.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isDevelopment) return;
    if (!scriptLoaded) {
      return;
    }

    const setupZendesk = async () => {
      if (currentUser) {
        window.zE("messenger", "loginUser", async (callback: (_: string) => void) => {
          try {
            const token = await authApi.zendeskSignIn(currentUser);
            callback(token);

            window.zE("messenger:set", "cookies", "all");
            window.zE("messenger", "show");
            window.zE("messenger:on", "unreadMessages", (count: number) => {
              setUnreadMessages(count);
            });
          } catch (error) {
            logger.error("Error signing in to Zendesk", error);

            window.zE("messenger", "logoutUser");
            window.zE("messenger:set", "cookies", "none");
          }
        });
      } else {
        window.zE("messenger", "logoutUser");
        window.zE("messenger:set", "cookies", "none");
      }
    };

    if (window.zE) {
      setupZendesk();
    }
  }, [scriptLoaded, currentUser, authApi]);

  const handleZendeskOpen = () => {
    if (isDevelopment) {
      alert("Zendesk widget is not loaded in development mode.");
    } else {
      window.zE("messenger", "open");
    }
  };

  return (
    <Tooltip title={<Typography variant="body2">In-App Messaging</Typography>} placement="bottom">
      <Button
        sx={{
          px: 3,
          color: "white",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
        onClick={handleZendeskOpen}
      >
        <Badge badgeContent={unreadMessages} color="error" invisible={unreadMessages === 0}>
          <HeadsetMic fontSize="small" />
        </Badge>
      </Button>
    </Tooltip>
  );
};

export default observer(ZendeskWidget);
