import {
  StudentRow,
  StudentRowValidation,
  StudentUploadBody,
  StudentUploadResponse,
  StudentUploadSourceResponse,
} from "@parallel/vertex/types/bulk.upload.types";
import { SingleReport } from "@parallel/vertex/types/report.types";
import { ServiceType } from "@parallel/vertex/types/service.types";
import { ExtendedStudentDocumentUpload } from "@parallel/vertex/types/user/student.types";
import { BaseAPI } from "@/api/base.api";

export type DocumentFileUploadRequest = {
  documentType?: string;
  file: File;
};

export class StudentAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public getSheet = ({
    googleSheetId,
    serviceType,
  }: {
    googleSheetId: string;
    serviceType: ServiceType;
  }): Promise<StudentUploadSourceResponse> =>
    this.instance
      .get(`student/bulk/source?googleSheetId=${googleSheetId}&serviceType=${serviceType}`)
      .then(r => r.data);

  public postRowValidate = ({
    data,
    serviceType,
  }: {
    data: StudentRow;
    serviceType: ServiceType;
  }): Promise<StudentRowValidation> =>
    this.instance.post(`student/bulk/validate?serviceType=${serviceType}`, data).then(r => r.data);

  public createStudents = (body: StudentUploadBody): Promise<StudentUploadResponse> =>
    this.instance.post("student/bulk", body).then(r => r.data);

  public uploadDocuments = (
    studentId: string,
    files: DocumentFileUploadRequest[],
    reportId?: string,
  ): Promise<ExtendedStudentDocumentUpload[]> => {
    const formData = new FormData();
    files.forEach(({ file, documentType }) => formData.append(documentType ?? "other", file, file.name));
    return this.instance
      .post(`student/${studentId}/document?reportId=${reportId ?? ""}`, formData)
      .then(r => r.data.documents);
  };

  public getStudentInfoDocuments = (studentId: string): Promise<ExtendedStudentDocumentUpload[]> =>
    this.instance.get(`student/${studentId}/document`).then(r => r.data.documents);

  public deleteDocumentUpload = (documentUploadId: string): Promise<SingleReport> =>
    this.instance.delete(`student/${documentUploadId}/document`).then(r => r.data);

  public getDocumentFileUrl = (documentId: string, studentId: string): Promise<string> =>
    this.instance.get(`student/${studentId}/file/${documentId}`).then(r => r.data.url);
}
