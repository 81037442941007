import type { ApprovalStatus, ComplianceType, TaskType, TaskTypeCategory, TimeEntry } from "@prisma/client";
import { z } from "zod";
import { approvalStatusEnum } from "../../enums/approval.enums";
import { recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { datetimeRequestField, Override, paginateQueryFields } from "../shared.types";
import { ExtendedUser } from "../user/user.types";
import { ExtendedCalendarItem, timeRangeFields, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type { TaskType, TaskTypeCategory } from "@prisma/client";

export type ExtendedTimeEntry = Override<
  ExtendedCalendarItem<TimeEntry> & {
    campus?: { institutionId: string; salesforceName: string };
    isLateSubmission?: boolean;
    latestApproval?: { approvalId: string; approvalStatus: ApprovalStatus };
    recurrence?: ExtendedRecurrence;
    students: ExtendedUser[];
    taskType: TaskType;
    user: { userId: string; fullName: string; email?: string };
    userName?: string; // deprecated
  },
  // ! Override for serialization
  {
    createdBy?: string;
    nylasEventId?: string | null;
    recurrenceId?: string | null;
  }
>;

export const searchIndirectTimeSchema = z.object({
  approvalStatus: approvalStatusEnum.optional(),
  endTime: datetimeRequestField.optional(),
  startTime: datetimeRequestField.optional(),
  taskTypeId: z.string().uuid().optional(),
  userIds: z.string().uuid().array().optional(),
  ...paginateQueryFields,
});

export type SearchIndirectTimeQuery = z.infer<typeof searchIndirectTimeSchema> & {
  complianceTypes?: ComplianceType[];
  recurrenceId?: string;
  studentId?: string;
};

const indirectTimeBody = z.object({
  approvalStatus: approvalStatusEnum.optional(),
  campusId: z.string().uuid().nullable().optional(),
  description: z.string().trim().nullable().optional(),
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
  studentUserIds: z.string().uuid().array().nullable().optional(),
  taskTypeId: z.string().uuid(),
  userId: z.string().uuid().optional(),
  ...timeRangeFields,
});

export const createIndirectTimeBodySchema = indirectTimeBody.refine(...timeRangeRefine);

export type CreateIndirectTimeBody = z.infer<typeof createIndirectTimeBodySchema>;

export const updateIndirectTimeBodySchema = indirectTimeBody.partial().refine(...timeRangeRefine);

export type UpdateIndirectTimeBody = z.infer<typeof updateIndirectTimeBodySchema> & { nylasEventId?: string | null };

export const bulkUpdateIndirectTimeBodySchema = z.record(z.string().uuid(), updateIndirectTimeBodySchema);

export type BulkUpdateIndirectTimeBody = z.infer<typeof bulkUpdateIndirectTimeBodySchema>;

export const deleteIndirectTimeBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteIndirectTimeBody = z.infer<typeof deleteIndirectTimeBodySchema>;

export const searchIndirectTimeTypesQuerySchema = z.object({ providerId: z.string().optional() });

export type SearchIndirectTimeTypesQuery = z.infer<typeof searchIndirectTimeTypesQuerySchema>;

export type IndirectTimeTypeCategory = {
  types: TaskType[];
  value: TaskTypeCategory;
};
