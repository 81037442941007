import { ReactNode } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { CommonProgressItem, getProgressIcon } from "@parallel/polygon/util/progress.util";

const ProgressListItemLayout = ({
  item,
  title,
  actions,
  data,
  endIcon,
  onPinnedToggle,
}: {
  item: CommonProgressItem;
  title: string;
  actions: ReactNode;
  data: { left: string; right: string }[];
  endIcon?: ReactNode;
  onPinnedToggle: () => Promise<unknown>;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={2} width="100%">
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 8 }}>
          <Stack direction="row" gap={1} alignItems="center">
            <ProcessIconButton size="small" process={onPinnedToggle} loadingOnly>
              {item.isPinned ? (
                <PushPinIcon fontSize="small" color="primary" />
              ) : (
                <PushPinOutlinedIcon fontSize="small" color="primary" />
              )}
            </ProcessIconButton>
            <Typography variant="body1">{title}</Typography>
            {getProgressIcon(item)}
          </Stack>
          <Typography variant="body1">{item.description}</Typography>
        </Grid>

        <Grid size={{ xs: 4 }}>
          {actions}
          <Stack pt={1}>
            {data.map(({ left, right }, i) => (
              <SplitRow
                left={<Typography variant="body2">{left}</Typography>}
                right={<Typography variant="body2">{right}</Typography>}
                sx={{ px: 1 }}
                key={i}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>

      {endIcon}
    </Stack>
  );
};

export default ProgressListItemLayout;
