import { ReactNode } from "react";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { NexusErrorCode } from "@parallel/vertex/enums/nexus.enums";
import { NexusError } from "@parallel/vertex/util/nexus.base.api";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const SUBMIT_ERROR_MESSAGES: Partial<Record<NexusErrorCode, string>> = {
  DUPLICATE_STUDENT_WRITE: "A student with these details already exists",
  DUPLICATE_USER_EMAIL_WRITE: "A user with this email already exists",
};

type FormAction = {
  onClick?: () => Promise<unknown>;
  label: string;
  icon?: ReactNode;
  isSync?: boolean;
};

const logger = initLogger("FormLayout", getLoggerContext);

export type SubmitFunction<B extends object> = (body: B) => Promise<{ warning: string } | void>;

export const getCancelAction = (onCancel: () => void): FormAction => ({
  onClick: async () => onCancel(),
  label: "Cancel",
});

export const getSubmitAction = <P extends object, B extends object>(
  recordName: string,
  operationName: "update" | "create" | "delete",
  params: P,
  validate: (p: P) => B | undefined,
  submit: SubmitFunction<B>,
  skipSuccessToast?: boolean,
  recordLabel?: string,
): FormAction => {
  const validParams = validate(params);
  const onClick = validParams
    ? () =>
        submit(validParams)
          .then(result => {
            if (skipSuccessToast) return;
            result?.warning
              ? toast.warn(result.warning)
              : toast.success(`Successfully ${toTitleCase(operationName)}d ${recordLabel || toTitleCase(recordName)}`);
          })
          .catch(error => {
            const genericMessage = `Failed to ${toTitleCase(operationName)} ${recordLabel || toTitleCase(recordName)}`;
            const specificMessage =
              error instanceof NexusError && error.nexusErrorCode && SUBMIT_ERROR_MESSAGES[error.nexusErrorCode];
            logger.error(genericMessage, { causedBy: error, context: { validParams, specificMessage } });
            toast.error(specificMessage || genericMessage);
          })
    : undefined;
  return {
    onClick,
    label: "Submit",
    icon: <SaveIcon />,
  };
};

const FormActionButton = ({
  primary,
  action: { onClick, label, icon, isSync },
}: {
  primary?: boolean;
  action: FormAction;
}) => {
  const commonButtonProps: ButtonProps = {
    variant: primary ? "contained" : undefined,
    startIcon: icon,
    disabled: !onClick,
  };
  return isSync ? (
    <Button {...commonButtonProps} onClick={onClick}>
      {label}
    </Button>
  ) : (
    <ProcessButton {...commonButtonProps} process={onClick}>
      {label}
    </ProcessButton>
  );
};

const FormLayout = <P extends object>({
  headerText,
  headerContent,
  subheaderContent,
  formContent,
  primaryAction,
  secondaryAction,
  minWidth = 500,
}: {
  headerText: string;
  headerContent?: ReactNode;
  subheaderContent?: ReactNode;
  formContent: ReactNode;
  initialParams?: P;
  primaryAction: FormAction;
  secondaryAction: FormAction;
  minWidth?: number;
}) => {
  return (
    <Stack gap={2} minWidth={minWidth}>
      <Stack direction="column" gap={1} mb={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{headerText}</Typography>
          {headerContent}
        </Stack>
        {subheaderContent}
      </Stack>

      {formContent}

      <Stack direction="row" justifyContent="space-between">
        <FormActionButton action={secondaryAction} />
        <FormActionButton primary action={primaryAction} />
      </Stack>
    </Stack>
  );
};

export default FormLayout;
