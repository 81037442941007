import { ReactNode, useContext } from "react";
import { observer } from "mobx-react-lite";
import UpdateGoalPrompt from "@/components/progress/prompt/UpdateGoalPrompt";
import UpdateObjectivePrompt from "@/components/progress/prompt/UpdateObjectivePrompt";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";

const UpdateProgressModal = () => {
  const {
    progressStore: { pendingUpdate, clearPendingUpdate },
  } = useContext(StoreContext);

  let modalContent: ReactNode | undefined = undefined;
  if (pendingUpdate?.objective) {
    modalContent = (
      <UpdateObjectivePrompt
        operation={pendingUpdate.operation}
        objective={pendingUpdate.objective}
        goal={pendingUpdate.goal}
      />
    );
  } else if (pendingUpdate) {
    modalContent = <UpdateGoalPrompt operation={pendingUpdate.operation} goal={pendingUpdate.goal} />;
  }

  return (
    <CenterModal isOpen={!!modalContent} onClose={clearPendingUpdate} minWidth={550}>
      {modalContent}
    </CenterModal>
  );
};

export default observer(UpdateProgressModal);
