import type { LanguageType, ProviderType, RequirementCadence } from "@prisma/client";

export type { LanguageType, UserType, ProviderType } from "@prisma/client";

export const FRIENDLY_PROVIDER_TYPES: Record<ProviderType, string> = {
  PSY: "School Psychologist",
  SEI: "Special Education Teacher",
  SSW: "School Social Worker",
  SLP: "Speech Language Pathologist",
};

export const AVAILABLE_LANGUAGES = ["en_US", "es_US"] as const;
export const AVAILABLE_LANGUAGE_NAMES = ["English", "Spanish"] as const;

type LanguageNames = {
  [key in LanguageType]: string;
};
export const LANGUAGE_NAMES: LanguageNames = {
  ar_AR: "Arabic",
  de_DE: "German",
  en_US: "English",
  es_US: "Spanish",
  fil_PH: "Filipino",
  fr_FR: "French",
  it_IT: "Italian",
  ru_RU: "Russian",
  zh_CN: "Chinese",
} as const;

export const REQUIREMENT_CADENCE_NOUNS: Record<RequirementCadence, string> = {
  DAILY: "day",
  WEEKLY: "week",
  MONTHLY: "month",
  YEARLY: "year",
};
