import { useContext, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import { GridActionsCellItem, GridColDef, GridDeleteIcon } from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { DateTime } from "luxon";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { DocumentFileTypeOptions } from "@parallel/vertex/enums/document.enums";
import { ExtendedStudentDocumentUpload } from "@parallel/vertex/types/user/student.types";
import { DocumentFileUploadRequest } from "@/api/student.api";
import CenterModal from "@/components/shared/layout/CenterModal";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import StudentDocumentUploadInput from "@/components/user/student/document/StudentDocumentUploadInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type DocumentTableRow = {
  id: string;
  name: string;
  documentType?: string;
  createdBy: string;
  createdAt: string;
};

const toDocumentTableRow = (upload: ExtendedStudentDocumentUpload, timezone: string): DocumentTableRow => ({
  id: upload.documentUploadId,
  name: upload.fileName,
  documentType: DocumentFileTypeOptions.find(option => option.key === upload.documentType)?.label,
  createdAt: upload.createdAt.setZone(timezone).toLocaleString(DateTime.DATETIME_MED),
  createdBy: upload.creator.fullName,
});

const STATIC_DOCUMENT_TABLE_COLUMNS: GridColDef<DocumentTableRow>[] = [
  { field: "name", headerName: "Document Name", flex: 1 },
  { field: "documentType", headerName: "Document Type", flex: 1 },
  { field: "createdBy", headerName: "Added By", flex: 1 },
  { field: "createdAt", headerName: "Date Added", flex: 1 },
];

type RowHandlerFn = (id: string) => void;

const getTableColumns = ({
  onDelete,
  onDownload,
}: {
  onDelete: RowHandlerFn;
  onDownload: RowHandlerFn;
}): GridColDef<DocumentTableRow>[] => [
  ...STATIC_DOCUMENT_TABLE_COLUMNS,
  {
    field: "actions",
    type: "actions",
    getActions: params => [
      <GridActionsCellItem
        icon={<GridDeleteIcon />}
        label="Delete"
        onClick={() => onDelete(params.id.toString())}
        key={params.id}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<DownloadIcon />}
        label="Download"
        onClick={() => onDownload(params.id.toString())}
        key={params.id}
        showInMenu
      />,
    ],
  },
];

const logger = initLogger("StudentDocuments", getLoggerContext);

const StudentDocuments = ({
  uploads,
  studentId,
  reportId,
  header,
  onDeleted,
  onUploaded,
  sx,
}: {
  uploads: ExtendedStudentDocumentUpload[];
  studentId: string;
  reportId?: string;
  header?: string;
  onDeleted: (uploadId: string) => void;
  onUploaded: (uploads: ExtendedStudentDocumentUpload[]) => void;
  sx?: SxProps;
}) => {
  const {
    apiStore: { studentApi },
    authStore: { timezone },
  } = useContext(StoreContext);

  const [isUploading, setIsUploading] = useState(false);

  const onUpload = async (requests: DocumentFileUploadRequest[]) => {
    const uploads = await studentApi.uploadDocuments(studentId, requests, reportId);
    onUploaded(uploads);
    setIsUploading(false);
  };

  const [deletingUploadId, setDeletingUploadId] = useState<string>();

  const onDelete = async () => {
    if (!deletingUploadId) return;
    await studentApi.deleteDocumentUpload(deletingUploadId);
    onDeleted(deletingUploadId);
    setDeletingUploadId(undefined);
  };

  const onDownload = async (uploadId: string) => {
    const url = await studentApi.getDocumentFileUrl(uploadId, studentId);
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      logger.warn("upload file pop-up blocked");
      toast.error("Pop-up blocked. Please allow pop-ups for this site.");
    }
  };

  const [filterString, setFilterString] = useState("");
  const isMatch = (test: string) => !filterString || test.toLowerCase().includes(filterString.toLowerCase());

  const columns = getTableColumns({ onDelete: id => setDeletingUploadId(id), onDownload });
  const rows = uploads.filter(u => isMatch(u.fileName)).map(u => toDocumentTableRow(u, timezone));

  const clearModal = () => {
    setIsUploading(false);
    setDeletingUploadId(undefined);
  };

  return (
    <FullStack gap={1} sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{header}</Typography>

        <Stack direction="row" gap={2}>
          <TextField
            label="Filter"
            value={filterString}
            onChange={e => setFilterString(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterAltIcon sx={{ color: "grey.400" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setFilterString("")} edge="end">
                      <CloseIcon fontSize="small" sx={{ color: "grey.400" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            size="small"
          />
          <Button startIcon={<UploadIcon />} onClick={() => setIsUploading(true)}>
            Upload Documents
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ width: "100%", flex: "1 1 0%" }}>
        <DataGridPremium
          columns={columns}
          rows={rows}
          slots={{
            noRowsOverlay: () => (
              <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
                <div>No documents uploaded</div>
              </Stack>
            ),
          }}
        />
      </Box>

      <CenterModal isOpen={isUploading || !!deletingUploadId} onClose={clearModal}>
        {isUploading && <StudentDocumentUploadInput onUpload={onUpload} onCancel={() => setIsUploading(false)} />}
        {deletingUploadId && (
          <PromptLayout
            headerText="Delete Document"
            leftAction={{ fn: () => setDeletingUploadId(undefined), label: "Cancel" }}
            rightAction={{ fn: () => onDelete(), label: "Delete" }}
          >
            <Typography variant="body1">Are you sure you want to delete this document?</Typography>
          </PromptLayout>
        )}
      </CenterModal>
    </FullStack>
  );
};

export default StudentDocuments;
