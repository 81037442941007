import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { DocumentType, DocumentFileTypeOptions } from "@parallel/vertex/enums/document.enums";
import { DocumentFileUploadRequest } from "@/api/student.api";
import SelectInput from "@/components/shared/input/SelectInput";
import { isValidInAISummary } from "@/util/document.util";

type UploadFileState = { file: File; documentType?: DocumentType };

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const isFileValid = ({ file, documentType }: UploadFileState) => {
  if (!documentType) return false;
  const isImage = file.type.split("/")[0] === "image";
  return isImage || ALLOWED_FILE_TYPES.includes(file.type);
};

const StudentDocumentUploadInput = ({
  onUpload,
  onCancel,
}: {
  onUpload: (requests: DocumentFileUploadRequest[]) => Promise<unknown>;
  onCancel: () => void;
}) => {
  const [files, setFiles] = useState<UploadFileState[]>([]);

  const appendFiles = (newFiles: File[]) => {
    const newFileState = newFiles.map(file => ({ file }));
    setFiles([...files, ...newFileState]);
  };

  const setDocumentType = (index: number, documentType: DocumentType) => {
    setFiles(files.map((f, i) => (i === index ? { ...f, documentType } : f)));
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const isValid = files.every(isFileValid);

  return (
    <Stack gap={2} width={800}>
      <Typography variant="h2">Upload Documents</Typography>
      <FileInputBox onFileSelect={appendFiles}>
        <Stack justifyContent="center" alignItems="center" height={200}>
          <NoteAddIcon sx={{ fontSize: 80, color: "primary.light" }} />
          <Link component="label" sx={{ cursor: "pointer" }}>
            <Typography variant="body2">Select files to upload</Typography>
            <VisuallyHiddenInput
              type="file"
              multiple
              onChange={e => e.target.files && appendFiles([...e.target.files])}
            />
          </Link>
          <Typography variant="body2">or drag and drop here</Typography>
        </Stack>
      </FileInputBox>

      <Stack width="100%" maxHeight={400}>
        {files.map(({ documentType, file }, i) => {
          return (
            <Stack
              direction="row"
              width="100%"
              p={1}
              gap={2}
              alignItems="center"
              key={i}
              justifyContent="center"
              height={74}
            >
              <Box>
                <NoteAddIcon sx={{ color: "primary.main" }} />
              </Box>
              <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="body1">{file.name}</Typography>
              </Stack>
              <Stack direction="column" marginTop="18px">
                <Box alignSelf="flex-end">
                  <SelectInput
                    label="Document Type"
                    options={DocumentFileTypeOptions}
                    value={documentType}
                    onChange={documentType => setDocumentType(i, documentType)}
                    width={230}
                    size="small"
                  />
                </Box>
                <Box alignContent="flex-end">
                  <FormHelperText sx={{ color: "warning.main" }}>
                    {documentType && !isValidInAISummary(documentType) ? (
                      <>This document will not be included in assessment summaries</>
                    ) : (
                      " "
                    )}
                  </FormHelperText>
                </Box>
              </Stack>
              <IconButton onClick={() => removeFile(i)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>

      <Stack direction="row" width="100%" justifyContent="space-between">
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <ProcessButton
          variant="contained"
          disabled={!isValid}
          process={() => onUpload(files)}
          startIcon={<UploadIcon />}
        >
          Upload
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default StudentDocumentUploadInput;
