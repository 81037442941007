import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { pick } from "lodash";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { ContractTypeValues, ProviderTypeValues } from "@parallel/vertex/enums/prisma.enums";
import { FRIENDLY_PROVIDER_TYPES } from "@parallel/vertex/enums/user.enums";
import {
  CreateProviderBody,
  ExtendedProviderUser,
  createProviderBodySchema,
} from "@parallel/vertex/types/user/provider.types";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SelectInput from "@/components/shared/input/SelectInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import LanguageInput from "@/components/user/input/LanguageInput";
import NameInput from "@/components/user/input/NameInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type ProviderParams = Omit<Partial<CreateProviderBody>, "districtIds"> & {
  districts: SelectOption[];
};

const logger = initLogger("ProviderForm", getLoggerContext);

const ProviderForm = ({
  editing,
  onWrite,
  onClose,
}: {
  editing?: ExtendedProviderUser;
  onWrite: () => void;
  onClose: () => void;
}) => {
  const {
    apiStore: { institutionApi, userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState<ProviderParams>({
    ...pick(editing, "firstName", "lastName", "email", "providerType", "contractType"),
    email: editing?.email || undefined,
    languages: editing?.languages || ["en_US"],
    districts: editing ? editing.districts.map(d => ({ key: d.id, label: d.name })) : [],
  });

  const formContent = (
    <>
      <NameInput params={params} setParams={setParams} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
      <SelectInput
        label="Provider Type"
        options={ProviderTypeValues.map(key => ({ key, label: FRIENDLY_PROVIDER_TYPES[key] }))}
        value={params.providerType || ""}
        onChange={t => setParams({ ...params, providerType: t || undefined })}
      />
      <LanguageInput params={params} setParams={setParams} />
      <SelectInput
        label="Contract Type"
        options={ContractTypeValues.map(key => ({ key, label: toTitleCase(key) }))}
        value={params.contractType || ""}
        onChange={t => setParams({ ...params, contractType: t || undefined })}
      />
      <AutoCompletePageSearchInput
        label="Districts (optional)"
        search={name =>
          institutionApi
            .searchInstitutions({ name, institutionType: "DISTRICT" })
            .catch(logger.handleFailure("searchInstitutions"))
        }
        getOption={i => ({ key: i.institutionId, label: i.salesforceName })}
        selected={params.districts}
        onSelect={districts => setParams({ ...params, districts })}
      />
    </>
  );

  const validate = (params: ProviderParams) =>
    createProviderBodySchema.safeParse({ ...params, districtIds: params.districts.map(d => d.key) })?.data;

  const onSubmit = async (body: CreateProviderBody) => {
    editing
      ? await userApi
          .updateProvider(editing.userId, body)
          .catch(logger.handleFailureAndThrow("updateProvider", { level: "warning" }))
      : await userApi.createProvider(body).catch(logger.handleFailureAndThrow("createProvider", { level: "warning" }));

    onWrite();
  };

  return (
    <SubmitForm
      recordName="provider"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ProviderForm;
