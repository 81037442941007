import { UserType } from "../enums/user.enums";
import { ExtendedAppointment } from "../types/calendar/appointment.types";
import { AccessLevel, getDataPermissionResolver, getRequestorTypeResolver } from "./role.shared";

type AppointmentCustomPermissionCheck = "facilitator-manages-any-student" | "requestor-assigned-to-student-district";

export type AppointmentPermissions = {
  accessLevels: AccessLevel[];
  allowIf: ((a: ExtendedAppointment, userId: string) => boolean) | AppointmentCustomPermissionCheck;
  defaults?: { providerId: "currentUser" };
};

const APPOINTMENT_PERMISSION_GRANTS: Partial<Record<UserType, AppointmentPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (a: ExtendedAppointment, userId: string) => a.provider.userId === userId,
    defaults: { providerId: "currentUser" },
  },
  FACILITATOR: {
    accessLevels: ["read"],
    allowIf: "facilitator-manages-any-student",
  },
  STUDENT: {
    accessLevels: ["read"],
    allowIf: (a: ExtendedAppointment, userId: string) => a.students.some(s => s.userId === userId),
  },
  SLPA: {
    accessLevels: ["read"],
    allowIf: () => true, // TODO define this condition
  },
  SITE_DIRECTOR: {
    accessLevels: ["read"],
    allowIf: "requestor-assigned-to-student-district",
  },
};

export const getAppointmentPermissions = getDataPermissionResolver(APPOINTMENT_PERMISSION_GRANTS);

export const getValidAppointmentRequestorTypes = getRequestorTypeResolver(APPOINTMENT_PERMISSION_GRANTS);
