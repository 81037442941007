import { findIndex } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { ExtendedStudentDocumentUpload } from "@parallel/vertex/types/user/student.types";
import { StudentAPI } from "@/api/student.api";

export class StudentStore {
  public documentUploads: ExtendedStudentDocumentUpload[] = [];

  constructor(private readonly studentApi: StudentAPI) {
    makeAutoObservable(this);
  }

  public getStudentInfoDocuments = async (studentId: string) =>
    await this.studentApi.getStudentInfoDocuments(studentId).then(uploads => {
      runInAction(() => {
        this.documentUploads = uploads;
      });
    });

  public appendDocumentUploads = (uploads: ExtendedStudentDocumentUpload[]) => {
    this.documentUploads.push(...uploads);
  };

  public removeDocumentUpload = (uploadId: string) => {
    const index = findIndex(this.documentUploads, u => u.documentUploadId === uploadId);
    this.documentUploads.splice(index, 1);
  };
}
