import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { CreateUserBody, createUserBodySchema } from "@parallel/vertex/types/user/user.types";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import NameInput from "@/components/user/input/NameInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("AdminForm", getLoggerContext);

const AdminForm = ({ onWrite, onClose }: { onWrite: () => void; onClose: () => void }) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState<Partial<CreateUserBody>>({
    userType: "ADMIN",
  });

  const formContent = (
    <>
      <NameInput params={params} setParams={setParams} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
    </>
  );

  const validate = (params: Partial<CreateUserBody>) => createUserBodySchema.safeParse(params)?.data;

  const onSubmit = async (body: CreateUserBody) => {
    await userApi.createAdmin(body).catch(logger.handleFailureAndThrow("createAdmin", { level: "warning" }));
    onWrite();
  };

  return (
    <SubmitForm
      recordName="admin"
      operationName="create"
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default AdminForm;
