import { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { getCalendarBlockPermissions } from "@parallel/vertex/role/role.calendar.block";
import { ExtendedCalendarBlock } from "@parallel/vertex/types/calendar/calendar.block.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import CalendarBlockForm, { RECORD_NAMES } from "@/components/calendar/block/CalendarBlockForm";
import CommonDisplay from "@/components/shared/layout/CommonDisplay";
import { getLoggerContext, StoreContext } from "@/stores";
import { recurrenceText } from "@/util/calendar.util";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("AvailabilityDisplay", getLoggerContext);

const CalendarBlockDisplay = ({ block, onClose }: { block: ExtendedCalendarBlock; onClose: () => void }) => {
  const {
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const writePermissions = getCalendarBlockPermissions(currentUser, "write");

  const [isEditing, setIsEditing] = useState(false);
  if (isEditing && writePermissions)
    return (
      <CalendarBlockForm
        blockType={block.calendarBlockType}
        editing={block}
        onClose={() => setIsEditing(false)}
        permissions={writePermissions}
      />
    );

  const fields = filterExists([
    !writePermissions?.defaults?.userId && { text: block.userName, icon: <AccountCircleIcon />, label: "User" },
    block.recurrence && { text: recurrenceText(block.recurrence), icon: <EventRepeatIcon /> },
  ]);

  const canEdit = calendarStore.canWriteCalendar(block.startTime) && !!writePermissions;

  return (
    <CommonDisplay
      title={block.title || (block.calendarBlockType === "FocusTime" ? "Focus Time" : "Availability")}
      record={block}
      recordName={RECORD_NAMES[block.calendarBlockType]}
      fields={fields}
      deleteRecord={mode =>
        calendarStore
          .deleteCalendarBlock(block.calendarBlockId, mode)
          .catch(logger.handleFailureAndThrow("deleteCalendarBlock"))
      }
      onEdit={canEdit ? () => setIsEditing(true) : undefined}
      onClose={onClose}
    />
  );
};

export default CalendarBlockDisplay;
