import { ObjectiveCategory } from "@prisma/client";
import { z } from "zod";
import { ObjectiveMetricValue, ProgressAppointment, StudentObjective } from "../types/progress.types";
import { secondsToString, toPercentString } from "./number.util";
import { toTitleCase } from "./string.util";

export const stepValuesSchema = z.object({
  correct: z.number().min(0),
  incorrect: z.number().min(0),
  prompt: z.number().min(0),
});
type StepValues = z.infer<typeof stepValuesSchema>;

export const getObjectiveTitle = (
  objective: { category: ObjectiveCategory },
  displayIndices: { goal: number; objective: number },
) => `${displayIndices.goal}.${displayIndices.objective} ${toTitleCase(objective.category)} Objective`;

export const getMetricNumberString = (
  objective: StudentObjective,
  value: number,
  steps?: StepValues,
): string | null => {
  switch (objective.category) {
    case "ACCURACY":
      const percent = toPercentString(value);
      if (!steps) return percent;
      return `${percent}  ( ${steps.correct} Correct / ${steps.incorrect} Incorrect / ${steps.prompt} Prompt )`;
    case "DURATION":
      return `${secondsToString(value)} Duration`;
    case "DECREASING_FREQUENCY":
    case "INCREASING_FREQUENCY":
    case "PROMPTING_FREQUENCY":
      return `${value} Occurrences`;
    default:
      return null;
  }
};

export const getMetricValueString = (objective: StudentObjective, metric: ObjectiveMetricValue): string | null => {
  if (metric.type === "number") {
    const steps = objective.category === "ACCURACY" ? stepValuesSchema.safeParse(metric.metadata)?.data : undefined;
    return getMetricNumberString(objective, metric.value, steps);
  } else if (objective.category === "ANECDOTAL") {
    return metric.value;
  }
  return null;
};

export const getObjectiveNoun = (objective: StudentObjective): string => {
  switch (objective.category) {
    case "ACCURACY":
      return "Accuracy";
    case "DURATION":
      return "Duration";
    case "DECREASING_FREQUENCY":
    case "INCREASING_FREQUENCY":
    case "PROMPTING_FREQUENCY":
      return "Occurrences";
    default:
      return "";
  }
};

export const getStatusRowText = (appointment: ProgressAppointment) => {
  const cancelReasonText = appointment.cancelReason?.title || "No Reason";
  switch (appointment.status) {
    case "CANCELLED":
      return { leftText: "Session Cancelled", rightText: cancelReasonText };
    case "LATE_CANCELLED":
      return { leftText: "Session Cancelled (<24 hrs)", rightText: cancelReasonText };
    case "NO_SHOW":
      return { leftText: "No-Show Session", rightText: "This session was a no-show" };
  }
};
