import { ReactElement, useContext, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { StatusIcon, useAsyncProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { FullBox, FullGrid, FullStack, SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { CommonItemFields, getProgressMenuItems } from "@parallel/polygon/util/progress.util";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import GoalDisplay from "@/components/progress/GoalDisplay";
import ObjectiveDisplay from "@/components/progress/ObjectiveDisplay";
import ObjectiveMetricDisplay from "@/components/progress/ObjectiveMetricDisplay";
import UpdateProgressModal from "@/components/progress/prompt/UpdateProgressModal";
import { FloatingMenuButton, MenuItem } from "@/components/shared/display/menu";
import SearchTextInput from "@/components/shared/input/SearchTextInput";
import { StoreContext } from "@/stores";

export type ProgressDisplayLayoutProps = {
  headerAction: { text: string; icon: ReactElement; perform: () => void };
  onEditGoal: (editing: { goal: StudentGoal; index: number }) => void;
  appointmentDetails?: {
    appointmentId: string;
    isWaived: boolean;
    toggleWaived: () => Promise<unknown>;
  };
  isReadOnly?: boolean;
};

const ProgressDisplayLayout = ({
  headerAction,
  onEditGoal,
  appointmentDetails,
  isReadOnly,
}: ProgressDisplayLayoutProps) => {
  const {
    authStore: { currentUser },
    progressStore: { currentStudentProgress, updateGoal },
  } = useContext(StoreContext);

  const { goals = [] } = currentStudentProgress || {};

  const [selectedItemId, setSelectedItemId] = useState<string>(goals[0]?.goalId);

  const [searchString, setSearchString] = useState("");
  const [onlyShowPinned, setOnlyShowPinned] = useState(goals.some(g => g.objectives.some(o => o.isPinned)));
  const [isShowingCompleted, setIsShowingCompleted] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const doesItemMatchFilters = (item: CommonItemFields) =>
    (!searchString || item.description.toLowerCase().includes(searchString.toLowerCase())) &&
    (!onlyShowPinned || !!item.isPinned) &&
    (isShowingCompleted || !item.completedBy) &&
    (isShowingArchived || !item.isArchived);

  const { menuItems, selection } = getProgressMenuItems(goals, selectedItemId, doesItemMatchFilters, updateGoal);

  const { status: progressWaivedStatus, perform: toggleProgressWaived } = useAsyncProcessStatus(async () =>
    appointmentDetails?.toggleWaived(),
  );

  let primaryContent: ReactElement | undefined = undefined;
  if (selection) {
    if (!selection.objective) {
      const goalSelection = { goal: selection.goal.record, index: selection.goal.item.displayIndex };
      primaryContent = (
        <GoalDisplay
          goal={goalSelection.goal}
          goalIndex={goalSelection.index}
          onEdit={() => onEditGoal(goalSelection)}
          onObjectiveSelect={objective => setSelectedItemId(objective.objectiveId)}
        />
      );
    } else {
      const listIndex = {
        objective: selection.objective.item.displayIndex,
        goal: selection.goal.item.displayIndex,
      };
      primaryContent = appointmentDetails ? (
        <ObjectiveMetricDisplay
          objective={selection.objective.record}
          listIndex={listIndex}
          goal={selection.goal.record}
          appointmentId={appointmentDetails.appointmentId}
          onMetricWritten={() => appointmentDetails?.isWaived && toggleProgressWaived(null)}
          isReadOnly={isReadOnly}
          key={selection.objective.record.objectiveId}
        />
      ) : (
        <ObjectiveDisplay objective={selection.objective.record} listIndex={listIndex} goal={selection.goal.record} />
      );
    }
  }

  const isNewUx = currentUser?.featureFlags.showNewStudentGoals;

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const moreMenuItems: MenuItem[] = [
    {
      type: "sync",
      icon: isSearchVisible ? <SearchOffIcon /> : <SearchIcon />,
      text: `${isSearchVisible ? "Hide" : "Show"} Search`,
      onClick: () => setIsSearchVisible(!isSearchVisible),
    },
    { type: "divider" },
    {
      type: "toggle",
      toggle: () => setOnlyShowPinned(!onlyShowPinned),
      isEnabled: onlyShowPinned,
      text: "Pinned Only",
    },
    {
      type: "toggle",
      toggle: () => setIsShowingCompleted(!isShowingCompleted),
      isEnabled: isShowingCompleted,
      text: "Show Achieved",
    },
    {
      type: "toggle",
      toggle: () => setIsShowingArchived(!isShowingArchived),
      isEnabled: isShowingArchived,
      text: "Show Archived",
    },
    { type: "divider" },
    {
      type: "async",
      icon: appointmentDetails?.isWaived ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
      text: "No data collected this session",
      onClick: () => toggleProgressWaived(!appointmentDetails?.isWaived),
    },
  ];

  const content = (
    <FullGrid container sx={{ height: "100%", overflowY: "hidden" }}>
      <Grid
        size={{ xs: 4 }}
        sx={{ height: "100%", overflowY: "auto", borderRight: 1, borderColor: "grey.200", pr: 2, pt: isNewUx ? 1 : 0 }}
      >
        {isNewUx && (
          <Stack gap={1} sx={{ p: 1, pb: 2 }}>
            <SplitRow
              left={<Typography variant="h3">Goals & Objectives</Typography>}
              right={<FloatingMenuButton label="More" items={moreMenuItems} />}
            />
            {isSearchVisible && <SearchTextInput value={searchString} setValue={setSearchString} fullWidth />}
          </Stack>
        )}
        <ListMenu nested items={menuItems} selectedKey={selectedItemId} onSelect={id => id && setSelectedItemId(id)} />
      </Grid>

      <Grid size={{ xs: 8 }} pl={2} sx={{ height: "100%", overflowY: "auto", pt: isNewUx ? 2 : 0 }}>
        <FullStack alignItems="center">
          <FullBox sx={{ height: "100%", overflowY: "auto", pr: 4, maxWidth: "100%" }} as="main">
            {primaryContent}
          </FullBox>
        </FullStack>
      </Grid>
    </FullGrid>
  );

  if (isNewUx)
    return (
      <>
        {content}
        <UpdateProgressModal />
      </>
    );

  return (
    <FullStack pt={2}>
      {!isNewUx && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" pb={2}>
          <Stack direction="row" gap={4} alignItems="center">
            <SearchTextInput value={searchString} setValue={setSearchString} width={300} />
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={onlyShowPinned} onChange={e => setOnlyShowPinned(e.target.checked)} />}
                label="Pinned Only"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={isShowingCompleted} onChange={e => setIsShowingCompleted(e.target.checked)} />
                }
                label="Show Achieved"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isShowingArchived} onChange={e => setIsShowingArchived(e.target.checked)} />}
                label="Show Archived"
              />
            </FormGroup>
            {appointmentDetails && (
              <Stack direction="row" gap={1} alignItems="center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentDetails.isWaived}
                        onChange={e => toggleProgressWaived(e.target.checked)}
                        disabled={isReadOnly}
                      />
                    }
                    label="No data collected this session"
                  />
                </FormGroup>
                {progressWaivedStatus && <StatusIcon status={progressWaivedStatus} />}
              </Stack>
            )}
          </Stack>

          <Button onClick={headerAction.perform} startIcon={headerAction.icon}>
            {headerAction.text}
          </Button>
        </Stack>
      )}

      <Box sx={{ width: "100%", height: "calc(100vh - 100px)", overflowY: "hidden" }}>{content}</Box>

      <UpdateProgressModal />
    </FullStack>
  );
};

export default observer(ProgressDisplayLayout);
