import { UserType } from "@prisma/client";
import { CALENDAR_ROLE_FLAG_GRANTS, CalendarRoleFlag } from "./role.calendar";
import { REPORT_ROLE_FLAG_GRANTS, ReportRoleFlag } from "./role.report";
import { PartialUser } from "./role.shared";
import { STUDENT_ROLE_FLAG_GRANTS, StudentRoleFlag } from "./role.student";

type GenericRoleFlag = "internal-support" | "manage-form-approvals" | "edit-assessment-stimuli";

const GENERIC_ROLE_FLAG_GRANTS: Record<GenericRoleFlag, UserType[]> = {
  "internal-support": ["ADMIN", "PROVIDER"],
  "manage-form-approvals": ["ADMIN"],
  "edit-assessment-stimuli": ["ADMIN"],
};

type RoleFlag = GenericRoleFlag | CalendarRoleFlag | ReportRoleFlag | StudentRoleFlag;

const ALL_ROLE_FLAG_GRANTS: Record<RoleFlag, UserType[]> = {
  ...GENERIC_ROLE_FLAG_GRANTS,
  ...CALENDAR_ROLE_FLAG_GRANTS,
  ...REPORT_ROLE_FLAG_GRANTS,
  ...STUDENT_ROLE_FLAG_GRANTS,
};

export const hasRoleFlag = (user: PartialUser, flag: RoleFlag): boolean =>
  user ? ALL_ROLE_FLAG_GRANTS[flag].includes(user.userType) : false;
