import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack,
  Typography,
  Drawer,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import { isEmpty, partition } from "lodash";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import {
  EntityReference,
  StudentRow,
  StudentRowValidation,
  StudentUploadBody,
} from "@parallel/vertex/types/bulk.upload.types";
import { ServiceType } from "@parallel/vertex/types/service.types";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import SelectInput from "@/components/shared/input/SelectInput";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import StudentUploadDataGrid from "@/components/user/student/bulkUpload/StudentUploadDataGrid";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { useUrlNavigation } from "@/util/router.util";

type ServiceTypeStepProps = {
  navigateToUsers: () => void;
  serviceType: ServiceType;
  setServiceType: (s: ServiceType) => void;
  setCurrentStep: (s: number) => void;
};

type GoogleSheetStepProps = {
  sheetUrl: string;
  setSheetUrl: (s: string) => void;
  fetchSheet: () => void;
  setCurrentStep: (s: number) => void;
  isLoadingSheet: boolean;
};

const logger = initLogger("StudentBulkUploadScreen", getLoggerContext);

const parseGoogleSheetUrl = (url: string) => {
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 2];
};

const DialogContainer = ({ children, title }: { children: React.ReactNode; title: string }) => (
  <Dialog open>
    <DialogTitle sx={{ marginTop: 1 }}>
      <Typography variant="h2">{title}</Typography>
    </DialogTitle>
    <DialogContent sx={{ width: "600px", maxWidth: "600px" }}>{children}</DialogContent>
  </Dialog>
);

const ServiceTypeStep = ({ serviceType, setServiceType, setCurrentStep, navigateToUsers }: ServiceTypeStepProps) => {
  return (
    <DialogContainer title="Add Students via Google Sheets">
      <Box py={2}>
        <SelectInput
          label="Type of Services"
          options={[
            { key: "HOURLY", label: "Hourly Services" },
            { key: "ASSESSMENT", label: "Assessment" },
          ]}
          value={serviceType}
          onChange={setServiceType}
          fullWidth
        />
      </Box>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={navigateToUsers} color="primary">
          Cancel
        </Button>
        <Button onClick={() => setCurrentStep(1)} color="primary">
          Continue
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};

const GoogleSheetStep = ({
  sheetUrl,
  setSheetUrl,
  fetchSheet,
  setCurrentStep,
  isLoadingSheet,
}: GoogleSheetStepProps) => {
  return (
    <DialogContainer title="Upload Students">
      <Box py={2}>
        <TextField
          id="google-sheets-url"
          label="Google Sheets URL"
          type="text"
          fullWidth
          variant="outlined"
          value={sheetUrl}
          onChange={e => setSheetUrl(e.target.value)}
        />
      </Box>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={() => setCurrentStep(0)} color="primary">
          Back
        </Button>
        <Button onClick={fetchSheet} color="primary" disabled={isLoadingSheet || !sheetUrl}>
          Submit
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};

const StudentBulkUploadStepper: React.FC = () => {
  const {
    apiStore: { studentApi },
  } = useContext(StoreContext);

  const { navigate } = useUrlNavigation();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [sheetUrl, setSheetUrl] = useState<string>("");
  const [serviceType, setServiceType] = useState<ServiceType>("HOURLY");

  const [initialData, setInitialData] = useState<StudentRow[]>();
  const [currentData, setCurrentData] = useState<StudentRow[]>();
  const [campuses, setCampuses] = useState<EntityReference[]>([]);
  const [serviceLines, setServiceLines] = useState<EntityReference[]>([]);
  const [isLoadingSheet, setIsLoadingSheet] = useState(false);

  const [validationResults, setValidationResults] = useState<StudentRowValidation[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const navigateToUsers = () => navigate("/user/student");

  const fetchSourceData = async () => {
    setIsLoadingSheet(true);
    const googleSheetId = parseGoogleSheetUrl(sheetUrl);
    await studentApi
      .getSheet({ googleSheetId, serviceType: serviceType })
      .then(res => {
        const data = res.rows.map((r, i) => ({ ...r.data, id: i }));
        setInitialData(data);
        setCurrentData(data);
        setValidationResults(res.rows.map(r => r.validation));
        setCampuses(res.campuses);
        setServiceLines(res.serviceLines);
        setCurrentStep(2);
        setIsLoadingSheet(false);
      })
      .catch(e => {
        logger.logFailure("getSheet", e);
        setIsLoadingSheet(false);
        toast.error("Failed to fetch sheet data");
      });
  };

  const handleRowUpdate = async (updatedRow: StudentRow) => {
    setCurrentData(currentData?.map((current, i) => (i === updatedRow.id ? updatedRow : current)));
    setIsValidating(true);
    studentApi
      .postRowValidate({ data: updatedRow, serviceType })
      .catch(e => {
        logger.logFailure("postRowValidate", e);
        return { failures: [{ message: "Unexpected server error validating row" }] };
      })
      .then(result =>
        setValidationResults(validationResults.map((current, i) => (i === updatedRow.id ? result : current))),
      )
      .finally(() => setIsValidating(false));
  };

  const rowUploadBodies = mapExists(validationResults, r => r.validUploadBody);
  const allRowsUploadBody: StudentUploadBody | undefined =
    rowUploadBodies.length === validationResults.length ? { rows: rowUploadBodies } : undefined;

  const onSubmit = async () => {
    if (!allRowsUploadBody) return;
    studentApi
      .createStudents(allRowsUploadBody)
      .then(r => {
        const [successes, failures] = partition(r.results, r => r.type === "success");
        if (isEmpty(failures)) {
          toast.success("Successfully created all students");
        } else if (isEmpty(successes)) {
          toast.error("Failed to create all requested students");
        } else {
          toast.warn(`${successes.length} students created, ${failures.length} requests failed`);
        }
      })
      .catch(e => {
        logger.logFailure("createStudents", e);
        toast.error("Unexpected error creating students");
      });
  };

  const toggleValidationErrors = () => {
    setShowValidationErrors(!showValidationErrors);
  };

  const validationErrorCount = validationResults.reduce((acc, r) => acc + r.failures.length, 0);

  if (currentStep === 0) {
    return (
      <ServiceTypeStep
        serviceType={serviceType}
        setServiceType={setServiceType}
        setCurrentStep={setCurrentStep}
        navigateToUsers={navigateToUsers}
      />
    );
  } else if (currentStep === 1) {
    return (
      <GoogleSheetStep
        sheetUrl={sheetUrl}
        setSheetUrl={setSheetUrl}
        fetchSheet={fetchSourceData}
        setCurrentStep={setCurrentStep}
        isLoadingSheet={isLoadingSheet}
      />
    );
  } else if (currentStep === 2 && initialData) {
    return (
      <FullStack>
        <Box sx={{ paddingTop: "24px", height: 0, width: "100%", flex: "1 1 0%" }}>
          <FullBox>
            <StudentUploadDataGrid
              onSubmit={onSubmit}
              initialRows={initialData}
              campuses={campuses}
              serviceLines={serviceLines}
              serviceType={serviceType}
              onRowUpdate={handleRowUpdate}
              allRowsUploadBody={allRowsUploadBody}
              validationErrorCount={validationErrorCount}
              toggleValidationErrors={toggleValidationErrors}
              isValidating={isValidating}
            />
          </FullBox>
        </Box>

        <Drawer
          anchor="bottom"
          open={showValidationErrors && validationErrorCount > 0}
          onClose={toggleValidationErrors}
          hideBackdrop
          variant="persistent"
          ModalProps={{
            disableEnforceFocus: true,
          }}
          PaperProps={{
            sx: { maxHeight: "50%" },
          }}
        >
          <Stack>
            <Stack
              px={2}
              py={1}
              justifyContent={"space-between"}
              display="flex"
              direction="row"
              alignItems="center"
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
                borderBottom: "1px solid",
                borderColor: "grey.200",
              }}
            >
              <Box>
                <Typography variant="h3">Validation Failures</Typography>
              </Box>
              <IconButton onClick={toggleValidationErrors} size="small">
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack p={2}>
              {validationResults.map((result, rowIndex) => {
                const row = currentData && currentData[rowIndex];
                const numberedTitle = `Row ${rowIndex + 1}`;
                const names = filterExists([row?.firstName, row?.lastName]);
                const title = !isEmpty(names) ? `${numberedTitle}: ${names.join(" ")}` : numberedTitle;

                if (isEmpty(result.failures)) return null;

                return (
                  <Stack key={rowIndex}>
                    <Box>
                      <Typography variant="h4">{title}</Typography>
                      <List>
                        {result.failures.map((failure, failureIndex) => (
                          <ListItem key={`${rowIndex}_${failureIndex}`}>
                            <Typography variant="body2">{failure.message}</Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Drawer>
      </FullStack>
    );
  } else {
    return <div>There was an issue processing your request. Please try again.</div>;
  }
};

const StudentBulkUploadScreen: React.FC = () => {
  return (
    <PrimaryLayout headerContent={<SubHeader title={"Bulk Upload"} icon={<PeopleAltIcon />} />}>
      <StudentBulkUploadStepper />
    </PrimaryLayout>
  );
};

export default StudentBulkUploadScreen;
