import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useKeyboardEvent, useMeasure } from "@react-hookz/web";
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy, PDFPageProxy, PageViewport } from "pdfjs-dist";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { ImageMargins, MarginSelect } from "@/components/stimulus/MarginSelect";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const UPLOAD_SCALE = 3;

type PixelArea = {
  width: number;
  height: number;
};
const aspectRatio = (area: PixelArea) => area.width / area.height;
const isWider = (a1: PixelArea, a2: PixelArea) => aspectRatio(a1) > aspectRatio(a2);

const logger = initLogger("PdfViewer", getLoggerContext);

const PdfViewer = ({
  header,
  canvasRef,
  margins,
  setMargins,
  isClient,
}: {
  header: string;
  canvasRef: RefObject<HTMLCanvasElement>;
  margins?: ImageMargins;
  setMargins: (m: ImageMargins) => void;
  isClient?: boolean;
}) => {
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [pageNumber, setPageNumber] = useState(1);
  const [uploadViewport, setUploadViewport] = useState<PageViewport>();

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const displayCanvasRef = useRef<HTMLCanvasElement>(null);

  const loadPdf = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.onload = function () {
      if (!(this.result instanceof ArrayBuffer)) return;
      const typedarray = new Uint8Array(this.result);
      pdfjs
        .getDocument(typedarray)
        .promise.then(setPdfDocument)
        .catch(e => logger.error("error parsing document", e));
    };
    fileReader.readAsArrayBuffer(file);
  };

  const getViewports = (page: PDFPageProxy): [PageViewport, PageViewport] | undefined => {
    const uploadCanvas = canvasRef?.current;
    const displayCanvas = displayCanvasRef?.current;
    if (!containerArea || !uploadCanvas || !displayCanvas) return;
    if (containerArea.width === 0 || containerArea.height === 0) return;

    const uploadViewport = page.getViewport({ scale: UPLOAD_SCALE });
    uploadCanvas.height = uploadViewport.height;
    uploadCanvas.width = uploadViewport.width;

    const scale = isWider(uploadViewport, containerArea)
      ? (containerArea.width / uploadViewport.width) * UPLOAD_SCALE
      : (containerArea.height / uploadViewport.height) * UPLOAD_SCALE;

    const displayViewport = page.getViewport({ scale });
    displayCanvas.height = displayViewport.height;
    displayCanvas.width = displayViewport.width;

    setUploadViewport(uploadViewport);
    return [displayViewport, uploadViewport];
  };

  useEffect(() => {
    if (!pdfDocument || !canvasRef?.current || !displayCanvasRef.current) return;
    pdfDocument.getPage(pageNumber).then(async page => {
      const canvasContext = canvasRef.current?.getContext("2d");
      const displayCanvasContext = displayCanvasRef.current?.getContext("2d");
      const [displayViewport, fullSizeViewport] = getViewports(page) || [];
      if (!canvasContext || !displayCanvasContext || !displayViewport || !fullSizeViewport) return;

      await page.render({ canvasContext, viewport: fullSizeViewport, intent: "print" }).promise;
      await page.render({ canvasContext: displayCanvasContext, viewport: displayViewport }).promise;
    });
  }, [pdfDocument, pageNumber, canvasRef, displayCanvasRef, containerArea]);

  useKeyboardEvent(
    ({ shiftKey, altKey }) => (isClient ? altKey : shiftKey),
    e => {
      if (e.key === "ArrowRight") {
        e.preventDefault();
        setPageNumber(pageNumber + 1);
      }
      if (e.key === "ArrowLeft" && pageNumber !== 1) {
        e.preventDefault();
        setPageNumber(pageNumber - 1);
      }
    },
  );

  return (
    <FullStack gap={1} sx={{ overflowY: "auto" }}>
      <Typography variant="h2">{header}</Typography>

      <Stack direction="row" height={40} alignItems="center" justifyContent="space-between">
        <Link component="label" sx={{ cursor: "pointer" }}>
          <Typography variant="body1">Select Source PDF</Typography>
          <VisuallyHiddenInput type="file" onChange={loadPdf} />
        </Link>
        {pdfDocument && (
          <Stack direction="row">
            <IconButton onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === pdfDocument.numPages}>
              <ArrowForwardIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Box width="100%" sx={{ width: "100%", flex: "1 1 0%", px: 0.5 }} ref={containerRef}>
        <MarginSelect selection={margins} setSelection={setMargins} selectableArea={uploadViewport}>
          <Box sx={{ outline: 1, outlineColor: "grey.200" }}>
            <canvas ref={displayCanvasRef} />
          </Box>
        </MarginSelect>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </Box>
    </FullStack>
  );
};

export default PdfViewer;
