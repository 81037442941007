import { useState, ComponentType } from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateTime } from "luxon";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { CalendarViewType } from "@/stores/calendar.store";

interface CustomPickerDayProps extends PickersDayProps<DateTime> {
  isSelected: boolean;
  isHovered: boolean;
}

const getColorProps = ({ isSelected, isHovered }: CustomPickerDayProps, theme: any) => {
  if (isSelected) {
    return {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    };
  }
  if (isHovered) {
    return {
      backgroundColor: theme.palette.primary.light,
    };
  }
  return {};
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(props => {
  const theme = useTheme();
  const { day } = props;
  const { backgroundColor, color } = getColorProps(props, theme);

  return {
    borderRadius: 0,
    backgroundColor,
    color,
    "&:hover, &:focus": {
      backgroundColor,
    },
    ...(day.weekdayLong === "Sunday" && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(day.weekdayLong === "Saturday" && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
}) as ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: DateTime, dayB: DateTime | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  const startOfWeek =
    dayB.weekday === 7 ? dayB.startOf("week").plus({ days: 1 }) : dayB.startOf("week").minus({ days: 1 });
  const endOfWeek = dayB.endOf("week").minus({ days: 1 });

  return dayA >= startOfWeek && dayA <= endOfWeek;
};

const toCustomPicker = (
  props: PickersDayProps<DateTime> & {
    selectedDay?: DateTime | null;
    hoveredDay?: DateTime | null;
  },
) => {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
};

const CalendarPeriodPicker = ({
  viewType,
  period,
  onUpdate,
}: {
  viewType: CalendarViewType;
  period: DateTimeRange;
  onUpdate: (startDate: DateTime, endDate?: DateTime) => void;
}) => {
  const [hoveredDay, setHoveredDay] = useState<DateTime | null>(null);

  if (viewType === "list") {
    return (
      <StaticDateRangePicker
        value={[period.startTime, period.endTime]}
        onAccept={([startDate, endDate]) => startDate && endDate && onUpdate(startDate, endDate)}
      />
    );
  }

  return (
    <DateCalendar
      value={period.startTime}
      onChange={(date, pickerState) => {
        if (pickerState === "finish") {
          onUpdate(date);
        }
      }}
      onMonthChange={() => {}}
      onYearChange={() => {}}
      showDaysOutsideCurrentMonth
      views={["year", "month", "day"]}
      slots={viewType === "week" ? { day: toCustomPicker } : undefined}
      slotProps={{
        day: newState =>
          ({
            selectedDay: period.startTime.weekday === 7 ? period.startTime.plus({ days: 1 }) : period.startTime,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(newState.day),
            onPointerLeave: () => setHoveredDay(null),
          }) as any,
      }}
    />
  );
};

export default CalendarPeriodPicker;
