import { useContext, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { isNull, isUndefined } from "lodash";
import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { AppointmentType } from "@parallel/vertex/types/calendar/appointment.types";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("AppointmentTypeInput", getLoggerContext);

const toSelectOption = (t: AppointmentType): SelectOption => ({
  key: t.appointmentTypeId,
  label: t.title,
});

const AppointmentTypeInput = ({
  providerId,
  selected,
  onSelect,
  size,
  width,
}: {
  providerId?: string;
  selected: AppointmentType | null;
  onSelect: (t: AppointmentType | null) => void;
  size?: "small";
  width?: number;
}) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[] | null>();

  useMountEffect(() => {
    setAppointmentTypes(undefined);
    calendarApi
      .searchAppointmentTypes({ providerId, pageSize: 100 })
      .then(page => setAppointmentTypes(page.records))
      .catch(logger.handleFailure("searchAppointmentTypeCategories"));
  });

  return (
    <AutoCompleteInput
      label="Appointment Type"
      options={appointmentTypes?.map(toSelectOption)}
      selected={selected ? toSelectOption(selected) : null}
      onSelect={option => {
        if (!option) {
          onSelect(null);
          return;
        }

        const selected = appointmentTypes?.find(t => t.appointmentTypeId === option?.key);
        selected && onSelect(selected);
      }}
      loadingText={
        isUndefined(appointmentTypes)
          ? "Loading"
          : isNull(appointmentTypes)
            ? "Error Fetching Appointment Types"
            : undefined
      }
      size={size}
      width={width}
    />
  );
};

export default AppointmentTypeInput;
