import { UserType } from "@prisma/client";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type TaskPermissions = {
  accessLevels: AccessLevel[];
  defaults?: { assignedUser: "currentUser" };
};

const TASK_PERMISSION_GRANTS: Partial<Record<UserType, TaskPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    defaults: { assignedUser: "currentUser" },
  },
};

export const getTaskPermissions = getDataPermissionResolver(TASK_PERMISSION_GRANTS);
