import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FullStack, GrowBox } from "@parallel/polygon/components/shared/layout/container";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import TaskHeader from "@/components/task/TaskHeader";
import TaskListControls from "@/components/task/TaskListControls";
import TaskListData from "@/components/task/TaskListData";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const TaskListScreen = ({ isHome }: { isHome?: boolean }) => {
  const {
    authStore: { currentUser },
    taskStore: { loadTasks },
  } = useContext(StoreContext);

  const providerId = currentUser?.userType === "PROVIDER" ? currentUser.userId : undefined;

  useEffect(() => {
    if (!providerId) return;
    loadTasks(providerId);
  }, [providerId]);

  if (!providerId) return <NotFoundScreen />;

  return (
    <PrimaryLayout headerContent={<TaskHeader isHome={isHome} />}>
      <FullStack gap={2} pt={2}>
        <TaskListControls onWrite={() => loadTasks(providerId)} />
        <GrowBox>
          <TaskListData />
        </GrowBox>
      </FullStack>
    </PrimaryLayout>
  );
};

export default observer(TaskListScreen);
