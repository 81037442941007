import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isNull } from "lodash";
import { DateTime } from "luxon";
import { WithLabel } from "@parallel/polygon/components/shared/display/value";
import { percentString } from "@parallel/polygon/util/style.util";
import { REQUIREMENT_CADENCE_NOUNS } from "@parallel/vertex/enums/user.enums";
import { getReportPermissions } from "@parallel/vertex/role/role.report";
import { getServicePermissions } from "@parallel/vertex/role/role.service";
import {
  SingleStudentUser,
  ServiceCadenceRequirement,
  StudentService,
} from "@parallel/vertex/types/user/student.types";
import { formatNounWithCount } from "@parallel/vertex/util/string.util";
import { StoreContext } from "@/stores";

const CadenceLabel = ({
  icon,
  value,
  label,
}: {
  icon: { type: "label" | "color"; value: string };
  value: number;
  label: string;
}) => (
  <Tooltip title={label}>
    <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
      {icon.type === "color" && <Box width="16px" height="16px" borderRadius="50%" bgcolor={icon.value} />}
      {icon.type === "label" && <Typography variant="body2">{icon.value}:</Typography>}
      <Typography variant="body2">{value}</Typography>
    </Stack>
  </Tooltip>
);

const StudentServiceCadence = ({ cadence }: { cadence: ServiceCadenceRequirement }) => {
  const getRatio = (value: number = 0) => {
    if (!cadence.minutesRequired) return 0;
    return value / cadence.minutesRequired;
  };
  const requiredMinutesLabel = cadence.repeat
    ? `Minutes Required (${formatNounWithCount(cadence.minutes, "minute")} / ${formatNounWithCount(cadence.repeat.size, REQUIREMENT_CADENCE_NOUNS[cadence.repeat.unit])})`
    : "Minutes Required";
  return (
    <Stack gap={0.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">{cadence.requirementTitle}</Typography>
        <Stack direction="row" gap={2}>
          <CadenceLabel
            value={cadence.minutesAttended || 0}
            icon={{ type: "color", value: "primary.main" }}
            label="Minutes Attended"
          />
          <CadenceLabel
            value={cadence.minutesOffered || 0}
            icon={{ type: "color", value: "success.main" }}
            label="Minutes Offered"
          />
          <CadenceLabel
            value={cadence.minutesRequired || 0}
            icon={{ type: "label", value: "Required" }}
            label={requiredMinutesLabel}
          />
        </Stack>
      </Stack>

      <Box width="100%" border={1} borderColor="grey.300">
        <Tooltip title="Minutes Attended">
          <Box
            height="16px"
            width={percentString(getRatio(cadence.minutesAttended))}
            maxWidth="100%"
            bgcolor="primary.main"
          />
        </Tooltip>
        <Tooltip title="Minutes Offered">
          <Box
            height="16px"
            width={percentString(getRatio(cadence.minutesOffered))}
            maxWidth="100%"
            bgcolor="success.main"
          />
        </Tooltip>
      </Box>
    </Stack>
  );
};

const StudentServices = ({
  student,
  onArchive,
}: {
  student: SingleStudentUser;
  onArchive: (service: StudentService) => void;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const serviceWritePermissions = getServicePermissions(currentUser, "write");
  const canArchiveService = (service: StudentService) => serviceWritePermissions?.allowIf(service, currentUser);

  const reportWritePermissions = getReportPermissions(currentUser, "write");
  const showReportLinks = !!reportWritePermissions && currentUser?.featureFlags.showNewReportWriter;

  return (
    <Stack>
      {student.services.map((service, i) => {
        const startDate = service.requirements.dates.find(d => d.type === "START_DATE");
        const endDate = service.requirements.dates.find(d => d.type === "END_DATE");
        const hasDeliveryRange = !!startDate && !!endDate;
        return (
          <Stack width="100%" gap={2} px={1} py={3} borderBottom={1} borderColor="grey.200" key={i}>
            <Stack direction="row" justifyContent="space-between" alignItems="start">
              <Typography variant="body1">{service.title}</Typography>
              {canArchiveService(service) && (
                <IconButton onClick={() => onArchive(service)} sx={{ p: "2px" }}>
                  <ArchiveIcon sx={{ color: "primary.main", fontSize: "20px" }} />
                </IconButton>
              )}
            </Stack>

            {service.requirements.users.map(user => (
              <WithLabel label={user.requirementTitle} key={user.requirementTypeId}>
                <Box>
                  <Chip label={user.fullName} size="medium" />
                </Box>
              </WithLabel>
            ))}
            {service.requirements.cadences.map(cadence => (
              <StudentServiceCadence cadence={cadence} key={cadence.requirementTypeId} />
            ))}
            {hasDeliveryRange && (
              <WithLabel label="Parallel Service Period">
                <Typography variant="body1">
                  {startDate.value.toLocaleString(DateTime.DATE_MED)} -{" "}
                  {endDate.value.toLocaleString(DateTime.DATE_MED)}
                </Typography>
              </WithLabel>
            )}
            {service.requirements.dates.map(
              date =>
                (!hasDeliveryRange || date.type === "DATE") && (
                  <WithLabel label={date.requirementTitle} key={date.requirementTypeId}>
                    <Typography variant="body1">{date.value.toLocaleString(DateTime.DATE_MED)}</Typography>
                  </WithLabel>
                ),
            )}
            {showReportLinks && isNull(service.reportId) && (
              <Box pt={1}>
                <Button startIcon={<PostAddIcon />} href={`/report/start/${service.serviceClientId}`}>
                  Start Assessment
                </Button>
              </Box>
            )}
            {showReportLinks && service.reportId && (
              <Box pt={1}>
                <Button startIcon={<AssessmentIcon />} href={`/report/${service.reportId}`}>
                  Edit Assessment
                </Button>
              </Box>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default StudentServices;
