import { makeAutoObservable, runInAction } from "mobx";
import { ExtendedTask, TaskSearchResults } from "@parallel/vertex/types/task.types";
import { TaskAPI } from "@/api/task.api";

export class TaskStore {
  public currentTasks?: TaskSearchResults = undefined;

  constructor(private taskApi: TaskAPI) {
    makeAutoObservable(this);
  }

  public loadTasks = async (providerId: string) => {
    this.currentTasks = undefined;
    const results = await this.taskApi.searchTasks(providerId);
    runInAction(() => {
      this.currentTasks = results;
    });
  };

  public toggleTaskCompleted = async (task: ExtendedTask) => {
    const isComplete = !task.completedAt;
    const { task: updatedTask, groupCounts } = await this.taskApi.updateTask(task.key, { isComplete });

    const currentTasks = this.currentTasks;
    if (currentTasks) {
      runInAction(() => {
        this.currentTasks = {
          ...currentTasks,
          records: currentTasks.records.map(t => (t.key === task.key ? updatedTask : t)),
          groupCounts,
        };
      });
    }
  };
}
