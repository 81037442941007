import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { AuthorizedUser } from "@parallel/vertex/types/auth.types";
import { AuthAPI } from "@/api/auth.api";
import { UserAPI } from "@/api/user.api";

export class AuthStore {
  currentUser?: AuthorizedUser = undefined;

  constructor(
    private readonly authApi: AuthAPI,
    private readonly userApi: UserAPI,
  ) {
    makeAutoObservable(this);
  }

  get timezone() {
    return this.currentUser?.timezone ?? "local";
  }

  get shortTimezone() {
    return DateTime.utc().setZone(this.timezone).toFormat("ZZZZ");
  }

  public passwordSignIn = async (request: { email: string; password: string }) =>
    this.signIn(this.authApi.passwordSignIn(request));

  public googleSignIn = async (token: string) => this.signIn(this.authApi.googleSignIn(token));

  public cleverSignIn = async (token: string) => this.signIn(this.authApi.cleverSignIn(token));

  public restoreSignIn = () => this.signIn(this.authApi.restoreSignIn());

  private readonly signIn = async (userPromise: Promise<AuthorizedUser>) => {
    runInAction(() => (this.currentUser = undefined));
    const user = await userPromise;
    runInAction(() => (this.currentUser = user));
    return user;
  };

  public signOut = async () => {
    await this.authApi.signOut();
    runInAction(() => {
      this.currentUser = undefined;
    });
  };

  public setTimezone = async (timezone: string) => {
    if (!this.currentUser) return;
    await this.userApi.setUserTimezone(this.currentUser.userId, timezone);
    runInAction(() => {
      if (this.currentUser) this.currentUser.timezone = timezone;
    });
  };
}
