import { useContext } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ExtendedTask } from "@parallel/vertex/types/task.types";
import { StoreContext } from "@/stores";

const TaskQuicklink = ({ task }: { task: ExtendedTask }) => {
  const {
    taskStore: { toggleTaskCompleted },
  } = useContext(StoreContext);

  if (task.category !== "PERSONAL") return <></>;

  const isComplete = !!task.completedAt;

  return (
    <ProcessButton
      startIcon={isComplete ? <RadioButtonUncheckedIcon /> : <CheckCircleIcon />}
      process={() => toggleTaskCompleted(task)}
      loadingOnly
    >
      {isComplete ? "Mark Incomplete" : "Mark Complete"}
    </ProcessButton>
  );
};

export default TaskQuicklink;
