import { ReactNode } from "react";
import Stack from "@mui/material/Stack";

export const SUB_HEADER_HEIGHT = "48px";

const SubHeaderLayout = ({
  title,
  leftControls,
  rightControls,
}: {
  title?: ReactNode;
  leftControls?: ReactNode;
  rightControls?: ReactNode;
}) => {
  return (
    <Stack
      direction="row"
      width="100%"
      height={SUB_HEADER_HEIGHT}
      px={title ? 3 : 0}
      justifyContent="space-between"
      alignItems="center"
      bgcolor="surface.light"
      sx={{ borderBottom: 1, borderBottomColor: "grey.300" }}
    >
      <Stack direction="row" gap={3} alignItems="center" height="100%">
        {title}
        {leftControls}
      </Stack>

      {rightControls}
    </Stack>
  );
};

export default SubHeaderLayout;
