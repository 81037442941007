import { DateTime } from "luxon";
import { z } from "zod";
import { datetimeRequestField, PaginatedResult, paginateQueryFields } from "./shared.types";

export type TaskCategory =
  | "PERSONAL"
  | "ASSESSMENT"
  | "IEP_DUE"
  | "DATA_CLEANUP"
  | "LICENSING"
  | "PAYROLL"
  | "PARALLEL";

export type ExtendedTask = {
  key: string;
  category: TaskCategory;
  assignedUserId: string;
  dueDate: DateTime;
  title: string;
  completedAt?: DateTime;
  students: { userId: string; fullName: string }[];
};

export const ALL_TASK_GROUPS = ["overdue", "dueThisWeek", "completedThisWeek"] as const;
export type TaskGroup = (typeof ALL_TASK_GROUPS)[number];
export type TaskGroupCounts = Record<TaskGroup, number>;

export type TaskSearchResults = PaginatedResult<ExtendedTask> & {
  groupCounts: TaskGroupCounts;
};

export type TaskUpdateResponse = {
  task: ExtendedTask;
  groupCounts: TaskGroupCounts;
};

export const searchTaskQuerySchema = z.object({
  ...paginateQueryFields,
  providerId: z.string().uuid(),
});
export type SearchTaskQuery = z.infer<typeof searchTaskQuerySchema>;

export const createTaskBodySchema = z.object({
  assignedUserId: z.string().uuid(),
  dueDate: datetimeRequestField,
  title: z.string(),
  studentIds: z.string().uuid().array(),
});
export type CreateTaskBody = z.infer<typeof createTaskBodySchema>;

export const updateTaskBodySchema = z.object({
  isComplete: z.boolean().optional(),
});
export type UpdateTaskBody = z.infer<typeof updateTaskBodySchema>;
