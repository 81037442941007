import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { useMountEffect } from "@react-hookz/web";
import {
  CreateGoalBody,
  ObjectiveType,
  StudentGoal,
  createGoalBodySchema,
} from "@parallel/vertex/types/progress.types";
import SingleGoalInput from "@/components/progress/form/SingleGoalInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { SingleGoalParams, getInitialGoalParams, transformGoalObjectiveValues } from "@/util/progress.form.util";

const logger = initLogger("SingleGoalForm", getLoggerContext);

const SingleGoalForm = ({
  goalIndex,
  editing,
  onFinished,
}: {
  goalIndex: number;
  editing?: StudentGoal;
  onFinished: (maybeUpdated?: StudentGoal) => void;
}) => {
  const {
    apiStore: { progressApi },
    progressStore: { createGoal, updateGoal },
  } = useContext(StoreContext);

  const [params, setParams] = useState<SingleGoalParams>(getInitialGoalParams(editing));

  const [allObjectiveTypes, setAllObjectiveTypes] = useState<ObjectiveType[]>([]);
  useMountEffect(() =>
    progressApi.getAllObjectiveTypes().then(setAllObjectiveTypes).catch(logger.handleFailure("getAllObjectiveTypes")),
  );

  const formContent = (
    <Box width={1000} height={500}>
      <SingleGoalInput
        goal={params}
        goalIndex={goalIndex}
        updateGoal={update => setParams({ ...params, ...update })}
        allObjectiveTypes={allObjectiveTypes}
        isEditing={!!editing}
      />
    </Box>
  );

  const validate = (params: SingleGoalParams) =>
    createGoalBodySchema.safeParse(transformGoalObjectiveValues(params, allObjectiveTypes))?.data;

  const onSubmit = async (request: CreateGoalBody) => {
    const updated = editing
      ? await updateGoal(editing.goalId, request).catch(
          logger.handleFailureAndThrow("updateStudentGoal", { level: "warning" }),
        )
      : await createGoal(request).catch(logger.handleFailureAndThrow("createStudentGoal", { level: "warning" }));

    onFinished(updated);
  };

  return (
    <SubmitForm
      recordName="goal"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onFinished}
    />
  );
};

export default SingleGoalForm;
