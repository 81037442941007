import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material/styles";
import { ClientLogger } from "../../../util/logging.util";

export type CopyContent = { value: string; type: "text/html" | "text/plain" };

export const CopyIconButton = ({
  content: { value, type },
  label,
  sx,
  logger,
}: {
  content: CopyContent;
  label?: string;
  sx?: SxProps;
  logger?: ClientLogger;
}) => {
  const blob = new Blob([value], { type });
  const clipboardItem = new ClipboardItem({ [type]: blob });

  const [isDone, setIsDone] = useState(false);
  const onClick = async () => {
    await navigator.clipboard.write([clipboardItem]);
    setIsDone(true);
    setTimeout(() => setIsDone(false), 1000);
    logger?.info(`copied ${label} content to clipboard`);
  };

  return (
    <IconButton onClick={onClick} size="small" sx={sx}>
      {isDone ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
    </IconButton>
  );
};
