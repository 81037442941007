import { useContext } from "react";
import { useNavigate } from "react-router";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EventIcon from "@mui/icons-material/Event";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { GridFooterContainer } from "@mui/x-data-grid";
import { pick, uniq } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { downloadBlob } from "@parallel/polygon/util/file.util";
import { AppointmentStatus, ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import DataTable, { ChipColor, DataTableColumn, renderChip } from "@/components/shared/layout/DataTable";
import { StoreContext } from "@/stores";

type AppointmentEntryRow = {
  appointmentId: string;
  groupDate: string;
  timeRange: string;
  providerName: string;
  sessionType: string;
  students: { title: string; internalLink: string }[];
  campusNames: string[];
  facilitatorNames: string[];
  appointmentStatus: AppointmentStatus | null;
  groupDateSeconds: number;
  startTime: string;
  endTime: string;
};

const getStatusChipColor = (status: AppointmentStatus | null): ChipColor => {
  switch (status) {
    case "CANCELLED":
    case "LATE_CANCELLED":
    case "NO_SHOW":
      return "disabled";
    case "OCCURRED":
      return "success";
    case null:
      return "primary";
  }
};

const CHIP_HEIGHT = 20;

const TABLE_COLUMNS: DataTableColumn<AppointmentEntryRow>[] = [
  { key: "appointmentId", header: "ID", hidden: true, isID: true, sortable: false },
  { key: "groupDate", header: "Date", hidden: true, sortable: false },
  { key: "timeRange", header: "Time", width: 160, sortable: false },
  { key: "providerName", header: "Provider", sortable: false },
  { key: "sessionType", header: "Session Type", sortable: false },
  {
    key: "students",
    header: "Students",
    renderCell: params => renderChip(params.value, { height: CHIP_HEIGHT }),
    sortable: false,
  },
  {
    key: "campusNames",
    header: "Campuses",
    renderCell: params =>
      renderChip(
        params.value.map((title: string) => ({ title })),
        { height: CHIP_HEIGHT },
      ),
    sortable: false,
  },
  {
    key: "facilitatorNames",
    header: "Facilitators",
    renderCell: params =>
      renderChip(
        params.value.map((title: string) => ({ title })),
        { height: CHIP_HEIGHT },
      ),
    sortable: false,
  },
  {
    key: "appointmentStatus",
    header: "Session Status",
    renderCell: params =>
      renderChip([{ title: toTitleCase(params.value || "SCHEDULED") }], {
        color: getStatusChipColor(params.value),
        height: CHIP_HEIGHT,
      }),
    sortable: false,
  },
];

const toAppointmentEntryRow = (a: ExtendedAppointment, timezone: string): AppointmentEntryRow => {
  const zonedStart = a.startTime.setZone(timezone);
  const zonedEnd = a.endTime.setZone(timezone);
  return {
    ...pick(a, "appointmentId", "appointmentStatus"),
    groupDate: `GROUP - ${zonedStart.toLocaleString(DateTime.DATE_FULL)}`,
    timeRange: `${zonedStart.toLocaleString(DateTime.TIME_SIMPLE)} - ${zonedEnd.toLocaleString(DateTime.TIME_SIMPLE)}`,
    providerName: a.provider.fullName,
    sessionType: a.appointmentType.title,
    students: a.students.map(s => ({ title: s.fullName, internalLink: `/user/student/${s.userId}` })),
    campusNames: uniq(mapExists(a.students, s => s.campus?.name)),
    facilitatorNames: uniq(a.students.flatMap(s => s.facilitators.map(f => f.fullName))),
    groupDateSeconds: zonedStart.startOf("day").toSeconds(),
    startTime: zonedStart.toLocaleString(DateTime.DATETIME_SHORT).replace(",", ""),
    endTime: zonedEnd.toLocaleString(DateTime.DATETIME_SHORT).replace(",", ""),
  };
};

const CalendarListView = () => {
  const {
    authStore: { timezone },
    calendarStore: { filteredAppointments, currentView },
  } = useContext(StoreContext);

  const navigate = useNavigate();

  const rows = filteredAppointments.map(a => toAppointmentEntryRow(a, timezone));

  const downloadCsv = () => {
    const csvHeader = [
      "Start Time",
      "End Time",
      "Provider",
      "Session Type",
      "Students",
      "Campuses",
      "Facilitators",
      "Session Status",
    ].join(",");
    const csvRows = rows.map(a =>
      [
        a.startTime,
        a.endTime,
        a.providerName,
        a.sessionType,
        a.students.map(s => s.title).join("~"),
        a.campusNames.join("~"),
        a.facilitatorNames.join("~"),
        a.appointmentStatus ? toTitleCase(a.appointmentStatus) : "Scheduled",
      ].join(","),
    );
    const csvContentString = [csvHeader, ...csvRows].join("\n");
    const csvFile = new Blob([csvContentString], { type: "text/csv" });

    const range = [currentView.startTime, currentView.endTime].map(d => d.toLocaleString(DateTime.DATE_SHORT));
    const fileName = `parallel_appointments_${range[0]}_${range[1]}.csv`;

    downloadBlob(csvFile, fileName);
  };

  return (
    <DataTable
      columns={TABLE_COLUMNS}
      data={rows}
      group="groupDate"
      groupPrefixIcon={<EventIcon sx={{ fontSize: "20px", color: "text.secondary" }} />}
      onRowClick={appointmentId => navigate(`/calendar/appointment/${appointmentId}`)}
      actions={[{ icon: <ArrowRightIcon /> }]}
      groupRowSx={{ bgcolor: "grey.50" }}
      groupRowHeight={50}
      staticRowHeight={36}
      footer={() => (
        <GridFooterContainer sx={{ px: 2 }}>
          <Button onClick={downloadCsv} startIcon={<UploadFileIcon />}>
            Export All
          </Button>
        </GridFooterContainer>
      )}
    />
  );
};

export default observer(CalendarListView);
