import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { FullCenterBox, FullStack, SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { CommonProgressItem } from "@parallel/polygon/util/progress.util";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { FloatingMenu, SyncMenuItemProps } from "@/components/shared/display/menu";
import SearchTextInput from "@/components/shared/input/SearchTextInput";
import GoalListItem from "@/components/user/student/progress/GoalListItem";
import { StoreContext } from "@/stores";

const PROGRESS_FILTER_KEYS = ["pinnedOnly", "achieved", "archived"] as const;

const PROGRESS_FILTER_NAMES = {
  pinnedOnly: "Show Pinned Only",
  achieved: "Show Achieved",
  archived: "Show Archived",
};

type ProgressFilterKey = (typeof PROGRESS_FILTER_KEYS)[number];

type ProgressFilters = Record<ProgressFilterKey, boolean>;

const StudentGoalList = ({
  onGoalAdd,
  onGoalEdit,
}: {
  onGoalAdd: () => void;
  onGoalEdit: (editing: { goal: StudentGoal; index: number }) => void;
}) => {
  const {
    progressStore: { currentStudentProgress },
  } = useContext(StoreContext);

  const goals = currentStudentProgress?.goals || [];

  const [searchString, setSearchString] = useState("");

  const [filters, setFilters] = useState<ProgressFilters>({
    pinnedOnly: false,
    achieved: true,
    archived: false,
  });

  const [settingsAnchor, setSettingsAnchor] = useState<HTMLElement>();

  const settingsMenuItems: SyncMenuItemProps[] = PROGRESS_FILTER_KEYS.map(key => {
    const isEnabled = filters[key];
    return {
      type: "sync",
      icon: isEnabled ? <CheckIcon /> : <></>,
      text: PROGRESS_FILTER_NAMES[key],
      onClick: () => setFilters({ ...filters, [key]: !isEnabled }),
    };
  });

  const matchesFilters = (item: CommonProgressItem) =>
    (!searchString || item.description.toLowerCase().includes(searchString.toLowerCase())) &&
    (!filters.pinnedOnly || !!item.isPinned) &&
    (filters.achieved || !item.completedAt) &&
    (filters.archived || !item.isArchived);

  const filteredGoals = mapExists(goals, (goal, i) => {
    const filteredObjectives = goal.objectives.map((o, i) => ({ ...o, displayIndex: i + 1 })).filter(matchesFilters);
    if (!matchesFilters(goal) && !isEmpty(goal.objectives) && isEmpty(filteredObjectives)) return;

    return { ...goal, objectives: filteredObjectives, displayIndex: i + 1 };
  });

  return (
    <FullCenterBox>
      <FullStack sx={{ maxWidth: 1600, overflowY: "hidden" }}>
        <SplitRow
          left={<SearchTextInput value={searchString} setValue={setSearchString} width={300} />}
          right={
            <Stack direction="row" gap={2}>
              <Button startIcon={<SettingsIcon />} onClick={e => setSettingsAnchor(e.currentTarget)}>
                Settings
              </Button>
              <FloatingMenu
                anchorEl={settingsAnchor}
                onClose={() => setSettingsAnchor(undefined)}
                items={settingsMenuItems}
              />
              <Button startIcon={<AddIcon />} onClick={onGoalAdd}>
                New Goal
              </Button>
            </Stack>
          }
          sx={{ p: 2 }}
        />
        <Stack gap={2} sx={{ flex: "1 1 0%", px: 2, overflowY: "auto" }}>
          {filteredGoals.map(goal => (
            <GoalListItem goal={goal} onEdit={() => onGoalEdit({ goal, index: goal.displayIndex })} key={goal.goalId} />
          ))}
          {isEmpty(goals) && <Typography variant="body1">No Goals Found</Typography>}
        </Stack>
      </FullStack>
    </FullCenterBox>
  );
};

export default StudentGoalList;
