import { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMeasure } from "@react-hookz/web";
import { last } from "lodash";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import MarginData from "@/components/stimulus/MarginData";
import { MarginSelect } from "@/components/stimulus/MarginSelect";
import config from "@/config";
import { StoreContext } from "@/stores";

export type ImageType = "staff" | "client";

const getImageDetails = (bookId: string, test: AssessmentTestData, imageType: ImageType) => {
  const filePath = test.image[imageType];
  const fileName = last(filePath?.split("/"));
  if (!fileName) return {};

  const fileUrl = `${config.nexusServerUrl}/assessment/stimulus/book/${bookId}/image/${fileName}`;
  switch (imageType) {
    case "client":
      return { fileUrl, margins: test.image.clientMargin };
    case "staff":
      return { fileUrl, margins: test.image.staffMargin };
  }
};

const TestImageMarginInput = ({
  bookId,
  test,
  imageType,
  onNext,
  onPrev,
}: {
  bookId: string;
  test: AssessmentTestData;
  imageType: ImageType;
  onNext?: () => void;
  onPrev?: () => void;
}) => {
  const {
    stimulusStore: { updateTest },
  } = useContext(StoreContext);

  const { fileUrl, margins } = getImageDetails(bookId, test, imageType);
  const [stagedMargins, setStagedMargins] = useState(margins);

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const [naturalImageArea, setNaturalImageArea] = useState<{ width: number; height: number }>();
  useEffect(() => {
    if (!fileUrl) return;
    setNaturalImageArea(undefined);
    const image = new Image();
    image.onload = () => setNaturalImageArea({ width: image.naturalWidth, height: image.naturalHeight });
    image.src = fileUrl;
  }, [fileUrl]);

  if (!fileUrl) return <p>Missing Image</p>;
  if (!naturalImageArea) return <CircularProgress />;

  const containerHeight = containerArea?.height || 0;
  const imageAspectRatio = naturalImageArea.width / naturalImageArea.height;

  const marginContainerStyle = {
    backgroundImage: `url("${fileUrl}")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%",
    width: containerHeight * imageAspectRatio,
  };

  const saveMargin = async () => {
    const updateKey = imageType === "client" ? "clientMargin" : "staffMargin";
    await updateTest({
      id: test.id,
      image: {
        ...test.image,
        [updateKey]: stagedMargins,
      },
    });
  };

  return (
    <Stack direction="row" width="80vw" height="80vh" gap={4} ref={containerRef}>
      <Box sx={marginContainerStyle}>
        <MarginSelect
          selection={stagedMargins}
          setSelection={setStagedMargins}
          selectableArea={naturalImageArea}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <Stack gap={2} width={200} sx={{ flexShrink: "0", textAlign: "center" }}>
        <Typography variant="h2">{test.name}</Typography>
        <Typography variant="h3">{imageType} image margins</Typography>
        <MarginData margins={stagedMargins} />

        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={onPrev} disabled={!onPrev}>
            <ArrowBackIcon />
          </IconButton>
          <Stack direction="row" gap={1}>
            <ProcessIconButton color="primary" process={saveMargin}>
              <SaveIcon />
            </ProcessIconButton>
            <IconButton onClick={() => setStagedMargins(margins)}>
              <UndoIcon />
            </IconButton>
          </Stack>

          <IconButton onClick={onNext} disabled={!onNext}>
            <ArrowForwardIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TestImageMarginInput;
