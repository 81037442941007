import { useContext } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { lighten, useTheme } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { ALL_TASK_GROUPS, TaskGroup } from "@parallel/vertex/types/task.types";
import SubHeaderLayout from "@/components/shared/layout/SubHeaderLayout";
import { StoreContext } from "@/stores";

const useGroupFilterProps = (group: TaskGroup) => {
  const { palette } = useTheme();
  switch (group) {
    case "overdue":
      return { label: "Overdue", color: palette.error.main };
    case "dueThisWeek":
      return { label: "Due This Week", color: palette.warning.main };
    case "completedThisWeek":
      return { label: "Completed This Week", color: palette.success.main };
  }
};

const _GroupFilterButton = ({ group }: { group: TaskGroup }) => {
  const {
    taskStore: { currentTasks },
  } = useContext(StoreContext);

  const { label, color } = useGroupFilterProps(group);

  if (!currentTasks) return <></>;
  const count = currentTasks.groupCounts[group];

  return (
    <ButtonBase sx={{ bgcolor: lighten(color, 0.7), height: 32, px: 2, pt: "4px", borderRadius: 1 }}>
      <Stack gap={1} direction="row" alignItems="center">
        <Typography variant="h3">{count}</Typography>
        <Typography variant="subtitle2">{label}</Typography>
      </Stack>
    </ButtonBase>
  );
};
const GroupFilterButton = observer(_GroupFilterButton);

const TaskHeader = ({ isHome }: { isHome?: boolean }) => {
  const groupFilters = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      sx={{ height: "100%", pl: 3, borderLeft: 1, borderColor: "grey.200" }}
    >
      {ALL_TASK_GROUPS.map(group => (
        <GroupFilterButton group={group} key={group} />
      ))}
    </Stack>
  );
  return (
    <SubHeaderLayout
      title={<Typography variant="body1">{isHome ? "Home" : "Tasks"}</Typography>}
      leftControls={groupFilters}
    />
  );
};

export default TaskHeader;
