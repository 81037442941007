import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { isString } from "lodash";

const MultipleSelectInput = <A extends string>({
  label,
  options,
  value,
  onChange,
  width,
  size,
  fullWidth,
  itemStyle,
}: {
  label?: string;
  options: { key: A; label: string }[];
  value: A[];
  onChange: (newValue: A[]) => void;
  width?: number;
  size?: "medium" | "small";
  fullWidth?: boolean;
  itemStyle?: "chip";
}) => {
  const labelId = `${(label || "select").toLowerCase().replace(/ /g, "-")}-label`;

  const renderValue =
    itemStyle === "chip"
      ? (selected: A[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )
      : undefined;

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={labelId} size={size}>
        {label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={({ target: { value } }) => onChange(isString(value) ? (value.split(",") as A[]) : value)}
        labelId={labelId}
        label={label}
        sx={{ width }}
        size={size}
        renderValue={renderValue}
      >
        {options.map(o => (
          <MenuItem value={o.key} key={o.key} sx={{ "&.Mui-selected": { bgcolor: "primary.light" } }}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectInput;
