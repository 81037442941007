import { useContext, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateTime } from "luxon";
import AutoCompleteInput, { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { APPROVAL_STATUS_NAMES } from "@parallel/vertex/enums/approval.enums";
import { ApprovalStatusValues } from "@parallel/vertex/enums/prisma.enums";
import { ApprovalType } from "@parallel/vertex/types/approval.types";
import { AppointmentType } from "@parallel/vertex/types/calendar/appointment.types";
import { TaskType } from "@parallel/vertex/types/calendar/time.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import AppointmentTypeInput from "@/components/calendar/appointment/AppointmentTypeInput";
import TaskTypeInput from "@/components/calendar/time/TaskTypeInput";
import { StyledMenu } from "@/components/shared/display/menu";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

export type SearchParams = {
  provider: SelectOption | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  approvalStatus: SelectOption | null;
  appointmentType: AppointmentType | null;
  taskType: TaskType | null;
};

export type SettingsFilters = {
  showTaskDescription: boolean;
};

const logger = initLogger("ApprovalDataFilters", getLoggerContext);

const ApprovalDataFilters = ({
  approvalType,
  searchParams,
  setSearchParams,
  settingsFilters,
  setSettingsFilters,
}: {
  approvalType: ApprovalType;
  searchParams: SearchParams;
  setSearchParams: (params: SearchParams) => void;
  settingsFilters: SettingsFilters;
  setSettingsFilters: (settings: SettingsFilters) => void;
}) => {
  const {
    apiStore: { userApi },
    authStore: { timezone },
  } = useContext(StoreContext);

  const [settingsAnchor, setSettingsAnchor] = useState<HTMLElement>();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} py={2} pb={2}>
      <DateRangePicker
        label="Date Range"
        value={[searchParams.startDate || null, searchParams.endDate || null]}
        onAccept={range =>
          setSearchParams({
            ...searchParams,
            startDate: range[0] ? toLocalDate(range[0], timezone) : null,
            endDate: range[1] ? toLocalDate(range[1], timezone) : null,
          })
        }
        slots={{ field: SingleInputDateRangeField }}
        sx={{ width: 360 }}
        slotProps={{ textField: { size: "small" } }}
      />

      <Stack direction="row" alignItems="center" gap={2}>
        {approvalType === "TIME" && (
          <>
            <Button startIcon={<SettingsIcon />} onClick={e => setSettingsAnchor(e.currentTarget)}>
              <Typography variant="body1" fontWeight="500">
                Settings
              </Typography>
            </Button>
            <StyledMenu anchorEl={settingsAnchor} open={!!settingsAnchor} onClose={() => setSettingsAnchor(undefined)}>
              <ToggleMenuItem
                toggle={() =>
                  setSettingsFilters({
                    ...settingsFilters,
                    showTaskDescription: !settingsFilters.showTaskDescription,
                  })
                }
                isEnabled={settingsFilters.showTaskDescription}
                text="Show Task Description"
                key="show-description"
              />
            </StyledMenu>
          </>
        )}

        <AutoCompletePageSearchInput
          label="Provider"
          search={keyword => userApi.searchProviders({ keyword }).catch(logger.handleFailure("searchProviders"))}
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={searchParams.provider}
          onSelect={provider => setSearchParams({ ...searchParams, provider })}
          size="small"
          width={240}
        />

        {approvalType === "TIME" ? (
          <TaskTypeInput
            selected={searchParams.taskType}
            onSelect={taskType => setSearchParams({ ...searchParams, taskType })}
            size="small"
            width={240}
          />
        ) : (
          <AppointmentTypeInput
            selected={searchParams.appointmentType}
            onSelect={appointmentType => setSearchParams({ ...searchParams, appointmentType })}
            size="small"
            width={240}
          />
        )}

        <AutoCompleteInput
          label="Approval Status"
          options={ApprovalStatusValues.map(key => ({ key, label: APPROVAL_STATUS_NAMES[key] }))}
          selected={searchParams.approvalStatus}
          onSelect={approvalStatus => setSearchParams({ ...searchParams, approvalStatus })}
          size="small"
          width={240}
        />
      </Stack>
    </Stack>
  );
};

export default ApprovalDataFilters;
