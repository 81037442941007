import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import ObjectiveMetricInput, {
  UpsertMetricOptions,
} from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { ObjectiveMetricBody, StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { getMetricValueString } from "@parallel/vertex/util/progress.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ObjectiveMetricDisplay", getLoggerContext);

const ObjectiveMetricDisplay = ({
  objective,
  goal,
  listIndex,
  appointmentId,
  onMetricWritten,
  isReadOnly,
}: {
  objective: StudentObjective;
  goal: StudentGoal;
  listIndex: { goal: number; objective: number };
  appointmentId: string;
  onMetricWritten: () => void;
  isReadOnly?: boolean;
}) => {
  const {
    authStore: { timezone },
    progressStore: { upsertMetric, updateNoteMetric },
  } = useContext(StoreContext);

  const attemptMetricUpsert = async (body: ObjectiveMetricBody & UpsertMetricOptions) =>
    upsertMetric(objective.objectiveId, goal, appointmentId, body)
      .catch(logger.handleFailureAndThrow("upsertObjectiveAppointmentMetric"))
      .then(onMetricWritten);

  return (
    <FullStack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {toTitleCase(objective.category)} Objective {listIndex.goal}.{listIndex.objective}
        </Typography>

        <ObjectiveActions objective={objective} goal={goal} displayIndices={listIndex} />
      </Stack>

      <Typography>{objective.description}</Typography>

      <ObjectiveMetricInput
        objective={objective}
        upsertMetric={attemptMetricUpsert}
        onNoteUpdated={note => updateNoteMetric(objective.objectiveId, goal, note)}
        isReadOnly={isReadOnly}
      />

      {objective.latestMetric && (
        <Stack>
          <Stack direction="row">
            <Typography variant="body1" sx={{ width: 150 }}>
              Latest Metric:
            </Typography>
            <Typography variant="body1">{getMetricValueString(objective, objective.latestMetric.value)}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body1" sx={{ width: 150 }}>
              Collected On:
            </Typography>
            <Typography variant="body1">
              {toLocalDate(objective.latestMetric.collectedAt, timezone).toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </Stack>
        </Stack>
      )}
    </FullStack>
  );
};

export default ObjectiveMetricDisplay;
