import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { TaskPermissions } from "@parallel/vertex/role/role.task";
import { CreateTaskBody, createTaskBodySchema } from "@parallel/vertex/types/task.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type CreateTaskParams = Omit<Partial<CreateTaskBody>, "studentIds"> & { students: SelectOption[] };

const logger = initLogger("TaskForm", getLoggerContext);

const TaskForm = ({
  onWrite,
  onClose,
  writePermissions,
}: {
  onWrite: () => void;
  onClose: () => void;
  writePermissions: TaskPermissions;
}) => {
  const {
    apiStore: { taskApi, userApi },
    authStore: { currentUser, timezone },
  } = useContext(StoreContext);

  const [params, setParams] = useState<CreateTaskParams>({
    assignedUserId: writePermissions.defaults?.assignedUser === "currentUser" ? currentUser?.userId : undefined,
    dueDate: toLocalDate(DateTime.utc(), timezone).plus({ days: 7 }),
    students: [],
  });

  const formContent = (
    <>
      <DatePicker
        label="Due Date"
        value={params.dueDate}
        onChange={d => setParams({ ...params, dueDate: d || undefined })}
      />
      <TextField
        label="Task Title"
        value={params.title || ""}
        onChange={e => setParams({ ...params, title: e.target.value })}
      />
      <AutoCompletePageSearchInput
        label="Students"
        search={keyword => userApi.searchStudents({ keyword }).catch(logger.handleFailure("searchStudents"))}
        getOption={s => ({ key: s.userId, label: s.fullName })}
        selected={params.students}
        onSelect={students => setParams({ ...params, students })}
      />
    </>
  );

  const validate = (params: CreateTaskParams) =>
    createTaskBodySchema.safeParse({ ...params, studentIds: params.students.map(s => s.key) })?.data;

  const onSubmit = async (body: CreateTaskBody) => {
    await taskApi.createTask(body).catch(logger.handleFailureAndThrow("createTask"));
    onWrite();
    onClose();
  };

  return (
    <SubmitForm
      recordName="task"
      operationName="create"
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default TaskForm;
