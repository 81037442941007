import { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import EditIcon from "@mui/icons-material/Edit";
import NoteIcon from "@mui/icons-material/Note";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import { isUndefined, pick } from "lodash";
import { observer } from "mobx-react-lite";
import AutoSaveTextField from "@parallel/polygon/components/shared/input/AutoSaveTextField";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { getProgressPermissions } from "@parallel/vertex/role/role.progress";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import CalendarItemSubHeader from "@/components/calendar/CalendarItemSubHeader";
import ProgressDisplayLayout from "@/components/progress/ProgressDisplayLayout";
import MultipleGoalForm from "@/components/progress/form/MultipleGoalForm";
import SingleGoalForm from "@/components/progress/form/SingleGoalForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("AppointmentProgressScreen", getLoggerContext);

const showNoteErrorToast = (retry: () => unknown) =>
  toast.error(<FullBox onClick={retry}>Note save failed. Click here to retry.</FullBox>);

type AppointmentProgressStudentDetails = Partial<
  Record<string, { isWaived: boolean; note?: string; previousNote?: string }>
>;

const AppointmentProgressScreen = () => {
  const {
    apiStore: { progressApi },
    authStore: { currentUser },
    calendarStore,
    progressStore: { currentStudentProgress: currentStudent, setAppointmentStudents, setCurrentStudentId },
  } = useContext(StoreContext);
  const { fetchStatus, selectedAppointment, previousPayPeriod } = calendarStore;

  const { itemId: appointmentId } = useParams();
  useEffect(() => {
    if (!appointmentId) return;
    calendarStore.loadItem({ itemId: appointmentId, itemType: "appointment" });
  }, [appointmentId]);

  const [studentDetails, setStudentDetails] = useState<AppointmentProgressStudentDetails>({});
  const [groupNote, setGroupNote] = useState<string>();

  const currentStudentDetails = currentStudent ? studentDetails[currentStudent.userId] : undefined;

  useEffect(() => {
    if (!selectedAppointment) return;
    progressApi
      .getAppointmentProgress(selectedAppointment.appointmentId)
      .then(progress => {
        const progressByStudent = setAppointmentStudents(selectedAppointment, progress);
        const studentDetails = selectedAppointment.students.reduce((currentProgress, { userId }) => {
          const progress = progressByStudent[userId];
          return {
            ...currentProgress,
            [userId]: progress ? pick(progress, "isWaived", "note", "previousNote") : { isWaived: false },
          };
        }, {} as AppointmentProgressStudentDetails);
        setStudentDetails(studentDetails);
        setGroupNote(progress.groupNote || "");
      })
      .catch(logger.handleFailure("getAppointmentProgress"));
  }, [selectedAppointment]);

  const [editingGoal, setEditingGoal] = useState<{ goal: StudentGoal; index: number }>();
  const [isEditingAllGoals, setIsEditingAllGoals] = useState(false);

  const [groupNoteAnchor, setGroupNoteAnchor] = useState<HTMLElement>();
  const [isShowingStudentNote, setIsShowingStudentNote] = useState(false);

  const readPermissions = getProgressPermissions(currentUser, "read");
  if (!readPermissions) return <NotFoundScreen />;

  if (!appointmentId || !selectedAppointment)
    return fetchStatus === "not-found" ? <NotFoundScreen /> : <LoadingScreen />;

  if (!currentStudent || !currentStudentDetails) return <LoadingScreen />;

  const saveGroupNote = (note: string) =>
    progressApi.setGroupNote(appointmentId, note).catch(e => {
      logger.logFailure(e, "setGroupNote");
      showNoteErrorToast(() => saveGroupNote(note));
    });

  const header = (
    <CalendarItemSubHeader
      appointment={selectedAppointment}
      navigation={{
        tabs: selectedAppointment.students.map(s => ({ key: s.userId, label: s.fullName, icon: <PersonIcon /> })),
        currentKey: currentStudent.userId,
        onChange: setCurrentStudentId,
      }}
      actions={
        <Stack direction="row" gap={1}>
          <ToggleButton
            value="student-note"
            selected={isShowingStudentNote}
            onChange={() => setIsShowingStudentNote(!isShowingStudentNote)}
            classes={{ selected: "Mui-selected" }}
            sx={{
              border: 0,
              borderRadius: 0,
              width: "48px",
              height: "48px",
              color: "primary.main",
              "&.Mui-selected": {
                color: "text.primary",
                backgroundColor: "primary.light",
              },
            }}
          >
            <ContactPageIcon />
          </ToggleButton>
          <ToggleButton
            value="group-note"
            selected={!!groupNoteAnchor}
            onChange={e => setGroupNoteAnchor(groupNoteAnchor ? undefined : e.currentTarget)}
            sx={{
              border: 0,
              borderRadius: 0,
              width: "48px",
              height: "48px",
              color: "primary.main",
              "&.Mui-selected": {
                color: "text.primary",
                backgroundColor: "primary.light",
              },
            }}
          >
            <NoteIcon />
          </ToggleButton>
          <Popover
            anchorEl={groupNoteAnchor}
            open={!!groupNoteAnchor}
            onClose={() => setGroupNoteAnchor(undefined)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            sx={{
              "& .MuiPopover-paper": {
                p: 2,
                bgcolor: "surface.light",
                width: 400,
              },
            }}
          >
            {!isUndefined(groupNote) && (
              <AutoSaveTextField
                label="Group Note"
                value={groupNote}
                onSave={saveGroupNote}
                fullWidth
                multiline
                rows={12}
                inputRef={(input: HTMLInputElement | null) => input && input.focus()}
                saveKey="group-note"
              />
            )}
          </Popover>
        </Stack>
      }
    />
  );

  const saveStudentNote = (note: string) => {
    setStudentDetails(previousState => ({
      ...previousState,
      [currentStudent.userId]: { ...previousState[currentStudent.userId]!, note },
    }));
    return progressApi.setStudentNote(currentStudent.userId, appointmentId, note).catch(e => {
      logger.logFailure("setStudentNote", e);
      showNoteErrorToast(() => saveStudentNote(note));
    });
  };

  const toggleWaived = () =>
    progressApi
      .updateStudentAppointmentProgress(currentStudent.userId, appointmentId, {
        isWaived: !currentStudentDetails.isWaived,
      })
      .then(progress => setStudentDetails({ ...studentDetails, [currentStudent.userId]: progress }))
      .catch(logger.handleFailureAndThrow("updateStudentAppointmentProgress"));

  const onModalFinished = () => {
    setEditingGoal(undefined);
    setIsEditingAllGoals(false);
  };

  let modalContent: ReactNode | undefined = undefined;
  if (editingGoal) {
    modalContent = (
      <SingleGoalForm goalIndex={editingGoal.index} editing={editingGoal.goal} onFinished={onModalFinished} />
    );
  } else if (isEditingAllGoals) {
    modalContent = <MultipleGoalForm onFinished={onModalFinished} />;
  }

  const previousNote = currentStudentDetails.previousNote;

  return (
    <PrimaryLayout headerContent={header} containerStyles={{ px: 0, pb: 0 }}>
      <FullStack direction="row">
        <Box sx={{ px: 3, width: "100%" }}>
          <ProgressDisplayLayout
            headerAction={{ text: "Edit Goals", icon: <EditIcon />, perform: () => setIsEditingAllGoals(true) }}
            onEditGoal={setEditingGoal}
            appointmentDetails={{
              appointmentId,
              isWaived: currentStudentDetails.isWaived,
              toggleWaived,
            }}
            isReadOnly={readPermissions.canWriteForAppointment(selectedAppointment, previousPayPeriod)}
          />
        </Box>
        {isShowingStudentNote && (
          <Stack
            gap={2}
            sx={{
              width: 400,
              height: "100%",
              p: 2,
              bgcolor: "surface.light",
              borderLeft: 1,
              borderLeftColor: "grey.200",
            }}
          >
            <AutoSaveTextField
              label="Student Note"
              value={currentStudentDetails.note || ""}
              onSave={saveStudentNote}
              multiline
              fullWidth
              rows={16}
              saveKey={currentStudent.userId}
              key={currentStudent.userId}
            />
            {previousNote && !currentStudentDetails.note && (
              <Box>
                <Button startIcon={<CopyAllIcon />} onClick={() => saveStudentNote(previousNote)}>
                  Copy previous session note
                </Button>
              </Box>
            )}
          </Stack>
        )}
      </FullStack>
      <CenterModal isOpen={!!modalContent} onClose={onModalFinished}>
        {modalContent}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(AppointmentProgressScreen);
