import { useContext, RefObject, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMeasure } from "@react-hookz/web";
import { isNull } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { getSequence, mapSequence } from "@parallel/vertex/util/collection.util";
import { StoreContext } from "@/stores";

// when sections taller than this, split to two sections per hour
const MAX_HOUR_SECTION_HEIGHT = 75;

const CalendarBackground = ({ parentContainerRef }: { parentContainerRef?: RefObject<HTMLDivElement> }) => {
  const {
    authStore: { timezone },
    calendarStore: { hourRange },
  } = useContext(StoreContext);

  const hourSectionProportion = 1 / (hourRange.max - hourRange.min);
  const hourSectionHeight = percentString(hourSectionProportion);

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const theme = useTheme();

  let sectionsPerHour: number | null = null;
  if (containerArea) {
    const sectionHeight = containerArea.height * hourSectionProportion;
    sectionsPerHour = sectionHeight < MAX_HOUR_SECTION_HEIGHT ? 1 : 2;
  }

  useEffect(() => {
    if (parentContainerRef && parentContainerRef.current && containerArea && sectionsPerHour !== null) {
      const targetTime = DateTime.fromObject({ hour: 7, minute: 30 }, { zone: timezone });
      const targetHours = targetTime.hour + targetTime.minute / 60; // e.g., 7.5
      const hourBlockHeight = containerArea.height * hourSectionProportion;
      const rowHeight = sectionsPerHour === 2 ? hourBlockHeight / 2 : hourBlockHeight;
      const targetRow = sectionsPerHour === 2 ? targetHours * 2 : targetHours;
      const scrollTop = targetRow * rowHeight;

      requestAnimationFrame(() => {
        if (parentContainerRef.current) {
          parentContainerRef.current.scrollTop = scrollTop;
        }
      });
    }
  }, [parentContainerRef, sectionsPerHour]);

  return (
    <FullStack ref={containerRef}>
      {!isNull(sectionsPerHour) &&
        getSequence(hourRange.min, hourRange.max - 1).flatMap(hour =>
          mapSequence(sectionsPerHour, i => (
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: hourSectionHeight,
                paddingLeft: 1,
                paddingTop: 1,
                borderTop: hour === hourRange.min && i === 0 ? 0 : 1,
                borderColor: theme.palette.bordergrey.light,
                overflow: "hidden",
              }}
              key={`${hour}-${i}`}
            >
              <Typography
                variant="body2"
                sx={{ width: "4.5rem", alignSelf: "start", textAlign: "right", color: theme.palette.bordergrey.main }}
              >
                {DateTime.fromObject({ hour, minute: Math.round(60 / sectionsPerHour) * i }).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
              </Typography>
            </Stack>
          )),
        )}
    </FullStack>
  );
};

export default observer(CalendarBackground);
