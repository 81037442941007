import { useState } from "react";
import Box from "@mui/material/Box";
import FileHandler from "@tiptap-pro/extension-file-handler";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Image from "@tiptap/extension-image";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorProvider } from "@tiptap/react";
import { noop } from "lodash";
import { Status } from "@parallel/polygon/components/shared/input/status.input";
import { filterExists } from "@parallel/vertex/util/collection.util";
import BlockContentMenu from "@/components/report/editor/content/BlockContentMenu";
import { getContentHtmlString, InjectedValueNode } from "@/util/tiptap.util";

const defaultExtensions = [
  Document,
  Paragraph,
  Text,
  InjectedValueNode,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  History,
  Image,
];

const TiptapInput = ({
  content,
  placeholder,
  saveContent,
  uploadImages,
  saveStatus,
  areCommentsExpanded,
  setAreCommentsExpanded = noop,
  disabled,
}: {
  content?: string;
  placeholder?: string;
  saveContent: (content: string) => unknown;
  uploadImages?: (files: File[]) => Promise<{ url: string; key: string }[]>;
  saveStatus?: Status;
  areCommentsExpanded?: boolean;
  setAreCommentsExpanded?: (areExpanded: boolean) => void;
  disabled?: boolean;
}) => {
  const onUpdate = ({ editor }: { editor: Editor }) => {
    const content = getContentHtmlString(editor);
    if (!content) return;
    saveContent(content);
  };

  const [isImageLoading, setIsImageLoading] = useState(false);

  const extensions = filterExists([
    ...defaultExtensions,
    placeholder && Placeholder.configure({ placeholder }),
    uploadImages &&
      FileHandler.configure({
        allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],
        onPaste: (editor, files) => {
          setIsImageLoading(true);
          uploadImages(files)
            .then(results => {
              results.forEach(({ url, key }) => {
                editor
                  .chain()
                  .insertContentAt(editor.state.selection.anchor, {
                    type: "image",
                    attrs: { src: url, alt: key },
                  })
                  .focus()
                  .run();
              });
            })
            .finally(() => setIsImageLoading(false));
        },
      }),
  ]);

  return (
    <Box
      width="100%"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
        bgcolor: disabled ? "grey.50" : undefined,
        "&:focus-within": { borderColor: "primary.main" },
        "& .tiptap": {
          minHeight: 200,
          p: 1,
          outline: 0,
          color: disabled && placeholder && !content ? "#9e9e9e" : undefined,
        },
      }}
    >
      <EditorProvider
        extensions={extensions}
        content={disabled ? content || placeholder : content}
        slotBefore={
          <BlockContentMenu
            content={content}
            saveStatus={isImageLoading ? { value: "loading" } : saveStatus}
            areCommentsExpanded={areCommentsExpanded}
            setAreCommentsExpanded={setAreCommentsExpanded}
            disabled={disabled}
          />
        }
        onUpdate={onUpdate}
        editable={!disabled}
      />
    </Box>
  );
};

export default TiptapInput;
