import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { getTaskPermissions } from "@parallel/vertex/role/role.task";
import FilterSearchInput, { FilterProperty } from "@/components/shared/input/FilterSearchInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import TaskForm from "@/components/task/TaskForm";
import { StoreContext } from "@/stores";

const FILTER_PROPERTIES: FilterProperty[] = [
  { propertyName: "Task Type", searchParamKey: "categoryKeyword" },
  { propertyName: "Student Name/Email", searchParamKey: "studentKeyword" },
];

const TaskListControls = ({ onWrite }: { onWrite: () => void }) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [isCreating, setIsCreating] = useState(false);

  const writePermissions = getTaskPermissions(currentUser, "write");

  const filterInput = (
    <FilterSearchInput properties={FILTER_PROPERTIES} selectedOptions={[]} setSelectedOptions={() => {}} />
  );
  if (!writePermissions) return filterInput;

  return (
    <>
      <SplitRow
        // TODO
        left={<FilterSearchInput properties={FILTER_PROPERTIES} selectedOptions={[]} setSelectedOptions={() => {}} />}
        right={
          <Button startIcon={<AddIcon />} onClick={() => setIsCreating(true)}>
            Add Personal Task
          </Button>
        }
      />
      <CenterModal isOpen={isCreating} onClose={() => setIsCreating(false)}>
        <TaskForm onWrite={onWrite} onClose={() => setIsCreating(false)} writePermissions={writePermissions} />
      </CenterModal>
    </>
  );
};

export default TaskListControls;
