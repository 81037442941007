import { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMountEffect } from "@react-hookz/web";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { SingleStudentUser, StudentService } from "@parallel/vertex/types/user/student.types";
import SingleGoalForm from "@/components/progress/form/SingleGoalForm";
import UpdateProgressModal from "@/components/progress/prompt/UpdateProgressModal";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import StudentHeader from "@/components/user/student/StudentHeader";
import StudentInfo from "@/components/user/student/StudentInfo";
import StudentProgressConfiguration from "@/components/user/student/StudentProgressConfiguration";
import StudentProgressReport from "@/components/user/student/StudentProgressReport";
import StudentDocuments from "@/components/user/student/document/StudentDocuments";
import StudentArchiveForm from "@/components/user/student/form/StudentArchiveForm";
import StudentEditForm, { StudentEditColumn } from "@/components/user/student/form/StudentEditForm";
import StudentRestoreForm from "@/components/user/student/form/StudentRestoreForm";
import StudentServiceArchiveForm from "@/components/user/student/form/StudentServiceArchiveForm";
import { ObjectiveItemProps } from "@/components/user/student/progress/ObjectiveListItem";
import StudentGoalList from "@/components/user/student/progress/StudentGoalList";
import StudentObjectiveDetails from "@/components/user/student/progress/StudentObjectiveDetails";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { StudentViewType } from "@/util/student.util";

const logger = initLogger("StudentScreen", getLoggerContext);

const StudentScreen = ({ currentView = "info" }: { currentView?: StudentViewType }) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
    progressStore: { setSingleStudent: setProgressStudent, currentStudentProgress: studentProgress },
    studentStore: { documentUploads, getStudentInfoDocuments, appendDocumentUploads, removeDocumentUpload },
  } = useContext(StoreContext);

  const { studentId, goalId, objectiveId } = useParams();
  const [student, setStudent] = useState<SingleStudentUser | null>();

  useMountEffect(() => {
    if (!studentId) {
      setStudent(null);
      return;
    }
    userApi
      .getStudent(studentId)
      .catch(logger.handleFailure("getStudent"))
      .then(student => {
        setStudent(student);
        student && setProgressStudent(student);
      });
  });

  const [editColumn, setEditColumn] = useState<StudentEditColumn>();

  const [isArchiving, setIsArchiving] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [editingGoal, setEditingGoal] = useState<{ goal: StudentGoal; index: number }>();

  const [archivingStudentService, setArchivingStudentService] = useState<StudentService>();

  useEffect(() => {
    if (currentView !== "documents" || !studentId) return;
    getStudentInfoDocuments(studentId);
  }, [currentView]);

  if (isNull(student)) return <NotFoundScreen />;
  if (!student)
    return (
      <PrimaryLayout>
        <LoadingScreen />
      </PrimaryLayout>
    );

  const onModalFinished = (updated?: SingleStudentUser) => {
    updated && setStudent(updated);
    setEditColumn(undefined);
    setIsArchiving(false);
    setIsRestoring(false);
    setIsAddingGoal(false);
    setEditingGoal(undefined);
    setArchivingStudentService(undefined);
  };

  let modalContent: ReactNode | undefined = undefined;
  if (editColumn) {
    modalContent = <StudentEditForm student={student} column={editColumn} onFinished={onModalFinished} />;
  } else if (isArchiving) {
    modalContent = <StudentArchiveForm student={student} onFinished={onModalFinished} />;
  } else if (isRestoring) {
    modalContent = <StudentRestoreForm student={student} onFinished={onModalFinished} />;
  } else if (editingGoal || isAddingGoal) {
    const goalIndex = editingGoal ? editingGoal.index : (studentProgress?.goals.length || 0) + 1;
    modalContent = (
      <SingleGoalForm goalIndex={goalIndex} editing={editingGoal?.goal} onFinished={() => onModalFinished()} />
    );
  } else if (archivingStudentService) {
    modalContent = (
      <StudentServiceArchiveForm
        studentName={student.fullName}
        service={archivingStudentService}
        onArchived={() =>
          onModalFinished({
            ...student,
            services: student.services.filter(s => s.serviceClientId !== archivingStudentService.serviceClientId),
          })
        }
        onClose={() => onModalFinished(student)}
      />
    );
  }

  const resolveCurrentObjectiveProps = (): ObjectiveItemProps | undefined => {
    if (!goalId || !objectiveId || !studentProgress) return;

    const goalIndex = studentProgress.goals.findIndex(g => g.goalId === goalId);
    const goal = studentProgress.goals[goalIndex];
    if (!goal) return;

    const objectiveIndex = goal.objectives.findIndex(o => o.objectiveId === objectiveId);
    const objective = goal.objectives[objectiveIndex];
    if (!objective) return;

    return {
      objective,
      displayIndices: { goal: goalIndex + 1, objective: objectiveIndex + 1 },
      goal,
    };
  };
  const currentObjectiveProps = resolveCurrentObjectiveProps();

  return (
    <PrimaryLayout
      headerContent={
        <StudentHeader
          student={student}
          currentView={currentView}
          onArchive={() => setIsArchiving(true)}
          onRestore={() => setIsRestoring(true)}
        />
      }
    >
      {currentView === "info" && (
        <StudentInfo student={student} onEdit={setEditColumn} onServiceArchive={setArchivingStudentService} />
      )}
      {currentView === "documents" && (
        <StudentDocuments
          uploads={documentUploads}
          studentId={student.userId}
          onUploaded={appendDocumentUploads}
          onDeleted={removeDocumentUpload}
          sx={{ pt: 2 }}
        />
      )}
      {currentView === "goals" &&
        (!currentUser?.featureFlags.showNewStudentGoals ? (
          <StudentProgressConfiguration onGoalAdd={() => setIsAddingGoal(true)} onGoalEdit={setEditingGoal} />
        ) : currentObjectiveProps ? (
          <StudentObjectiveDetails {...currentObjectiveProps} />
        ) : (
          <StudentGoalList onGoalAdd={() => setIsAddingGoal(true)} onGoalEdit={setEditingGoal} />
        ))}
      {currentView === "progress" && <StudentProgressReport studentId={student.userId} />}

      <CenterModal isOpen={!!modalContent} onClose={() => onModalFinished(student)}>
        {modalContent}
      </CenterModal>

      <UpdateProgressModal />
    </PrimaryLayout>
  );
};

export default observer(StudentScreen);
