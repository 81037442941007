import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { CSSObject, styled, SxProps } from "@mui/material/styles";

export const GreyBox = styled(Box)({
  backgroundColor: "#f5f5f5",
  borderRadius: "6px",
  padding: "12px 24px",
  margin: "0 -12px",
});

export const AlertBox = styled(Box)({
  backgroundColor: "#ffdddd",
  borderRadius: "6px",
  padding: "12px",
  margin: "0",
});

const fullStyles: CSSObject = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const centerStyles: CSSObject = {
  display: "grid",
  placeItems: "center",
};

export const FullBox = styled(Box)(fullStyles);

export const FullCenterBox = styled(FullBox)(centerStyles);

export const FullStack = styled(Stack)(fullStyles);

export const FullGrid = styled(Grid)(fullStyles);

export const CenterBox = styled(Box)(centerStyles);

export const SplitRow = ({ left, right, sx }: { left: ReactNode; right: ReactNode; sx?: SxProps }) => (
  <Stack direction="row" justifyContent="space-between" sx={{ alignItems: "center", width: "100%", ...sx }}>
    {left}
    {right}
  </Stack>
);

export const GrowBox = styled(Box)({ height: 0, width: "100%", flex: "1 1 0%" });
