import { useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { ReportEditorParentSection } from "@parallel/vertex/types/report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { getParentSectionVisiblity } from "@parallel/vertex/util/report.util";
import ReportEditorBlock from "@/components/report/editor/ReportEditorBlock";
import ReportEditorSubsectionComponent from "@/components/report/editor/ReportEditorSubsection";
import SignatureInput from "@/components/report/editor/SignatureInput";
import GeneralInformationTable from "@/components/report/editor/content/GeneralInformationTable";
import InformationSourceTables from "@/components/report/editor/content/InformationSourceTables";
import RecommendationEditorSection from "@/components/report/editor/guidance/RecommendationEditorSection";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getParentSectionCopyContent } from "@/util/report.content.util";
import { useEditorAutoScroll } from "@/util/report.util";

const GENERAL_INFO_TITLES = ["Identifying Information", "General Information"];
const INFO_SOURCES_TITLE = "Information Sources";

const logger = initLogger("ReportEditorSectionComponent", getLoggerContext);

const ReportEditorSectionComponent = ({ section }: { section: ReportEditorParentSection }) => {
  const {
    authStore: { currentUser },
    reportStore: { currentReport, upsertCustomSection },
  } = useContext(StoreContext);

  const { containerRef } = useEditorAutoScroll(section.reportSectionTemplateId);

  const { isHidden: isAutoHidden, visibleSubsections = [] } =
    (currentReport && getParentSectionVisiblity(currentReport, section)) || {};

  const isHidden = isAutoHidden || section.custom?.isHidden;
  const canCopy = currentReport && !isHidden && ![...GENERAL_INFO_TITLES, INFO_SOURCES_TITLE].includes(section.title);
  const canToggleCustomHide = section.canHide && !isAutoHidden;

  const toggleCustomHide = () => {
    const isHidden = !section.custom?.isHidden;
    return upsertCustomSection(section.reportSectionTemplateId, { isHidden }).catch(
      logger.handleFailureAndThrow("setParentSectionHidden", { context: { isHidden } }),
    );
  };

  const controls = (
    <Stack direction="row" gap={1} pr="9px">
      {canToggleCustomHide && (
        <ProcessIconButton process={toggleCustomHide} size="small" loadingOnly>
          {section.custom?.isHidden ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
        </ProcessIconButton>
      )}
      {canCopy && (
        <CopyIconButton
          content={getParentSectionCopyContent(currentReport, section, currentUser)}
          label={`Section ${section.title}`}
          logger={logger}
        />
      )}
    </Stack>
  );

  return (
    <Stack width="100%" gap={3}>
      <Stack width="100%" gap={3} ref={containerRef}>
        <SplitRow
          left={
            <Typography variant="h2" sx={{ textDecoration: isHidden ? "line-through" : undefined }}>
              {section.title}
            </Typography>
          }
          right={controls}
        />

        {GENERAL_INFO_TITLES.includes(section.title) && <GeneralInformationTable />}
        {section.title === INFO_SOURCES_TITLE && <InformationSourceTables />}

        {!isHidden &&
          mapExists(
            section.children,
            child => child.type === "block" && <ReportEditorBlock block={child} key={child.reportBlockTemplateId} />,
          )}
      </Stack>

      {section.title === "Recommendations" &&
        mapExists(currentReport?.needGroups, group => (
          <RecommendationEditorSection needGroup={group} key={group.groupId} />
        ))}

      {visibleSubsections.map(subsection => (
        <ReportEditorSubsectionComponent subsection={subsection} key={subsection.reportSectionTemplateId} />
      ))}

      {section.title === "Signature" && <SignatureInput />}
    </Stack>
  );
};

export default observer(ReportEditorSectionComponent);
