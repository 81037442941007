import { useContext, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AssessmentBookRawData } from "@parallel/vertex/types/assessment.types";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { StoreContext } from "@/stores";

const StimulusBookForm = ({
  onCreated,
  onFinished,
}: {
  onCreated: (b: AssessmentBookRawData) => void;
  onFinished: () => void;
}) => {
  const {
    stimulusStore: { createBook },
  } = useContext(StoreContext);

  const [name, setName] = useState("");
  const [testPrefix, setTestPrefix] = useState("");

  const createRequest = name && testPrefix && { name, testPrefix };

  const onSubmit = async () => {
    if (!createRequest) return;
    const newBook = await createBook(createRequest);
    onCreated(newBook);
  };

  return (
    <PromptLayout
      headerText="Create New Stimulus Book"
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{ fn: onSubmit, icon: <SaveIcon />, label: "Submit", disabled: !createRequest }}
    >
      <Stack width={400} gap={2}>
        <TextField
          label="Full Name"
          size="small"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder={`e.g. "WJ Cognitive Std"`}
        />
        <TextField
          label="Test Prefix"
          size="small"
          value={testPrefix}
          onChange={e => setTestPrefix(e.target.value)}
          placeholder={`e.g. "WJCS"`}
        />
      </Stack>
    </PromptLayout>
  );
};

export default StimulusBookForm;
