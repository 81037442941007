import { UserType } from "@parallel/vertex/enums/user.enums";
import { toTitleCase } from "@parallel/vertex/util/string.util";

export const getUserTypeLabel = (userType: UserType): string => {
  switch (userType) {
    case "SITE_DIRECTOR":
      return "SpEd Admin";
    default:
      return toTitleCase(userType);
  }
};
