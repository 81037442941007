import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import { StyledMenu, StyledMenuItem } from "@/components/shared/display/menu";
import FilterSearchInput, { FilterOption, FilterProperty } from "@/components/shared/input/FilterSearchInput";
import ToggleMenuItem from "@/components/shared/input/ToggleMenuItem";
import { StoreContext } from "@/stores";
import { getUserTypeLabel } from "@/util/user.util";

const FILTER_PROPERTIES: Record<UserType, FilterProperty[]> = {
  STUDENT: [
    { propertyName: "Student Name/Email", searchParamKey: "keyword" },
    { propertyName: "Provider Name/Email", searchParamKey: "providerKeyword" },
    { propertyName: "Facilitator Name/Email", searchParamKey: "facilitatorKeyword" },
    { propertyName: "District/Campus Name", searchParamKey: "institutionKeyword" },
    { propertyName: "Service Line Name", searchParamKey: "serviceLineKeyword" },
  ],
  FACILITATOR: [
    { propertyName: "Facilitator Name/Email", searchParamKey: "keyword" },
    { propertyName: "District Name", searchParamKey: "districtName" },
  ],
  SITE_DIRECTOR: [
    { propertyName: "SpEd Admin Name/Email", searchParamKey: "keyword" },
    { propertyName: "District Name", searchParamKey: "districtName" },
  ],
  SLPA: [
    { propertyName: "SLPA Name/Email", searchParamKey: "keyword" },
    { propertyName: "District Name", searchParamKey: "districtName" },
  ],
  PROVIDER: [{ propertyName: "Provider Name/Email", searchParamKey: "keyword" }],
  ADMIN: [{ propertyName: "Internal User Name/Email", searchParamKey: "keyword" }],
};

export type CategoricalFilters = {
  showTestData: boolean;
  showArchivedStudents: boolean;
  filterUnassignedStudents: boolean;
};

const UserListFilter = ({
  listType,
  selectedFilters,
  setSelectedFilters,
  categoricalFilters,
  setCategoricalFilters,
  onAdd,
}: {
  listType: UserType;
  selectedFilters: FilterOption[];
  setSelectedFilters: (fs: FilterOption[]) => void;
  categoricalFilters: CategoricalFilters;
  setCategoricalFilters: (fs: CategoricalFilters) => void;
  onAdd?: () => void;
}) => {
  const navigate = useNavigate();

  const { authStore } = useContext(StoreContext);
  const { currentUser } = authStore;

  const [filterAnchor, setFilterAnchor] = useState<HTMLElement>();
  const [actionAnchor, setActionAnchor] = useState<HTMLElement>();

  const label = getUserTypeLabel(listType);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
      <Stack direction="row" gap={2.5}>
        <FilterSearchInput
          properties={FILTER_PROPERTIES[listType]}
          selectedOptions={selectedFilters}
          setSelectedOptions={setSelectedFilters}
          width={540}
        />
        {listType === "STUDENT" && hasRoleFlag(currentUser, "category-filters") && (
          <>
            <Button startIcon={<SettingsIcon />} onClick={e => setFilterAnchor(e.currentTarget)}>
              <Typography variant="body1" fontWeight="500">
                Settings
              </Typography>
            </Button>
            <StyledMenu anchorEl={filterAnchor} open={!!filterAnchor} onClose={() => setFilterAnchor(undefined)}>
              <ToggleMenuItem
                toggle={() =>
                  setCategoricalFilters({
                    ...categoricalFilters,
                    showTestData: !categoricalFilters.showTestData,
                  })
                }
                isEnabled={categoricalFilters.showTestData}
                text="Show Test Students"
                key="test-students"
              />
              <ToggleMenuItem
                toggle={() =>
                  setCategoricalFilters({
                    ...categoricalFilters,
                    showArchivedStudents: !categoricalFilters.showArchivedStudents,
                  })
                }
                isEnabled={categoricalFilters.showArchivedStudents}
                text="Show Archived Students"
                key="archived-students"
              />
              {hasRoleFlag(currentUser, "view-unassigned") && [
                <Divider key="divider" sx={{ marginY: 1 }} />,
                <ToggleMenuItem
                  toggle={() =>
                    setCategoricalFilters({
                      ...categoricalFilters,
                      filterUnassignedStudents: !categoricalFilters.filterUnassignedStudents,
                    })
                  }
                  isEnabled={categoricalFilters.filterUnassignedStudents}
                  text="Reveal Unassigned Students"
                  key="unassigned-students"
                />,
              ]}
            </StyledMenu>
          </>
        )}
      </Stack>

      <Stack direction="row" gap={2}>
        {onAdd && (
          <Button onClick={onAdd} startIcon={<AddIcon />} size="medium" sx={{ height: 40 }}>
            <Typography variant="body1" fontWeight="500">
              Add {label}
            </Typography>
          </Button>
        )}

        {listType === "STUDENT" && hasRoleFlag(currentUser, "bulk-create") && (
          <>
            <Button endIcon={<KeyboardArrowDownIcon />} onClick={e => setActionAnchor(e.currentTarget)}>
              <Typography variant="body1" fontWeight="500">
                More
              </Typography>
            </Button>
            <StyledMenu anchorEl={actionAnchor} open={!!actionAnchor} onClose={() => setActionAnchor(undefined)}>
              <StyledMenuItem onClick={() => navigate("/user/student/bulk-create")}>
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText>Bulk Add Students</ListItemText>
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default UserListFilter;
