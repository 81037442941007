import { useContext, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useMountEffect } from "@react-hookz/web";
import { FullBox, FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { ExtendedReportTemplate } from "@parallel/vertex/types/report.template.types";
import ReportEditor from "@/components/report/editor/ReportEditor";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportTemplateScreen", getLoggerContext);

const TEMPLATE_IDS: Record<string, string> = {
  psy: "108cb639-31e0-4682-81fa-a957109a98db",
  slp: "00791a3a-cf06-4844-a986-1257c5c5f43e",
};

const ReportTemplateScreen = () => {
  const {
    apiStore: { reportTemplateApi },
  } = useContext(StoreContext);

  const { templateKey: templateKeyParam } = useParams();
  const templateId = (templateKeyParam && TEMPLATE_IDS[templateKeyParam]) || templateKeyParam;

  const [template, setTemplate] = useState<ExtendedReportTemplate>();

  const loadTemplate = async () => {
    if (!templateId) return;
    return reportTemplateApi
      .getReportTemplate(templateId)
      .then(setTemplate)
      .catch(e => {
        logger.logFailure("getTemplateUrl", e);
        toast.error("Template fetch failed");
      });
  };
  useMountEffect(loadTemplate);

  if (!template) return <LoadingScreen />;

  return (
    <PrimaryLayout>
      <FullCenterBox>
        <FullBox height="100%" maxWidth={1200} p={2}>
          <ReportEditor title={template.title} editorSections={template.editorSections} />
        </FullBox>
      </FullCenterBox>
    </PrimaryLayout>
  );
};

export default ReportTemplateScreen;
