import { useContext, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { keyBy, sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { CenterBox } from "@parallel/polygon/components/shared/layout/container";
import { AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import ConfigGroupForm from "@/components/stimulus/ConfigGroupForm";
import { StoreContext } from "@/stores";
import { TestGroup } from "@/stores/stimulus.store";

const StyledLink = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": { textDecoration: "underline" },
});

const TestGroupDetails = ({ group, allTests }: { group: TestGroup; allTests: AssessmentTestData[] }) => {
  const {
    stimulusStore: { selectedDisplaySubgroup, setSelectedSubgroupId, createDisplaySubgroup },
  } = useContext(StoreContext);

  const testsById = keyBy(allTests, "id");
  // TODO figure out a good way to ensure that deleted tests are removed from groups (so we don't have to make this check for non-existence) (https://parallellearning.atlassian.net/browse/ENG-946)

  const testIds = selectedDisplaySubgroup?.testIds || group.testIds;
  const tests = sortBy(
    testIds.map(t => testsById[t]).filter(t => !!t),
    "orderIndex",
  );

  const [newSubgroupName, setNewSubgroupName] = useState("");

  const submitSubgroup = async () => {
    if (group.type !== "display") return;
    await createDisplaySubgroup(group, newSubgroupName);
    setNewSubgroupName("");
  };

  const header = selectedDisplaySubgroup ? (
    <Stack direction="row" gap={0.5}>
      <StyledLink onClick={() => setSelectedSubgroupId(undefined)}>
        <Typography variant="h3">{group.name}</Typography>
      </StyledLink>
      <Typography variant="h3">/</Typography>
      <Typography variant="h3">{selectedDisplaySubgroup.name}</Typography>
    </Stack>
  ) : (
    <Typography variant="h3">{group.name}</Typography>
  );

  return (
    <Stack gap={2} sx={{ p: 2, bgcolor: "white", border: 1, borderColor: "grey.300", borderRadius: 1 }}>
      {header}
      <Stack gap={0.5}>
        <Typography variant="subtitle2">Member Tests</Typography>
        <Stack>
          {tests.map(t => (
            <Typography variant="body2" key={t.id}>
              {t.displayName || t.name || t.id}
            </Typography>
          ))}
        </Stack>
      </Stack>
      {group.type === "display" && !selectedDisplaySubgroup && (
        <Stack gap={0.5}>
          <Typography variant="subtitle2">Subgroups</Typography>
          <Stack>
            {group.subgroups?.map(({ id, name }, i) => (
              <StyledLink
                onClick={() => setSelectedSubgroupId(id)}
                sx={{ cursor: "pointer", textDecoration: "none" }}
                key={i}
              >
                <Typography variant="body2">{name}</Typography>
              </StyledLink>
            ))}
            <Stack direction="row" gap={1} pt={1.5}>
              <TextField
                label="New Subgroup Name"
                size="small"
                value={newSubgroupName}
                onChange={e => setNewSubgroupName(e.target.value)}
                fullWidth
              />
              <CenterBox sx={{ flexShrink: 0 }}>
                <ProcessIconButton size="small" process={submitSubgroup} disabled={!newSubgroupName}>
                  <SaveIcon fontSize="small" />
                </ProcessIconButton>
              </CenterBox>
            </Stack>
          </Stack>
        </Stack>
      )}
      {group.type === "config" && <ConfigGroupForm group={group} />}
    </Stack>
  );
};

export default observer(TestGroupDetails);
