import { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, CategoryScale, TimeScale } from "chart.js";
import "chartjs-adapter-luxon";
import { isEmpty, isNumber, last, sortBy } from "lodash";
import { DateTime } from "luxon";
import { percentString, PRIMARY_MAIN_HEX } from "@parallel/polygon/util/style.util";
import { SingleStudentAppointmentProgress, StudentObjective } from "@parallel/vertex/types/progress.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { secondsToString } from "@parallel/vertex/util/number.util";
import { getObjectiveNoun } from "@parallel/vertex/util/progress.util";
import { StoreContext } from "@/stores";

ChartJS.register(CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Tooltip);

const StudentObjectiveMetricChart = ({
  appointments,
  objective,
  goalId,
}: {
  appointments: SingleStudentAppointmentProgress[];
  objective: StudentObjective;
  goalId: string;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const { objectiveId } = objective;

  if (appointments.length < 2 || objective.category === "ANECDOTAL") return <></>;

  const orderedProgress = sortBy(appointments, a => a.appointment.startTime.toMillis());
  const data = mapExists(orderedProgress, p => {
    const goal = p.goals.find(g => g.goalId === goalId);
    const objective = goal?.objectives.find(o => o.objectiveId === objectiveId);

    if (objective?.metric?.type !== "number") return;

    return { x: toLocalDate(p.appointment.startTime, timezone), y: objective.metric.value };
  });

  if (isEmpty(data)) return <></>;

  const getValueString = (value: string | number) => {
    const numberValue = isNumber(value) ? value : parseInt(value);
    if (objective.category === "DURATION") return secondsToString(numberValue);
    if (objective.category === "ACCURACY") return percentString(numberValue);
    return value.toString();
  };

  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        borderColor: PRIMARY_MAIN_HEX,
        scales: {
          y: {
            title: {
              display: true,
              text: getObjectiveNoun(objective),
            },
            min: 0,
            ticks: {
              callback: getValueString,
            },
          },
          x: {
            type: "time",
            min: data[0].x.toMillis(),
            max: last(data)?.x.toMillis(),
            ticks: {
              maxTicksLimit: 20,
            },
            time: {
              unit: "day",
            },
          },
        },
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              title: context => DateTime.fromMillis(context[0].parsed.x).toFormat("d LLL y"),
              label: ({ dataset: { label }, parsed: { y } }) => `${label}: ${getValueString(y)}`,
            },
          },
        },
        clip: 10,
      }}
      data={{
        datasets: [
          {
            label: getObjectiveNoun(objective),
            data,
            pointRadius: 6,
            pointBackgroundColor: PRIMARY_MAIN_HEX,
            pointBorderWidth: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: PRIMARY_MAIN_HEX,
            tension: 0.5,
          },
        ],
      }}
    />
  );
};

export default StudentObjectiveMetricChart;
