import { z } from "zod";
import { paginateQueryFields, sortQueryFields } from "../shared.types";
import { ExtendedUser } from "./user.types";

export type ExtendedSlpaUser = ExtendedUser & {
  districtId?: string;
  districtName?: string;
  supervisorIds: string[];
  supervisorNames: string[];
};

export const searchSlpaQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  campusId: z.string().uuid().optional(),
  districtName: z.string().trim().optional(),
  providerId: z.string().uuid().optional(),
  districtUserId: z.string().uuid().optional(),
  supervisorIds: z.string().uuid().optional(),
  eligibleStudentIds: z.string().uuid().array().optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchSlpaQuery = z.infer<typeof searchSlpaQuerySchema>;

export const createSlpaBodySchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email().trim().toLowerCase(),
  districtId: z.string().uuid(),
  supervisorIds: z.string().uuid().array().min(1),
  sendWelcomeEmail: z.boolean().optional(),
});

export type CreateSlpaBody = z.infer<typeof createSlpaBodySchema>;

export const updateSlpaBodySchema = createSlpaBodySchema.partial();

export type UpdateSlpaBody = z.infer<typeof updateSlpaBodySchema>;
