import type { TaskTypeCategory } from "@prisma/client";
import { z } from "zod";

export type { AppointmentStatus, RecurrenceUnit, DayOfWeek } from "@prisma/client";

export const CANCEL_STATUSES = ["CANCELLED", "LATE_CANCELLED"] as const;
export type CancelStatus = (typeof CANCEL_STATUSES)[number];

type TaskTypeGroupConfig = {
  title: string;
  student?: "required" | "single-optional";
  campus?: "required";
};

export const TYPE_CATEGORY_CONFIG: Record<TaskTypeCategory, TaskTypeGroupConfig> = {
  ASSESSMENT: {
    title: "Assessments and Evaluations",
    student: "required",
  },
  CASE_MANAGEMENT: {
    title: "Case Management",
    student: "required",
  },
  PARALLEL_ACTIVITY: {
    title: "Parallel Activities",
  },
  SCHOOL_MEETING: {
    title: "School Meetings",
    campus: "required",
  },
  THERAPY: {
    title: "Session Delivery",
    student: "single-optional",
    campus: "required",
  },
};

export const recurrenceEditModeEnum = z.enum(["single", "future"]);
export type RecurrenceEditMode = z.infer<typeof recurrenceEditModeEnum>;
