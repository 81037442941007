import { UserType } from "@prisma/client";
import { ExtendedReport } from "../types/report.types";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type ReportRoleFlag = "download-protocols";

export const REPORT_ROLE_FLAG_GRANTS: Record<ReportRoleFlag, UserType[]> = {
  "download-protocols": ["ADMIN", "PROVIDER"],
};

export type ReportPermissions = {
  accessLevels: AccessLevel[];
  allowIf: (report: ExtendedReport, userId: string) => boolean;
  defaults?: { provider: "currentUser" };
};

const REPORT_PERMISSION_GRANTS: Partial<Record<UserType, ReportPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (report, userId) => report.providerId === userId,
    defaults: { provider: "currentUser" },
  },
};

export const getReportPermissions = getDataPermissionResolver(REPORT_PERMISSION_GRANTS);
