import { keyBy, last, max, orderBy, set, sortBy, uniq } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";
import {
  AssessmentBookMetadata,
  AssessmentBookRawData,
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
  BaseDisplayGroup,
  UpdateAssessmentTest,
} from "@parallel/vertex/types/assessment.types";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import { StimulusAPI } from "@/api/stimulus.api";

export type NamedAssessmentBookMetadata = AssessmentBookMetadata & { name: string };

export type ConfigGroup = AssessmentConfigGroupData;
export type DisplayGroup = BaseDisplayGroup & { id: string; subgroups?: DisplayGroup[] };
export type TestGroup = ({ type: "display" } & DisplayGroup) | ({ type: "config" } & ConfigGroup);

export type GroupType = "display" | "config";

type GroupSelection = {
  groupId: string;
  subgroupId?: string;
  groupType: GroupType;
};

export class StimulusStore {
  books?: AssessmentBookRawData[];
  activeBook?: NamedAssessmentBookMetadata;

  groupSelection?: GroupSelection = undefined;

  constructor(private stimulusApi: StimulusAPI) {
    makeAutoObservable(this);
  }

  get orderedParentDisplayGroups(): DisplayGroup[] {
    return sortBy(this.activeBook?.displayGroups, "orderIndex").map(this.parseDisplayGroupData);
  }

  get flatDisplayGroups(): DisplayGroup[] {
    return this.orderedParentDisplayGroups.flatMap(g => [g, ...(g.subgroups || [])]);
  }

  get displayGroupsByTestId(): Record<string, DisplayGroup> {
    return this.flatDisplayGroups.reduce<Record<string, DisplayGroup>>(
      (curr, g) => ({
        ...curr,
        ...g.testIds.reduce<Record<string, DisplayGroup>>((ids, tid) => ({ ...ids, [tid]: g }), {}),
      }),
      {},
    );
  }

  get orderedConfigGroups(): ConfigGroup[] {
    return sortBy(this.activeBook?.configGroups, "orderIndex");
  }

  get configGroupsByTestId(): Record<string, ConfigGroup> {
    return this.orderedConfigGroups.reduce<Record<string, AssessmentConfigGroupData>>(
      (curr, g) => ({
        ...curr,
        ...g.testIds.reduce<Record<string, AssessmentConfigGroupData>>((ids, tid) => ({ ...ids, [tid]: g }), {}),
      }),
      {},
    );
  }

  get testGroupsById(): Record<string, TestGroup> {
    const allGroups = [
      ...this.flatDisplayGroups.map(g => ({ type: "display", ...g }) as TestGroup),
      ...this.orderedConfigGroups.map(g => ({ type: "config", ...g }) as TestGroup),
    ];
    return keyBy(allGroups, "id");
  }

  private findGroup(groupId?: string): TestGroup | undefined {
    if (!groupId) return;
    return this.testGroupsById[groupId];
  }

  get selectedRootGroup(): TestGroup | undefined {
    return this.findGroup(this.groupSelection?.groupId);
  }

  get selectedDisplaySubgroup(): DisplayGroup | undefined {
    const parent = this.selectedRootGroup;
    if (parent?.type !== "display") return;

    return parent.subgroups?.find(g => g.id === this.groupSelection?.subgroupId);
  }

  public parseDisplayGroupData = (group: AssessmentDisplayGroupData): DisplayGroup => ({
    ...group,
    subgroups: mapExists(group.subgroups, (g, i) => ({ ...g, id: `${group.id}.${i}`, subgroups: undefined })),
  });

  public selectRootGroup = (groupId: string, groupType: GroupType) => {
    this.groupSelection = { groupId, groupType };
  };

  public setSelectedSubgroupId = (subgroupId: string | undefined) => {
    if (!this.groupSelection) return;
    this.groupSelection = { ...this.groupSelection, subgroupId };
  };

  public fetchRawBooks = async () => {
    const books = await this.stimulusApi.getAllRawBooks();
    runInAction(() => (this.books = books));
    return books;
  };

  public setBookHidden = async (bookId: string, isHidden: boolean) => {
    const updatedBook = await this.stimulusApi.setBookHidden(bookId, isHidden);
    runInAction(() => (this.books = this.books?.map(b => (b.id === bookId ? updatedBook : b))));
    return updatedBook;
  };

  public selectBook = async (bookId: string) => {
    const allBooks = this.books || (await this.fetchRawBooks());
    const rawBook = allBooks.find(b => b.id === bookId);
    if (!rawBook) return;
    const metadata = await this.stimulusApi.getBookMetadata(bookId);
    runInAction(() => (this.activeBook = { ...metadata, name: rawBook.name }));
  };

  public createBook = async (request: { name: string; testPrefix: string }) => {
    const maxOrder = max(mapExists(this.books, b => b.orderIndex)) || 0;
    const createdBook = await this.stimulusApi.createBook({ ...request, id: uuid(), orderIndex: maxOrder + 1 });

    runInAction(() => this.books?.push(createdBook));

    return createdBook;
  };

  public updateTests = async (updates: UpdateAssessmentTest[]) => {
    return this.updateBook(({ bookId }) => this.stimulusApi.updateTests(bookId, updates));
  };

  public updateTest = async (update: UpdateAssessmentTest) => {
    if (!this.activeBook) return;
    const updatedTests = this.activeBook.tests.map(t => (t.id === update.id ? { ...t, ...update } : t));
    this.activeBook = {
      ...this.activeBook,
      tests: updatedTests,
    };
    return this.stimulusApi.updateTests(this.activeBook.bookId, [update]);
  };

  public deleteTest = async (testId: string) => {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteTest(bookId, testId));
  };

  public updateGroupName = (groupId: string, groupType: GroupType, name: string) => {
    switch (groupType) {
      case "display":
        return this.updateDisplayGroup(groupId, { name });
      case "config":
        return this.updateConfigGroup(groupId, { name });
    }
  };

  public swapGroupOrders = async (
    groupA: { id: string; index: number },
    groupB: { id: string; index: number },
    groupType: GroupType,
  ) => {
    const book = this.activeBook;
    if (!book) return;

    const performUpdates = () => {
      switch (groupType) {
        case "display":
          return Promise.all([
            this.stimulusApi.updateDisplayGroup(book.bookId, groupA.id, { orderIndex: groupB.index }),
            this.stimulusApi.updateDisplayGroup(book.bookId, groupB.id, { orderIndex: groupA.index }),
          ]);
        case "config":
          return Promise.all([
            this.stimulusApi.updateConfigGroup(book.bookId, groupA.id, { orderIndex: groupB.index }),
            this.stimulusApi.updateConfigGroup(book.bookId, groupB.id, { orderIndex: groupA.index }),
          ]);
      }
    };
    const [updatedA, updatedB] = await performUpdates();

    runInAction(() => {
      if (!this.activeBook) return;
      set(this.activeBook, `${groupType}Groups[${groupB.index}]`, updatedA);
      set(this.activeBook, `${groupType}Groups[${groupA.index}]`, updatedB);
    });
  };

  public deleteGroup = (groupId: string, groupType: GroupType) => {
    switch (groupType) {
      case "display":
        return this.deleteDisplayGroup(groupId);
      case "config":
        return this.deleteConfigGroup(groupId);
    }
  };

  public createGroup = (name: string, groupType: GroupType) => {
    switch (groupType) {
      case "display":
        return this.createDisplayGroup(name);
      case "config":
        return this.createConfigGroup(name);
    }
  };

  public createDisplayGroup = async (name: string): Promise<AssessmentDisplayGroupData | undefined> => {
    return this.updateBookGroups(
      ({ bookId, displayGroups }) => {
        const orderIndex = (max(displayGroups.map(g => g.orderIndex)) || -1) + 1;
        return this.stimulusApi.createDisplayGroup(bookId, { name, orderIndex });
      },
      (currBook, newGroup) => ({ displayGroups: [...currBook.displayGroups, newGroup] }),
    );
  };

  public createDisplaySubgroup = (parentGroup: DisplayGroup, name: string) => {
    const currSubgroups = parentGroup.subgroups || [];
    const orderIndex = (last(currSubgroups)?.orderIndex ?? -1) + 1;
    const newSubgroup = {
      id: `${parentGroup.id}.${orderIndex}`,
      name,
      orderIndex,
      testIds: [],
    };
    return this.updateDisplayGroup(parentGroup.id, { subgroups: [...currSubgroups, newSubgroup] });
  };

  public createConfigGroup = async (name: string): Promise<AssessmentConfigGroupData | undefined> => {
    return this.updateBookGroups(
      ({ bookId, configGroups }) => {
        const orderIndex = (max(configGroups.map(g => g.orderIndex)) || -1) + 1;
        return this.stimulusApi.createConfigGroup(bookId, { name, orderIndex });
      },
      (currBook, newGroup) => ({ configGroups: [...currBook.configGroups, newGroup] }),
    );
  };

  public updateDisplayGroup = async (
    groupId: string,
    update: Partial<AssessmentDisplayGroupData>,
  ): Promise<AssessmentDisplayGroupData | undefined> => {
    return this.updateBookGroups(
      ({ bookId }) => this.stimulusApi.updateDisplayGroup(bookId, groupId, update),
      (currBook, updatedGroup) => ({
        displayGroups: orderBy(
          currBook.displayGroups.map(g => (g.id === groupId ? updatedGroup : g)),
          "orderIndex",
        ),
      }),
    );
  };

  public updateConfigGroup = async (
    groupId: string,
    update: Partial<AssessmentConfigGroupData>,
  ): Promise<AssessmentConfigGroupData | undefined> => {
    return this.updateBookGroups(
      ({ bookId }) => this.stimulusApi.updateConfigGroup(bookId, groupId, update),
      (currBook, updatedGroup) => ({
        configGroups: orderBy(
          currBook.configGroups.map(g => (g.id === groupId ? updatedGroup : g)),
          "orderIndex",
        ),
      }),
    );
  };

  public assignTestToConfigGroupId = async (testId: string, addGroupId: string): Promise<void> => {
    const addGroup = this.orderedConfigGroups.find(g => g.id === addGroupId);
    if (!addGroup) return;
    return this.assignTestToConfigGroup(testId, addGroup, this.configGroupsByTestId[testId]);
  };

  public assignTestToDisplayGroupId = async (testId: string, addGroupId: string): Promise<void> => {
    const addGroup = this.flatDisplayGroups.find(g => g.id === addGroupId);
    if (!addGroup) return;
    return this.assignTestToDisplayGroup(testId, addGroup, this.displayGroupsByTestId[testId]);
  };

  private assignTestToConfigGroup = async (
    testId: string,
    addGroup: ConfigGroup,
    removeGroup?: ConfigGroup,
  ): Promise<void> => {
    this.updateBook(async currBook => {
      const { bookId, configGroups } = currBook;
      const updatedGroups = await Promise.all([
        this.stimulusApi.updateConfigGroup(bookId, addGroup.id, { testIds: uniq([...addGroup.testIds, testId]) }),
        removeGroup &&
          this.stimulusApi.updateConfigGroup(bookId, removeGroup.id, {
            testIds: removeGroup.testIds.filter(id => id !== testId),
          }),
      ]).then(filterExists);
      return {
        ...currBook,
        configGroups: configGroups.map(g => updatedGroups.find(u => u.id === g.id) || g),
      };
    });
  };

  private findDisplayGroupParent = (group: DisplayGroup): DisplayGroup | undefined =>
    this.orderedParentDisplayGroups.find(g => g.subgroups?.some(s => s.id === group.id));

  private assignTestToDisplayGroup = async (
    testId: string,
    addGroup: DisplayGroup,
    removeGroup?: DisplayGroup,
  ): Promise<void> => {
    this.updateBook(async currBook => {
      const { bookId, displayGroups } = currBook;

      const addUpdate = { testIds: uniq([...addGroup.testIds, testId]) };
      const addParent = this.findDisplayGroupParent(addGroup);
      const addResult = !addParent
        ? await this.stimulusApi.updateDisplayGroup(bookId, addGroup.id, addUpdate)
        : await this.stimulusApi.updateDisplayGroup(bookId, addParent.id, {
            subgroups: mapExists(addParent.subgroups, g => (g.name === addGroup.name ? { ...g, ...addUpdate } : g)),
          });

      let removeResult: AssessmentDisplayGroupData | null = null;
      if (removeGroup) {
        const removeUpdate = { testIds: removeGroup.testIds.filter(id => id !== testId) };
        const removeParent = this.findDisplayGroupParent(removeGroup);
        removeResult = !removeParent
          ? await this.stimulusApi.updateDisplayGroup(bookId, removeGroup.id, removeUpdate)
          : await this.stimulusApi.updateDisplayGroup(bookId, removeParent.id, {
              subgroups: mapExists(removeParent.subgroups, g =>
                g.name === removeGroup.name ? { ...g, ...removeUpdate } : g,
              ),
            });
      }

      const updatedGroups = filterExists([removeResult, addResult]).map(this.parseDisplayGroupData);
      return {
        ...currBook,
        displayGroups: displayGroups.map(g => updatedGroups.find(u => u.id === g.id) || g),
      };
    });
  };

  public deleteDisplayGroup = async (groupId: string) => {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteDisplayGroup(bookId, groupId));
  };

  public deleteConfigGroup = async (groupId: string) => {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteConfigGroup(bookId, groupId));
  };

  private updateBook = async (fn: (currBook: AssessmentBookMetadata) => Promise<AssessmentBookMetadata>) => {
    if (!this.activeBook) return;
    const currBook = this.activeBook;
    const updatedBook = await fn(currBook);
    runInAction(() => (this.activeBook = { ...currBook, ...updatedBook }));
    return updatedBook;
  };

  private updateBookGroups = async <G>(
    fn: (currBook: AssessmentBookMetadata) => Promise<G>,
    updateBook: (currBook: AssessmentBookMetadata, result: G) => Partial<AssessmentBookMetadata>,
  ): Promise<G | undefined> => {
    if (!this.activeBook) return;
    const currBook = this.activeBook;
    const result = await fn(currBook);
    runInAction(() => (this.activeBook = { ...currBook, ...updateBook(currBook, result) }));
    return result;
  };
}
