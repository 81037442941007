import { useContext, useState } from "react";
import { DateTime } from "luxon";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { hasRoleFlag } from "@parallel/vertex/role";
import { ApprovalType } from "@parallel/vertex/types/approval.types";
import { searchAppointmentsQuerySchema } from "@parallel/vertex/types/calendar/appointment.types";
import { searchIndirectTimeSchema } from "@parallel/vertex/types/calendar/time.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import AppointmentDataTable from "@/components/calendar/approval/AppointmentDataTable";
import ApprovalDataFilters, { SearchParams, SettingsFilters } from "@/components/calendar/approval/ApprovalDataFilters";
import ApprovalListHeader from "@/components/calendar/approval/ApprovalListHeader";
import TimeDataTable from "@/components/calendar/approval/TimeDataTable";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const ApprovalScreen = ({ approvalType }: { approvalType: ApprovalType }) => {
  const {
    authStore: { currentUser, timezone },
    calendarStore: { previousPayPeriod },
  } = useContext(StoreContext);

  const today = DateTime.local({ zone: timezone });

  const [searchParams, setSearchParams] = useState<SearchParams>({
    provider: null,
    startDate: previousPayPeriod?.startTime ?? today.minus({ days: 7 }),
    endDate: previousPayPeriod?.endTime ?? today,
    approvalStatus: null,
    appointmentType: null,
    taskType: null,
  });

  const [settingsFilters, setSettingsFilters] = useState<SettingsFilters>({ showTaskDescription: false });

  const searchAppointmentsQuery = searchAppointmentsQuerySchema.safeParse({
    providerId: searchParams.provider ? searchParams.provider.key : undefined,
    startTime: searchParams.startDate ? searchParams.startDate.toUTC().toISO() : null,
    endTime: searchParams.endDate ? searchParams.endDate.endOf("day").toUTC().toISO() : null,
    approvalStatus: searchParams.approvalStatus?.key,
    appointmentTypeIds: searchParams.appointmentType ? [searchParams.appointmentType.appointmentTypeId] : undefined,
  })?.data;

  const searchTimeEntriesQuery = searchIndirectTimeSchema.safeParse({
    userIds: searchParams.provider ? [searchParams.provider.key] : undefined,
    startTime: searchParams.startDate ? toLocalDate(searchParams.startDate, timezone) : null,
    endTime: searchParams.endDate ? toLocalDate(searchParams.endDate, timezone).endOf("day") : null,
    approvalStatus: searchParams.approvalStatus?.key,
    taskTypeId: searchParams.taskType?.taskTypeId,
  })?.data;

  if (!hasRoleFlag(currentUser, "manage-approvals")) return <NotFoundScreen />;

  return (
    <PrimaryLayout headerContent={<ApprovalListHeader approvalType={approvalType} />}>
      <FullStack>
        <ApprovalDataFilters
          approvalType={approvalType}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          settingsFilters={settingsFilters}
          setSettingsFilters={setSettingsFilters}
        />

        {approvalType === "TIME" ? (
          <TimeDataTable searchQuery={searchTimeEntriesQuery} settingsFilters={settingsFilters} />
        ) : (
          <AppointmentDataTable searchQuery={searchAppointmentsQuery} />
        )}
      </FullStack>
    </PrimaryLayout>
  );
};

export default ApprovalScreen;
