import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Button from "@mui/material/Button";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { StoreContext } from "@/stores";

const GoalActions = ({
  goal,
  displayIndex,
  onEdit,
  logger,
}: {
  goal: StudentGoal;
  displayIndex: number;
  onEdit: () => void;
  logger: ClientLogger;
}) => {
  const {
    progressStore: { startPendingUpdate, setGoalCompleted, setGoalArchived },
  } = useContext(StoreContext);

  const reOpenGoal = () =>
    setGoalCompleted({ goal, isCompleted: false }).catch(logger.handleFailureAndThrow("setGoalCompleted"));

  const unarchiveGoal = () =>
    setGoalArchived({ goal, isArchived: false }).catch(logger.handleFailureAndThrow("setGoalArchived"));

  const isCompleted = !!goal.completedAt;
  return (
    <>
      {!goal.isArchived ? (
        <>
          <Button
            onClick={() => startPendingUpdate("archive", { record: goal, displayIndex })}
            startIcon={<ArchiveIcon />}
          >
            Archive
          </Button>
          {isCompleted ? (
            <ProcessButton process={reOpenGoal} startIcon={<LockOpenIcon />} key="re-open">
              Re-Open
            </ProcessButton>
          ) : (
            <>
              <Button
                onClick={() => startPendingUpdate("complete", { record: goal, displayIndex })}
                startIcon={<CheckCircleOutlineIcon />}
              >
                Achieved
              </Button>
              <Button onClick={onEdit} startIcon={<EditIcon />}>
                Edit
              </Button>
            </>
          )}
        </>
      ) : (
        <ProcessButton process={unarchiveGoal} startIcon={<UnarchiveIcon />} key="unarchive">
          Unarchive
        </ProcessButton>
      )}
    </>
  );
};

export default GoalActions;
