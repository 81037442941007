import { isEmpty, isEqual, isNull, max, min, pick } from "lodash";
import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import { UserTypeValues } from "@parallel/vertex/enums/prisma.enums";
import { hasRoleFlag } from "@parallel/vertex/role";
import {
  CreateAppointmentBody,
  ExtendedAppointment,
  UpdateAppointmentBody,
  UpdateAppointmentUserBody,
} from "@parallel/vertex/types/calendar/appointment.types";
import {
  CreateCalendarBlockBody,
  ExtendedCalendarBlock,
  UpdateCalendarBlockBody,
} from "@parallel/vertex/types/calendar/calendar.block.types";
import {
  CalendarItems,
  CalendarRelation,
  CalendarRelationType,
  SearchCalendarQuery,
} from "@parallel/vertex/types/calendar/calendar.types";
import {
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  UpdateIndirectTimeBody,
} from "@parallel/vertex/types/calendar/time.types";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { isConsultAppointment, isProgressAppointment } from "@parallel/vertex/util/appointment.util";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import { isInRange, isWithin } from "@parallel/vertex/util/datetime.util";
import { roundToDecimal } from "@parallel/vertex/util/number.util";
import { CalendarAPI } from "@/api/calendar.api";
import { InstitutionAPI } from "@/api/institution.api";
import { UserAPI } from "@/api/user.api";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import { FilterOption } from "@/components/shared/input/FilterSearchInput";
import { UserOption } from "@/components/user/input/UserInput";
import config from "@/config";
import { AuthStore } from "@/stores/auth.store";
import { DEFAULT_MAX_CALENDAR_HOUR, DEFAULT_MIN_CALENDAR_HOUR } from "@/util/calendar.util";
import { FetchStatus } from "@/util/shared.util";

export const CALENDAR_VIEW_TYPES = ["day", "week", "list"] as const;
export type CalendarViewType = (typeof CALENDAR_VIEW_TYPES)[number];

export const ITEM_KEYS = ["appointments", "indirectTime", "blocks"] as const;
export type CalendarItemKey = (typeof ITEM_KEYS)[number];

export const TYPE_FILTERS = [
  "appointments",
  "indirectTime",
  "availabilities",
  "focusTime",
  "consultAppointments",
  "progressMeetings",
] as const;
export type CalendarTypeFilter = (typeof TYPE_FILTERS)[number];

export const APPOINTMENT_KEYWORD_FILTERS = ["student", "provider", "facilitator", "campus", "appointmentType"] as const;
export type AppointmentKeywordFilter = (typeof APPOINTMENT_KEYWORD_FILTERS)[number];

type CalendarView = {
  type?: CalendarViewType;
  startTime: DateTime;
  endTime: DateTime;
};

export type CalendarItemType = "appointment" | "block" | "indirect-time";

type CalendarItemSelection = { itemId: string; itemType: CalendarItemType };

export class CalendarStore {
  public currentPayPeriod?: DateTimeRange;
  public previousPayPeriod?: DateTimeRange;

  public currentView: CalendarView;
  public maybeCalendarItems?: CalendarItems;
  public fetchStatus: FetchStatus = "waiting";

  public partialTypeFilters: Partial<Record<CalendarTypeFilter, boolean>> = {
    appointments: true,
    availabilities: true,
    focusTime: true,
    indirectTime: true,
    consultAppointments: undefined,
    progressMeetings: undefined,
  };
  public isShowingCancelled = true;
  public isShowingWeekends = false;

  public appointmentKeywordFilters: { filter: AppointmentKeywordFilter; value: string }[] = [];

  public currentSelection?: CalendarItemSelection;

  public selectedRelationFilter?: CalendarRelation = undefined;

  public zoomFactor = 2.71;

  constructor(
    private authStore: AuthStore,
    private calendarApi: CalendarAPI,
    private institutionApi: InstitutionAPI,
    private userApi: UserAPI,
    private logger: ClientLogger,
  ) {
    this.currentView = this.createView(undefined);
    makeAutoObservable(this);
    makePersistable(this, {
      name: "CalendarStore",
      properties: ["currentView", "partialTypeFilters", "isShowingCancelled", "isShowingWeekends"],
      storage: window.localStorage,
    });
  }

  get viewType(): CalendarViewType {
    if (this.currentView?.type) return this.currentView.type;
    return this.authStore.currentUser?.userType === "SITE_DIRECTOR" ? "list" : "day";
  }

  get period(): DateTimeRange {
    return pick(this.currentView, "startTime", "endTime");
  }

  get typeFilters(): Record<CalendarTypeFilter, boolean> {
    const customFiltersDefaultOn = hasRoleFlag(this.authStore.currentUser, "calendar-custom-filtersDefaultOn");
    return {
      consultAppointments: customFiltersDefaultOn,
      progressMeetings: customFiltersDefaultOn,
      appointments: true,
      availabilities: true,
      focusTime: true,
      indirectTime: true,
      ...this.partialTypeFilters,
    };
  }

  get calendarItems(): CalendarItems {
    if (!this.maybeCalendarItems) return { appointments: [], blocks: [], indirectTime: [] };

    const blockTypes = filterExists([
      this.typeFilters.availabilities && "Availability",
      this.typeFilters.focusTime && "FocusTime",
    ]);

    return {
      appointments: this.filteredAppointments,
      blocks: this.maybeCalendarItems.blocks.filter(b => blockTypes.includes(b.calendarBlockType)),
      indirectTime: this.typeFilters.indirectTime ? this.maybeCalendarItems.indirectTime : [],
    };
  }

  get filteredAppointments() {
    if (!this.maybeCalendarItems) return [];
    if (!this.typeFilters.appointments && this.currentView.type !== "list") return [];
    return this.maybeCalendarItems.appointments.filter(a => {
      const filterCancelled =
        !this.isShowingCancelled && ["CANCELLED", "LATE_CANCELLED"].includes(a.appointmentStatus || "");

      const filterConsult = !this.typeFilters && isConsultAppointment(a);

      const filterProgress = !this.typeFilters.progressMeetings && isProgressAppointment(a, config);

      const keywordsMatchFilter =
        isEmpty(this.appointmentKeywordFilters) ||
        this.appointmentKeywordFilters.some(f => this.doesAppointmentMatchFilter(a, f.filter, f.value));

      return !filterCancelled && !filterConsult && !filterProgress && keywordsMatchFilter;
    });
  }

  private doesAppointmentMatchFilter = (
    appointment: ExtendedAppointment,
    filter: AppointmentKeywordFilter,
    filterValue: string,
  ) => {
    const isKeywordMatch = (actualValue: string) => actualValue.toLowerCase().includes(filterValue.toLowerCase());

    switch (filter) {
      case "student":
        return appointment.students.some(s => isKeywordMatch(s.fullName));
      case "provider":
        return isKeywordMatch(appointment.provider.fullName);
      case "facilitator":
        return appointment.students.some(s => s.facilitators.some(f => isKeywordMatch(f.fullName)));
      case "campus":
        return appointment.students.some(s => s.campus && isKeywordMatch(s.campus.name));
      case "appointmentType":
        return isKeywordMatch(appointment.appointmentType.title);
    }
  };

  get flattenedItems(): { startTime: DateTime; endTime: DateTime }[] {
    const items = this.calendarItems;
    return [...items.appointments, ...items.blocks, ...items.indirectTime];
  }

  get hourRange(): { min: number; max: number } {
    const items = this.calendarItems;
    const flattenedItems = [...items.appointments, ...items.blocks, ...items.indirectTime];
    return {
      min:
        min([
          ...flattenedItems.map(i => i.startTime.setZone(this.authStore.timezone).hour),
          DEFAULT_MIN_CALENDAR_HOUR,
        ]) ?? DEFAULT_MIN_CALENDAR_HOUR,
      max:
        max([
          ...flattenedItems.map(i => i.endTime.setZone(this.authStore.timezone).hour + 1),
          DEFAULT_MAX_CALENDAR_HOUR,
        ]) ?? DEFAULT_MAX_CALENDAR_HOUR,
    };
  }

  get selectedAppointment(): ExtendedAppointment | undefined {
    if (this.currentSelection?.itemType === "appointment")
      return this.calendarItems.appointments.find(a => a.appointmentId === this.currentSelection?.itemId);
  }

  get selectedBlock(): ExtendedCalendarBlock | undefined {
    if (this.currentSelection?.itemType === "block")
      return this.calendarItems.blocks.find(a => a.calendarBlockId === this.currentSelection?.itemId);
  }

  get selectedIndirectTime(): ExtendedTimeEntry | undefined {
    if (this.currentSelection?.itemType === "indirect-time")
      return this.calendarItems.indirectTime.find(a => a.timeEntryId === this.currentSelection?.itemId);
  }

  get defaultTimeRange() {
    const now = DateTime.utc();

    if (!isInRange(this.currentView, now)) {
      return {
        startTime: this.currentView.startTime.set({ hour: 12, minute: 0 }),
        endTime: this.currentView.startTime.set({ hour: 12, minute: 30 }),
      };
    }

    const userTime = now.setZone(this.authStore.timezone);
    const currentBlockStart = userTime.set({
      second: 0,
      millisecond: 0,
      minute: Math.floor(userTime.minute / 30) * 30,
    });
    const defaultStartTime = currentBlockStart.minus({ minutes: 30 });
    return {
      startTime: defaultStartTime,
      endTime: defaultStartTime.plus({ minutes: 30 }),
    };
  }

  get baseUrl() {
    return this.selectedRelationFilter
      ? `/calendar/${this.selectedRelationFilter.type.toLowerCase()}/${this.selectedRelationFilter.key}`
      : "/calendar";
  }

  get selectedUserOption(): UserOption | undefined {
    const selectedRelation = this.selectedRelationFilter;
    if (!selectedRelation) return;

    const userType = UserTypeValues.find(t => t === selectedRelation.type);
    if (!userType) return;

    return {
      userId: selectedRelation.key,
      userType,
      fullName: selectedRelation.label,
    };
  }

  public toRelationOption = (relation: CalendarRelation): SelectOption => ({
    ...pick(relation, "key", "label"),
    groupName: relation.type,
  });

  get availableRelationTypes(): CalendarRelationType[] | null {
    switch (this.authStore.currentUser?.userType) {
      case "ADMIN":
        return ["FACILITATOR", "SLPA", "PROVIDER", "STUDENT", "CAMPUS"];
      case "SITE_DIRECTOR":
        return ["PROVIDER", "CAMPUS", "STUDENT"];
      default:
        return null;
    }
  }

  public loadItem = async (itemSelection: CalendarItemSelection, viewType?: CalendarViewType) => {
    this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";

    const item = await this.resolveTruncatedItem(itemSelection).catch(e => {
      this.logger.error("error resolving calendar item for initialization", {
        causedBy: e,
        context: { itemSelection },
      });
      return null;
    });
    if (!item) {
      this.fetchStatus = "not-found";
      return;
    }

    // if item found in cache, assume everything is configured correctly and simply set the selected calendar item
    if (item.source === "cache") {
      runInAction(() => {
        this.currentSelection = itemSelection;
        this.fetchStatus = "finished";
      });
      return item;
    }

    // resolve optional selected user update from fetched calendar item - fail if not found
    const selectedRelationFilterUpdate = await this.resolveSelectedRelationFilterUpdate(item.userIds).catch(e => {
      this.logger.error("error resolving item user", { causedBy: e, context: { item } });
      return null;
    });
    if (isNull(selectedRelationFilterUpdate)) {
      this.fetchStatus = "not-found";
      return item;
    }

    // resolve optional view update from fetched calendar item
    const viewUpdate = this.resolveViewUpdate(viewType, { includesTime: item.startTime });

    // set state + return if selected user and view both don't require update + data already been fetched
    if (!selectedRelationFilterUpdate && !viewUpdate && this.maybeCalendarItems) {
      runInAction(() => {
        this.currentSelection = itemSelection;
        this.fetchStatus = "finished";
      });
      return item;
    }

    // fetch data + set state
    const newItems = await this.fetchData(
      viewUpdate || this.currentView,
      selectedRelationFilterUpdate || this.selectedRelationFilter || undefined,
    ).catch(this.logger.handleFailureAndReturn("loadItemDataFetch", undefined));

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      this.currentSelection = itemSelection;
      if (selectedRelationFilterUpdate) this.selectedRelationFilter = selectedRelationFilterUpdate;
      if (viewUpdate) this.currentView = viewUpdate;
      this.fetchStatus = "finished";
    });

    return item;
  };

  private resolveTruncatedItem = async ({
    itemId,
    itemType,
  }: CalendarItemSelection): Promise<{ userIds: string[]; startTime: DateTime; source: "cache" | "fetch" }> => {
    switch (itemType) {
      case "appointment":
        const existingAppointment = this.calendarItems.appointments.find(a => a.appointmentId === itemId);
        return existingAppointment
          ? this.truncateAppointment(existingAppointment, "cache")
          : this.calendarApi.getAppointment(itemId).then(a => this.truncateAppointment(a, "fetch"));
      case "block":
        const existingAvailability = this.calendarItems.blocks.find(a => a.calendarBlockId === itemId);
        return existingAvailability
          ? this.truncateBlock(existingAvailability, "cache")
          : this.calendarApi.getCalendarBlock(itemId).then(a => this.truncateBlock(a, "fetch"));
      case "indirect-time":
        const existingTime = this.calendarItems.indirectTime.find(t => t.timeEntryId === itemId);
        return existingTime
          ? this.truncateIndirectTime(existingTime, "cache")
          : this.calendarApi.getIndirectTime(itemId).then(t => this.truncateIndirectTime(t, "fetch"));
    }
  };

  private truncateAppointment = (a: ExtendedAppointment, source: "cache" | "fetch") => ({
    startTime: a.startTime,
    userIds: [a.provider.userId, ...a.students.map(s => s.userId)],
    source,
  });

  private truncateBlock = (a: ExtendedCalendarBlock, source: "cache" | "fetch") => ({
    startTime: a.startTime,
    userIds: [a.userId],
    source,
  });

  private truncateIndirectTime = (t: ExtendedTimeEntry, source: "cache" | "fetch") => ({
    startTime: t.startTime,
    userIds: [t.userId],
    source,
  });

  public loadSelectedRelation = async (
    { id, type }: { id: string; type: CalendarRelationType },
    range?: DateTimeRange,
    viewType?: CalendarViewType,
  ) => {
    this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";

    const selectedRelationFilterUpdate = await this.resolveSelectedRelationFilterUpdate([id], {
      isCampus: type === "CAMPUS",
    }).catch(e => {
      this.logger.error("error resolving param relation", { causedBy: e, context: { id, type } });
      return null;
    });
    if (
      isNull(selectedRelationFilterUpdate) ||
      (selectedRelationFilterUpdate && selectedRelationFilterUpdate.type !== type)
    ) {
      this.fetchStatus = "not-found";
      return;
    }

    // resolve optional view update
    const viewUpdate = this.resolveViewUpdate(viewType, { range });

    // return if selected user and view both don't require update + data already fetched
    if (!selectedRelationFilterUpdate && !viewUpdate && this.maybeCalendarItems) {
      runInAction(() => (this.fetchStatus = "finished"));
      return;
    }

    // fetch data + set state
    const newItems = await this.fetchData(
      viewUpdate || this.currentView,
      selectedRelationFilterUpdate || this.selectedRelationFilter || undefined,
    ).catch(this.logger.handleFailureAndReturn("loadSelectedUserDataFetch", undefined));

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      if (selectedRelationFilterUpdate) this.selectedRelationFilter = selectedRelationFilterUpdate;
      if (viewUpdate) this.currentView = viewUpdate;
      this.fetchStatus = "finished";
    });
  };

  public loadCurrentUser = async (range?: DateTimeRange, viewType?: CalendarViewType) => {
    this.selectedRelationFilter = undefined;

    const canShowRelationFilterSelect = !isNull(this.availableRelationTypes);
    const initialViewType = viewType || this.viewType;
    const requireRelationFilter =
      canShowRelationFilterSelect && (this.authStore.currentUser?.userType === "ADMIN" || initialViewType !== "list");

    if (requireRelationFilter) {
      this.currentView = this.createView(initialViewType, { range });
      this.fetchStatus = "waiting";
      return;
    } else {
      this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";
    }

    const viewUpdate = this.resolveViewUpdate(viewType, { range });
    if (!viewUpdate && this.maybeCalendarItems) {
      this.fetchStatus = "finished";
      return;
    }

    // fetch data + set state
    const newItems = await this.fetchData(viewUpdate || this.currentView).catch(
      this.logger.handleFailureAndReturn("loadCurrentUserDataFetch", undefined),
    );

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      if (viewUpdate) this.currentView = viewUpdate;
      this.fetchStatus = "finished";
    });
  };

  private resolveSelectedRelationFilterUpdate = async (
    availableKeys: string[],
    { isCampus }: { isCampus?: boolean } = {},
  ): Promise<CalendarRelation | undefined> => {
    // selected calendar user only needs to be updated for users with full org calendar access (i.e. admin users)
    if (isNull(this.availableRelationTypes)) return;

    // nothing to do if available user is already selected
    if (this.selectedRelationFilter && availableKeys.includes(this.selectedRelationFilter.key)) return;

    const key = availableKeys[0];
    return isCampus
      ? this.institutionApi.getCampus(key).then(c => ({
          key: c.institutionId,
          label: c.salesforceName,
          type: "CAMPUS",
        }))
      : this.userApi.getUser(key).then(u => ({
          key: u.userId,
          label: u.fullName,
          type: u.userType as CalendarRelationType,
        }));
  };

  // generate new view with params - if same as current state, return undefined
  private resolveViewUpdate = (
    viewType: CalendarViewType | undefined = this.currentView?.type,
    options: { range?: DateTimeRange; includesTime?: DateTime },
  ) => {
    const newView = this.createView(viewType, options);
    return isEqual(newView, this.currentView) ? undefined : newView;
  };

  public getPeriod = (type: CalendarViewType, includesTime: DateTime = DateTime.utc()): DateTimeRange => {
    const zonedIncludesTime = includesTime.setZone(this.authStore.timezone);
    if (type === "day") {
      return {
        startTime: zonedIncludesTime.startOf("day"),
        endTime: zonedIncludesTime.endOf("day"),
      };
    }
    const startOfWeek = zonedIncludesTime.plus({ days: 1 }).startOf("week");
    return {
      startTime: startOfWeek.minus({ day: this.isShowingWeekends ? 1 : 0 }),
      endTime: startOfWeek.endOf("week").minus({ day: this.isShowingWeekends ? 1 : 2 }),
    };
  };

  private createView = (
    type: CalendarViewType | undefined,
    { range, includesTime }: { range?: DateTimeRange; includesTime?: DateTime } = {},
  ): CalendarView => {
    const period = range || this.getPeriod(type || this.viewType, includesTime);
    return { type, ...period };
  };

  private getSelectedRelationFetchOptions = (relation?: CalendarRelation): Partial<SearchCalendarQuery> => {
    if (!relation) return {};
    switch (relation?.type) {
      case "PROVIDER":
        return { providerId: relation.key };
      case "STUDENT":
        return { studentId: relation.key };
      case "FACILITATOR":
        return { facilitatorId: relation.key };
      case "SLPA":
        return { slpaId: relation.key };
      case "CAMPUS":
        return { campusId: relation.key };
      default:
        return {};
    }
  };

  private fetchData = async (timeRange: DateTimeRange, selectedRelation?: CalendarRelation) => {
    return this.calendarApi.searchCalendarItems({
      ...timeRange,
      ...this.getSelectedRelationFetchOptions(selectedRelation),
    });
  };

  public setPayPeriod = async () => {
    const payPeriod = await this.calendarApi.getPayPeriods();
    runInAction(() => {
      this.currentPayPeriod = payPeriod.current;
      this.previousPayPeriod = payPeriod.previous;
    });
  };

  public canWriteCalendar = (startTime?: DateTime) => {
    const currentUser = this.authStore.currentUser;

    if (!hasRoleFlag(currentUser, "write-calendar")) return false;

    if (!startTime || !this.previousPayPeriod) return true;
    if (!hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period")) return true;

    return startTime > this.previousPayPeriod.startTime;
  };

  public toggleFilter = (key: CalendarTypeFilter) => {
    this.partialTypeFilters = {
      ...this.typeFilters,
      [key]: !this.typeFilters[key],
    };
  };

  public setCalendarTypeFilters = (filters: Partial<Record<CalendarTypeFilter, boolean>>) => {
    this.partialTypeFilters = {
      ...this.typeFilters,
      ...filters,
    };
  };

  public toggleCancelFilter = () => {
    this.isShowingCancelled = !this.isShowingCancelled;
  };

  public zoom = (delta: number) => {
    this.zoomFactor = roundToDecimal(this.zoomFactor + delta, 1);
  };

  public setAppointmentKeywordFilters = (selection: FilterOption[]) => {
    this.appointmentKeywordFilters = mapExists(selection, ({ searchParamKey, searchParamValue }) => {
      const filter = APPOINTMENT_KEYWORD_FILTERS.find(f => f === searchParamKey);
      if (!filter) return;
      return { filter, value: searchParamValue };
    });
  };

  public createAppointment = async (body: CreateAppointmentBody) => {
    const appointments = await this.calendarApi.createAppointment(body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    appointments.forEach(appointment => this.addItem(appointment, "appointments"));
  };

  public updateAppointment = async (appointmentId: string, body: UpdateAppointmentBody) => {
    const appointments = await this.calendarApi.updateAppointment(appointmentId, body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    appointments.forEach(appointment => this.updateItem(appointment, "appointments", "appointmentId"));
  };

  public deleteAppointment = async (appointmentId: string, mode?: RecurrenceEditMode) => {
    const appointments = await this.calendarApi.deleteAppointment(appointmentId, mode, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    appointments.forEach(appointment => this.removeItem(appointment.appointmentId, "appointments", "appointmentId"));
  };

  public createCalendarBlock = async (body: CreateCalendarBlockBody) => {
    const calendarBlocks = await this.calendarApi.createCalendarBlock(body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    calendarBlocks.forEach(calendarBlock => this.addItem(calendarBlock, "blocks"));
  };

  public updateCalendarBlock = async (availabilityId: string, body: UpdateCalendarBlockBody) => {
    const calendarBlocks = await this.calendarApi.updateCalendarBlock(availabilityId, body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    calendarBlocks.forEach(calendarBlock => this.updateItem(calendarBlock, "blocks", "calendarBlockId"));
  };

  public deleteCalendarBlock = async (availabilityId: string, mode?: RecurrenceEditMode) => {
    const calendarBlocks = await this.calendarApi.deleteCalendarBlock(availabilityId, mode, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    calendarBlocks.forEach(calendarBlock =>
      this.removeItem(calendarBlock.calendarBlockId, "blocks", "calendarBlockId"),
    );
  };

  public createIndirectTime = async (body: CreateIndirectTimeBody) => {
    const timeEntries = await this.calendarApi.createIndirectTime(body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    timeEntries.forEach(timeEntry => this.addItem(timeEntry, "indirectTime"));
  };

  public updateIndirectTime = async (timeId: string, body: UpdateIndirectTimeBody) => {
    const timeEntries = await this.calendarApi.updateIndirectTime(timeId, body, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    timeEntries.forEach(timeEntry => this.updateItem(timeEntry, "indirectTime", "timeEntryId"));
  };

  public deleteIndirectTime = async (timeId: string, mode?: RecurrenceEditMode) => {
    const timeEntries = await this.calendarApi.deleteIndirectTime(timeId, mode, {
      displayRangeStart: this.currentView.startTime.toISODate() || undefined,
      displayRangeEnd: this.currentView.endTime.toISODate() || undefined,
    });
    timeEntries.forEach(timeEntry => this.removeItem(timeEntry.timeEntryId, "indirectTime", "timeEntryId"));
  };

  public updateAppointmentStudent = async (
    appointmentId: string,
    userId: string,
    update: UpdateAppointmentUserBody,
  ) => {
    const currentAppointment = this.calendarItems.appointments.find(a => a.appointmentId === appointmentId);
    const currentUser = currentAppointment?.students.find(s => s.userId === userId);
    if (!currentAppointment || !currentUser) return;

    const localUpdated = {
      ...currentAppointment,
      students: currentAppointment.students.map(s => (s.userId === userId ? { ...s, ...update } : s)),
    };
    this.updateItem(localUpdated, "appointments", "appointmentId");

    await this.calendarApi.updateAppointmentUser(appointmentId, userId, update);
    // TODO handle inconsistencies
  };

  private addItem = <A extends { startTime: DateTime; endTime: DateTime }>(
    newItem: A,
    itemKey: keyof CalendarItems,
  ) => {
    if (!isWithin(this.currentView, newItem)) return;
    this.setItems([...this.calendarItems[itemKey], newItem], itemKey);
  };

  private updateItem = <A extends { startTime: DateTime; endTime: DateTime; archivedAt: DateTime | null }>(
    updatedItem: A,
    itemKey: keyof CalendarItems,
    idKey: keyof A,
  ) => {
    if (!isWithin(this.currentView, updatedItem) || updatedItem.archivedAt !== null)
      return this.removeItem(updatedItem[idKey] as string, itemKey, idKey);

    const currentList: any[] = this.calendarItems[itemKey];

    if (!currentList.some(item => item[idKey] === updatedItem[idKey])) return this.addItem(updatedItem, itemKey);

    this.setItems(
      currentList.map(item => (item[idKey] !== updatedItem[idKey] ? item : updatedItem)),
      itemKey,
    );
  };

  private removeItem = <A>(deletedId: string, itemKey: keyof CalendarItems, idKey: keyof A) => {
    const currentList: any[] = this.calendarItems[itemKey];
    const updatedList = currentList.flatMap(item => (item[idKey] !== deletedId ? [item] : []));
    this.setItems(updatedList, itemKey);
  };

  private setItems = <A>(list: A[], itemKey: keyof CalendarItems) => {
    runInAction(
      () =>
        (this.maybeCalendarItems = {
          ...this.calendarItems,
          [itemKey]: list,
        }),
    );
  };
}
