import { useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { DistrictUserPermissions } from "@parallel/vertex/role/role.user";
import { CreateUserBody, createUserBodySchema, ExtendedUser } from "@parallel/vertex/types/user/user.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import NameInput from "@/components/user/input/NameInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getUserTypeLabel } from "@/util/user.util";

type CreateUserParams = Partial<Omit<CreateUserBody, "districtId">> & { district: SelectOption | null };

const logger = initLogger("FacilitatorForm", getLoggerContext);

const DistrictUserForm = ({
  userType,
  editing,
  onWrite,
  onClose,
  writePermissions,
}: {
  userType: "FACILITATOR" | "SITE_DIRECTOR";
  editing?: ExtendedUser;
  onWrite: () => void;
  onClose: () => void;
  writePermissions: DistrictUserPermissions;
}) => {
  const {
    apiStore: { institutionApi, userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const defaultDistrict = writePermissions.defaults?.district === "currentUser.district" &&
    currentUser?.district && {
      key: currentUser.district.id,
      label: currentUser.district.name,
    };
  const initialDistrictOption =
    defaultDistrict ||
    (editing?.districtId && editing?.districtName ? { key: editing.districtId, label: editing.districtName } : null);

  const [params, setParams] = useState<CreateUserParams>({
    userType,
    firstName: editing?.firstName,
    lastName: editing?.lastName,
    email: editing?.email || undefined,
    district: initialDistrictOption,
  });

  const formContent = (
    <>
      <NameInput params={params} setParams={p => setParams({ ...params, ...p })} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
      <AutoCompletePageSearchInput
        label="District"
        search={name =>
          institutionApi
            .searchInstitutions({ name, institutionType: "DISTRICT" })
            .catch(logger.handleFailure("searchInstitutions"))
        }
        getOption={i => ({ key: i.institutionId, label: i.salesforceName })}
        selected={params.district}
        onSelect={district => setParams({ ...params, district })}
        disabled={!!defaultDistrict}
      />
      {!editing && (
        <FormControlLabel
          label="Send Welcome Email"
          control={
            <Checkbox
              checked={params.sendWelcomeEmail}
              onChange={e => setParams({ ...params, sendWelcomeEmail: e.target.checked })}
            />
          }
        />
      )}
    </>
  );

  const validate = (params: CreateUserParams) => {
    console.log("VALIDATE", createUserBodySchema.safeParse({ ...params, districtId: params.district?.key }));
    return createUserBodySchema.safeParse({ ...params, districtId: params.district?.key })?.data;
  };

  const onSubmit = async (body: CreateUserBody) => {
    if (editing) {
      await userApi
        .updateUser(editing.userId, body)
        .catch(logger.handleFailureAndThrow("updateFacilitator", { level: "warning" }));
    } else {
      const result = await userApi
        .createUser(body)
        .catch(logger.handleFailureAndThrow("createFacilitator", { level: "warning" }));

      if (result.welcomeEmailFailed) {
        return {
          warning: `Successfully created ${getUserTypeLabel(userType).toLowerCase()}, but failed to send welcome email`,
        };
      }
    }
    onWrite();
  };

  return (
    <SubmitForm
      recordName={userType.toLowerCase()}
      recordLabel={getUserTypeLabel(userType)}
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default DistrictUserForm;
