import { UserType } from "../enums/user.enums";
import { ExtendedCalendarBlock } from "../types/calendar/calendar.block.types";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type CalendarBlockPermissions = {
  accessLevels: AccessLevel[];
  allowIf: (a: ExtendedCalendarBlock, userId: string) => boolean;
  defaults?: { userId: "currentUser" };
};

const CALENDAR_BLOCK_PERMISSION_GRANTS: Partial<Record<UserType, CalendarBlockPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (a: ExtendedCalendarBlock, userId: string) => a.userId === userId,
    defaults: { userId: "currentUser" },
  },
};

export const getCalendarBlockPermissions = getDataPermissionResolver(CALENDAR_BLOCK_PERMISSION_GRANTS);
