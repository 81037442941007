import type { Appointment, ApprovalStatus, AppointmentCompletionRequirement, ComplianceType } from "@prisma/client";
import { JsonArray } from "@prisma/client/runtime/library";
import { DateTime } from "luxon";
import { z } from "zod";
import { approvalStatusEnum } from "../../enums/approval.enums";
import { recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { AppointmentStatusValues } from "../../enums/prisma.enums";
import { datetimeRequestField, Override, paginateQueryFields, queryStringArray } from "../shared.types";
import { ExtendedUser } from "../user/user.types";
import { ExtendedCalendarItem, timeRangeFields, timeRangeQuerySchema, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type { AppointmentType, AppointmentCancelReason, AppointmentStatus } from "@prisma/client";

export type ExtendedAppointmentUser = ExtendedUser & {
  campus?: { id: string; name: string };
  facilitators: ExtendedUser[];
  hasAttended: boolean | null;
  joinedAt?: DateTime | null;
  leftAt?: DateTime | null;
  shortCode: string;
};

export type ExtendedAppointment = Override<
  ExtendedCalendarItem<Appointment> & {
    appointmentType: {
      title: string;
      completionRequirements: AppointmentCompletionRequirement[];
      complianceType?: ComplianceType;
      formTemplateId?: string;
    };
    cancelReason?: { id: string; title: string };
    isLateStatusUpdate?: boolean;
    latestApproval?: { approvalId: string; approvalStatus: ApprovalStatus };
    provider: ExtendedAppointmentUser;
    recurrence?: ExtendedRecurrence;
    students: ExtendedAppointmentUser[];
    title: string;
  },
  // ! Override for serialization
  {
    actualDuration?: number | null;
    cancelReasonId?: string | null;
    createdBy?: string;
    history?: JsonArray | null;
    nylasEventId?: string | null;
    recurrenceId?: string | null;
  }
>;

export type AppointmentFormTemplate = {
  formTemplateId: string;
  templateTitle: string;
  featheryId: string;
  userSubmissions: {
    userId: string;
    submissions: { submissionId: string; hasAnswers: boolean }[];
  }[];
};

export type AppointmentDetails = {
  recordingFiles: { nexusUrl: string }[];
  studentMeetingConnections: {
    studentId: string;
    joinedAt: DateTime;
    leftAt?: DateTime;
  }[];
  formTemplates: AppointmentFormTemplate[];
};

const appointmentStatusEnum = z.enum(AppointmentStatusValues);

export const searchAppointmentsQuerySchema = timeRangeQuerySchema.extend({
  ...paginateQueryFields,
  studentId: z.string().uuid().optional(),
  providerId: z.string().uuid().optional(),
  slpaId: z.string().uuid().optional(),
  facilitatorId: z.string().uuid().optional(),
  districtUserId: z.string().uuid().optional(),
  campusId: z.string().uuid().optional(),
  appointmentStatus: z.array(appointmentStatusEnum.nullable()).optional(),
  approvalStatus: approvalStatusEnum.optional(),
  recurrenceId: z.string().uuid().optional(),
  appointmentTypeIds: z.string().uuid().array().optional(),
  filterApprovals: z
    .union([z.boolean(), z.string()])
    .optional()
    .transform(val => (typeof val === "string" ? val === "true" : val)),
});

export type SearchAppointmentsQuery = z.infer<typeof searchAppointmentsQuerySchema> & {
  complianceTypes?: ComplianceType[];
};

const appointmentBody = z.object({
  providerId: z.string().uuid(),
  ...timeRangeFields,
  studentIds: z.string().uuid().array().min(1),
  appointmentTypeId: z.string().uuid(),
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export const createAppointmentBodySchema = appointmentBody.refine(...timeRangeRefine);

export type CreateAppointmentBody = z.infer<typeof createAppointmentBodySchema>;

export const updateAppointmentBodySchema = appointmentBody
  .partial()
  .extend({
    appointmentStatus: appointmentStatusEnum.nullable().optional(),
    cancelReasonId: z.string().uuid().optional(),
    willMakeUpAbsence: z.boolean().optional(),
  })
  .refine(...timeRangeRefine);

export type UpdateAppointmentBody = z.infer<typeof updateAppointmentBodySchema> & { nylasEventId?: string | null };

export const updateLateSubmissionsBodySchema = z.record(approvalStatusEnum);

export const deleteAppointmentBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteAppointmentBody = z.infer<typeof deleteAppointmentBodySchema>;

export const updateAppointmentUserBodySchema = z.object({
  hasAttended: z.boolean().optional(),
  joinedAt: datetimeRequestField.nullable().optional(),
  leftAt: datetimeRequestField.nullable().optional(),
});

export type UpdateAppointmentUserBody = z.infer<typeof updateAppointmentUserBodySchema>;

export const searchAppointmentTypeQuerySchema = z.object({
  providerId: z.string().uuid().optional(),
  studentIds: queryStringArray(z.string().uuid()).optional(),
  ...paginateQueryFields,
});

export type SearchAppointmentTypeQuery = z.infer<typeof searchAppointmentTypeQuerySchema>;

export type BookAppointmentOptions = Omit<CreateAppointmentBody, "recurrence"> & {
  createdByUserId: string;
  recurrenceId?: string;
};

export type BulkWriteRequest = {
  createRequests: BookAppointmentOptions[];
  updateRequests: { model: ExtendedAppointment; update: UpdateAppointmentBody }[];
  archiveIds: string[];
};
