import { AxiosError } from "axios";
import { noop } from "lodash";
import { getEnvConfig } from "../config/env.config";

type LogObject<S> = { severity?: S } & any;
type LogFn<S> = (s: string, obj?: LogObject<S>, error?: any) => void;

type InfoSeverity = "DEFAULT" | "INFO";
type WarningSeverity = "WARNING";
type ErrorSeverity = "ERROR" | "CRITICAL" | "ALERT" | "EMERGENCY";

export type LogLevel = "info" | "warn" | "error";

type ChildLoggerOptions = { tag?: string; context?: any };
export type Logger = {
  info: LogFn<InfoSeverity>;
  warn: LogFn<WarningSeverity>;
  error: LogFn<ErrorSeverity>;
  debug: LogFn<"DEBUG">;
  child: (options: ChildLoggerOptions) => Logger;
};

export const stripPasswords = (logString: string) => {
  // need to support double-quote-escaped json strings (\\?) and optional whitespace (\s?)
  return logString.replace(/(\\?"password\\?":\s?\\?")(.*?)(\\?")/g, "$1REDACTED$3");
};

const logForConsole =
  <S>(logFn: (...data: any[]) => void, tag: string, context?: any): LogFn<S> =>
  (s: string, obj?: LogObject<S>, error?: any) => {
    const logMessage = `[${tag}] ${s}`;
    const logObject = { ...context, ...obj };
    const redactedLogString = stripPasswords(JSON.stringify(logObject, null, 2));
    const isAxiosError = error && error instanceof AxiosError;

    if (error && !isAxiosError) {
      logFn(logMessage, redactedLogString, error);
    } else {
      logFn(logMessage, redactedLogString);
    }

    if (isAxiosError) {
      console.debug("caused by axios error", error);
    }
  };

const logForGcp =
  <S>(logFn: (...data: any[]) => void, tag: string, context?: any): LogFn<S> =>
  (s: string, obj?: LogObject<S>, error?: any) => {
    const logObject = {
      logger: "AppLogger",
      ...context,
      ...obj,
      message: `[${tag}] ${s}`,
      error,
    };
    const redactedLogString = stripPasswords(JSON.stringify(logObject));
    const isAxiosError = error && error instanceof AxiosError;

    if (error && !isAxiosError) {
      logFn(redactedLogString, isAxiosError ? error.message : error);
    } else {
      logFn(redactedLogString);
    }
  };

const envConfig = getEnvConfig(process.env);

export const initLogger = (tag: string, context?: any): Logger => {
  const showDebugLogs = process.env.SHOW_DEBUG_LOGS ? process.env.SHOW_DEBUG_LOGS === "true" : false;
  const showLogContext = process.env.SHOW_LOG_CONTEXT ? process.env.SHOW_LOG_CONTEXT === "true" : true;

  const child = ({ tag: childTag, context: childContext }: ChildLoggerOptions) =>
    initLogger(childTag ? `${tag}.${childTag}` : tag, { ...context, ...childContext });

  if (envConfig.parallelEnv === "local" || envConfig.isFnTest) {
    return {
      info: logForConsole(console.log, tag, showLogContext ? { severity: "INFO", ...context } : undefined),
      warn: logForConsole(console.warn, tag, showLogContext ? { severity: "WARNING", ...context } : undefined),
      error: logForConsole(console.error, tag, showLogContext ? { severity: "ERROR", ...context } : undefined),
      debug: showDebugLogs ? logForConsole(console.debug, tag, { severity: "DEBUG", ...context }) : noop,
      child,
    };
  }

  return {
    info: logForGcp(console.log, tag, { severity: "INFO", ...context }),
    warn: logForGcp(console.warn, tag, { severity: "WARNING", ...context }),
    error: logForGcp(console.error, tag, { severity: "ERROR", ...context }),
    debug: showDebugLogs ? logForGcp(console.debug, tag, { severity: "DEBUG", ...context }) : noop,
    child,
  };
};
