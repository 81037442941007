import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ASSESSMENT_DISPLAY_CONFIG_VALUES, AssessmentConfigGroupData } from "@parallel/vertex/types/assessment.types";
import SelectInput from "@/components/shared/input/SelectInput";
import { StoreContext } from "@/stores";
import { ConfigGroup } from "@/stores/stimulus.store";

const ConfigGroupForm = ({ group }: { group: ConfigGroup }) => {
  const {
    stimulusStore: { updateConfigGroup },
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const onUpdate = (update: Partial<AssessmentConfigGroupData>) => {
    setIsLoading(true);
    return updateConfigGroup(group.id, update).finally(() => setIsLoading(false));
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between" pb={1}>
        <Typography variant="subtitle2">Configuration</Typography>
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <SelectInput
        label="Provider Display Priority"
        options={ASSESSMENT_DISPLAY_CONFIG_VALUES.map(key => ({ key, label: key }))}
        value={group.providerDisplayPriority}
        onChange={providerDisplayPriority => onUpdate({ providerDisplayPriority })}
        size="small"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={group.clientStimulus.showClient}
            onChange={e => onUpdate({ clientStimulus: { ...group.clientStimulus, showClient: e.target.checked } })}
          />
        }
        label="Show Client Stimulus to Client"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={group.clientStimulus.showProvider}
            onChange={e => onUpdate({ clientStimulus: { ...group.clientStimulus, showProvider: e.target.checked } })}
          />
        }
        label="Show Client Stimulus to Provider"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={group.shareProviderMouse}
            onChange={e => onUpdate({ shareProviderMouse: e.target.checked })}
          />
        }
        label="Share Provider Mouse"
      />
      <FormControlLabel
        control={
          <Checkbox checked={group.shareClientMouse} onChange={e => onUpdate({ shareClientMouse: e.target.checked })} />
        }
        label="Share Client Mouse"
      />
      <FormControlLabel
        control={<Checkbox checked={group.stopwatch} onChange={e => onUpdate({ stopwatch: e.target.checked })} />}
        label="Show Stopwatch"
      />
    </Stack>
  );
};

export default ConfigGroupForm;
