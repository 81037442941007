import { UserType } from "../enums/user.enums";
import { ExtendedStudentUser } from "../types/user/student.types";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type StudentRoleFlag =
  | "category-filters"
  | "view-unassigned"
  | "bulk-create"
  | "edit-related-providers"
  | "edit-related-facilitators";

export const STUDENT_ROLE_FLAG_GRANTS: Record<StudentRoleFlag, UserType[]> = {
  "category-filters": ["ADMIN", "PROVIDER"],
  "view-unassigned": ["ADMIN"],
  "bulk-create": ["ADMIN"],
  "edit-related-providers": ["ADMIN"],
  "edit-related-facilitators": ["ADMIN", "PROVIDER", "SITE_DIRECTOR"],
};

type StudentCustomPermissionCheck = "is-assigned-to-site-director" | "is-assigned-to-slpa";

export type StudentPermissions = {
  accessLevels: AccessLevel[];
  allowIf: ((student: ExtendedStudentUser, userId: string) => boolean) | StudentCustomPermissionCheck;
  defaults?: { provider: "currentUser" };
  infoOnly?: boolean;
};

export const STUDENT_ROLE_GRANTS: Partial<Record<UserType, StudentPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (student, userId) => student.providers.some(p => p.userId === userId),
    defaults: { provider: "currentUser" },
  },
  FACILITATOR: {
    accessLevels: ["read"],
    allowIf: (student, userId) => student.facilitators.some(f => f.userId === userId),
  },
  SITE_DIRECTOR: {
    accessLevels: ["read"],
    allowIf: "is-assigned-to-site-director",
    infoOnly: true,
  },
  SLPA: {
    accessLevels: ["read"],
    allowIf: "is-assigned-to-slpa",
  },
};

export const getStudentPermissions = getDataPermissionResolver(STUDENT_ROLE_GRANTS);
