import { useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import { isEmpty, pick } from "lodash";
import { observer } from "mobx-react-lite";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { hasRoleFlag } from "@parallel/vertex/role";
import { AppointmentPermissions } from "@parallel/vertex/role/role.appointment";
import {
  CreateAppointmentBody,
  ExtendedAppointment,
  createAppointmentBodySchema,
} from "@parallel/vertex/types/calendar/appointment.types";
import { SYSTEM_START_DATE } from "@parallel/vertex/types/calendar/calendar.types";
import AutoCompleteFetchInput from "@/components/shared/input/AutoCompleteFetchInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import RecurrenceEditModeInput from "@/components/shared/input/RecurrenceEditModeInput";
import RecurrenceInput from "@/components/shared/input/RecurrenceInput";
import TimeRangeInput from "@/components/shared/input/TimeRangeInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { UserOption } from "@/components/user/input/UserInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type AppointmentParams = Partial<Omit<CreateAppointmentBody, "providerId" | "studentIds" | "appointmentTypeId">> & {
  provider: SelectOption | null;
  students: SelectOption[];
  appointmentType: SelectOption | null;
};

const logger = initLogger("AppointmentForm", getLoggerContext);

const AppointmentForm = ({
  editing,
  selectedUser,
  onClose,
  permissions,
}: {
  editing?: ExtendedAppointment;
  selectedUser?: UserOption;
  onClose: () => void;
  permissions: AppointmentPermissions;
}) => {
  const {
    apiStore: { calendarApi, userApi },
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const { currentPayPeriod, previousPayPeriod } = calendarStore;

  const toUserSelectOption = (user?: UserOption): SelectOption | null =>
    user ? { key: user.userId, label: user.fullName } : null;

  const editingProviderOption = toUserSelectOption(editing?.provider);
  const selectedProviderOption = selectedUser?.userType === "PROVIDER" && toUserSelectOption(selectedUser);
  const defaultProviderOption = permissions.defaults?.providerId === "currentUser" && toUserSelectOption(currentUser);

  const [params, setParams] = useState<AppointmentParams>({
    ...calendarStore.defaultTimeRange,
    ...pick(editing, "recurrence"),
    appointmentType: editing ? { key: editing.appointmentTypeId, label: editing.appointmentType.title } : null,
    startTime: editing ? editing.startTime : calendarStore.defaultTimeRange.startTime,
    endTime: editing ? editing.endTime : calendarStore.defaultTimeRange.endTime,
    provider: editingProviderOption || selectedProviderOption || defaultProviderOption || null,
    students:
      selectedUser?.userType === "STUDENT"
        ? [{ key: selectedUser.userId, label: selectedUser.fullName }]
        : editing
          ? editing.students.map(s => ({ key: s.userId, label: s.fullName }))
          : [],
  });

  const isLateSubmission = params.startTime && currentPayPeriod && params.startTime < currentPayPeriod.startTime;

  const formContent = (
    <>
      {!permissions.defaults?.providerId && (
        <AutoCompletePageSearchInput
          label="Provider"
          search={keyword =>
            userApi
              .searchProviders({
                keyword,
                assignedStudentIds: isEmpty(params.students) ? undefined : params.students.map(s => s.key),
              })
              .catch(logger.handleFailure("searchProviders"))
          }
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={params.provider}
          onSelect={provider => setParams({ ...params, provider })}
        />
      )}

      <TimeRangeInput
        value={pick(params, "startTime", "endTime")}
        onChange={range => setParams({ ...params, ...range })}
        minStart={
          hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period")
            ? previousPayPeriod?.startTime
            : SYSTEM_START_DATE
        }
      />

      {hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period") && isLateSubmission && (
        <GreyBox sx={{ maxWidth: "524px" }}>
          <Typography variant="subtitle2" fontWeight="normal" fontStyle="italic">
            This session is scheduled for the pay period that just closed, and will go through an additional approval
            process in order to be paid. Payments for these late submissions may not be applied to the original pay
            period but will be processed and reflected in the following pay cycle.
          </Typography>
        </GreyBox>
      )}

      {(!editing || editing.recurrence) && !isLateSubmission && (
        <RecurrenceInput
          recurrence={params.recurrence}
          onChange={recurrence => setParams({ ...params, recurrence })}
          eventStartTime={params.startTime || calendarStore.defaultTimeRange.startTime}
        />
      )}

      <AutoCompletePageSearchInput
        dataTestid="students"
        label="Students"
        search={keyword =>
          userApi
            .searchStudents({ keyword, providerId: params.provider?.key })
            .catch(logger.handleFailure("searchStudents"))
        }
        getOption={s => ({ key: s.userId, label: s.fullName })}
        selected={params.students}
        onSelect={students => setParams({ ...params, students })}
        disabled={!params.provider}
      />

      <AutoCompleteFetchInput
        dataTestid="appointment-type"
        label="Appointment Type"
        params={
          params.provider ? { providerId: params.provider.key, studentIds: params.students.map(s => s.key) } : null
        }
        fetchOptions={fetchParams =>
          calendarApi
            .searchAppointmentTypes(fetchParams)
            .then(r => r.records.map(t => ({ key: t.appointmentTypeId, label: t.title })))
            .catch(logger.handleFailure("searchAppointmentTypes"))
        }
        selected={params.appointmentType}
        onSelect={appointmentType => setParams({ ...params, appointmentType })}
      />
    </>
  );

  const validate = (params: AppointmentParams) => {
    if (
      hasRoleFlag(currentUser, "restrict-calendar-writes-to-pay-period") &&
      params.startTime &&
      previousPayPeriod &&
      params.startTime < previousPayPeriod.startTime
    )
      return;

    return createAppointmentBodySchema.safeParse({
      ...params,
      providerId: params.provider?.key,
      studentIds: params.students.map(s => s.key),
      appointmentTypeId: params.appointmentType?.key,
    })?.data;
  };

  const onSubmit = async (body: CreateAppointmentBody) => {
    editing
      ? await calendarStore
          .updateAppointment(editing.appointmentId, body)
          .catch(logger.handleFailureAndThrow("updateAppointment", { level: "warning" }))
      : await calendarStore
          .createAppointment(body)
          .catch(logger.handleFailureAndThrow("createAppointment", { level: "warning" }));

    onClose();
  };

  const confirmation = editing?.recurrence
    ? {
        prompt: async () => setParams({ ...params, recurrenceEditMode: "single" }),
        content: params.recurrenceEditMode && (
          <RecurrenceEditModeInput
            params={params}
            setParams={setParams}
            actionName="update"
            recordName={{ singular: "session", plural: "sessions" }}
          />
        ),
      }
    : undefined;

  return (
    <SubmitForm
      recordName="session"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
      confirmation={confirmation}
    />
  );
};

export default observer(AppointmentForm);
