import { ReactNode, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { getIcon, useAsyncProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import ToggleMenuItem, { ToggleMenuItemProps } from "@/components/shared/input/ToggleMenuItem";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    paddingX: theme.spacing(1),
    backgroundColor: theme.palette.surface.light,
  },
}));

export const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  "& .MuiListItemText-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.main,
  },
}));

export type SyncMenuItemProps = { type: "sync"; icon?: ReactNode; text: string; onClick?: () => void; href?: string };

const SyncMenuItem = ({ item: { icon, text, onClick, href } }: { item: SyncMenuItemProps }) => {
  const item = (
    <StyledMenuItem onClick={onClick}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{text}</ListItemText>
    </StyledMenuItem>
  );
  if (href)
    return (
      <Link href={href} rel="noopener noreferrer" target="_blank" sx={{ textDecoration: "none" }}>
        {item}
      </Link>
    );
  return item;
};

type AsyncMenuItemProps = { type: "async"; icon: ReactNode; text: string; onClick: () => Promise<unknown> };

const AsyncMenuItem = ({
  item: { icon, text, onClick },
  onFinished,
}: {
  item: AsyncMenuItemProps;
  onFinished: () => void;
}) => {
  const { status, perform } = useAsyncProcessStatus(() => onClick().then(onFinished));
  return (
    <StyledMenuItem onClick={perform}>
      <ListItemIcon>{getIcon(status, 20, icon)}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </StyledMenuItem>
  );
};

export type MenuItem =
  | SyncMenuItemProps
  | AsyncMenuItemProps
  | ({ type: "toggle" } & ToggleMenuItemProps)
  | { type: "divider" };

export const FloatingMenu = ({
  items,
  anchorEl,
  onClose,
}: {
  items: MenuItem[];
  anchorEl?: Element;
  onClose: () => void;
}) => (
  <StyledMenu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
    {items.map((item, index) => {
      switch (item.type) {
        case "sync":
          return <SyncMenuItem key={`sync-${index}`} item={item} />;
        case "async":
          return <AsyncMenuItem key={`async-${index}`} item={item} onFinished={onClose} />;
        case "toggle":
          return <ToggleMenuItem key={`toggle-${index}`} {...item} />;
        case "divider":
          return <Divider />;
      }
    })}
  </StyledMenu>
);

export const FloatingMenuButton = ({ label, items }: { label: string; items: MenuItem[] }) => {
  const [anchor, setAnchor] = useState<HTMLElement>();
  return (
    <>
      <Button endIcon={<ArrowDropDownIcon />} onClick={e => setAnchor(e.currentTarget)}>
        {label}
      </Button>
      <FloatingMenu items={items} anchorEl={anchor} onClose={() => setAnchor(undefined)} />
    </>
  );
};
