import { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { omit } from "lodash";
import { Override } from "@parallel/vertex/types/shared.types";
import { StatusIcon, useDebouncedProcessStatus } from "./status.input";

type AutoSaveTextFieldProps = Override<
  TextFieldProps,
  { value: string; onSave: (values: string) => Promise<unknown>; saveKey: string; statusIcon?: boolean }
>;

const DEFAULT_WAIT_MS = 400;

const AutoSaveTextField = (props: AutoSaveTextFieldProps) => {
  const { value, onSave, saveKey, statusIcon } = props;
  const { status, perform: debouncedSave } = useDebouncedProcessStatus(onSave, { saveKey, wait: DEFAULT_WAIT_MS });

  const [stagedValue, setStagedValue] = useState(value);

  useEffect(() => {
    setStagedValue(value);
  }, [value, saveKey]);

  return (
    <TextField
      {...omit(props, "value", "onSave", "saveKey", "statusIcon")}
      value={stagedValue}
      onChange={e => {
        setStagedValue(e.target.value);
        props.onChange && props.onChange(e);
        debouncedSave(e.target.value);
      }}
      slotProps={
        statusIcon
          ? {
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <StatusIcon status={status} />
                  </InputAdornment>
                ),
              },
            }
          : undefined
      }
    />
  );
};

export default AutoSaveTextField;
