import type { DocumentType } from "@prisma/client";

export { DocumentType };

export type DocumentFileTypeOption = {
  key: DocumentType;
  label: string;
  validInAISummary: boolean;
};

export const DocumentFileTypeOptions: DocumentFileTypeOption[] = [
  { key: "studentReportCards", label: "Student Report Cards", validInAISummary: true },
  { key: "referralInformationPacket", label: "Referral Information/Packet", validInAISummary: true },
  { key: "consentSignedDocument", label: "Consent Signed Document", validInAISummary: true },
  { key: "iepDocuments", label: "IEP Documents (Previous and Current)", validInAISummary: true },
  { key: "mtssRtiData", label: "MTSS/RTI Data", validInAISummary: true },
  { key: "parentInput", label: "Parent Input", validInAISummary: true },
  { key: "teacherInput", label: "Teacher Input", validInAISummary: true },
  { key: "studentWorkSamples", label: "Student Work Samples", validInAISummary: false },
  { key: "speechLanguageSampleTranscription", label: "Speech/Language Sample Transcription", validInAISummary: false },
  { key: "other", label: "Other", validInAISummary: false },
];
