import { UserType } from "@prisma/client";
import { UserTypeValues } from "../enums/prisma.enums";
import { ExtendedUser } from "../types/user/user.types";
import {
  AccessLevel,
  FULL_ACCESS_PERMISSIONS,
  getDataPermissionResolver,
  PartialGrant,
  PartialUser,
} from "./role.shared";
import { SLPA_USER_GRANTS } from "./role.slpa";
import { STUDENT_ROLE_GRANTS } from "./role.student";

export type UserCustomPermissionCheck = "has-common-district";

export type UserPermissions = {
  accessLevels: AccessLevel[];
  allowEditIf?: ((u: ExtendedUser, requestedBy: string) => boolean) | UserCustomPermissionCheck;
  allowDeleteIf?: ((u: ExtendedUser, requestedBy: string) => boolean) | UserCustomPermissionCheck;
  validateRequestor?: (u: PartialUser) => boolean;
};

export type DistrictUserPermissions = UserPermissions & {
  defaults?: { district: "currentUser.district" };
};

type UserRoleGrant<P = UserPermissions> = Partial<Record<UserType, P>>;

// i.e. granting permissions to various requesting user types to read/write provider users
const REQUESTED_PROVIDER_USER_GRANTS: UserRoleGrant = {
  ADMIN: FULL_ACCESS_PERMISSIONS,
  SITE_DIRECTOR: {
    accessLevels: ["read"],
  },
};

const REQUESTED_SITE_DIRECTOR_USER_GRANTS: UserRoleGrant<DistrictUserPermissions> = {
  ADMIN: FULL_ACCESS_PERMISSIONS,
};

const REQUESTED_FACILITATOR_USER_GRANTS: UserRoleGrant<DistrictUserPermissions> = {
  ADMIN: FULL_ACCESS_PERMISSIONS,
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowEditIf: "has-common-district",
    allowDeleteIf: (user, requestedBy) => user.createdBy === requestedBy,
  },
  SITE_DIRECTOR: {
    accessLevels: ["read", "write"],
    allowEditIf: "has-common-district",
    allowDeleteIf: "has-common-district",
    defaults: { district: "currentUser.district" },
  },
};

const getPartialGrants = (requestedType: UserType): Partial<Record<UserType, PartialGrant>> => {
  switch (requestedType) {
    case "ADMIN":
      return { ADMIN: FULL_ACCESS_PERMISSIONS };
    case "PROVIDER":
      return REQUESTED_PROVIDER_USER_GRANTS;
    case "FACILITATOR":
      return REQUESTED_FACILITATOR_USER_GRANTS;
    case "SITE_DIRECTOR":
      return REQUESTED_SITE_DIRECTOR_USER_GRANTS;
    case "SLPA":
      return SLPA_USER_GRANTS;
    case "STUDENT":
      return STUDENT_ROLE_GRANTS;
  }
};

const getPartialUserTypePermissions = (requestedType: UserType, user: PartialUser, accessLevel: AccessLevel) => {
  if (!user) return null;
  const grants = getPartialGrants(requestedType);
  return getDataPermissionResolver(grants)(user, accessLevel);
};

export const getValidUserTypes = (user: PartialUser, accessLevel: AccessLevel): UserType[] =>
  UserTypeValues.filter(userType => !!getPartialUserTypePermissions(userType, user, accessLevel));

export const getProviderPermissions = getDataPermissionResolver(REQUESTED_PROVIDER_USER_GRANTS);

export const getFacilitatorPermissions = getDataPermissionResolver(REQUESTED_FACILITATOR_USER_GRANTS);

export const getSiteDirectorPermissions = getDataPermissionResolver(REQUESTED_SITE_DIRECTOR_USER_GRANTS);
