import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AppointmentObservationFormPrompt = ({
  navigateToForm,
  onBack,
}: {
  navigateToForm: () => void;
  onBack: () => void;
}) => {
  return (
    <Stack gap={2} width={500}>
      <Typography variant="body1">
        This appointment type requires observation notes to be taken before it can be marked as{" "}
        <strong>Occurred</strong>.
      </Typography>
      <Typography>Please fill out the observation notes and then return here to update the session status.</Typography>
      <Stack direction="row" gap={2} mt={2}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onBack} fullWidth>
          Back
        </Button>
        <Button variant="contained" startIcon={<StickyNote2Icon />} onClick={navigateToForm} fullWidth>
          View Observation Notes
        </Button>
      </Stack>
    </Stack>
  );
};

export default AppointmentObservationFormPrompt;
