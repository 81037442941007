import { UserType } from "../enums/user.enums";
import { ExtendedTimeEntry } from "../types/calendar/time.types";
import { AccessLevel, getDataPermissionResolver } from "./role.shared";

export type TimeEntryPermissions = {
  accessLevels: AccessLevel[];
  allowIf: (a: ExtendedTimeEntry, userId: string) => boolean;
  defaults?: { userId: "currentUser" };
};

const TIME_PERMISSION_GRANTS: Partial<Record<UserType, TimeEntryPermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (a: ExtendedTimeEntry, userId: string) => a.userId === userId,
    defaults: { userId: "currentUser" },
  },
};

export const getTimeEntryPermissions = getDataPermissionResolver(TIME_PERMISSION_GRANTS);
