import { isEmpty } from "lodash";
import TurndownService from "turndown";
import { CopyContent } from "@parallel/polygon/components/shared/input/button.input";
import { AuthorizedUser } from "@parallel/vertex/types/auth.types";
import {
  ReportEditorBlock,
  ReportEditorParentSection,
  ReportEditorSubsection,
  SingleReport,
} from "@parallel/vertex/types/report.types";
import { filterExists, mapExists, mapSequence } from "@parallel/vertex/util/collection.util";
import { isSectionChildVisible } from "@parallel/vertex/util/report.util";

const parseListMarkdown = (node: Element, level: number = 0): string => {
  const tag = node.tagName.toLowerCase();

  const getListItemString = (child: Element, itemIndex: number) => {
    const decorator = tag === "ul" ? "*" : `${itemIndex + 1}:`;
    const tabs = mapSequence(level, () => "\t").join("");
    return `${tabs}${decorator} ${child.textContent}`;
  };

  const entries = mapExists([...node.children], (child, i) => {
    switch (child.tagName.toLowerCase()) {
      case "ul":
      case "ol":
        return parseListMarkdown(child, level + 1);
      case "li":
        // Tiptap editor list items could include nested <p> and list tags
        const itemEntries = mapExists([...child.children], itemChild => {
          switch (itemChild.tagName.toLowerCase()) {
            case "p":
              return getListItemString(itemChild, i);
            case "ul":
            case "ol":
              return parseListMarkdown(itemChild, level + 1);
          }
        });
        // If no <p> or list tags in the <li>, we are parsing template content and can treat the item singularly
        return !isEmpty(itemEntries) ? itemEntries.join("\n") : getListItemString(child, i);
    }
  });

  return entries.join("\n");
};

const turndownService = new TurndownService().addRule("lists", {
  filter: ["ul", "ol"],
  replacement: (_, node: any) => node.tagName && parseListMarkdown(node),
});

export const getBlockTableText = (block: ReportEditorBlock): string => {
  if (!block.table) return "";

  const headerValues = block.table.columns.map(c => c.header);
  const headerLine = headerValues.join(" | ");

  const tableRows = block.custom?.tableRows || block.table.defaultRows;
  const rowsLines = tableRows?.map(row => row.cellValues.join(" | ")) || [];

  return [headerLine, ...rowsLines].join("\n");
};

export const getBlockTableHtml = (block: ReportEditorBlock): string => {
  if (!block.table) return "";

  const headerValues = block.table.columns.map(c => c.header);
  const headerLine = headerValues.map(c => `<th>${c}</th>`).join("");

  const tableRows = block.custom?.tableRows || block.table.defaultRows;
  const rowsLines = tableRows?.map(row => row.cellValues.map(c => `<td>${c}</td>`).join("")) || [];

  const allRows = [headerLine, ...rowsLines].map(r => `<tr>${r}</tr>`).join("\n");
  return `<table>${allRows}</table>`;
};

export const getBlockContentMarkdown = (contentHtml: string): string =>
  turndownService.turndown(contentHtml).replace(/\\_/g, "_");

export const getBlockMarkdown = (block: ReportEditorBlock, withTable: boolean = false): string => {
  const contentHtml = block.custom?.content || block.content;

  const contentLines = filterExists([
    contentHtml && getBlockContentMarkdown(contentHtml),
    withTable && getBlockTableText(block),
  ]);

  return !isEmpty(contentLines) ? contentLines.join("\n\n") : "";
};

export const getSubsectionMarkdown = (section: ReportEditorSubsection): string => {
  const blockStrings = section.blocks.map(b => getBlockMarkdown(b, true));
  return [`### ${section.title}`, ...blockStrings].join("\n\n");
};

export const getParentSectionMarkdown = (report: SingleReport, section: ReportEditorParentSection): string => {
  const childrenStrings = section.children
    .filter(item => isSectionChildVisible(report, item))
    .map(child => {
      switch (child.type) {
        case "subsection":
          return child.custom?.isHidden ? undefined : getSubsectionMarkdown(child);
        case "block":
          return getBlockMarkdown(child);
      }
    });
  return [`## ${section.title}`, ...childrenStrings].join("\n\n");
};

export const getBlockHtml = (block: ReportEditorBlock, withTable: boolean = false): string => {
  const contentHtml = block.custom?.content || block.content;

  const contentLines = filterExists([contentHtml, withTable && getBlockTableHtml(block)]);

  return !isEmpty(contentLines) ? contentLines.join("\n") : "";
};

export const getSubsectionHtml = (section: ReportEditorSubsection): string => {
  const blockStrings = section.blocks.map(b => getBlockHtml(b, true));
  return [`<h3>${section.title}</h3>`, ...blockStrings].join("\n");
};

export const getParentSectionHtml = (report: SingleReport, section: ReportEditorParentSection): string => {
  const childrenStrings = section.children
    .filter(item => isSectionChildVisible(report, item))
    .map(child => {
      switch (child.type) {
        case "subsection":
          return child.custom?.isHidden ? undefined : getSubsectionHtml(child);
        case "block":
          return getBlockHtml(child);
      }
    });
  return [`<h2>${section.title}</h2>`, ...childrenStrings].join("\n");
};

export const getBlockTableCopyContent = (block: ReportEditorBlock, user?: AuthorizedUser): CopyContent =>
  user?.featureFlags.useHtmlReportClipboard
    ? { value: getBlockTableHtml(block), type: "text/html" }
    : { value: getBlockTableText(block), type: "text/plain" };

export const getSubsectionCopyContent = (section: ReportEditorSubsection, user?: AuthorizedUser): CopyContent =>
  user?.featureFlags.useHtmlReportClipboard
    ? { value: getSubsectionMarkdown(section), type: "text/html" }
    : { value: getSubsectionHtml(section), type: "text/plain" };

export const getParentSectionCopyContent = (
  report: SingleReport,
  section: ReportEditorParentSection,
  user?: AuthorizedUser,
): CopyContent =>
  user?.featureFlags.useHtmlReportClipboard
    ? { value: getParentSectionHtml(report, section), type: "text/html" }
    : { value: getParentSectionMarkdown(report, section), type: "text/plain" };
