import type { ProductCode, UserType } from "@prisma/client";
import { getProductCodeProviderType } from "../util/service.util";
import { AccessLevel, getDataPermissionResolver, PartialUser } from "./role.shared";

type PartialService = { productCode: ProductCode };

type ServicePermissions = {
  accessLevels: AccessLevel[];
  allowIf: (service: PartialService, user: PartialUser) => boolean;
  defaults?: { provider: "currentUser" };
};

export const SERVICE_ROLE_GRANTS: Partial<Record<UserType, ServicePermissions>> = {
  ADMIN: {
    accessLevels: ["read", "write"],
    allowIf: () => true,
  },
  PROVIDER: {
    accessLevels: ["read", "write"],
    allowIf: (service, user) => getProductCodeProviderType(service.productCode) === user?.providerType,
    defaults: { provider: "currentUser" },
  },
};

export const getServicePermissions = getDataPermissionResolver(SERVICE_ROLE_GRANTS);
