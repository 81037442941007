import type { ProductCode, ProviderType, RequirementCadence } from "@prisma/client";
import { ProviderTypeValues } from "../enums/prisma.enums";
import { DateTimeRange } from "../types/shared.types";

const toLuxonDurationKey = (unit: RequirementCadence) => {
  switch (unit) {
    case "DAILY":
      return "days";
    case "MONTHLY":
      return "months";
    case "WEEKLY":
      return "weeks";
    case "YEARLY":
      return "years";
  }
};

export const getTotalCadenceMinutes = (
  minutes: number,
  unit: RequirementCadence,
  size: number,
  timeRange: DateTimeRange,
): number => {
  const durationKey = toLuxonDurationKey(unit);

  let periodCount = 0;
  let currentTime = timeRange.startTime;
  while (currentTime < timeRange.endTime) {
    const nextTime = currentTime.plus({ [durationKey]: size });
    if (nextTime > timeRange.endTime) {
      periodCount += timeRange.endTime.diff(currentTime, durationKey)[durationKey];
    } else {
      periodCount += 1;
    }
    currentTime = nextTime;
  }

  return Math.round(periodCount * minutes);
};

export const getProductCodeProviderType = (productCode: ProductCode): ProviderType | undefined => {
  const stringValue = productCode.split("_")[0];
  return ProviderTypeValues.find(providerType => providerType === stringValue);
};
