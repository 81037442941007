import { useContext } from "react";
import { useNavigate } from "react-router";
import UndoIcon from "@mui/icons-material/Undo";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { StoreContext } from "@/stores";

const ReportRevertPrompt = ({ onClose }: { onClose: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport },
  } = useContext(StoreContext);

  const navigate = useNavigate();

  if (!currentReport) return <></>;

  const performRevert = async () => {
    await reportApi.deleteReport(currentReport.reportId);
    navigate(`/report/start/${currentReport.serviceLineClientId}`);
  };

  return (
    <PromptLayout
      headerText="Revert Report"
      leftAction={{ fn: onClose, label: "Cancel" }}
      rightAction={{ fn: performRevert, label: "Revert", icon: <UndoIcon /> }}
    >
      <Stack width={400} gap={2}>
        <Typography variant="body1">
          This will take you to fill out a new Testing Plan and start a new report for this student.
        </Typography>
        <Typography variant="body1">
          Your current Testing Plan, uploaded score reports, interview notes, other documents, and any edits you’ve made
          to the current report will be deleted.
        </Typography>
      </Stack>
    </PromptLayout>
  );
};

export default ReportRevertPrompt;
