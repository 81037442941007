import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { StyledMenuItem } from "@/components/shared/display/menu";

export type ToggleMenuItemProps = { toggle: () => void; isEnabled: boolean; text: string };

const ToggleMenuItem = ({ toggle, isEnabled, text }: ToggleMenuItemProps) => {
  return (
    <StyledMenuItem onClick={toggle}>
      {isEnabled && (
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
      )}
      <ListItemText inset={!isEnabled}>{text}</ListItemText>
    </StyledMenuItem>
  );
};

export default ToggleMenuItem;
