import type { User } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { UserTypeValues } from "../../enums/prisma.enums";
import { Override, paginateQueryFields, sortQueryFields } from "../shared.types";

export type { UserArchiveReason } from "@prisma/client";

export type ExtendedUser = Override<
  User & { districtId?: string; districtName?: string; fullName: string },
  {
    createdAt: DateTime;
    // ! Override for serialization
    archivedAt?: DateTime | null;
    archiveReasonId?: string | null;
    createdBy?: string;
    isActive?: boolean;
    passwordHash?: string | null;
    phoneNumber?: string | null;
    updatedAt?: DateTime;
  }
>;

export const searchUserQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  districtName: z.string().trim().optional(),
  districtUserId: z.string().uuid().optional(),
  userTypes: z.enum(UserTypeValues).array().optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchUserQuery = z.infer<typeof searchUserQuerySchema> & {
  updatedAfter?: DateTime;
  email?: string;
};

export const createUserBodySchema = z.object({
  userType: z.enum(["ADMIN", "FACILITATOR", "SITE_DIRECTOR"]),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email().trim().toLowerCase(),
  districtId: z.string().uuid().optional(),
  sendWelcomeEmail: z.boolean().optional(),
});

export type CreateUserBody = z.infer<typeof createUserBodySchema>;

export const updateUserBodySchema = createUserBodySchema.omit({ userType: true }).partial();

export type UpdateUserBody = z.infer<typeof updateUserBodySchema>;
