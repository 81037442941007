import { ReactNode, useContext } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventIcon from "@mui/icons-material/Event";
import { Tooltip } from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { FullGrid } from "@parallel/polygon/components/shared/layout/container";
import {
  CommonProgressItem,
  getObjectiveTitle,
  ObjectiveDisplayIndices,
  ProgressItemType,
} from "@parallel/polygon/util/progress.util";
import {
  ObjectiveMetricValue,
  SingleStudentAppointmentProgress,
  StudentObjective,
} from "@parallel/vertex/types/progress.types";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import { secondsToString, toPercentString } from "@parallel/vertex/util/number.util";
import { getStatusRowText, stepValuesSchema } from "@parallel/vertex/util/progress.util";
import { StoreContext } from "@/stores";

const AppointmentEntryRow = ({
  leftContent,
  rightContent,
  borderBottom = 1,
}: {
  leftContent: ReactNode;
  rightContent?: ReactNode;
  borderBottom?: number;
}) => (
  <FullGrid container borderBottom={borderBottom} borderColor="grey.300" px={2} py={2} alignItems="center">
    <Grid size={{ xs: 6 }}>{leftContent}</Grid>
    <Grid size={{ xs: 6 }} pl={4}>
      {rightContent}
    </Grid>
  </FullGrid>
);

const ObjectiveTitleContent = ({
  objective,
  displayIndices,
}: {
  objective: StudentObjective;
  displayIndices: ObjectiveDisplayIndices;
}) => (
  <Tooltip title={objective.description} placement="bottom-start">
    <Typography variant="h3" mb={0.5} sx={{ cursor: "pointer" }}>
      {getObjectiveTitle(objective, displayIndices)}
    </Typography>
  </Tooltip>
);

const MetricEntryContent = ({ objective, metric }: { objective: StudentObjective; metric: ObjectiveMetricValue }) => {
  if (metric.type === "number") {
    switch (objective.category) {
      case "ACCURACY":
        const steps = stepValuesSchema.safeParse(metric.metadata)?.data;
        return (
          metric.type === "number" && (
            <Stack direction="row" gap={4}>
              <Typography variant="body1">{toPercentString(metric.value)}</Typography>
              {steps && (
                <Stack direction="row" gap={2}>
                  (<Typography variant="body1">{steps.correct} Correct</Typography> /
                  <Typography variant="body1">{steps.incorrect} Incorrect</Typography> /
                  <Typography variant="body1">{steps.prompt} Prompt</Typography>)
                </Stack>
              )}
            </Stack>
          )
        );
      case "DURATION":
        return <Typography variant="body1">{secondsToString(metric.value)} Duration</Typography>;
      case "DECREASING_FREQUENCY":
      case "INCREASING_FREQUENCY":
      case "PROMPTING_FREQUENCY":
        return <Typography variant="body1">{metric.value} Occurrences</Typography>;
    }
  } else if (objective.category === "ANECDOTAL") {
    return <Typography variant="body1">{metric.value}</Typography>;
  }

  return (
    <Typography variant="body1" fontStyle="italic">
      Invalid Data
    </Typography>
  );
};

export type ItemFilterFn = (item: CommonProgressItem & { id: string; type: ProgressItemType }) => boolean;

const AppointmentProgressData = ({
  progress,
  shouldIncludeItem,
}: {
  progress: SingleStudentAppointmentProgress;
  shouldIncludeItem: ItemFilterFn;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const objectiveRowProps = progress.goals.flatMap((goal, i) => {
    if (!shouldIncludeItem({ ...goal, type: "goal", id: goal.goalId })) return [];

    return mapExists(goal.objectives, (objective, j) => {
      if (!objective.metric || !shouldIncludeItem({ ...objective, type: "objective", id: objective.objectiveId }))
        return;

      return {
        left: <ObjectiveTitleContent objective={objective} displayIndices={{ goal: i + 1, objective: j + 1 }} />,
        right: <MetricEntryContent objective={objective} metric={objective.metric} />,
      };
    });
  });

  const getTextRow = ({ leftText, rightText }: { leftText: string; rightText: string }) => ({
    left: <Typography variant="h3">{leftText}</Typography>,
    right: <Typography variant="body1">{rightText}</Typography>,
  });
  const statusRowText = getStatusRowText(progress.appointment);
  const rows = filterExists([
    progress.note && getTextRow({ leftText: "Student Notes", rightText: progress.note }),
    progress.groupNote && getTextRow({ leftText: "Group Notes", rightText: progress.groupNote }),
    ...objectiveRowProps,
    statusRowText && getTextRow(statusRowText),
  ]);

  if (isEmpty(rows)) return <></>;

  return (
    <Stack width="100%">
      <FullGrid
        container
        alignItems="center"
        px={2}
        py={1}
        sx={{ backgroundColor: "surface.light", borderWidth: "0 0 1px 0", borderColor: "grey.300" }}
      >
        <Grid size={{ xs: 6 }}>
          <Stack direction="row" gap={1.5} alignItems="center">
            <EventIcon sx={{ fontSize: "20px", color: "text.secondary" }} />
            <Typography variant="body1">
              {progress.appointment.startTime.setZone(timezone).toLocaleString(DateTime.DATETIME_FULL)}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {progress.appointment.title}
            </Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: 6 }} pl={2}>
          <Chip
            label={
              <Stack direction="row" gap={1} alignItems="center">
                <AccountCircleIcon sx={{ fontSize: "18px", color: "text.secondary" }} />
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {progress.appointment.providerName}
                </Typography>
              </Stack>
            }
            sx={{ borderRadius: 1 }}
          />
        </Grid>
      </FullGrid>

      {isEmpty(rows) ? (
        <AppointmentEntryRow
          leftContent={
            <Typography variant="body1" fontStyle="italic">
              No Data
            </Typography>
          }
          borderBottom={0}
        />
      ) : (
        rows.map(({ left, right }, i) => (
          <AppointmentEntryRow
            leftContent={left}
            rightContent={right}
            borderBottom={i === objectiveRowProps.length - 1 ? 0 : 1}
            key={i}
          />
        ))
      )}
    </Stack>
  );
};

export default AppointmentProgressData;
