import { useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { mapSequence } from "@parallel/vertex/util/collection.util";
import CalendarBackground from "@/components/calendar/CalendarBackground";
import CalendarColumn from "@/components/calendar/CalendarColumn";
import { StoreContext } from "@/stores";

const CalendarWeekView = ({ date }: { date: DateTime }) => {
  const {
    calendarStore: { zoomFactor, isShowingWeekends },
  } = useContext(StoreContext);

  const height = percentString(zoomFactor);

  const theme = useTheme();

  const weekDates = mapSequence(isShowingWeekends ? 7 : 5, i => date.plus({ days: i }));

  const parentContainerRef = useRef<HTMLDivElement>(null);

  return (
    <FullBox
      role="main"
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.bordergrey.light,
      }}
    >
      <Stack
        direction="row"
        sx={{
          height: "30px",
          width: "100%",
          paddingLeft: 12,
          borderBottom: 1,
          borderColor: theme.palette.bordergrey.light,
          overflowY: "auto",
          scrollbarGutter: "stable",
        }}
      >
        {weekDates.map((date, i) => (
          <FullBox
            key={i}
            sx={{
              alignContent: "center",
              justifyContent: "center",
              borderLeft: 1,
              borderColor: theme.palette.bordergrey.light,
            }}
          >
            <Typography variant="body1" textAlign="center">
              {date.toFormat("ccc d")}
            </Typography>
          </FullBox>
        ))}
      </Stack>

      <Box
        ref={parentContainerRef}
        sx={{
          height: "calc(100% - 30px)",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            height: `calc(${height} - 30px)`,
            width: "100%",
            position: "relative",
          }}
        >
          <CalendarBackground key="week-view" parentContainerRef={parentContainerRef} />
          <FullStack direction="row" sx={{ position: "absolute", top: 0, paddingLeft: 12 }}>
            {weekDates.map((date, i) => (
              <FullBox sx={{ borderLeft: 1, borderColor: theme.palette.bordergrey.light }} key={i}>
                <CalendarColumn date={date} />
              </FullBox>
            ))}
          </FullStack>
        </Box>
      </Box>
    </FullBox>
  );
};

export default observer(CalendarWeekView);
