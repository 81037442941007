import { ApprovalStatus } from "@prisma/client";
import { intersection, isArray, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { NoOverrideGlobalStaticConfig } from "../config/static.config";
import { InterviewFormType } from "../enums/report.enums";
import { ExtendedAppointment, SearchAppointmentsQuery } from "../types/calendar/appointment.types";
import {
  ReportEditorBlock,
  ReportEditorParentSection,
  ReportEditorSection,
  ReportEditorSectionChild,
  ReportEditorSubsection,
  ReportFormSubmissions,
  ReportInterviewForm,
  ReportNeedGroup,
  SingleReport,
} from "../types/report.types";
import { filterExists, mapExists } from "./collection.util";
import { getCommaList } from "./string.util";

export const getFlattenedBlocks = (report: SingleReport): (ReportEditorBlock & { parentSectionId: string })[] =>
  report.editorSections.flatMap(section =>
    section.children.flatMap(child => {
      switch (child.type) {
        case "block":
          return [{ ...child, parentSectionId: section.reportSectionTemplateId }];
        case "subsection":
          return child.blocks.map(b => ({ ...b, parentSectionId: child.reportSectionTemplateId }));
      }
    }),
  );

type PartialReport = {
  testUploads: { reportTestUploadSourceId: string; skipReasonId?: string | null }[];
  formSubmissions: ReportFormSubmissions;
};

const getTestingPlanIncludeFilter =
  (fieldKey: string, optionKeys: string[], test: "and" | "or" = "or") =>
  (report: PartialReport): boolean => {
    if (!report.formSubmissions.testingPlanAnswers) return false;

    const field = report.formSubmissions.testingPlanAnswers[fieldKey];
    if (!isArray(field)) return false;

    switch (test) {
      case "and":
        return !optionKeys.some(k => !field.includes(k));
      case "or":
        return optionKeys.some(k => field.includes(k));
    }
  };

const CUSTOM_SECTION_FILTERS: { title: string; filter: (r: PartialReport) => boolean }[] = [
  {
    title: "Functional Communication Profile",
    filter: getTestingPlanIncludeFilter("criterion-measures", ["functional_comm_profile"]),
  },
  {
    title: "Speech/Language Sample",
    filter: getTestingPlanIncludeFilter("criterion-measures", ["speech_sample", "language_sample"]),
  },
  {
    title: "EVT-3 & PPVT-5 Comparison",
    filter: getTestingPlanIncludeFilter("performance-measures", ["evt-3", "ppvt-5"], "and"),
  },
  {
    title: "Table K: PPVT-5 / EVT-3 Receptive-Expressive Comparison Score Summary",
    filter: getTestingPlanIncludeFilter("performance-measures", ["evt-3", "ppvt-5"], "and"),
  },
  {
    title: "Caregiver, Teacher, & Student Input",
    filter: getTestingPlanIncludeFilter("non-test-measure", [
      "pl_clinical_interview",
      "pl_collateral_interview",
      "pl_teacher_interview",
    ]),
  },
];

// ? common visiblity logic shared between all report parent / child sections + blocks
const isEditorItemVisible = (report: PartialReport, item: { testUploadSourceIds: string[] }): boolean => {
  // item is visible if it does not include any test upload source ids
  if (isEmpty(item.testUploadSourceIds)) return true;

  // item is visible if any of the test upload source ids are present on the report
  const enabledUploadSourceIds = report.testUploads.filter(u => !u.skipReasonId).map(u => u.reportTestUploadSourceId);
  const matchingUploadSourceIds = intersection(enabledUploadSourceIds, item.testUploadSourceIds);

  return !isEmpty(matchingUploadSourceIds);
};

export const isSectionVisible = (report: PartialReport, section: ReportEditorSection): boolean => {
  if (!!section.custom && !section.custom.isHidden) return true;

  const customFilter = CUSTOM_SECTION_FILTERS.find(f => section.title === f.title)?.filter;
  if (customFilter) return customFilter(report);

  const manualTests = getManualTestNamesBySection(section.reportSectionTemplateId);
  if (!isEmpty(manualTests) && isEmpty(section.testUploadSourceIds)) return false;

  return isEditorItemVisible(report, section);
};

export const isSectionChildVisible = (report: PartialReport, child: ReportEditorSectionChild): boolean => {
  switch (child.type) {
    case "subsection":
      return isSectionVisible(report, child);
    case "block":
      return isEditorItemVisible(report, child);
  }
};

export const getParentSectionVisiblity = (report: SingleReport, section: ReportEditorParentSection) => {
  const allSubsections = mapExists(section.children, c => c.type === "subsection" && (c as ReportEditorSubsection));
  const visibleSubsections = allSubsections.filter(s => isSectionVisible(report, s));

  const nonHiddenSubsections = visibleSubsections.filter(s => !s.custom?.isHidden);
  const areAllSubsectionsHidden = isEmpty(nonHiddenSubsections) && !isEmpty(allSubsections);
  const isDisabled = !isEditorItemVisible(report, section);

  return {
    isHidden: areAllSubsectionsHidden || isDisabled,
    visibleSubsections,
  };
};

export const resolveBlockContentWarning = (block: ReportEditorBlock): string | null =>
  (!block.custom?.content && block.emptyWarningNoteText) || null;

export const isCustomTableEmpty = (block: ReportEditorBlock): boolean =>
  isEmpty(block.custom?.tableRows?.filter(r => !isEmpty(filterExists(r.cellValues))));

export const resolveBlockTableWarning = (block: ReportEditorBlock): string | null => {
  if (isCustomTableEmpty(block) && block.table?.emptyWarningNoteText) return block.table.emptyWarningNoteText;

  const warningColumn = block.table?.columns.find(
    (c, i) => c.emptyWarningNoteText && !block.custom?.tableRows?.some(r => r.cellValues[i]),
  );
  return warningColumn?.emptyWarningNoteText || null;
};

export const getTestingSessionAppointmentQuery = (
  report: SingleReport,
  config: NoOverrideGlobalStaticConfig,
): SearchAppointmentsQuery => ({
  providerId: report.providerId,
  studentId: report.clientId,
  appointmentTypeIds: [config.testingSessionAppointmentTypeId],
  appointmentStatus: ["OCCURRED"],
});

export const getGeneralInformationData = (
  { client: { fullName, birthDate, pronouns, campus, grade }, provider, assessmentDueDate }: SingleReport,
  testingSessions?: ExtendedAppointment[],
): { clientData: [string, string][]; reportData: [string, string][] } => {
  let birthDateString: string = "";
  if (birthDate) {
    const parsedBirthDate = birthDate?.toLocaleString(DateTime.DATE_FULL);
    const ageYears = Math.floor(Math.abs(birthDate.diffNow("years").years));
    birthDateString = `${parsedBirthDate} (Age ${ageYears})`;
  }
  return {
    clientData: [
      ["Student Name", fullName],
      ["Date of Birth", birthDateString],
      ["Gender Pronouns", pronouns || ""],
      ["Campus", campus?.name || ""],
      ["Grade", grade || ""],
    ],
    reportData: [
      ["Clinician", provider.fullName],
      ["Assessment Dates", testingSessions?.map(a => a.startTime.toLocaleString(DateTime.DATE_SHORT)).join(", ") ?? ""],
      ["Report Due Date", assessmentDueDate.toLocaleString(DateTime.DATE_SHORT)],
    ],
  };
};

export const getNeedGroupContent = (needGroup: ReportNeedGroup) => {
  const listItems = needGroup.recommendations.map(r => `<li>${r.text}</li>`);
  const content = needGroup.content || `<ul>${listItems.join("")}</ul>`;

  return {
    content,
    header: getCommaList(
      needGroup.needs.map(n => n.name),
      { delimiter: "&" },
    ),
  };
};

type ApprovalAction = { statusUpdate: ApprovalStatus; label: string };

export const getApprovalActions = (report: SingleReport, userId: string, isReviewer: boolean): ApprovalAction[] => {
  switch (report.approval?.approvalStatus) {
    case undefined:
    case "FOR_REVIEW":
      return report.providerId === userId ? [{ statusUpdate: "PENDING", label: "Submit Report" }] : [];
    case "PENDING":
      return isReviewer
        ? [
            { statusUpdate: "APPROVED", label: "Approve Report" },
            { statusUpdate: "FOR_REVIEW", label: "Notify Provider" },
          ]
        : [];
    case "DENIED":
      return isReviewer ? [{ statusUpdate: "APPROVED", label: "Approve Report" }] : [];
    default:
      return [];
  }
};

export type InterviewFormKey = "caregiverInterview" | "studentInterview" | "teacherInterview";

export const getInterviewFormKey = (interviewType: InterviewFormType): InterviewFormKey => {
  switch (interviewType) {
    case "caregiver":
      return "caregiverInterview";
    case "student":
      return "studentInterview";
    case "teacher":
      return "teacherInterview";
  }
};

export const getInterviews = (
  report: SingleReport | undefined,
  interviewType: InterviewFormType,
): ReportInterviewForm[] => {
  if (!report) return [];
  switch (interviewType) {
    case "caregiver":
      return report.formSubmissions.caregiverInterview;
    case "student":
      return filterExists([report.formSubmissions.studentInterview]);
    case "teacher":
      return report.formSubmissions.teacherInterview;
  }
};

export const INTERVIEW_TYPE_LABELS = {
  caregiver: "Caregiver",
  student: "Student",
  teacher: "Teacher",
};

const MANUAL_TESTS = [
  {
    name: "MASC",
    sectionIds: [
      "2a57ddc5-282e-4de1-a990-57a120e6305a",
      "ef07f36e-576b-47d2-8e14-b9f7e2d34586",
      "a2d1b6d4-0b86-439c-bd3f-74cf54724cd7",
      "9b1cec59-1c8d-4b99-b705-40bf21999756",
      "d5fe2f5c-6154-401a-9b2a-c0c6e2b80dab",
      "29e5dc9f-554e-42e2-b023-b63520960b8f",
      "f826462a-4dfd-4d3b-8091-239aa1737a29",
    ],
  },
  {
    name: "ABAS",
    sectionIds: ["2a57ddc5-282e-4de1-a990-57a120e6305a", "ef07f36e-576b-47d2-8e14-b9f7e2d34586"],
  },
  {
    name: "Raven's 2",
    sectionIds: ["188c8787-0f98-42c5-84f8-aeb3d340fa07"],
  },
  {
    name: "BDI-2",
    sectionIds: ["d5fe2f5c-6154-401a-9b2a-c0c6e2b80dab", "29e5dc9f-554e-42e2-b023-b63520960b8f"],
  },
  {
    name: "CDI-2",
    sectionIds: [
      "2a57ddc5-282e-4de1-a990-57a120e6305a",
      "ef07f36e-576b-47d2-8e14-b9f7e2d34586",
      "d5fe2f5c-6154-401a-9b2a-c0c6e2b80dab",
      "f826462a-4dfd-4d3b-8091-239aa1737a29",
    ],
  },
  {
    name: "KTEA-3",
    sectionIds: [
      "ec59f883-3a2e-4604-b28b-6b71472f74da",
      "216dfb1d-c0fe-4785-b621-13bc15ed0244",
      "f311d23b-29ab-4215-9937-af33e03b9f5b",
    ],
  },
  {
    name: "CASL-2",
    sectionIds: ["a826cc6c-55c2-4a51-b652-05307847e2a7"],
  },
  {
    name: "AACP",
    sectionIds: ["6d0a5ba5-27f3-4d5c-96ad-4a96ac588f0d"],
  },
];

export const getManualTestNamesBySection = (sectionId: string) =>
  MANUAL_TESTS.filter(({ sectionIds }) => sectionIds.includes(sectionId)).map(t => t.name);
