import ArchiveIcon from "@mui/icons-material/Archive";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PushPinIcon from "@mui/icons-material/PushPin";
import Stack from "@mui/material/Stack";
import { lighten, SxProps, useTheme } from "@mui/material/styles";
import { isEmpty, isNull } from "lodash";
import { DateTime } from "luxon";
import { StudentGoal, StudentObjective, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import { ListMenuItem, VerticalToggleMode } from "../components/shared/layout/ListMenu";

export type ProgressItemType = "goal" | "objective";

export type CommonItemFields = {
  type: ProgressItemType;
  description: string;
  completedBy: string | null;
  isPinned?: boolean;
  isArchived: boolean;
};

export type ProgressMenuItem = Override<
  ListMenuItem<string>,
  {
    displayIndex: number;
    children?: ProgressMenuItem[];
  }
>;

type GoalSelection = { record: StudentGoal; item: ProgressMenuItem };

export type ProgressSelection = {
  goal: GoalSelection;
  objective?: { record: StudentObjective; item: ProgressMenuItem };
};

type MenuItemsResponse = { menuItems: ProgressMenuItem[]; selection?: ProgressSelection };

export type ObjectiveDisplayIndices = { goal: number; objective: number };

export const getObjectiveTitle = (objective: StudentObjective, displayIndices: ObjectiveDisplayIndices): string =>
  `${displayIndices.goal}.${displayIndices.objective} ${toTitleCase(objective.category)} Objective`;

const EndIcons = ({
  isComplete,
  isPinned,
  togglePinned,
}: {
  isComplete: boolean;
  isPinned: boolean | null;
  togglePinned: () => void;
}) => (
  <Stack direction="row" gap={0.5}>
    {isComplete ? <CheckCircleIcon sx={{ color: "success.main" }} /> : undefined}
    {!isNull(isPinned) && (
      <PushPinIcon
        onClick={e => {
          togglePinned();
          e.stopPropagation();
        }}
        sx={{
          color: isPinned ? "primary.main" : "grey.300",
          "&:hover": { color: "primary.dark" },
        }}
      />
    )}
  </Stack>
);

const getObjectiveMenuItems = (
  objectives: StudentObjective[],
  goal: GoalSelection,
  selectedItemId: string | undefined,
  doesItemMatchFilters: (item: CommonItemFields) => boolean = () => true,
  updateGoal: (goalId: string, update: UpdateGoalBody) => void,
): MenuItemsResponse => {
  let selection: ProgressSelection | undefined = undefined;
  const menuItems = mapExists(objectives, (objective, j) => {
    const objectiveIndex = j + 1;
    if (!doesItemMatchFilters({ type: "objective", ...objective })) return;

    const objectiveItem = {
      key: objective.objectiveId,
      prefix: `${goal.item.displayIndex}.${objectiveIndex}`,
      content: objective.description,
      endIcon: (
        <EndIcons
          isComplete={!!objective.completedAt}
          isPinned={objective.isPinned}
          togglePinned={() =>
            updateGoal(goal.record.goalId, {
              objectives: [{ objectiveId: objective.objectiveId, isPinned: !objective.isPinned }],
            })
          }
        />
      ),
      displayIndex: objectiveIndex,
      canSelect: true,
    };

    if (objective.objectiveId === selectedItemId)
      selection = { goal, objective: { record: objective, item: objectiveItem } };

    return objectiveItem;
  });

  return { menuItems, selection };
};

export const getProgressMenuItems = (
  goals: StudentGoal[],
  selectedItemId: string | undefined,
  doesItemMatchFilters: (item: CommonItemFields) => boolean = () => true,
  updateGoal: (goalId: string, update: UpdateGoalBody) => void,
  { goalMode = "interactive" }: { goalMode?: "interactive" | "static" } = {},
): MenuItemsResponse => {
  let selection: ProgressSelection | undefined = undefined;

  let goalVerticalToggleMode: VerticalToggleMode | undefined = undefined;
  switch (goalMode) {
    case "interactive":
      goalVerticalToggleMode = "children";
      break;
    case "static":
      goalVerticalToggleMode = "content";
      break;
  }

  const menuItems = goals.flatMap((goal, i) => {
    const goalItem: ProgressMenuItem = {
      key: goal.goalId,
      prefix: `${i + 1}`,
      content: goal.description,
      endIcon: (
        <EndIcons
          isComplete={!!goal.completedAt}
          isPinned={goalMode === "static" ? null : goal.isPinned}
          togglePinned={() => updateGoal(goal.goalId, { isPinned: !goal.isPinned })}
        />
      ),
      displayIndex: i + 1,
      verticalToggleMode: goalVerticalToggleMode,
      disableSelect: goalMode === "static",
    };
    const goalSelection = { record: goal, item: goalItem };

    const { menuItems: objectiveItems, selection: objectiveSelection } = getObjectiveMenuItems(
      goal.objectives,
      goalSelection,
      selectedItemId,
      doesItemMatchFilters,
      updateGoal,
    );
    if (objectiveSelection) selection = objectiveSelection;

    if (!doesItemMatchFilters({ type: "goal", ...goal }) && !isEmpty(goal.objectives) && isEmpty(objectiveItems))
      return [];

    if (goal.goalId === selectedItemId) selection = { goal: goalSelection };
    return [{ ...goalItem, children: objectiveItems }];
  });

  return { menuItems, selection };
};

export const resolveObjectiveCompletionToggleUpdate = (
  objectiveId: string,
  isCompleted: boolean,
  parentGoal: StudentGoal,
): UpdateGoalBody => {
  const isParentCompleted = !!parentGoal.completedAt;
  const allObjectivesCompleted =
    isCompleted && parentGoal.objectives.every(o => o.objectiveId === objectiveId || !!o.completedAt);

  return {
    // ensure goal status matches all child objectives
    isCompleted: isParentCompleted !== allObjectivesCompleted ? isCompleted : undefined,
    objectives: [{ objectiveId, isCompleted }],
  };
};

export type ProgressUpdateOperation = "complete" | "archive";

export const getOperationResultName = (operation: ProgressUpdateOperation): string => {
  switch (operation) {
    case "complete":
      return "achieved";
    case "archive":
      return "archived";
  }
};

export type CommonProgressItem = {
  description: string;
  isPinned?: boolean;
  completedAt?: DateTime;
  isArchived?: boolean;
};

export const getProgressIcon = (item: CommonProgressItem, { size = "small" }: { size?: "small" } = {}) => {
  if (item.isArchived) return <ArchiveIcon fontSize={size} sx={{ color: "grey.600" }} />;
  if (item.completedAt) return <CheckCircleIcon fontSize={size} sx={{ color: "success.main" }} />;
  return <BarChartIcon fontSize={size} sx={{ color: "grey.600" }} />;
};

const ITEM_BG_COLORS = {
  goal: {
    default: undefined,
    archived: "grey.50",
    successLightness: 0.9,
  },
  objective: {
    default: "highlight.light",
    archived: "grey.100",
    successLightness: 0.7,
  },
  objectiveHover: {
    default: "highlight.main",
    archived: "grey.200",
    successLightness: 0.5,
  },
};

export const useListItemColorSx = (item: CommonProgressItem, itemType: ProgressItemType, isLink?: boolean): SxProps => {
  const theme = useTheme();
  const colors = ITEM_BG_COLORS[itemType];

  const getSuccessColor = (lightness: number) => lighten(theme.palette.success.light, lightness);

  const hoverColors = ITEM_BG_COLORS.objectiveHover;
  const getObjectiveHoverSx = (colorType: "default" | "archived" | "success") => {
    if (itemType === "goal" || !isLink) return undefined;
    const bgcolor = colorType === "success" ? getSuccessColor(hoverColors.successLightness) : hoverColors[colorType];
    return { "&:hover": { bgcolor } };
  };

  if (item.isArchived) {
    return {
      bgcolor: colors.archived,
      ...getObjectiveHoverSx("archived"),
    };
  }
  if (item.completedAt) {
    return {
      bgcolor: getSuccessColor(colors.successLightness),
      ...getObjectiveHoverSx("success"),
    };
  }
  return {
    bgcolor: ITEM_BG_COLORS[itemType].default,
    ...getObjectiveHoverSx("default"),
  };
};
