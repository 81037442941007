import { useContext } from "react";
import { observer } from "mobx-react-lite";
import FilterSearchInput from "@/components/shared/input/FilterSearchInput";
import { StoreContext } from "@/stores";
import { APPOINTMENT_KEYWORD_FILTERS, AppointmentKeywordFilter } from "@/stores/calendar.store";

const FILTER_LABELS: Record<AppointmentKeywordFilter, string> = {
  student: "Student Name/Email",
  provider: "Provider Name/Email",
  facilitator: "Facilitator Name/Email",
  campus: "Campus Name",
  appointmentType: "Session Type",
};

const CalendarHeaderFilterInput = () => {
  const {
    calendarStore: { appointmentKeywordFilters, setAppointmentKeywordFilters },
  } = useContext(StoreContext);

  const properties = APPOINTMENT_KEYWORD_FILTERS.map(filter => ({
    propertyName: FILTER_LABELS[filter],
    searchParamKey: filter,
  }));

  const selectedOptions = appointmentKeywordFilters.map(({ filter, value }) => ({
    filterName: `${FILTER_LABELS[filter]} contains "${value}"`,
    searchParamKey: filter,
    searchParamValue: value,
  }));

  return (
    <FilterSearchInput
      properties={properties}
      selectedOptions={selectedOptions}
      setSelectedOptions={setAppointmentKeywordFilters}
      width={300}
    />
  );
};

export default observer(CalendarHeaderFilterInput);
