import { useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import PrimaryLayout, { SimpleHeaderContent } from "@/components/shared/layout/PrimaryLayout";
import { StoreContext } from "@/stores";

const NotFoundScreen = () => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const content = (
    <FullStack pt={2}>
      <Typography>The page you have requested is not available.</Typography>
      <Link href="/">
        <Typography variant="body1">Back to {currentUser ? "Home" : "Login"}</Typography>
      </Link>
    </FullStack>
  );

  if (!currentUser) return <FullBox p={2}>{content}</FullBox>;

  return <PrimaryLayout headerContent={<SimpleHeaderContent text="Not Found" />}>{content}</PrimaryLayout>;
};

export default NotFoundScreen;
