import { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { ReportEditorSubsection } from "@parallel/vertex/types/report.types";
import ReportEditorBlock from "@/components/report/editor/ReportEditorBlock";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getSubsectionCopyContent } from "@/util/report.content.util";
import { useEditorAutoScroll } from "@/util/report.util";

const logger = initLogger("ReportEditorSubsectionComponent", getLoggerContext);

const ReportEditorSubsectionComponent = ({ subsection }: { subsection: ReportEditorSubsection }) => {
  const {
    authStore: { currentUser },
    reportStore: { upsertCustomSection, setPendingDeleteReportEligibilityId },
  } = useContext(StoreContext);

  const { containerRef } = useEditorAutoScroll(subsection.reportSectionTemplateId);

  const setSectionHidden = (isHidden: boolean) =>
    upsertCustomSection(subsection.reportSectionTemplateId, { isHidden }).catch(
      logger.handleFailureAndThrow("setSectionHidden", { context: { isHidden } }),
    );

  const isHidden = subsection.custom?.isHidden;

  const { eligibilityId } = subsection;

  const controls = (
    <Stack direction="row" gap={1} pr="9px">
      {isHidden ? (
        <ProcessIconButton process={() => setSectionHidden(false)} size="small" loadingOnly>
          <VisibilityIcon fontSize="small" />
        </ProcessIconButton>
      ) : (
        <>
          <ProcessIconButton process={() => setSectionHidden(true)} size="small" loadingOnly>
            <VisibilityOffIcon fontSize="small" />
          </ProcessIconButton>
          {eligibilityId && (
            <IconButton onClick={() => setPendingDeleteReportEligibilityId(eligibilityId)} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          <CopyIconButton
            content={getSubsectionCopyContent(subsection, currentUser)}
            label={`Section ${subsection.title}`}
            logger={logger}
          />
        </>
      )}
    </Stack>
  );

  return (
    <Stack width="100%" gap={1} ref={containerRef}>
      <SplitRow
        left={
          <Typography variant="h3" sx={{ textDecoration: isHidden ? "line-through" : undefined }}>
            {subsection.title}
          </Typography>
        }
        right={controls}
      />

      {!isHidden &&
        subsection.blocks.map(block => <ReportEditorBlock block={block} key={block.reportBlockTemplateId} />)}
    </Stack>
  );
};

export default observer(ReportEditorSubsectionComponent);
