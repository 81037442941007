import { useContext, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import AutoSaveTextField from "@parallel/polygon/components/shared/input/AutoSaveTextField";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { CenterBox } from "@parallel/polygon/components/shared/layout/container";
import { StoreContext } from "@/stores";
import { GroupType, TestGroup } from "@/stores/stimulus.store";

export const TestGroupList = <G extends TestGroup>({ groups, groupType }: { groups: G[]; groupType: GroupType }) => {
  const {
    stimulusStore: { selectRootGroup, updateGroupName, swapGroupOrders, deleteGroup, createGroup },
  } = useContext(StoreContext);

  const swapGroup = (group: G, index: number, delta: number) => {
    const nextIndex = index + delta;
    return swapGroupOrders({ id: group.id, index }, { id: groups[nextIndex].id, index: nextIndex }, groupType);
  };

  const [newGroupName, setNewGroupName] = useState("");

  const submitNewGroup = async () => {
    await createGroup(newGroupName, groupType);
    setNewGroupName("");
  };

  return (
    <Stack gap={2}>
      {groups.map((group, i) => (
        <Stack
          gap={1}
          key={group.id}
          sx={{ p: 1, pt: 2, bgcolor: "white", border: 1, borderColor: "grey.300", borderRadius: 1 }}
        >
          <AutoSaveTextField
            label="Name"
            size="small"
            value={group.name}
            onSave={name => updateGroupName(group.id, groupType, name)}
            saveKey={group.id}
            fullWidth
            statusIcon
          />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <ProcessIconButton size="small" process={() => deleteGroup(group.id, groupType)}>
                <DeleteIcon fontSize="small" />
              </ProcessIconButton>
              <ProcessIconButton size="small" process={() => swapGroup(group, i, -1)} disabled={i === 0}>
                <ArrowUpwardIcon fontSize="small" />
              </ProcessIconButton>
              <ProcessIconButton size="small" process={() => swapGroup(group, i, 1)} disabled={i === groups.length - 1}>
                <ArrowDownwardIcon fontSize="small" />
              </ProcessIconButton>
            </Stack>
            <IconButton size="small" onClick={() => selectRootGroup(group.id, groupType)}>
              <KeyboardDoubleArrowRightIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      ))}
      <Stack direction="row" gap={1}>
        <TextField
          label={`New ${capitalize(groupType)} Group Name`}
          size="small"
          value={newGroupName}
          onChange={e => setNewGroupName(e.target.value)}
          fullWidth
          sx={{ bgcolor: "white" }}
        />
        <CenterBox sx={{ flexShrink: 0 }}>
          <ProcessIconButton size="small" process={submitNewGroup} disabled={!newGroupName}>
            <SaveIcon fontSize="small" />
          </ProcessIconButton>
        </CenterBox>
      </Stack>
    </Stack>
  );
};

export default observer(TestGroupList);
